<template>
  <div>
    <div  v-if="permissions.GET">
            <el-collapse v-model="retrieveActiveNames">
              <el-collapse-item title="检索" name="1">
                <template slot="title">
                  检索<i class="header-icon el-icon-info"></i>
                </template>
            <el-form label-width="90px">
              <el-row>
                <el-col :span="7">
                  <el-form-item label="规则编号">
                    <el-input autocomplete="off" type="text" v-model="pm.pm.riskNum"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="7">
                  <el-form-item label="规则名称">
                    <el-input autocomplete="off" type="text" v-model="pm.pm.riskName"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="7">
                  <el-form-item label="风控状态">
                    <el-select v-model="pm.pm.status" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.risk_status"
                          :key="item.k"
                          :label="item.k"
                          :value="item.v"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>


              </el-row>
            </el-form>
            </el-collapse-item>
            </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="规则编号" prop="riskNum" > </el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="规则名称" prop="riskName" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="规则类型" prop="riskType" ></el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="风控级别" prop="riskLevel" ></el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="参数数量" prop="paramnum" ></el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="风控状态" prop="status" >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status==1" type="success">{{ getDICKV(scope.row.status, 'risk_status') }}</el-tag>
            <el-tag v-else type="danger">{{ getDICKV(scope.row.status, 'risk_status') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="备注" prop="remark" ></el-table-column>
        <el-table-column width="90" show-overflow-tooltip label="添加人" prop="addMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="添加时间" prop="addTime" ></el-table-column>
        <el-table-column width="90" show-overflow-tooltip label="修改人" prop="updateMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="修改时间" prop="updateTime" ></el-table-column>
        <el-table-column label="操作" fixed="right" width="80" v-if="permissions.PUT||permissions.DELETE" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>
<!--            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
            :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="规则编号" prop="riskNum">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.riskNum" placeholder="规则编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="规则名称" prop="riskName">
              <el-input autocomplete="off" type="text" v-model="rowData.riskName" placeholder="请输入规则名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="规则类型" prop="riskType">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.riskType" placeholder="请输入规则类型"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="风控级别" prop="riskLevel">
              <el-input autocomplete="off" type="text" oninput="value=value.replace(/[^\d]/g,'')" v-model="rowData.riskLevel" placeholder="请输入风控级别"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="参数数量" prop="paramnum">
              <el-input autocomplete="off" type="text" disabled oninput="value=value.replace(/[^\d]/g,'')" v-model="rowData.paramnum" placeholder="请输入参数数量"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="风控状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.risk_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="备注" prop="remark">
              <el-input autocomplete="off" type="text" v-model="rowData.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="规则名称" prop="riskName">
              <el-input autocomplete="off" type="text" v-model="rowData.riskName" placeholder="请输入规则名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="规则类型" prop="riskType">
              <el-input autocomplete="off" type="text" v-model="rowData.riskType" placeholder="请输入规则类型"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="风控级别" prop="riskLevel">
              <el-input autocomplete="off"   oninput="value=value.replace(/[^\d]/g,'')" v-model="rowData.riskLevel" placeholder="请输入风控级别"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="参数数量" prop="paramnum">
              <el-input autocomplete="off"   oninput="value=value.replace(/[^\d]/g,'')" v-model="rowData.paramnum" placeholder="请输入参数数量"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="风控状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.risk_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="备注" prop="remark">
              <el-input autocomplete="off" type="text" v-model="rowData.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      DICKV:{},
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {

        riskName: [{required: true, message: '请输入规则名称', trigger: 'blur'}],

        riskType: [{required: true, message: '请输入规则类型', trigger: 'blur'}],

        riskLevel: [{required: true, message: '请输入风控级别', trigger: 'blur'}],

        paramnum: [{required: true, message: '请输入参数数量', trigger: 'blur'}],

        status: [{required: true, message: '请输入风控状态0:开启校验 1:关闭校验', trigger: 'blur'}],

        remark: [{required: true, message: '请输入备注', trigger: 'blur'}],

        addMan: [{required: true, message: '请输入添加人', trigger: 'blur'}],

        addTime: [{required: true, message: '请输入添加时间', trigger: 'blur'}],

        updateMan: [{required: true, message: '请输入修改人', trigger: 'blur'}],

        updateTime: [{required: true, message: '请输入修改时间', trigger: 'blur'}],

        }
    }
  },created(){
    this.DICKV = this.$store.state.DICKV;
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/risk', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/risk', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.rowData = {}
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/risk', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/risk", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>

</style>
