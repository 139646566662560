<template>
  <div
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-row :gutter="20" style="margin-top: 20px" >
      <el-col :span="6">
        <el-card shadow="always" style="height: 100px;border-radius: 20px" hover-row  @click.native="customerExcel()">
          <el-col :span="18" >
            <el-row style="font-size:20px">商户号-客户经理.csv</el-row>
          </el-col>
          <el-col :span="4" >
            <svg class="icon" aria-hidden="true" style="font-size:60px">
              <use xlink:href="#icon-csv2"></use>
            </svg>
          </el-col>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always" style="height: 100px;border-radius: 20px"  @click.native="audioExcel()">
          <el-col :span="18" >
            <el-row style="font-size:20px">音响终端号-商户号-渠道商户号.csv</el-row>
          </el-col>
          <el-col :span="4" >
            <svg class="icon" aria-hidden="true" style="font-size:60px">
              <use xlink:href="#icon-csv2"></use>
            </svg>
          </el-col>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<style>
.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>

<script>
import fileDownload from "js-file-download";

export default {
  data() {
    return {
      loading:false,
      permissions: {}
    };
  },mounted() {
    this.permissions = this.$store.state.permissions;
  },
  methods:{
    customerExcel() {
      this.$confirm('确认下载？').then(() => {
            this.loading = true
            this.getRequestBlob('/sys/dataexport/customerExport').then(data => {
              if (!data) {
                return
              }
              fileDownload(data, '商户号-客户经理.csv')
              this.loading = false
            })
          })
      .catch(() => {
      });
    },

    audioExcel() {
      this.$confirm('确认下载？').then(() => {
        this.loading = true
        this.getRequestBlob('/sys/dataexport/audioExport').then(data => {
          if (!data) {
            return
          }
          fileDownload(data, '音响终端号-商户号-渠道商户号.csv')
          this.loading = false
        })
      })
          .catch(() => {
          });
    },
    }

}
</script>
