<template>
  <div class="MerTmpTerm">
    <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%">
      <el-table-column width="50" show-overflow-tooltip label="ID" prop="id"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="终端编号" prop="termCode"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="终端类型" prop="terType">
        <template slot-scope="scope">
          {{ getDICKV(scope.row.terType, 'term_type') }}
        </template>
      </el-table-column>
      <el-table-column width="50" show-overflow-tooltip label="终端状态" prop="status">
        <template slot-scope="scope">
          {{ getDICKV(scope.row.status, 'product_status') }}
        </template>
      </el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="添加人" prop="addMan"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="添加时间" prop="addTime"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="修改时间" prop="lastTime"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="修改人" prop="lastMan"></el-table-column>

      <el-table-column  label="操作" fixed="right" width="100" v-if="(permissions.PUT||permissions.GET) && resDate.htmlFromType!='affirm'">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="permissions.PUT" disabled @click="showEditTermView(scope.row)">解绑</el-button>
          <el-button type="success" size="mini" v-if="permissions.GET && scope.row.terType == 1" @click="show_qrcode(scope.row)">码牌查看</el-button>

        </template>
      </el-table-column>
    </el-table>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="sppm.page.page" :page-sizes="[10, 50, 100, 200]"
                       :page-size="sppm.page.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="sppm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>
      <div v-if="resDate.htmlFromType!='affirm'" class="block" style="align-self:flex-end">
        <el-button icon="el-icon-plus" v-if="permissions.POST" @click="showAddTermView()" type="primary" size="mini">绑定终端</el-button>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加终端" :visible.sync="addTermDialog" width="50%" append-to-body>
      <div style="text-align: center">
        <el-form  label-width="80px">
          <el-form-item label="终端类型" prop="businessFlag">
            <el-select v-model="terType" placeholder="请选择" @change="initTerTransFer()">
              <el-option
                  v-for="item in DICKV.term_type"
                  :key="item.k"
                  :label="item.k"
                  :value="Number.parseInt(item.v)"
                  :disabled="item.disabled=='1'?false:true" >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-transfer       style="text-align: left; display: inline-block"
             filterable
            filter-placeholder="请输入终端编号"
            v-model="termvalue"
            :titles="['终端列表', '绑定终端']"
            :props="{
                key: 'termCode',
                label: 'termCode',
                disabled: 'status'=='2'
              }"
            :button-texts="['取消终端', '添加终端']"
            :data="termdata">
        </el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="close()">取 消</el-button>
          <el-button type="primary" :disabled="termvalue.length==0" @click="terSave()">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog width="30%" :show-close="false" :center="true" title="查看码牌" @close="closeCode" :visible.sync="qrCodeDialog" append-to-body>
      <div class="demo-image__placeholder">
        <div class="block">
          <span class="demonstration"></span>
          <el-image :src="qrcodeurl">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "MerTmpTerm",
  data() {
    return {
      loading:false,
      permissions: {},
      DICKV:{},
      datas:[],
      rowData:{},
      addDialog:false,
      editDialog:false,
      agencyNo:'',
      rules: {

      },
      sppm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 10,
          pages: 1,
          total: 0
        }
      },
      addTermDialog:false,
      eduitTermDialog:false,

      termdata:[],
      termvalue:[],

      resDate:{},
      terType:0,

      //查看码牌
      qrCodeDialog:false,
      qrcodeurl:''
    }
  },mounted(){
    this.DICKV=this.$store.state.DICKV;
    this.permissions = this.$store.state.permissions;
    this.mount()
  },
  methods : {
    mount(){
      this.$on('init',(res)=>{
        this.resDate=res
        this.init()
      })
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = parseInt(this.DICKV[key][i].v); //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    initTerTransFer(){
        this.getRequest('/sys/mertmp/merAllTerm', {expandMan:this.resDate.expandMan,agencyNo:this.resDate.agencyNo,terType:this.terType}).then(data => {
          if (data.status == 200) {
            this.termvalue=[]
            this.termdata=data.obj
          } else {
            this.termvalue=[]
            this.termdata=[]
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
    },
    /*门店绑定终端--保存*/
    terSave(){
      let term={terType:this.terType,relationId:this.resDate.relationId,branchNo:this.resDate.branchNo,termArray:this.termvalue}
      console.log(term)
      this.postRequest('/sys/mertmp/merTerm', term).then(data => {
        if (data.status == 200) {
          this.addTermDialog=false
          Message.success("添加成功")
          this.init();
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    init() {
      this.sppm.pm.branchNo=this.resDate.branchNo//门店编号
      this.sppm.pm.relationId=this.resDate.relationId//商户编号
      this.getRequest('/sys/mertmp/merTerm', this.sppm).then(data => {
        if (data.status == 200) {
          this.datas = data.obj.list
          this.sppm.page = data.obj.page
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    handleSizeChange(val) {
      this.sppm.page.pageNum = 1
      this.sppm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.sppm.page.pageNum = val
      this.init()
    },
    showAddTermView(){
      this.addTermDialog=true;
      this.initTerTransFer();
    },
    submitAddTermForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.rowData.relationId=this.sppm.pm.relationId
          this.postRequest('/sys/mertmp/merTerm', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addTermDialog = false
              this.rowData={}
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
    show_qrcode (row) {
      //控制dialog
      this.qrCodeDialog = true
      this.getRequestBlob('/sys/qrcode', {termCode:row.termCode,terType:row.terType}).then(data => {
        this.loading = false
        this.qrcodeurl = window.URL.createObjectURL(data);
      })

      // //控制dialog
      // this.qrCodeDialog = true
      // this.$nextTick(() => {
      //   this.$refs.merqrcode.$emit('createQrcode', row)
      // })

    },
    // 关闭弹框,清除已经生成的二维码
    closeCode () {
      // 清除生成的二维码，每次打开后重新生成
      //注意这里的$ref，html部分也要加ref
      // this.$refs.merqrcode.innerHTML = ''
      this.qrCodeDialog = false
    },
    showEditTermView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.eduitTermDialog = true
    },
    submitEditTermForm() {
      this.rowData.relationId=this.sppm.pm.relationId;
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/mertmp/merTerm', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.eduitTermDialog = false
              this.rowData={}
              this.init()
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.eduitTermDialog = false
            this.addTermDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    },
  },
}
</script>

<style >

</style>
