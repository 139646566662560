<template>
  <div class="navMenu">

    <template v-for="navMenu in navMenus">
      <!-- 最后一级菜单 -->
      <el-menu-item v-if="!navMenu.children.length==0&&navMenu.parentId==0" :key="navMenu.id" :data="navMenu" :index="navMenu.name" >
        <i :class="navMenu.iconCls"></i>
        <span slot="title">{{navMenu.name}}</span>
      </el-menu-item>

      <!-- 此菜单下还有子菜单 -->
      <el-submenu v-if="navMenu.children.length>0&&navMenu.parentId>0"
                  :key="navMenu.id" :data="navMenu" :index="navMenu.name">
        <template slot="title">
          <i :class="navMenu.iconCls"></i>
          <span> {{navMenu.alias}}</span>
        </template>
        <!-- 递归 -->
        <NavMenu :navMenus="navMenu.children"></NavMenu>
      </el-submenu>
    </template>

  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    navMenus: Array
  },
  components: {

  },
  methods: {}
}
</script>

<style>
</style>
