<template>
  <div class="MerTmpRate">
    <el-table :data="rmenus" row-key="id" border :tree-props="{children: 'children',hasChildren:'hasChildren'}" >
      <el-table-column width="150" prop="sysTradeTypeId" label="产品类型"></el-table-column>
      <el-table-column prop="sysTradeTypeDescribe" label="交易描述"></el-table-column>
      <el-table-column prop="productStatus" label="状态">
        <template slot-scope="scope" >
          {{ getDICKV(scope.row.productStatus, 'product_status') }}
        </template>
      </el-table-column>
      <el-table-column width="80" label="隐藏状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="!scope.row.hidden">不隐藏</el-tag>
          <el-tag type="danger" v-else>隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.level!=0"
                     size="mini" type="primary" @click="showEditProRate(scope.row)">编辑 </el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog :close-on-click-modal="false"
               v-loading="merproRateloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="closeMerRateDialog" title="" :visible.sync="merRateDialog"
               width="37%" append-to-body>
      <template slot='title'>
        <span style="font-size:20px;">费率信息配置--{{merRate.sysTradeTypeDescribe}}</span>
      </template>
      <el-form :model="merRate" :rules="merRateRules" ref="merRateForm" label-width="180px">
        <el-row>
          <el-col :span="15">
            <el-form-item prop="feeRate" label="借记卡费率(‰)">
              <el-input-number size="medium" v-model="merRate.feeRate" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
            </el-form-item>
            <el-form-item prop="creditRate" label="贷记卡费率(‰)">
              <el-input-number size="medium" v-model="merRate.creditRate" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
            </el-form-item>
          </el-col>
          <el-col :span="15" v-if="merRate.sysTradeTypeId=='JSP534'">
            <el-form-item prop="feePen" label="元/笔" >
              <el-input-number size="medium" v-model="merRate.feePen" :precision="0" :step="1" :min="0" :max="100"/>
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item prop="lowLimit" label="借记单笔保底">
              <el-input-number size="medium" v-model="merRate.lowLimit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
            </el-form-item>
            <el-form-item prop="topLimit" label="借记单笔封顶">
              <el-input-number size="medium" v-model="merRate.topLimit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item label="贷记单笔保底" prop="lowLimitCredit">
              <el-input-number size="medium" v-model="merRate.lowLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
            </el-form-item>
            <el-form-item label="贷记单笔封顶" prop="topLimitCredit">
              <el-input-number size="medium" v-model="merRate.topLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
            </el-form-item>
          </el-col>
          <el-col :span="15" >
            <el-form-item prop="feeCycle" label="收费周期">
              <el-select  v-model="merRate.feeCycle"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.fee_cycle"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <template>
                <el-button type="primary"  @click="submitMerRateForm">提交</el-button>
              </template>
              <el-button @click="closeMerRateDialog()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "MerTmpRate",
  data(){
    return{
      rateloading:false,
      DICKV:{},
      permissions:{},
      tableRadio:'',
      rmenus:[],
      merRateDialog:false,
      merproRateloading:false,
      merRate:{},
      merRateRules:{
        feeRate:[{required: true, message: '必填', trigger: 'blur'}],
        creditRate:[{required: true, message: '必填', trigger: 'blur'}],
        feePen:[{required: true, message: '必填', trigger: 'blur'}],
        feeCycle:[{required: true, message: '必填', trigger: 'blur'}],
        lowLimit:[{required: true, message: '必填', trigger: 'blur'}],
        topLimit:[{required: true, message: '必填', trigger: 'blur'}],
        lowLimitCredit:[{required: true, message: '必填', trigger: 'blur'}],
        topLimitCredit:[{required: true, message: '必填', trigger: 'blur'}]
      }
    }
  },
  mounted() {
    this.DICKV=this.$store.state.DICKV;
    this.permissions = this.$store.state.permissions;
    this.mount()
  },
  methods:{
    mount(){
      this.$on('initR',(res)=>{
        this.tableRadio=res
        this.initR()
      })
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = parseInt(this.DICKV[key][i].v); //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    initR(){
      if (this.permissions.GET == 1) {
        this.rateloading = true
        this.getRequest("/sys/mertmp/merRateMenu", this.tableRadio).then(data => {
          if (data) {
            this.rmenus = data.obj
            this.rateloading = false
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
    },
    showEditProRate(row){
      this.merRateDialog=true
      this.merRate=row
      this.merRate.relationId=this.tableRadio.id
    },
    closeMerRateDialog(){
      this.$confirm('确认取消？')
          .then(() => {
            this.merRateDialog=false
          })
          .catch(() => {
          });
    },
    submitMerRateForm(){
      let Rate=this.merRate
      if (Rate.lowLimit>Rate.topLimit){
        Message.error("借记区间设置错误："+Rate.lowLimit+"--"+Rate.topLimit)
        return false;
      }
      if (Rate.lowLimitCredit>Rate.topLimitCredit){
        Message.error("贷记区间设置错误："+Rate.lowLimitCredit+"--"+Rate.topLimitCredit)
        return false;
      }
      this.$refs.merRateForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/mertmp/merRate', this.merRate).then(data => {
            if (data.status == 200) {
              Message.success("更新成功")
              this.merRateDialog=false
              this.merRate={}
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
