<template xmlns:span="http://www.w3.org/1999/xlink">
  <div>
    <div class="headerTool">
      <el-button v-if="permissions.POST" type="primary" @click="showAddView({id:0})">添加顶层</el-button>

      <el-button type="success" icon="el-icon-refresh" @click="init">刷新</el-button>

    </div>
    <div style="margin-top: 10px;">
      <el-table
          v-loading="loading"
          :data="menus"
          row-key="id"
          border
          :tree-props="{children: 'children',hasChildren:'hasChildren'}">
        <el-table-column width="150" prop="name" label="菜单名"></el-table-column>
        <el-table-column width="50" prop="id" label="id"></el-table-column>
        <el-table-column prop="path" label="访问路径"></el-table-column>
        <el-table-column prop="component" label="组件路径"></el-table-column>
        <el-table-column width="180" prop="iconCls" label="图标class"></el-table-column>
        <el-table-column width="80" label="启用状态">

        </el-table-column>
        <el-table-column width="50" prop="orderNum" label="顺序"></el-table-column>
        <el-table-column width="80" label="隐藏状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="!scope.row.hidden">不隐藏</el-tag>
            <el-tag type="danger" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button v-if="permissions.GETbutton" type="primary" size="mini" class="depBtn"
                       @click="showButtonView(scope.row)">按钮
            </el-button>
            <el-button v-if="permissions.PUT" size="mini" @click="showEditView(scope.row)">编辑</el-button>
            <el-button v-if="permissions.POST&&scope.row.parentId==0" size="mini" type="success"
                       @click="showAddView(scope.row)">添加
            </el-button>
            <el-button v-if="permissions.DELETE&&scope.row.children.length<1" size="mini" type="danger"
                       @click="deleteRow(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
          :title="editDialog.title"
          :visible.sync="editDialog.show"
          :close-on-click-modal="false"
          width="50%">
        <el-form :model="emp" :rules="rules" ref="editForm" label-width="180px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="id" prop="id">
                <el-input autocomplete="off" type="number" property="true" v-model="emp.id" placeholder="请输入菜单id"
                          disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="菜单名" prop="name">
                <el-input autocomplete="off" type="text" v-model="emp.name" placeholder="请输入菜单名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="访问路径" prop="path">
                <el-input autocomplete="off" type="text" v-model="emp.path" placeholder="请输入访问路径"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="组件路径" prop="component">
                <el-input autocomplete="off" type="text" v-model="emp.component" placeholder="请输入组件路径"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="图标" prop="iconCls">
                <el-input autocomplete="off" type="text" v-model="emp.iconCls" placeholder="请输入图标class"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="启用状态" prop="enabled">
                <!--              <el-input autocomplete="off" type="boolean" v-model="emp.enabled" placeholder="是否启用"></el-input>-->
                <el-switch :active-value="1" :inactive-value="0" v-model="emp.enabled"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="父级id" prop="parentId">
                <el-input disabled autocomplete="off" type="number" v-model="emp.parentId"
                          placeholder="请输入父级id"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="菜单顺序" prop="orderNum">
                <el-input autocomplete="off" type="number" v-model="emp.orderNum" placeholder="请输入菜单顺序"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="隐藏状态" prop="hidden">
                <el-switch :active-value="1" :inactive-value="0" v-model="emp.hidden"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button type="primary" @click="submitEditForm">提交</el-button>
                <!--              <el-button @click="resetEditForm">重置</el-button>-->
                <el-button @click="close()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
      <el-dialog
          :title="addDialog.title"
          :visible.sync="addDialog.show"
          :close-on-click-modal="false"
          width="50%">
        <el-form :model="emp" :rules="rules" ref="editForm" label-width="180px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="菜单名" prop="name">
                <el-input autocomplete="off" type="text" v-model="emp.name" placeholder="请输入菜单名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="访问路径" prop="path">
                <el-input autocomplete="off" type="text" v-model="emp.path" placeholder="请输入访问路径"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="组件路径" prop="component">
                <el-input autocomplete="off" type="text" v-model="emp.component" placeholder="请输入组件路径"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="图标" prop="iconCls">
                <el-input autocomplete="off" type="text" v-model="emp.iconCls" placeholder="请输入图标class"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="启用状态" prop="enabled">
                <!--              <el-input autocomplete="off" type="boolean" v-model="emp.enabled" placeholder="是否启用"></el-input>-->
                <el-switch :active-value="1" :inactive-value="0" v-model="emp.enabled"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="父级id" prop="parentId">
                <el-input autocomplete="off" type="number" v-model="emp.parentId" placeholder="请输入父级id"
                          disabled></el-input>
                <el-input autocomplete="off" type="text" v-model="addDialog.parentName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="菜单顺序" prop="orderNum">
                <el-input autocomplete="off" type="number" v-model="emp.orderNum" placeholder="请输入菜单顺序"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="隐藏状态" prop="hidden">
                <el-switch :active-value="1" :inactive-value="0" v-model="emp.hidden"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button type="primary" @click="submitAddForm">提交</el-button>
                <el-button @click="close()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
      <el-dialog
          title="按钮配置"
          :visible.sync="buttonDialogVisible"
          width="50%"
          :close-on-click-modal="false"
      >
        <MerButtonView :emp="emp" ref="buttonView"/>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {Message} from "element-ui";
import MerButtonView from "@/views/sys/merMenu/MerButtonView";

export default {
  name: "index",
  components: {MerButtonView},
  data() {
    return {
      permissions: {},
      loading: false,
      menus: [],
      editDialog: {
        title: "编辑菜单",
        show: false,
      }, addDialog: {
        title: "添加菜单",
        show: false,
        parentName: ''
      },
      buttonDialogVisible: false,
      emp: {
        id: '',
        name: '',
        path: '',
        component: '',
        iconCls: '',
        enabled: '',
        parentId: '',
        orderNum: '',
        hidden: ''
      }, parentButtons: [],
      rules: {
        id: [{required: true, message: '', trigger: 'blur'}],
        name: [{required: true, message: '', trigger: 'blur'}],
        path: [{required: true, message: '', trigger: 'blur'}],
        component: [{required: true, message: '', trigger: 'blur'}],
        iconCls: [{required: false, message: '', trigger: 'blur'}],
        enabled: [{required: true, message: '', trigger: 'blur'}],
        parentId: [{required: true, message: '', trigger: 'blur'}],
        orderNum: [{required: true, message: '', trigger: 'blur'}],
        hidden: [{required: true, message: '', trigger: 'blur'}]
      }
    }
  },
  mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    init() {
      if (this.permissions.GET == 1) {
        this.loading = true
        this.getRequest("/sys/merMenu/webMenu").then(data => {
          if (data) {
            this.menus = data.obj
            this.loading = false
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
    },
    showButtonView(rowdata) {
      this.emp = rowdata
      this.buttonDialogVisible = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.buttonView.init(this.emp.id);
        }, 100)
      })

    },
    showEditView(rowdata) {
      this.emp = rowdata
      this.editDialog.show = true
    },
    submitEditForm() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          this.putRequest("/sys/merMenu", this.emp).then(data => {
            if (data.status == 200) {
              Message.success("成功")
              this.emp = {};
              this.init()
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false
        }
        this.editDialog.show = false

      })
    }, showAddView(rowdata) {
      this.emp = {};
      this.emp.parentId = rowdata.id
      this.emp.enabled = 1
      this.emp.hidden = 0
      this.addDialog.parentName = rowdata.name
      this.addDialog.show = true

    },
    submitAddForm() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          this.postRequest("/sys/merMenu", this.emp).then(data => {
            if (data.status == 200) {
              Message.success("成功")
              this.emp = {};
              this.addDialog.show = false
              this.init()
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false
        }
      })
    }, deleteRow(rowdata) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/merMenu", {id: rowdata.id}).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init()
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog.show = false
            this.addDialog.show = false
            this.emp = {}
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>
.headerTool {
  display: flex;
  justify-content: flex-start;
}

.headerTool {
  margin-top: 10px;
  width: 700px;
}
</style>
