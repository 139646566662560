<template>
  <div>
    <div class="headerTool">
      <el-button v-if="permissions.POST" type="primary" @click="showUploadDialog({filePath:'/'})">上传文件</el-button>
      <el-button v-if="permissions.POSTmkdir" type="primary" @click="showMkdirDialog({filePath:'/'})">创建</el-button>
      <el-button v-if="permissions.GET" type="success" icon="el-icon-refresh" @click="init">刷新</el-button>
    </div>
    <div style="margin-top: 10px;">
      <el-table
          v-loading="loading"
          :data="datas"
          row-key="id"
          border
          :tree-props="{children: 'child',hasChildren:'hasChildren'}"
      >
        <el-table-column width="300" prop="fileName" label="文件名"></el-table-column>
        <el-table-column width="70" prop="isFile" label="类型">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.isFile==0">目录</el-tag>
            <el-tag type="success" v-else>文件</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="" prop="fileType" label="文件后缀"></el-table-column>
        <el-table-column width="" :formatter="formatterByte" prop="fileSize" label="文件大小"></el-table-column>
        <el-table-column width="" prop="filePath" label="文件相对路径"></el-table-column>
<!--        <el-table-column width="50" prop="id" label="id"></el-table-column>-->

        <el-table-column v-if="permissions.DELETE||permissions.GETdownload||permissions.POSTmkdir||permissions.POST"
                         width="210" label="操作">
          <template slot-scope="scope">
            <el-button v-if="permissions.POST&&scope.row.isFile==0" type="primary" size="mini"
                       @click="showUploadDialog({filePath:scope.row.filePath})">上传
            </el-button>
            <el-button v-if="permissions.POSTmkdir&&scope.row.isFile==0" type="primary" size="mini"
                       @click="showMkdirDialog({filePath:scope.row.filePath})">创建
            </el-button>
            <el-button size="mini" v-if="permissions.DELETE&&scope.row.child.length<1" type="danger"
                       @click="deleteRow(scope.row)">删除
            </el-button>
            <el-button size="mini" v-if="permissions.GETdownload&&scope.row.isFile!=0" type="success"
                       @click="download(scope.row)">下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        title="创建目录"
        :visible.sync="mkdirDialog"
        :close-on-click-modal="false"
        width="30%"
    >
      <el-form :model="file" :rules="rules" ref="mkdirForm" label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="上级目录" prop="filePath">
              <el-input autocomplete="off" disabled type="text" property="true" v-model="file.filePath"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="新目录" prop="newPath">
              <el-input autocomplete="off" type="text" property="true" v-model="file.newPath"
                        placeholder="请输入新目录名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitmkdirForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
        @close="init"
        title="上传"
        :visible.sync="uploadDialog"
        :close-on-click-modal="false"
        width="400px"
    >
      <div>
        <!--        :on-success="uploadSuccess"-->
        <el-upload
            class="upload-demo"
            ref="upload"
            drag
            action="/api/sys/fileStorage"
            :on-error="uploadError"
            :file-list="upload.fileList"
            :headers="upload.header"
            :limit="upload.limit"
            :multiple="upload.multiple"
            :auto-upload="false"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :data="upload.uploadPm"
            :name="upload.partName"
            :accept="upload.accept"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            <span v-if="upload.limit">最多选择:{{ upload.limit }}个文件<br></span>
            <span v-if="upload.maxFileSize">单文件最大:{{ formatterForByte(upload.maxFileSize) }}<br></span>
            <span v-if="upload.accept">支持格式：{{ upload.accept }}<br></span>
          </div>
        </el-upload>
      </div>
      <div style="margin-top: 10px; " align="right">
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="下载中..."
        :visible.sync="downloadingDialog"
        :close-on-click-modal="false"
        width="400px">
      <div style="display: block;">
        <span>下载中</span>
        <img style="margin-left: 10px;" width="30px" height="30px" src="/static/images/loading.gif"/>
<!--        <img style="margin-left: 10px;" width="30px" height="30px" src="@/assets/images/loading.gif"/>-->
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {Message} from "element-ui";
import fileDownload from "js-file-download";

export default {
  name: "Index",
  data() {
    return {
      loading: false,
      permissions: {},
      uploadDialog: false,
      mkdirDialog: false,
      downloadingDialog: false,
      upload: {
        fileList: [],
        header: {
          'Authorization': window.sessionStorage.getItem("authorization"),
        },
        partName: 'file',
        uploadPm: {},
        limit: 10,
        maxFileSize: 1024 * 1024 * 100,
        accept: '',
        multiple: true
      },
      file: {},
      datas: [],
      rules: {
        newPath: [{required: true, message: '新目录不可为空', trigger: 'blur'}]
      }

    }
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest("/sys/fileStorage").then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    // formatterByte(row,column){
    formatterByte(row) {
      let size = row.fileSize
      return this.formatterForByte(size)

    },
    deleteRow(rowdata) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/fileStorage", {filePath: rowdata.filePath}).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init()
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    download(rowdata) {
      this.downloadingDialog = true
      this.getRequestBlob("/sys/fileStorage/download", {filePath: rowdata.filePath}).then(data => {
        if (!data) {
          return
        }
        fileDownload(data, rowdata.fileName)
        // let url = window.URL.createObjectURL(new Blob([data]))
        // let link = document.createElement('a');
        // link.style.display = 'none';
        // link.href = url
        // link.setAttribute('download', rowdata.fileName)
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
        this.downloadingDialog = false
      })
    },
    showUploadDialog(pm) {
      this.upload.fileList = []
      this.upload.uploadPm.filePath = pm.filePath
      this.uploadDialog = true
    },
    showMkdirDialog(pm) {
      this.file = {}
      this.file.filePath = pm.filePath
      this.mkdirDialog = true;
    },
    submitmkdirForm() {
      this.$refs.mkdirForm.validate((valid) => {
        if (valid) {
          this.postRequest("/sys/fileStorage/mkdir", this.file).then(data => {
            if (data.status == 200) {
              Message.success("成功")
              this.file = {};
              this.mkdirDialog = false
              this.init()
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false
        }
      })
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    // uploadSuccess(response, file, fileList) {
    //   Message.success("文件上传成功")
    //
    // },
    beforeUpload(file) {
      if (file.size > this.upload.maxFileSize) {
        this.timer = window.setTimeout(() => {
          const h = this.$createElement
          this.$notify.error({
            title: '无法上传',
            message: h('i', {style: 'color: teal'}, file.name + ' 文件大于' + this.formatterForByte(this.upload.maxFileSize))
          })
        }, 0)
        return false
      }
      return true
    },
    uploadError(err, file) {
      Message.error(file.name + "上传失败" + err)
    },
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 ${this.limit}个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    close() {
      this.file = {}
      this.mkdirDialog = false
    },
    formatterForByte(size) {
      if (size < 1024) {
        return size + ' B'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' KB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' MB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' GB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' TB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' PB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' EB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' ZB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' YB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' BB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' NB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(2) + ' DB'
      }
      size = size / 1024
      return size.toFixed(2) + ' CB'
    }
  }
}
</script>

<style scoped>
img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
</style>
