<template>
  <div class="MerTmpProC">
    <el-table v-loading="proCtrlloading" :data="pmenus" row-key="id" border :tree-props="{children: 'children',hasChildren:'hasChildren'}" >
      <el-table-column width="150" prop="sysTradeTypeId" label="产品类型"></el-table-column>
      <el-table-column prop="sysTradeTypeDescribe" label="交易描述"></el-table-column>
      <el-table-column prop="productStatus" label="状态">
        <template slot-scope="scope" >
          {{ getDICKV(scope.row.productStatus, 'product_status') }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button v-if="(scope.row.children.length<1 && scope.row.fatherMap && scope.row.id)  && scope.row.level!=0"
                     size="mini" v-bind:type="tableRadio.htmlFromType=='affirm'?'success':'primary'" @click="showEditProCtrl(scope.row)" v-text="tableRadio.htmlFromType=='affirm'?'查看':'编辑'"> </el-button>
        </template>
      </el-table-column>

    </el-table>

    <el-dialog :close-on-click-modal="false"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="closeDialog" title="" :visible.sync="merProCtrlDialog"
               width="37%" append-to-body>
      <template slot='title'>
        <span style="font-size:20px;">产品开关控制--{{merProCtrl.sysTradeTypeDescribe}}</span>
      </template>
      <el-form :model="merProCtrl" :rules="merProrules" ref="merProCtrlForm" label-width="180px">
        <el-row >
          <el-col :span="15" >
            <el-form-item label="信用卡支付" >
              <el-form-item prop="limitPay">
                <el-select  v-model="merProCtrl.limitPay" :disabled="merProCtrl.htmlFromType=='affirm'" placeholder="请选择">
                  <el-option
                      v-for="item in DICKV.limit_pay"
                      :key="item.k"
                      :label="item.k"
                      :value="Number.parseInt(item.v)"
                      :disabled="item.disabled=='1'?false:true">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item  label="交易开通状态" prop="productStatus">
              <el-select  v-model="merProCtrl.productStatus" :disabled="merProCtrl.htmlFromType=='affirm'" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="merProCtrl.htmlFromType!='affirm'">
          <el-row >
            <el-col :span="20" >
              <el-form-item >
                <template v-if="merProCtrl.fatherMap">
                  <el-button type="primary"  @click="submitMerProCtrlForm">确认</el-button>
                </template>
                <template v-else>
                  <el-button type="primary" disabled @click="submitMerProCtrlForm">确认</el-button>
                </template>
                <el-button @click="closeDialog()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "MerTmpProC",
  data() {
    return {
      permissions:{},
      DICKV:{},
      protypeloading:false,
      protypeDialog:false,
      proCtrlloading:false,
      pmenus:[],
      merProCtrlDialog:false,
      merProCtrl:{},
      merProrules:{
        limitPay: [{required: true, message: '必填', trigger: 'blur'}],
        productStatus: [{required: true, message: '必填', trigger: 'blur'}]
      },
      closeMerProCtrlDialog:false,
      tableRadio:'',
    }
  },mounted() {
    this.DICKV=this.$store.state.DICKV;
    this.permissions = this.$store.state.permissions;
    this.mount()
  },methods:{
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    mount(){
      this.$on('initProC',(res)=>{
        this.tableRadio=res
        this.initProC()
      })
    },
    initProC() {
      if (this.permissions.GET == 1) {
        this.protypeloading = true
        this.getRequest("/sys/mertmp/proTypeMenu", this.tableRadio).then(data => {
          if (data) {
            this.pmenus = data.obj
          }
          this.protypeDialog=false;
          this.protypeloading = false
          this.proCtrlloading=false
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }

    },
    submitMerProCtrlForm(){
      this.$refs.merProCtrlForm.validate((valid) => {
        if (valid) {
          this.postRequest("/sys/mertmp/merProCtrl", this.merProCtrl).then(resp=> {
            let data = resp.data;
            if (resp && resp.status == 200) {
              Message.success("成功")

            }else{
              Message.error({message: data.msg + '(' + data.status + ')'})
              return false;
            }
          });
          this.merProCtrlDialog=false
        } else {
          return false;
        }
      })

    },
    showEditProCtrl(row){
      this.merProCtrlDialog=true
      this.merProCtrl=row
      this.merProCtrl.htmlFromType=this.tableRadio.htmlFromType
      console.log(this.merProCtrl)
    },
    closeDialog(){
      if(this.tableRadio.htmlFromType=='affirm'){
        this.merProCtrlDialog=false
      }else{
        this.$confirm('确认取消？')
            .then(() => {
              this.merProCtrlDialog=false
            })
            .catch(() => {
            });
      }

    },

  }
}
</script>

<style scoped>

</style>
