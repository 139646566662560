<template>
  <div>
    <div class="custom-tree-node" style="display:flex;">
      <div class="block">
        <div>
          <!--          <el-button v-if="permissions.POST" type="primart">添加顶层</el-button>-->
          <el-button type="success" icon="el-icon-refresh" @click="init">刷新</el-button>
<!--          <el-button v-if="permissions.GETrefreshCache" type="danger" icon="el-icon-refresh" @click="refreshCache">-->
<!--            刷新缓存-->
<!--          </el-button>-->
        </div>
        <div style="margin-top: 10px;">
          <el-tree
              v-loading="loading"
              style="width: 500px"
              :data="roles"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              node-key="id"
              :default-expanded-keys="defaultExpandedKeys"
              @node-click="handleNodeClick"
              :highlight-current="true"
              ref="tree"
          >
            <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;"
                  slot-scope="{ node, data }">
              <span>{{ data.name }}</span>
              <span>
                <el-button v-if="permissions.POST"
                           type="primary"
                           size="mini"
                           class="depBtn"
                           @click="showAddView(data)"
                >添加</el-button>
<!--                <el-button v-if="(roles.length>0&&data.id!=roles[0].id)&&permissions.PUT"-->
                <el-button v-if="permissions.PUT"
                           type="primary"
                           size="mini"
                           class="depBtn"
                           @click="showEditView(data)"
                >编辑</el-button>
                <el-button v-if="(data.children==null||data.children.length<1)&&data.id!=1&&permissions.DELETE"
                           type="danger"
                           size="mini"
                           class="depBtn"
                           @click="deleteRow(data)"
                >删除</el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
      <div style="margin-left: 20px">
        <div>
          <span>菜单权限</span>
          <el-button v-if="permissions.PUTmenuPermissions" type="success" style="margin-left: 5px" size="mini" round
                     @click="submitMenuPermissions">保存
          </el-button>
        </div>
        <div style="margin-top: 10px;">
          <el-tree
              v-loading="menuPermissionsLoading"
              :data="menuPermissions"
              show-checkbox
              node-key="id"
              ref="menuTree"
              default-expand-all
              @node-click="handleMenuNodeClick"
              :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.name }}</span>
          </span>
          </el-tree>
        </div>
      </div>
      <div style="margin-left:20px">
        <div>
          <span>按钮权限</span>
          <el-button v-if="permissions.PUTbuttonPermissions" style="margin-left: 5px" type="success" round
                     @click="submitButtonPermissions">保存
          </el-button>
        </div>
        <div style="margin-top: 10px;">
          <el-tree
              v-loading="buttonPermissionsLoading"
              :data="buttons"
              node-key="id"
              show-checkbox
              ref="buttenTree"
          >
          <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;"
                slot-scope="{ node, data }">
            <span>{{ data.name }}</span>
          </span>
          </el-tree>
        </div>
      </div>
    </div>

    <el-dialog
        title="修改部门名称"
        :visible.sync="editDialogVisible"
        width="50%"
    >
      <el-form
          :model="role" :rules="rules" ref="editForm" label-width="180px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="部门名称" prop="name">
              <el-input autocomplete="off" type="text" v-model="role.name" placeholder="请输入部门名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog title="添加部门" :visible.sync="addDialogVisible" width="50%" >
      <el-form
          :model="role" :rules="rules" ref="addForm" label-width="180px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="上级部门">
              <el-tag>{{ role.parentName }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="部门名称" prop="name">
              <el-input autocomplete="off" type="text" v-model="role.name" placeholder="请输入部门名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>

import {Message} from "element-ui";

export default {
  name: "index",
  data() {
    return {
      permissions: {},
      loading: false,
      menuPermissionsLoading: false,
      buttonPermissionsLoading: false,
      role: {
        id: '',
        name: '',
        parentId: '',
        parentName: '',
      },
      editDialogVisible: false,
      addDialogVisible: false,
      roles: [],
      defaultProps: {
        childre: 'children',
        label: 'name'
      },
      rules: {
        id: [{required: true, message: '', trigger: 'blur'}],
        name: [{required: true, message: '', trigger: 'blur'}],
        parentId: [{required: true, message: '', trigger: 'blur'}]
      },
      defaultExpandedKeys: [1],
      menuPermissions: [],
      handleNode: {},
      menuChecked: [],
      buttons: [],
      handleMenuNode: {},
    }
  }, mounted() {
    this.permissions = this.$store.state.permissions;

    this.init()
  }, methods: {
    init(dek) {
      if (this.permissions.GET) {
        this.menuPermissions = []
        this.buttons = []
        this.loading = true
        this.getRequest("/sys/merRole").then(data => {
          this.loading = false
          if (data) {
            this.roles = data.obj
            this.defaultExpandedKeys = [1, dek]
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    showEditView(data) {
      this.editDialogVisible = true
      this.role = {}
      this.role = data
    }, submitEditForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.role.children = null
          this.putRequest('/sys/merRole', this.role).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialogVisible = false
              this.init(this.role.id)
              this.role = {}
            } else {
              Message.error("修改失败（" + data.status + "）")
            }
          })
        } else return false
      })
    }, showAddView(data) {
      this.addDialogVisible = true
      this.role = {}
      this.role.parentName = data.name
      this.role.parentId = data.id
    }, submitAddForm() {
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          this.postRequest("/sys/merRole", this.role).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialogVisible = false;
              this.init(this.role.parentId)
              this.role = {}
            } else {
              Message.error("添加失败（" + data.status + "）")
            }
          })
        } else return false;
      })
    }, deleteRow(data) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/merRole", {id: data.id}).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    }, close() {
      this.role = {}
      this.editDialogVisible = false
      this.addDialogVisible = false
    }, handleNodeClick(data) {
      if (this.permissions.GETmenuPermissions) {
        this.$refs.menuTree.setCheckedKeys([])
        this.menuPermissionsLoading = true;
        this.handleNode = data;
        this.menuChecked = []
        this.buttons = []

        this.getRequest("/sys/merRole/menuPermissions", {
          id: this.handleNode.id,
          parentId: this.handleNode.parentId
        }).then(data => {
          this.menuPermissionsLoading = false;

          if (data) {
            this.menuPermissions = data.obj.menuList
            let checked = data.obj.menuPermissions
            this.setcheckn(checked)
            this.$refs.menuTree.setCheckedKeys(this.menuChecked)
          }
        })
      }
    }, setcheckn(checked) {
      for (let ck in checked) {
        if ((checked[ck].children.length) > 0) {
          this.setcheckn(checked[ck].children)
        } else {
          this.menuChecked.push(checked[ck].id)
        }
      }
    }, submitMenuPermissions() {
      let halfCheckedKeys = this.$refs.menuTree.getHalfCheckedKeys()
      let checkedKeys = this.$refs.menuTree.getCheckedKeys()
      let checked = halfCheckedKeys.concat(checkedKeys)
      this.putRequest("/sys/merRole/menuPermissions", {
        id: this.handleNode.id,
        parentId: this.handleNode.parentId,
        checked: checked
      }).then(data => {
        if (data.status == 200) {
          Message.success("配置成功")
          this.handleNodeClick(this.handleNode)
        }
      })

    }, handleMenuNodeClick(data) {
      if (this.permissions.GETbuttonPermissions) {
        this.handleMenuNode = data
        this.buttonPermissionsLoading = true
        this.$refs.buttenTree.setCheckedKeys([])
        this.getRequest("/sys/merRole/buttonPermissions", {
          roleId: this.handleNode.id,
          roleParentId: this.handleNode.parentId,
          menuId: this.handleMenuNode.id
        }).then(data => {
          this.buttonPermissionsLoading = false

          if (data.status == 200) {
            this.buttons = data.obj.buttonList
            let checked = data.obj.buttonPermissions;
            if (checked.length > 0) {
              let willChecked = []
              for (let ck in checked) {
                willChecked.push(checked[ck].id)
              }
              this.$refs.buttenTree.setCheckedKeys(willChecked)
            }
          } else {
            Message.error("获取按钮失败（" + data.status + "）")

          }
        })
      }
    }, submitButtonPermissions() {
      let checkedKeys = this.$refs.buttenTree.getCheckedKeys()
      this.putRequest("/sys/merRole/buttonPermissions", {
        roleId: this.handleNode.id,
        roleParentId: this.handleNode.parentId,
        menuId: this.handleMenuNode.id,
        checked: checkedKeys
      }).then(data => {
        if (data.status == 200) {
          Message.success("成功")
          this.handleMenuNodeClick(this.handleMenuNode)
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    }
  }
}
</script>

<style scoped>
.depBtn {
  padding: 2px;
}

/*.custom-tree-node {*/
/*  flex: 1;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  font-size: 14px;*/
/*  padding-right: 8px;*/
/*}*/
</style>
