import { render, staticRenderFns } from "./MerBracnch.vue?vue&type=template&id=51ee1f24&scoped=true&"
import script from "./MerBracnch.vue?vue&type=script&lang=js&"
export * from "./MerBracnch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ee1f24",
  null
  
)

export default component.exports