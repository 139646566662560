<template>
  <div class="search">
    <!--    <div>-->
    <!--      <iframe frameborder=”no”-->
    <!--              style="width:85%; height:85%; position: absolute; margin-bottom: 0; margin-top: 0; margin-right: 0;"-->
    <!--              src="/api/druid/index.html"></iframe>-->
    <!--    </div>-->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="首页" name="tab_1">
        <div>
          <el-row>
            <el-col :span="6">版本</el-col>
            <el-col :span="18">{{ tab_1_data.Version }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="6">驱动</el-col>
            <el-col :span="18"><span :index="index+''" v-for="(item,index) in tab_1_data.Drivers" :key="index">{{
                item
              }}<BR/></span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">是否允许重置</el-col>
            <el-col :span="18">{{ tab_1_data.ResetEnable }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="6">重置次数</el-col>
            <el-col :span="18">{{ tab_1_data.ResetCount }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="6">JVM名称</el-col>
            <el-col :span="18">{{ tab_1_data.JavaVMName }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="6">java版本</el-col>
            <el-col :span="18">{{ tab_1_data.JavaVersion }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="6">启动时间</el-col>
            <el-col :span="18">{{ tab_1_data.StartTime }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="6">classpath路径</el-col>
            <el-col :span="18">{{ tab_1_data.JavaClassPath }}</el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据源" name="tab_2">
        <div>
          <el-tabs type="card">
            <el-tab-pane :index="index+''" v-for="(item,index) in tab_2_data" :key="index">
              <span slot="label">{{ item.Name }}</span>
              <div>
                <el-row :index="index+''" v-for="(val,key,index) in item" :key="index">
                  <el-col :span="8">{{ key }}</el-col>
                  <el-col :span="16">{{ val }}</el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
      <el-tab-pane label="sql监控" name="tab_3"><pre>{{ tab_3_data }}</pre></el-tab-pane>
      <!--      <el-tab-pane label="sql防火墙" name="tab_4">sql防火墙？？？？？？？</el-tab-pane>-->
      <el-tab-pane label="web应用" name="tab_5">
        <div>
          <el-tabs type="card">
            <el-tab-pane :index="index+''" v-for="(item,index) in tab_5_data" :key="index">
              <span slot="label">WebApp-{{ item.ContextPath }}</span>
              <div>
                <el-row :index="index+''" v-for="(val,key,index) in item" :key="index">
                  <el-col :span="8">{{ key }}</el-col>
                  <el-col :span="16">{{ val }}</el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
      <el-tab-pane label="URI监控" name="tab_6">
        <el-table
            :data="tab_6_data"
            style="width: 100%"
        >
          <el-table-column
              lable="N"
              type="index"
              width="50">
          </el-table-column>
          <el-table-column
              label="URI"
              prop="URI"
              width="150"
              show-overflow-tooltip
          ></el-table-column>
          <el-table-column
              label="请求次数"
              prop="RequestCount"
              width="50"
          ></el-table-column>
          <el-table-column
              label="请求时间（和）"
              prop="RequestTimeMillis"
              width="80"
              show-overflow-tooltip
          ></el-table-column>
          <el-table-column
              label="请求最慢（单次）"
              prop="RequestTimeMillisMax"
              width="80"
              show-overflow-tooltip
          ></el-table-column>
          <el-table-column
              label="执行中"
              prop="RunningCount"
              width="50"
          ></el-table-column>
          <el-table-column
              label="最大并发"
              prop="ConcurrentMax"
              width="50"
          ></el-table-column>
          <el-table-column
              label="Jdbc执行数"
              prop="JdbcExecuteCount"
              width="50"
          ></el-table-column>
          <el-table-column
              label="Jdbc出错数"
              prop="JdbcExecuteErrorCount"
              width="50"
          ></el-table-column>
          <el-table-column
              label="Jdbc时间"
              prop="JdbcExecuteTimeMillis"
              width="50"
              show-overflow-tooltip
          ></el-table-column>
          <el-table-column
              label="事务提交数"
              prop="JdbcCommitCount"
              width="50"
          ></el-table-column>
          <el-table-column
              label="事务回滚数"
              prop="JdbcRollbackCount"
              width="50"
          ></el-table-column>
          <el-table-column
              label="读取行数"
              prop="JdbcFetchRowCount"
              width="80"
          ></el-table-column>
          <el-table-column
              label="更新行数"
              prop="JdbcUpdateCount"
              width="80"
          ></el-table-column>
          <el-table-column
              label="区间分布[ - - - - - - - - ]"
              prop="Histogram"
              width="160"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Session监控" name="tab_7">
        <el-table
            :data="tab_7_data"
            style="width: 100%"
        >
          <el-table-column
              lable="N"
              type="index"
              width="50">
          </el-table-column>
          <el-table-column
              label="SESSIONID"
              prop="SESSIONID"
              width="200"
          ></el-table-column>
          <el-table-column
              label="Principal"
              prop="Principal"
              width="50"
              show-overflow-tooltip
          ></el-table-column>
          <el-table-column
              label="创建时间"
              prop="CreateTime"
              width="90"
          ></el-table-column> <el-table-column
              label="最后访问时间"
              prop="LastAccessTime"
              width="90"
          ></el-table-column><el-table-column
              label="访问ip地址"
              prop="RemoteAddress"
              width="80"
          ></el-table-column><el-table-column
              label="请求次数"
              prop="RequestCount"
              width="50"
          ></el-table-column><el-table-column
              label="总共请求时间"
              prop="RequestTimeMillisTotal"
              width="50"
          ></el-table-column><el-table-column
              label="执行中"
              prop="RunningCount"
              width="50"
          ></el-table-column><el-table-column
              label="最大并发"
              prop="ConcurrentMax"
              width="50"
          ></el-table-column><el-table-column
              label="Jdbc执行数"
              prop="JdbcExecuteCount"
              width="50"
          ></el-table-column><el-table-column
              label="Jdbc时间"
              prop="JdbcExecuteTimeMillis"
              width="50"
          ></el-table-column><el-table-column
              label="事务提交数"
              prop="JdbcCommitCount"
              width="50"
          ></el-table-column><el-table-column
              label="事务回滚数"
              prop="JdbcRollbackCount"
              width="50"
          ></el-table-column><el-table-column
              label="读取行数"
              prop="JdbcFetchRowCount"
              width="50"
          ></el-table-column><el-table-column
              label="更新行数"
              prop="JdbcUpdateCount"
              width="50"
          ></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Spring监控" name="tab_8"><pre>{{ tab_8_data }}</pre></el-tab-pane>
    </el-tabs>
  </div>
</template>
<!---->
<script>
export default {
  name: "Index",
  data() {
    return {
      activeName: 'tab_1',
      tab_1_data: '',
      tab_2_data: [],
      tab_3_data: '',
      tab_5_data: [],
      tab_6_data: [],
      tab_7_data: [],
      tab_8_data: []
    }
  }, mounted() {
    this.$options.methods[this.activeName](this)
  }, methods: {
    // handleClick(tab, event) {
    handleClick(tab) {
      this.$options.methods[tab.name](this)
    },
    tab_1(that) {
      that.getRequest("/druid/basic.json").then(data => {
        that.tab_1_data = data.Content
      })
    },
    tab_2(that) {
      that.getRequest("/druid/datasource.json").then(data => {
        that.tab_2_data = data.Content
      })
    },
    tab_3(that) {
      that.getRequest("/druid/sql.json").then(data => {
        that.tab_3_data = data.Content
      })
    },
    tab_5(that) {
      that.getRequest("/druid/webapp.json").then(data => {
        that.tab_5_data = data.Content
      })
    },
    tab_6(that) {
      that.getRequest("/druid/weburi.json").then(data => {
        that.tab_6_data = data.Content
      })
    },
    tab_7(that) {
      that.getRequest("/druid/websession.json").then(data => {
        that.tab_7_data = data.Content
      })
    },
    tab_8(that) {
      that.getRequest("/druid/spring.json").then(data => {
        that.tab_8_data = data.Content
      })
    }
  }
}
</script>

<style scoped>
.el-row {
  margin-bottom: 5px;
}

.el-col {
  border-radius: 4px;
}
</style>
