<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="5">
                <el-form-item label="终端类型">
                  <el-select v-model="pm.pm.terType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.term_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="代理商编号">
                  <el-select v-model="pm.pm.agencyNo" placeholder="请选择">
                    <el-option
                        v-for="item in allAgency"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="经理编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.salesPersonNo"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="终端编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.termCode"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="绑定状态">
                  <el-select v-model="pm.pm.status" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.bind_status"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="绑定方式">
                  <el-select v-model="pm.pm.uniteType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.unite_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>


            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column width="55" type="selection" > </el-table-column>
        <el-table-column width="140" show-overflow-tooltip label="代理商编号" prop="agencyNo" ></el-table-column>
        <el-table-column width="140" show-overflow-tooltip label="客户经理编号" prop="salesPersonNo" ></el-table-column>
        <el-table-column width="140" show-overflow-tooltip label="终端编号" prop="termCode" ></el-table-column>
        <el-table-column width="140" show-overflow-tooltip label="终端类型" prop="terType" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.terType, 'term_type') }}
          </template>
        </el-table-column>
        <el-table-column width="140" show-overflow-tooltip label="状态" prop="status" >
<!--          0:未绑定 1:已绑定 2:回收-->
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'bind_status') }}
          </template>
        </el-table-column>
        <el-table-column width="140" show-overflow-tooltip label="绑定方式" prop="uniteType" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.uniteType, 'unite_type') }}
          </template>
        </el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="绑定时间" prop="uniteTime" ></el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="分配时间" prop="distrTime" ></el-table-column>
        <el-table-column width="140" show-overflow-tooltip label="添加人" prop="addMan" ></el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="添加时间" prop="addTime" ></el-table-column>
        <el-table-column label="操作" fixed="right" width="100"  >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.DELETEunbind && scope.row.terType=='2' && scope.row.status=='1'" @click="TermUnbind(scope.row)">解绑</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button icon="el-icon-plus" v-if="permissions.POST" @click="showAddView" type="primary" size="mini">终端分配</el-button>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :before-close="close" title="终端分配" :visible.sync="addDialog" width="75%" >
      <div text-align="center">
        <el-form :model="reqDate" ref="addForm" :rules="rules" label-width="90px" v-loading.fullscreen.lock="divideloading">
          <el-row>
            <el-col :span="5">
              <el-form-item label="终端类型" prop="terType">
                <el-select v-model="reqDate.terType" placeholder="请选择" @change="clean()">
                  <el-option
                      v-for="item in DICKV.term_type"
                      :key="item.k"
                      :label="item.k"
                      :value="item.v"
                      :disabled="item.disabled=='1'?false:true">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="分配对象" prop="agencyNo">
                <el-select v-model="reqDate.type" placeholder="请选择分配对象" @change="clean()">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5" >
              <el-form-item label="代理商" prop="agencyNo">
                <el-select v-model="reqDate.agencyNo" placeholder="请选择代理商"  @change="initSaPeAndMer" >
                  <el-option
                      v-for="item in allAgency"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                  </el-option>
                </el-select>

              </el-form-item>
            </el-col>

            <el-col :span="5" v-if="reqDate.type==1">
              <el-form-item label="客户经理" prop="salesPersonNo">
                <el-select v-model="reqDate.salesPersonNo" placeholder="请选择客户经理" @change="initCnapsDOM()">
                  <el-option
                      v-for="item in saleperoptions"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value">
                    <span style="float: left">{{ item.value }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                  </el-option>
                </el-select>
              </el-form-item>

            </el-col>

            <el-col :span="5">
              <el-form-item label="终端区间" prop="uniteType">
                <el-input autocomplete="off" type="text" v-model="reqDate.termCodeStart"></el-input>~
                <el-input autocomplete="off" type="text" v-model="reqDate.termCodeEnd"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
                <el-button type="primary" icon="el-icon-search" style="margin-left: 6px" @click="QueryTerm()">查询终端</el-button>
            </el-col>
          </el-row>

          <div style="text-align: center;">
            <el-row >
              <el-col :span="24">
                <el-transfer
                    style="text-align: left; display: inline-block;"
                     filterable
                     filter-placeholder="请输入终端编号"
                     v-model="reqDate.termvalue"
                     :titles="['终端列表', '划分终端']"
                     :props="{
                        key: 'termCode',
                        label: 'termCode',
                        disabled: 'status'=='0'
                    }"
                     :button-texts="['取消终端', '添加终端']"
                     :data="termdata">
                </el-transfer>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-close" @click="close()">取 消</el-button>
        <el-button type="primary" icon="el-icon-check" v-bind:disabled="reqDate.termvalue.length==0"  @click="terSave()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      DICKV:{},
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        terType:[{required: true, message: '请输入终端配型', trigger: 'blur'}],
        agencyNo: [{required: true, message: '请输入代理商编号', trigger: 'blur'}],
        // salesPersonNo: [{required: true, message: '请输入客户经理编号', trigger: 'blur'}],
      },

      allAgency:{},
      //客户经理
      saleperoptions:[{}],
      termdata:[],

      reqDate:{
        termvalue:[]
      },
      divideloading:false,

      options: [{
        value: 0,
        label: '代理商'
      }, {
        value: 1,
        label: '客户经理'
      }],

    }
  },created(){
    this.DICKV = this.$store.state.DICKV;
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init();
    this.initAgency();
  }, methods: {




    clean(){
      this.reqDate.salesPersonNo=''
      this.termdata=[]
      this.reqDate.termvalue=[]
    },
    initAgency(){
      this.getRequest("/sys/mertmp/agency").then(resp=> {
        if (resp && resp.status == 200) {
          this.allAgency=resp.obj
        }
      });
    },
    initSaPeAndMer(){
      this.termdata=[];
      this.reqDate.salesPersonNo='';
      this.reqDate.termvalue=[]



      this.getRequest("/sys/mertmp/salePer",{belongAgencyNo:this.reqDate.agencyNo}).then(resp=> {
        if (resp && resp.status == 200) {
          this.saleperoptions=resp.obj
        }
      });
    },
    initCnapsDOM(){
      this.$forceUpdate();
    },
    /*终端分配--保存*/
    terSave(){
      // let term={terType:this.terType,relationId:this.resDate.relationId,branchNo:this.resDate.branchNo,termArray:this.termvalue}
      // console.log(term)
      this.divideloading=true
      this.postRequest('/sys/distr/saveTerm', this.reqDate).then(data => {
        if (data.status == 200) {
          this.divideloading = false;
          this.addTermDialog=false
          Message.success("添加成功")
          this.addDialog=false;
          this.reqDate={
            termvalue:[]
          }
          this.termdata=[]
          this.init();
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
          this.divideloading = false;
        }
      })
    },
    TermUnbind(row) {
      this.$confirm('此操作将会【解除商户已绑定终端】, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/sys/distr/unbind',row).then(data=>{
          if (data.status == 200) {
            Message.success("解绑成功")
            this.init();
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      }).catch(() => {
        Message.info("已取消解绑")
      });
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    QueryTerm(){
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if(this.reqDate.type==1&& (this.reqDate.salesPersonNo==undefined||this.reqDate.salesPersonNo==null||this.reqDate.salesPersonNo=='')){
            Message.error("销售员不可空")
            return false
       }
          this.getRequest('/sys/distr/queryTerm', this.reqDate).then(data => {
            this.loading = false
            if (data.status == 200) {
              this.reqDate.termvalue=[]
              this.termdata=data.obj
            } else {
              this.reqDate.termvalue=[]
              this.termdata=[]
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        }else{
          return false;
        }
      })

    },
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/distr', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }

    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/distr', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.reqDate={
        termvalue:[]
      }
      this.termdata=[]
      this.rowData = {}
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/distr', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/distr", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.qrCodeDialog=false
            this.rowData = {}
            this.reqDate={
              termvalue:[]
            }
            this.init()
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>
.drawer /deep/ .el-drawer__body {
  height: 100%;
}
</style>
