<template>
  <div>
    <div  v-if="permissions.GET">
            <el-collapse v-model="retrieveActiveNames">
              <el-collapse-item title="检索" name="1">
                <template slot="title">
                  检索<i class="header-icon el-icon-info"></i>
                </template>
      <el-form label-width="90px">
        <el-row>
          <el-col :span="4">
            <el-form-item label="总行代号">
              <el-input autocomplete="off" type="text" v-model="pm.pm.headBankCode"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="联行行号">
              <el-input autocomplete="off" type="text" v-model="pm.pm.cnapsCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="总行名称">
              <el-input autocomplete="off" type="text" v-model="pm.pm.headBankName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="银行名称">
              <el-input autocomplete="off" type="text" v-model="pm.pm.bankName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="所在省份">
              <el-input autocomplete="off" type="text" v-model="pm.pm.province"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="所在市">
              <el-input autocomplete="off" type="text" v-model="pm.pm.city"></el-input>
            </el-form-item>
          </el-col>



        </el-row>
      </el-form>
            </el-collapse-item>
            </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="ID" prop="id" >
        </el-table-column>
        <el-table-column width="180" show-overflow-tooltip label="总行代号" prop="headBankCode" ></el-table-column>
        <el-table-column width="180" show-overflow-tooltip label="总行名称" prop="headBankName" ></el-table-column>
        <el-table-column width="180" show-overflow-tooltip label="联行行号" prop="cnapsCode" ></el-table-column>
        <el-table-column width="280" show-overflow-tooltip label="银行名称" prop="bankName" ></el-table-column>
        <el-table-column width="180" show-overflow-tooltip label="所在省份" prop="province" ></el-table-column>
        <el-table-column width="180" show-overflow-tooltip label="所在市" prop="city" ></el-table-column>
        <el-table-column label="操作" fixed="right" width="150" v-if="permissions.PUT||permissions.DELETE" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>
            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
            :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 7px" >
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="联行行号" prop="cnapsCode">
              <el-input autocomplete="off" type="text" v-model="rowData.cnapsCode" placeholder="请输入联行行号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="总行名称" prop="headBankName">
              <el-input autocomplete="off" type="text" v-model="rowData.headBankName" placeholder="请输入总行名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="银行名称" prop="bankName">
              <el-input autocomplete="off" type="text" v-model="rowData.bankName" placeholder="请输入银行名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所在省份" prop="province">
              <el-input autocomplete="off" type="text" v-model="rowData.province" placeholder="请输入所在省份"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所在市" prop="city">
              <el-input autocomplete="off" type="text" v-model="rowData.city" placeholder="请输入所在市"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="总行代号" prop="headBankCode">
              <el-input autocomplete="off" type="text" v-model="rowData.headBankCode" placeholder="请输入总行代号"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="联行行号" prop="cnapsCode">
              <el-input autocomplete="off" type="text" v-model="rowData.cnapsCode" placeholder="请输入联行行号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="总行名称" prop="headBankName">
              <el-input autocomplete="off" type="text" v-model="rowData.headBankName" placeholder="请输入总行名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="银行名称" prop="bankName">
              <el-input autocomplete="off" type="text" v-model="rowData.bankName" placeholder="请输入银行名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所在省份" prop="province">
              <el-input autocomplete="off" type="text" v-model="rowData.province" placeholder="请输入所在省份"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所在市" prop="city">
              <el-input autocomplete="off" type="text" v-model="rowData.city" placeholder="请输入所在市"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="总行代号" prop="headBankCode">
              <el-input autocomplete="off" type="text" v-model="rowData.headBankCode" placeholder="请输入总行代号"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {

        cnapsCode: [{required: true, message: '请输入联行行号', trigger: 'blur'}],

        headBankName: [{required: true, message: '请输入总行名称', trigger: 'blur'}],

        bankName: [{required: true, message: '请输入银行名称', trigger: 'blur'}],

        province: [{required: true, message: '请输入所在省份', trigger: 'blur'}],

        city: [{required: true, message: '请输入所在市', trigger: 'blur'}],

        headBankCode: [{required: true, message: '请输入总行代号', trigger: 'blur'}],

        }
    }
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/bank', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/bank', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.rowData = {}
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/bank', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/bank", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>

</style>
