<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="100px">
            <el-row>
              <el-col :span="4">
                <el-form-item label="渠道标识">
                  <el-select v-model="pm.pm.channelFlag" placeholder="渠道标识">
                    <el-option
                        v-for="item in DICKV.channel_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="系统产品编号">
                  <el-select v-model="pm.pm.sysProductId" clearable >
                    <el-option
                        v-for="item in allSysPro"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value" >
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="渠道产品编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.productId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="开通状态">
                  <el-select v-model="pm.pm.status" placeholder="开通状态">
                    <el-option
                        v-for="item in DICKV.product_status"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>



            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="50" show-overflow-tooltip label="编号" prop="id" ></el-table-column>
        <el-table-column width="110" show-overflow-tooltip label="系统产品编号" prop="sysProductId" ></el-table-column>
        <el-table-column width="110" show-overflow-tooltip label="系统产品描述" prop="sysProductDescribe" ></el-table-column>
        <el-table-column width="110" show-overflow-tooltip label="渠道产品编号" prop="productId" ></el-table-column>
        <el-table-column width="110" show-overflow-tooltip label="渠道产品描述" prop="productDescribe" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="开通状态" prop="status" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'product_status') }}
          </template>
        </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="渠道标识" prop="channelFlag" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.channelFlag, 'channel_flag') }}
          </template>
        </el-table-column>
        <el-table-column width="110" show-overflow-tooltip label="添加人" prop="addMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="添加时间" prop="addTime" ></el-table-column>
        <el-table-column width="110" show-overflow-tooltip label="最后修改人" prop="lastMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="修改时间" prop="lastTime" ></el-table-column>
        <el-table-column label="操作" fixed="right" width="80" v-if="permissions.PUT" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>
<!--            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除 </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="渠道标识" prop="channelFlag">
              <el-select v-model="rowData.channelFlag" placeholder="请选择渠道标识">
                <el-option
                    v-for="item in DICKV.channel_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="开通状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择开通状态">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="系统产品编号" prop="sysProductId">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.sysProductId" placeholder="请输入系统产品编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="系统产品描述" prop="sysProductDescribe">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.sysProductDescribe" placeholder="请输入系统产品描述"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="渠道产品编号" prop="productId">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.productId" placeholder="请输入渠道产品编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="渠道产品描述" prop="productDescribe">
              <el-input autocomplete="off" type="text" v-model="rowData.productDescribe" placeholder="请输入渠道产品描述"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="添加人" prop="addMan">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.addMan" placeholder="请输入添加人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="添加时间" prop="addTime">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.addTime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="最后修改人" prop="lastMan">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.lastMan" placeholder="请输入最后修改人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="修改时间" prop="lastTime">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.lastTime" placeholder="请输入修改时间"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="150px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="渠道标识" prop="channelFlag">
              <el-select v-model="rowData.channelFlag" placeholder="请选择渠道标识">
                <el-option
                    v-for="item in DICKV.channel_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="开通状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择开通状态">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="系统产品编号" prop="sysProductId">
              <el-select v-model="rowData.sysProductId" clearable @change="(val) => changProD(val)" >
                <el-option
                    v-for="item in allSysPro"
                    :key="item.value"
                    :label="item.value"
                    :value="item" >
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="系统产品描述" prop="sysProductDescribe">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.sysProductDescribe" placeholder="请输入系统产品描述"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="渠道产品编号" prop="productId">
              <el-input autocomplete="off" type="text" v-model="rowData.productId" placeholder="请输入渠道产品编号"></el-input>

            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="渠道产品描述" prop="productDescribe">
              <el-input autocomplete="off" type="text" v-model="rowData.productDescribe" placeholder="请输入渠道产品描述"></el-input>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";

export default {
  name: "ChanPro",
  data() {
    return {
      DICKV:{},
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        productId: [{required: true, message: '请输入渠道产品编号', trigger: 'blur'}],
        productDescribe: [{required: true, message: '请输入渠道产品描述', trigger: 'blur'}],
        sysProductId: [{required: true, message: '请输入系统产品编号', trigger: 'blur'}],
        sysProductDescribe: [{required: true, message: '请输入系统产品描述', trigger: 'blur'}],
        status: [{required: true, message: '请选择开通状态', trigger: 'blur'}],
        channelFlag: [{required: true, message: '请输入渠道标识;1:通联', trigger: 'blur'}],
      },
      allSysPro:[],
    }
  }, created(){
    this.DICKV = this.$store.state.DICKV;
  },mounted() {
    this.permissions = this.$store.state.permissions;
    this.initAllSysPro()
    this.init()
  }, methods: {
    changProD(val){
      this.rowData.sysProductId=val.value;
      this.rowData.sysProductDescribe=val.label;
    },
    initAllSysPro(){
      this.getRequest('/sys/sysprdt/allSysPro').then(data => {
        this.loading = false
        if (data.status == 200) {
          this.allSysPro = data.obj
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/chanprd', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/chanprd', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.rowData = {}
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/chanprd', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/chanprd", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>

</style>
