<template>
  <div>
    <div>
      <el-form label-width="80px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="用户名">
              <el-input autocomplete="off" type="text" v-model="pm.pm.username"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="sdt">
              <!--              <el-input autocomplete="off" type="text" v-model="pm.pm.sdt"></el-input>-->
              <el-date-picker
                  v-model="pm.pm.sdt"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="edt">
              <!--              <el-input autocomplete="off" type="text" v-model="pm.pm.edt"></el-input>-->
              <el-date-picker
                  v-model="pm.pm.edt"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="url">
              <el-input autocomplete="off" type="text" v-model="pm.pm.url"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="ip">
              <el-input autocomplete="off" type="text" v-model="pm.pm.ip"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="method">
              <el-select clearable v-model="pm.pm.method" placeholder="请选择提交方式">
                <el-option label="GET" value="GET"></el-option>
                <el-option label="PUT" value="PUT"></el-option>
                <el-option label="POST" value="POST"></el-option>
                <el-option label="DELETE" value="DELETE"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="className">
              <el-input autocomplete="off" type="text" v-model="pm.pm.className"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="token">
              <el-input autocomplete="off" type="text" v-model="pm.pm.token"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-button type="success" size="mini" v-if="permissions.GET" @click="queryForm">查询</el-button>
            <el-button size="mini" v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div  style="margin-top: 10px;">
      <el-table
          v-loading="loading"
          :data="datas"
          style="width: 100%"
      >
        <el-table-column
            width="80"
            show-overflow-tooltip
            label="id"
            prop="id"
        >
          <template slot-scope="scope">
            <el-popover
                placement="top-start"
                title="详情"
                width="500"
                trigger="hover">
              <div>
                <el-row type="flex"  justify="space-between">
                  <el-col :span="3"><span>id</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.id}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>用户</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.username}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>时间</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.dt}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>ip</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.ip}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>地址</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.url}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>方法</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.method}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>类名</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.className}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>参数</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.pm}}</span>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="space-between">
                  <el-col :span="3"><span>token</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="14">
                    <span>{{ scope.row.token}}</span>
                  </el-col>
                </el-row>
              </div>
              <span slot="reference">{{scope.row.id}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            label="用户名"
            prop="username"
            width="80"

        ></el-table-column>
        <el-table-column
            label="时间"
            prop="dt"
            width="180"
        ></el-table-column>
        <el-table-column
            label="ip"
            prop="ip"
            width="120"
        ></el-table-column>
        <el-table-column
            label="url"
            prop="url"
            width="100"

        ></el-table-column>
        <el-table-column
            label="method"
            prop="method"
            width="80"

        ></el-table-column>
        <el-table-column
            label="className"
            prop="className"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="pm"
            prop="pm"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="token"
            prop="token"
        ></el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pm.page.page"
            :page-sizes="[15, 50, 100, 200]"
            :page-size="pm.page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pm.page.total">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      permissions: {},
      loading: false,
      datas: [],
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      }
    }
  },
  mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {

    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/dblog', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    }, queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    }, clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    }
  }
}
</script>

<style scoped>
.el-table .cell {
  white-space: pre !important;
}
</style>
