<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="4">
                <el-form-item label="系统商户号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.merId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="商户名称">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.merName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="商户类型">
                  <el-select v-model="pm.pm.merType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.mer_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="业务场景">
                  <el-select v-model="pm.pm.businessFlag" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.business_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="商户标识">
                  <el-select v-model="pm.pm.merFlag" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.mer_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="商户来源">
                  <el-select v-model="pm.pm.merSource" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.mer_source"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="开通状态">
                  <el-select v-model="pm.pm.status" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.mer_status"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
<!--              <el-col :span="4">-->
<!--                <el-form-item label="审核状态">-->
<!--                  <el-select v-model="pm.pm.auditStatus" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in DICKV.audit_status"-->
<!--                        :key="item.k"-->
<!--                        :label="item.k"-->
<!--                        :value="item.v"-->
<!--                        :disabled="item.disabled=='1'?false:true">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="4">
                <el-form-item label="创建时间">
                  <el-date-picker
                      v-model="pm.pm.addTime"
                      type="date"
                      value-value-format="yyyy-MM-dd"
                      placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="商户负责人">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.contactPerson"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="拓展人">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.expandMan"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="负责人电话">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.contactPhone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">

<!--      <el-button size="mini" type="warning" v-if="permissions.GET" :disabled="btnBoolean ? false : true" @click="edit_merBranch()">门店配置</el-button>-->
<!--      <el-button size="mini" type="warning" v-if="permissions.GET" :disabled="btnBoolean ? false : true" @click="edit_merRate()">费率信息配置</el-button>-->
<!--      <el-button size="mini" type="warning" v-if="permissions.GET" :disabled="btnBoolean ? false : true" @click="edit_merSettle()">结算信息配置</el-button>-->


      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" @current-change="clickChange">
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio v-model="tableRadio" :label="scope.row"><i></i></el-radio>
          </template>
        </el-table-column>

        <el-table-column width="120" show-overflow-tooltip label="系统商户号" prop="merId" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="渠道商户号" prop="merIdChannel" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="商户名称" prop="merName" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="商户简称" prop="shortName" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="客服电话" prop="servicePhone" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="所属行业" prop="mccid" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="商户类型" prop="merType" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.merType, 'mer_type') }}
          </template>
        </el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="拓展人" prop="expandMan" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="所属父客户" prop="parentId" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="联系人姓名" prop="businessContactName" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="联系人电话" prop="businessContactPhone" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="添加人" prop="addMan" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="注册地址" prop="address" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="商户开通状态" prop="status" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'status') }}
          </template>
        </el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="审核状态" prop="auditStatus" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.auditStatus, 'audit_status') }}
          </template>
        </el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="商户创建时间" prop="addTime" ></el-table-column>
        <el-table-column label="操作" fixed="right" width="100" v-if="permissions.PUT||permissions.DELETE" >
          <template slot-scope="scope" >
            <el-button type="primary" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row,'affirm')">商户审核 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>
    </div>

<!--    配置门店的产品控制-->
    <el-dialog :close-on-click-modal="false"
               v-loading="merBranchloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="merBranchDialog"
               width="50%">
      <template slot='title'>
        <span style="font-size:20px;">商户门店产品控制管理-{{tableRadio.id}}</span>
      </template>
      <MerBracnch ref="merbranch" ></MerBracnch>
    </el-dialog>

    <!--    配置商户费率-->
    <el-dialog :close-on-click-modal="false"
               v-loading="merRateloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="merRateDialog"
               width="50%">
      <template slot='title'>
        <span style="font-size:20px;">商户费率管理-{{tableRadio.id}}</span>
      </template>
      <MerRate ref="merrate" ></MerRate>
    </el-dialog>


<!--&lt;!&ndash;    商户编辑&ndash;&gt;-->
<!--    <el-dialog :close-on-click-modal="false"-->
<!--               v-loading="merEduitloading"-->
<!--               element-loading-text="拼命加载中"-->
<!--               element-loading-spinner="el-icon-loading"-->
<!--               element-loading-background="rgba(0, 0, 0, 0.8)"-->
<!--               :before-close="close" title="" :visible.sync="merEduitDialog"-->
<!--               width="70%">-->
<!--      <template slot='title'>-->
<!--        <span style="font-size:20px;">商户信息编辑</span>-->
<!--      </template>-->
<!--      <div style="text-align: left" >-->
<!--        <MerEduit ref="mereduit" v-on:colseFaDia="colseFaDia()" ></MerEduit>-->
<!--      </div>-->
<!--    </el-dialog>-->

    <!--    商户信息确认-->
    <el-dialog :close-on-click-modal="false"
               v-loading="merAffirmloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="merAffirmDialog"
               width="70%">
      <template slot='title'>
        <span style="font-size:20px;">商户审核</span>
      </template>
      <div style="text-align: left" >
        <MerAffirm ref="meraffirm" v-on:colseFaDia="colseFaDia()"></MerAffirm>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";
import MerBracnch from "@/views/sys/merch/MerBracnch";
import MerRate from "@/views/sys/merch/MerRate";
import MerAffirm from "@/views/sys/merch/MerAffirm";

export default {
  name: "Index",
  components: {MerBracnch,MerRate,MerAffirm},

  data() {
    return {
      DICKV:{},
      btnBoolean: false,//禁用批量按钮
      tableRadio: {},

      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {
          auditStatus:'S1'
        },
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        lastMan: [{required: true, message: '请输入最后修改人', trigger: 'blur'}],
        mccidName: [{required: true, message: '请输入所属行业中文名', trigger: 'blur'}],
        addressCode: [{required: true, message: '请输入注册地址code', trigger: 'blur'}],
        relationId: [{required: true, message: '请输入临时表之间的关联id，等同于商户表的id字段', trigger: 'blur'}],
        commitStatus: [{required: true, message: '请输入提交状态：0：未提交；1：已提交；  未提交时可以随便修改临时表数据。 已提交后，再做修改必须做插入操作', trigger: 'blur'}],
        legalPhone: [{required: true, message: '请输入法人手机号', trigger: 'blur'}],
        id: [{required: true, message: '请输入id', trigger: 'blur'}],
        merId: [{required: true, message: '请输入系统商户号', trigger: 'blur'}],
        merIdChannel: [{required: true, message: '请输入渠道商户号', trigger: 'blur'}],
        merName: [{required: true, message: '请输入商户名称', trigger: 'blur'}],
        shortName: [{required: true, message: '请输入商户简称', trigger: 'blur'}],
        servicePhone: [{required: true, message: '请输入客服电话', trigger: 'blur'}],
        mccid: [{required: true, message: '请输入所属行业', trigger: 'blur'}],
        legalIdNo: [{required: true, message: '请输入法人代表证件号', trigger: 'blur'}],
        legalIdExpire: [{required: true, message: '请输入法人身份证有效期', trigger: 'blur'}],
        address: [{required: true, message: '请输入注册地址', trigger: 'blur'}],
        contactPerson: [{required: true, message: '请输入商户负责人', trigger: 'blur'}],
        contactPhone: [{required: true, message: '请输入商户负责人电话', trigger: 'blur'}],
        expandMan: [{required: true, message: '请输入拓展人', trigger: 'blur'}],
        businessContactName: [{required: true, message: '请输入业务联系人姓名', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '请输入业务联系人电话', trigger: 'blur'}],
        merFlag: [{required: true, message: '请输入商户标识 1：TL通联商户；XX其他商户', trigger: 'blur'}],
        merSource: [{required: true, message: '请输入商户来源：1:H5；2:WEB；3:api；', trigger: 'blur'}],
        auditTime: [{required: true, message: '请输入审核终态时间', trigger: 'blur'}],
        commitAuditTime: [{required: true, message: '请输入提交审核时间', trigger: 'blur'}],
        lastTime: [{required: true, message: '请输入最后修改时间', trigger: 'blur'}],
        addTime: [{required: true, message: '请输入商户创建时间', trigger: 'blur'}],
        changeSerial: [{required: true, message: '请输入更新流水号。对应临时表中的流水号', trigger: 'blur'}],
        belongAgencyNo: [{required: true, message: '请输入所属代理商编号', trigger: 'blur'}],
        changeAuditStatus: [{required: true, message: '请输入修改信息后的审核状态', trigger: 'blur'}]
      },
      // 门店参数
      merBranchloading:false,
      merBranchDialog:false,

      //费率信息
      merRateloading:false,
      merRateDialog:false,



      //商户编辑
      merEduitDialog:false,
      merEduitloading:false,

      //信息确认
      merAffirmDialog:false,
      merAffirmloading:false,
    }
  },
  created(){
    this.DICKV = this.$store.state.DICKV;
  },
  mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  },
  methods: {
    colseFaDia(){
      this.merEduitDialog=false
      this.merAffirmDialog=false
      this.init()
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      this.pm.pm.auditStatus='S1'
      this.btnBoolean=false//禁用批量按钮
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/merch/merinfo', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
      this.pm.pm.auditStatus='S1'
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    // showEditView(rowData) {
    //   this.rowData = {}
    //   this.rowData = rowData
    //   this.merEduitDialog = true
    //   this.$nextTick(()=>{
    //     this.$refs.mereduit.$emit('init', this.rowData.id)
    //   })
    // },
    showEditView(rowData){
      this.rowData = {}
      this.rowData = rowData
      this.merAffirmDialog = true
      this.$nextTick(()=>{
        this.$refs.meraffirm.$emit('init', this.rowData.id ,false)
      })
    },
    clickChange(item) {
      if (item){
        this.tableRadio = item
        this.btnBoolean = true;
      }
    },

    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.merBranchDialog=false
            this.merRateDialog=false
            this.merEduitDialog = false
            this.index=0
            this.merAffirmDialog=false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    },
    edit_merBranch(){
      if(this.tableRadio.belongAgencyNo==''||this.tableRadio.belongAgencyNo==null||this.tableRadio.belongAgencyNo==undefined){
        Message.error("未设置所属代理")
        return false
      }
      this.merBranchDialog=true;
      this.$nextTick(()=>{
        this.$refs.merbranch.$emit('init', this.tableRadio.id)
      })
    },
    edit_merRate(){
      if(this.tableRadio.belongAgencyNo==''||this.tableRadio.belongAgencyNo==null||this.tableRadio.belongAgencyNo==undefined){
        Message.error("未设置所属代理")
        return false
      }
      this.merRateDialog=true
      this.$nextTick(()=>{
        this.$refs.merrate.$emit('initR', this.tableRadio)
      })
    }
    }
  }
</script>

<style scoped>

</style>
