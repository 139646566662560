<template>
  <div class="AgcSalePer">
    <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%">
      <el-table-column width="100" show-overflow-tooltip label="经理编号" prop="salesPersonNo"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="姓名" prop="salesPersonName"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="企业微信号" prop="userid"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="联系电话" prop="salesPersonPhone"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="客户经理状态" prop="status">
        <template slot-scope="scope">
          {{ getDICKV(scope.row.status, 'sal_per_status') }}
        </template>
      </el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="添加人" prop="addMan"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="添加时间" prop="addTime"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="修改人" prop="lastMan"></el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="修改时间" prop="lastTime"></el-table-column>
      <el-table-column label="操作" fixed="right" width="160" v-if="permissions.PUT||permissions.DELETE">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" v-if="permissions.DELETEsalePer" @click="deleteSalePer(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="sppm.page.page" :page-sizes="[10, 50, 100, 200]"
                       :page-size="sppm.page.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="sppm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button icon="el-icon-plus" v-if="permissions.POST" @click="showAddView('')" type="primary" size="mini">添加客户经理</el-button>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" append-to-body>
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="150px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="客户经理编号" prop="salesPersonNo">
              <el-input autocomplete="off" type="text" v-model="rowData.salesPersonNo" disabled placeholder="请输入客户经理编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="企业微信号" prop="userid">
              <el-select v-model="rowData.userid" filterable placeholder="请选择" @change="changeWx(rowData.userid)">
                <el-option
                    v-for="item in users"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                  <span style="float: left; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                  <span style="float: right">{{ item.value }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="姓名" prop="salesPersonName">
              <el-input autocomplete="off" type="text" v-model="rowData.salesPersonName" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="联系电话" prop="salesPersonPhone">
              <el-input type="text" v-model="rowData.salesPersonPhone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="客户经理状态：" prop="status">
              <el-select  v-model="rowData.status"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.sal_per_status"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" width="50%" append-to-body>
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="客户经理编号" prop="salesPersonNo">
              <el-input autocomplete="off" type="text" v-model="rowData.salesPersonNo" disabled placeholder="请输入客户经理编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所属机构编号" prop="belongAgencyNo">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.belongAgencyNo" placeholder="请输入所属机构编号"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="企业微信号" prop="userid">
              <el-select v-model="rowData.userid" filterable disabled placeholder="请选择" @change="changeWx(rowData.userid)">
                <el-option
                    v-for="item in users"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                  <span style="float: left; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                  <span style="float: right">{{ item.value }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="姓名" prop="salesPersonName">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.salesPersonName" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="联系电话" prop="salesPersonPhone">
              <el-input autocomplete="off" type="text"  disabled v-model="rowData.salesPersonPhone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="客户经理状态" prop="status">
              <el-select  v-model="rowData.status"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.sal_per_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加时间" prop="addTime">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.addTime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="最后修改时间" prop="lastTime">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.lastTime" placeholder="请输入最后修改时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加人" prop="addMan">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.addMan" placeholder="请输入添加人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="最后修改人" prop="lastMan">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.lastMan" placeholder="请输入最后修改人"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>



  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "AgcSalePer",
  data() {
    return {
      loading:false,
      permissions: {},
      DICKV:{},
      users:[],
      datas:[],
      rowData:{},
      addDialog:false,
      editDialog:false,
      agencyNo:'',
      rules: {
        userid: [{required: true, message: '请输入企业微信号', trigger: 'blur'}],
        salesPersonName: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        salesPersonPhone: [{required: true, message: '请输入联系电话', trigger: 'blur'}],
        status: [{required: true, message: '请输入客户经理状态', trigger: 'blur'}],
      },
      sppm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 10,
          pages: 1,
          total: 0
        }
      },

    }
  },mounted(){
    this.wxuser()
    this.DICKV=this.$store.state.DICKV;
    this.permissions = this.$store.state.permissions;
    this.mount()
  },
  methods : {
    wxuser(){
      this.getRequest('/sys/agency/wxuser').then(data => {
        this.loading = false
        if (data.status == 200) {
          this.users = data.obj
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    changeWx(userid){
      this.getRequest('/sys/agency/getWxuser',{userid:userid}).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.rowData.salesPersonName=data.obj.salesPersonName
          // this.rowData.salesPersonPhone=""
          this.$forceUpdate();
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })

    },
    mount(){
      this.$on('init',(res)=>{
        this.sppm.pm.agencyNo=res
        this.init()
      })
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/agency/salePer', this.sppm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.sppm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    handleSizeChange(val) {
      this.sppm.page.pageNum = 1
      this.sppm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.sppm.page.pageNum = val
      this.init()
    },
    showAddView(){
      this.rowData.status='0'
      this.addDialog=true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.rowData.belongAgencyNo=this.sppm.pm.agencyNo
          this.postRequest('/sys/agency/salePer', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.rowData={}
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },

    //删除销售人员
    deleteSalePer(rowData){
      this.$confirm('确定删除销售人员吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/sys/agency/salePer', rowData).then(data =>{
          if (data.status == 200) {
            Message.success("修改成功")
          }else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
          this.init()
        })
      }).catch(() => {
        Message.info("已取消删除")
      });



    },
    submitEditForm() {
      this.rowData.belongAgencyNo=this.sppm.pm.agencyNo;
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/agency/salePer', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.rowData={}
              this.init()
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    }
  },
}
</script>

<style scoped>

</style>
