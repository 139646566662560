<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :index="index+''" v-for="(val,key,index) in links" :key="index" :name="key">
        <span slot="label">{{ key }}</span>
        <pre>{{ links[key].data }}</pre>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      activeName: 'health',
      links: []
    }
  },
  mounted() {
    this.init()
  }, methods: {
    init() {
      this.getRequest("/actuator/").then(data => {
        let links = data._links
        for (let key in links) {
          if (links[key].href.indexOf("{") != -1) {
            delete links[key]
          }
        }
        delete links['self']
        this.links = links
      })
      this.initActiveDefault()
    },
    initActiveDefault() {
      this.getRequest('/actuator/'+this.activeName).then(data => {
        this.links[this.activeName].data = JSON.stringify(data, null, 4)
        this.$forceUpdate()
      })
    },
    handleClick(tab) {
      let href = this.links[tab.name].href
      href = href.substr(href.indexOf("/actuator"))
      this.getRequest(href).then(data => {
        this.links[tab.name].data = JSON.stringify(data, null, 4)
        this.$forceUpdate()
      })
      // this.$options.methods[tab.name](this)
    }
  }
}
</script>

<style scoped>
</style>
