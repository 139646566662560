<template>
  <!-- 封装 导航栏 动态循环  element多层导航菜单-->
  <div class="sidebar">
    <template v-for="item in list">
      <!-- 标题 -->
      <template v-if="item.children.length" >
        <el-submenu :key="item.id" :index="item.id+''" popper-class="headSelectNavMenu" >
          <template :index="item.index" slot="title" >
            <!-- 图标 -->
             <i :class="item.icon"></i>
            <span>{{item.name}}</span>
          </template>
          <!-- 递归循环 -->
          <IndexHeadElMenu :list="item.children" ></IndexHeadElMenu>
        </el-submenu>
      </template>
      <!-- 选项 -->
      <template v-else>
        <el-menu-item :key="item.id" :index="item.id+''" @click="topash(item.path)">
          <!-- 图标 -->
           <i :class="item.icon"></i>
          <span>{{item.name}}</span>
        </el-menu-item>
      </template>

    </template>
  </div>
</template>

<script>
export default {
  name: "IndexHeadElMenu",
  props: {
    list: Array
  },
  components: {

  },
  methods:{
    topash(path){
      this.$router.push(path);
    }
  }

};
</script>
<style>

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 850px;

}
</style>

