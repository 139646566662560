<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>

              <el-col :span="6">
                <el-form-item label="厂商编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.vendorNum"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="厂商名称">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.vendorName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="设备类型">
                  <el-select v-model="pm.pm.termType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.term_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="状态">
                  <el-select v-model="pm.pm.status" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.product_status"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="厂商编号" prop="vendorNum" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="厂商名称" prop="vendorName" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="设备型号" prop="termModel" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="设备类型" prop="termType" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.termType, 'term_type') }}
          </template>
        </el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="状态" prop="status" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'product_status') }}
          </template>
        </el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="联系人" prop="linkman" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="联系电话" prop="linkphone" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="地址" prop="address" ></el-table-column>
        <el-table-column width="120" show-overflow-tooltip label="添加人" prop="addMan" ></el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="添加时间" prop="addTime" ></el-table-column>
        <el-table-column label="操作" fixed="right" width="150" v-if="permissions.PUT||permissions.DELETE" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>
            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="厂商编号" prop="vendorNum">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.vendorNum" placeholder="请输入厂商编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="厂商名称" prop="vendorName">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.vendorName" placeholder="请输入厂商名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="设备型号" prop="termModel">
              <el-input disabled autocomplete="off" type="text"  v-model="rowData.termModel" placeholder="设备型号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备类型" prop="termType">
              <el-select disabled v-model="rowData.termType"   placeholder="请选择设备类型">
                <el-option
                    v-for="item in DICKV.term_type"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备密钥1" prop="termKey1">
              <el-input autocomplete="off" type="text" v-model="rowData.termKey1" placeholder="设备密钥1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备密钥2" prop="termKey2">
              <el-input autocomplete="off" type="text" v-model="rowData.termKey2" placeholder="设备密钥2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备参数1" prop="termParam1">
              <el-input autocomplete="off" type="text" v-model="rowData.termParam1" placeholder="设备参数1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备参数2" prop="termParam2">
              <el-input autocomplete="off" type="text" v-model="rowData.termParam2" placeholder="设备参数2"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="联系人" prop="linkman">
              <el-input autocomplete="off" type="text" v-model="rowData.linkman" placeholder="请输入联系人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="联系电话" prop="linkphone">
              <el-input autocomplete="off" type="text" v-model="rowData.linkphone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="地址" prop="address">
              <el-input autocomplete="off" type="text" v-model="rowData.address" placeholder="请输入地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="备注" prop="notes">
              <el-input autocomplete="off" type="text" v-model="rowData.notes" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="15">
            <el-form-item label="设备图片" >
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :data="IMGList[0]"
                  :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[0])}"
                  :show-file-list="false"
                  :http-request="httpRequest"><!--覆盖默认上传-->
                <img v-if="IMGList[0].imageUrl" :src="IMGList[0].imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="厂商编号" prop="vendorNum">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.vendorNum" placeholder="请输入厂商编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="厂商名称" prop="vendorName">
              <el-input autocomplete="off" type="text" v-model="rowData.vendorName" placeholder="请输入厂商名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="设备型号" prop="termModel">
              <el-input autocomplete="off" type="text" v-model="rowData.termModel" placeholder="设备型号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备类型" prop="termType">
              <el-select v-model="rowData.termType" placeholder="请选择设备类型">
                <el-option
                    v-for="item in DICKV.term_type"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备密钥1" prop="termKey1">
              <el-input autocomplete="off" type="text" v-model="rowData.termKey1" placeholder="设备密钥1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备密钥2" prop="termKey2">
              <el-input autocomplete="off" type="text" v-model="rowData.termKey2" placeholder="设备密钥2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备参数1" prop="termParam1">
              <el-input autocomplete="off" type="text" v-model="rowData.termParam1" placeholder="设备参数1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="设备参数2" prop="termParam2">
              <el-input autocomplete="off" type="text" v-model="rowData.termParam2" placeholder="设备参数2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="联系人" prop="linkman">
              <el-input autocomplete="off" type="text" v-model="rowData.linkman" placeholder="请输入联系人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="联系电话" prop="linkphone">
              <el-input autocomplete="off" type="text" v-model="rowData.linkphone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="地址" prop="address">
              <el-input autocomplete="off" type="text" v-model="rowData.address" placeholder="请输入地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="备注" prop="notes">
              <el-input autocomplete="off" type="text" v-model="rowData.notes" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="15">
            <el-form-item label="设备图片" >
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :data="IMGList[0]"
                  :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[0])}"
                  :show-file-list="false"
                  :http-request="httpRequest"><!--覆盖默认上传-->
                <img v-if="IMGList[0].imageUrl" :src="IMGList[0].imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item style="display: flex;align-items: center;justify-content: center;padding: 0px;margin: 0px;">
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      DICKV:{},
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        vendorName: [{required: true, message: '请输入厂商名称', trigger: 'blur'}],
        linkman: [{required: true, message: '请输入联系人', trigger: 'blur'}],
        linkphone: [{required: true, message: '请输入联系电话', trigger: 'blur'}],
        address: [{required: true, message: '请输入地址', trigger: 'blur'}],
        status:[{required: true, message: '请输入状态', trigger: 'blur'}],
        termModel:[{required: true, message: '请输入状态', trigger: 'blur'}],
        termType:[{required: true, message: '请输入状态', trigger: 'blur'}],

      },

      //图片上传
      IMGList: [ {type:'TERM',imageUrl:'',tempUrl:''}]
    }
  },created(){
    this.DICKV = this.$store.state.DICKV;
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    init() {
      this.IMGList=[{type:'TERM',imageUrl:'',tempUrl:''}]
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/vendor', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.baseIMG();
      this.editDialog = true
    },
    baseIMG(){
      let IMGList=[{picPath:this.rowData.termImg}];
      for (let i = 0; i < IMGList.length; i++) {
        if (IMGList[i].picPath){
          this.getRequestBlob('/sys/fileStorage/baseIMG?path='+IMGList[i].picPath+'&type=TERM&time=' + new Date()).then(data => {
            this.IMGList[i].imageUrl = window.URL.createObjectURL(data);
            this.IMGList[i].picPath = IMGList[i].picPath;
          })
        }else{
          this.IMGList[i].imageUrl=''
          this.IMGList[i].picPath = '';
        }
      }
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/vendor', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.rowData = {}
      this.rowData.vendorNum=new Date().getTime();
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/vendor', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/vendor", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    },

    //将上传图片的原路径赋值给临时路径
    handleChange(file, fileList,data) {
      switch (data.type){
        case 'TERM':
          this.IMGList[0].tempUrl = URL.createObjectURL(file.raw);
          break
      }
    },
    //实现图片上传功能
    httpRequest(item) {
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        // post上传图片
        let App = this;
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        mf.append('type', item.data.type);
        mf.append('path', '');
        mf.append('relationId', this.rowData.vendorNum);
        App.postRequest('/sys/fileStorage/img',mf).then(result => {
          Message.success( result.message )
          this.imgtype(item.data.type,result)
        })
      }
    },
    imgtype(type,result){
      //将临时文件路径赋值给显示图片路径（前端显示的图片）
      //将后台传来的数据库图片路径赋值给car对象的图片路径
      switch (type){
        case 'TERM':
          this.IMGList[0].imageUrl = this.IMGList[0].tempUrl;
          this.IMGList[0].picPath = result.picPath;
          this.rowData.termImg=result.picPath;
          break
      }
    },
  }
}
</script>

<style scoped>
.avatar-uploader {
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 150px;
  height: 150px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
}
</style>
