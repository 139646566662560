<template>
  <div>
    <div class="custom-tree-node" style="display:flex;">
      <div class="block">
        <div style="margin-top: 10px;">


          <div style="margin-top: 5px;margin-bottom: 5px">
            <el-button v-if="permissions.GETsynchDept" type="warning" icon="el-icon-refresh" @click="synchDept">同步部门</el-button>
            <el-button v-if="permissions.GETsynchTag" type="warning" icon="el-icon-refresh" @click="synchTag">同步标签</el-button>
            <el-button v-if="permissions.GETsynchUser" type="warning" icon="el-icon-refresh" @click="synchUser"> 同步用户 </el-button>
          </div>
          <div>
            <el-tabs type="border-card" :stretch="true" v-model="activeName" @tab-click="handleClickTab">
              <el-tab-pane label="组织架构" name="dept">
                <el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
                <el-tree v-loading="loading" style="width: 500px" :data="roles" :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNodeD" node-key="id"  :default-expanded-keys="defaultExpandedKeys" @node-click="handleNodeClick"  :highlight-current="true" ref="depttree" >
                  <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;" slot-scope="{ node, data }">
                    <span class="el-icon-folder">{{ data.name }}</span>
                    <span>
<!--                      <el-button v-if="permissions.POST" type="primary" size="mini" class="depBtn" @click="showAddView(data)" >添加子部门</el-button>-->
<!--                      <el-button v-if="permissions.PUT" type="primary" size="mini" class="depBtn" @click="showEditView(data)" >编辑</el-button>-->
<!--                      <el-button v-if="(data.children==null||data.children.length<1)&&data.id!=1&&permissions.DELETE" type="danger" size="mini" class="depBtn" @click="deleteRow(data)" >删除</el-button>-->
                    </span>
                  </span>
                </el-tree>
              </el-tab-pane>
              <el-tab-pane label="权限组"    name="tag">
                <el-row>
                  <el-col :span="22"><el-input placeholder="输入关键字进行过滤" v-model="treeText"> </el-input></el-col>
<!--                  <el-col :span="1"><el-button style="margin-left: 5px" type="primary" icon="el-icon-plus" circle @click="AddTag"></el-button></el-col>-->
                </el-row>
                <el-tree v-loading="loading" style="width: 500px" :data="tagRoles" :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNodeT" node-key="tagid"  :default-expanded-keys="defaultExpandedKeys" @node-click="handleNodeClickTag"  :highlight-current="true" ref="usertree" >
                  <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;" slot-scope="{ node, data }">
                    <span class="el-icon-price-tag">{{ data.tagname }}</span>
                    <span>
<!--                      <el-button v-if="permissions.PUT" type="primary" size="mini" class="depBtn" @click="EditTag(data)" >编辑</el-button>-->
<!--                      <el-button v-if="(data.children==null||data.children.length<1)&&data.id!=1&&permissions.DELETE" type="danger" size="mini" class="depBtn" @click="DeleteTag(data)" >删除</el-button>-->
                    </span>
                  </span>
                </el-tree>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div style="margin-left: 20px;margin-top: 10px;width: 100%" >
        <div v-if="activeName=='dept'" style="margin-top: 10px;">
          <div style="margin-bottom: 5px">
            <span>{{talbename}}({{pm.page.total}}人)</span>
          </div>
<!--          <el-button type="primary" @click="showUserAddView" >添加成员</el-button>-->
<!--          <el-button type="warning">批量导入/导出</el-button>-->
<!--          <el-button size="mini" type="danger" v-if="permissions.DELETEdeptuser" :disabled="btnBoolean ? false : true" @click="deleteDeptUser()" >删除成员</el-button>-->

          <el-table :data="datas" border style="width: 100%;margin-top: 10px" v-loading="loadingUser"   ref="checkTable" @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55"></el-table-column>
            <el-table-column prop="userid" show-overflow-tooltip label="用户ID"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="depts" label="部门" show-overflow-tooltip>
              <template slot-scope="scope" >
                <el-tag type="primary"  style="margin-right: 5px" v-for="(item, index) in scope.row.depts" :key="index">{{ item.name }}<a style="padding-left: 10px;color: #505458" v-if="item.id==scope.row.mainDepartment">(主)</a></el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                {{ getDICKV(scope.row.status, 'status') }}
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="150" >
              <template slot-scope="scope">
                <el-button type="success" size="mini"  @click="showUserEditView(scope.row)">查看 </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="flex: 1">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pm.page.page"
                :page-sizes="[15, 50, 100, 200]"
                :page-size="pm.page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pm.page.total"
                style="text-align:center">
            </el-pagination>
          </div>
        </div>
        <div v-if="activeName=='tag'" style="margin-top: 10px;">
          <div style="margin-bottom: 5px">
            <span>{{tagname}}</span>
          </div>
<!--          <el-button type="primary" :disabled="tag.tagid == '' " @click="AddTagDeptUser(tag)" >添加部门 / 成员</el-button>-->
<!--          <el-button type="warning">批量导入 / 导出</el-button>-->
<!--          <el-button size="mini" type="danger" v-if="permissions.POSTdeleteTagBind" :disabled="tagbtnBoolean ? false : true" @click="deleteTagBind()" >移除</el-button>-->

          <el-table :data="tagdatas" border style="width: 100%;margin-top: 10px" v-loading="loadingUser"   ref="checkTable" @selection-change="handleSelectionChangeTag" @row-click="rowClick"	>
            <el-table-column type="selection"  width="55"></el-table-column>
            <el-table-column prop="bindid" show-overflow-tooltip label="ID"></el-table-column>
            <el-table-column prop="name" width="200" label="名称">
              <template slot-scope="scope" >
                <i class="el-icon-folder-opened" v-if="scope.row.bindtype=='0'"></i>
                <i class="el-icon-user" v-if="scope.row.bindtype=='1'"></i>
                <span style="margin-left: 10px">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="部门" >
              <template slot-scope="scope" >
                <el-tag type="primary"  style="margin-right: 5px">{{ scope.row.name }}</el-tag>
              </template>
            </el-table-column>
            <!--            <el-table-column label="操作" fixed="right" width="150" >-->
            <!--              <template slot-scope="scope">-->
            <!--                <el-button type="success" size="mini"  @click="showUserEditView(scope.row)">编辑 </el-button>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </el-table>
          <div class="block" style="flex: 1">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pmtag.page.page"
                :page-sizes="[15, 50, 100, 200]"
                :page-size="pmtag.page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pmtag.page.total"
                style="text-align:center">
            </el-pagination>
          </div>
        </div>
      </div>

      <el-dialog title="修改部门名称" :visible.sync="editDialogVisible" width="50%" >
        <el-form
            :model="role" :rules="rules" ref="editForm" label-width="180px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="部门名称" prop="name">
                <el-input  type="text" v-model="role.name" placeholder="请输入部门名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="英文名称" prop="name_en">
                <el-input  type="text" v-model="role.name_en" placeholder="请输入英文名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
<!--                <el-button type="primary" @click="submitEditForm">提交</el-button>-->
                <el-button @click="close()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
      <el-dialog title="添加子部门" :visible.sync="addDialogVisible" width="50%" >
        <el-form
            :model="role" :rules="rules" ref="addForm" label-width="180px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="上级部门">
                <el-tag>{{ role.parentName }}</el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="部门名称" prop="name_en">
                <el-input  type="text" v-model="role.name" placeholder="请输入部门名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="英文名称" prop="name_en">
                <el-input  type="text" v-model="role.name_en" placeholder="请输入英文名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
<!--                <el-button type="primary" @click="submitAddForm">提交</el-button>-->
                <el-button @click="close()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <el-dialog title="添加用户"  :before-close="closeDialog" :visible.sync="addUserDialog"  width="50%" >
        <el-form :model="rowData"  :label-position="labelPosition" :rules="rules" ref="addUserForm" label-width="85px">
          <el-row>
            <el-col :span="16">
              <el-form-item label="用户名称" prop="name">
                <el-input  type="text" v-model="rowData.name" placeholder="请输入用户名称"></el-input>
              </el-form-item>

              <el-form-item label="用户别名" prop="alias">
                <el-input  type="text" v-model="rowData.alias" placeholder="别名"></el-input>
              </el-form-item>

              <el-form-item label="账号" prop="userid">
                <el-input  type="text" v-model="rowData.userid" placeholder="成员唯一标识，不支持修改"></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="rowData.gender">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="16">
              <el-form-item label="手机" prop="mobile" :error="errorMsgmobile" >
                <el-input  type="text" v-model="rowData.mobile" @blur="checkMobile('add','mobile')"></el-input>
              </el-form-item>
              <el-form-item label="座机" prop="telephone">
                <el-input  type="text" v-model="rowData.telephone" ></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="address">
                <el-input  type="text" v-model="rowData.address" ></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email" :error="errorMsgemail">
                <el-input  type="text" v-model="rowData.email" @blur="checkEmail('add','email')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="16">
              <el-form-item label="部门" prop="depts" >
                <div style="max-height: 70%!important;overflow: auto">
                  <template  >
                    <el-dropdown size="mini"  split-button type="primary" style="margin-left: 4px;"  v-for="(item, index) in rowData.depts" :key="index" @command="(command) => {setChoosedDeptUser(command,index,'add',item.id)}">
                      {{ item.name }} <a   v-if="item.id==rowData.mainDepartment">(主)</a>
<!--                      <el-dropdown-menu slot="dropdown">-->
<!--                        <template v-if="item.id!=rowData.mainDepartment" >-->
<!--                          <el-dropdown-item command="M">设为主部门</el-dropdown-item>-->
<!--                        </template>-->
<!--                        <el-dropdown-item command="D">删除</el-dropdown-item>-->
<!--                      </el-dropdown-menu>-->
                    </el-dropdown>
                  </template>
<!--                  <el-button type="warning" style="margin-left: 4px" size="mini" @click="chooseDeptUser(rowData.depts,'add','dept')" >修改</el-button>-->
                </div>
              </el-form-item>


<!--              <el-form-item label="标签" prop="title" >-->
<!--                <template  >-->

<!--                  <el-tag :key="index" v-for="(item,index) in rowData.title" closable :disable-transitions="false">-->
<!--                    {{item.name}}-->
<!--                  </el-tag>-->

<!--                </template>-->
<!--                <el-button type="warning" size="mini"  >修改</el-button>-->
<!--              </el-form-item>-->


              <el-form-item label="职务" prop="position" >
                <el-input  type="text" v-model="rowData.position" placeholder="职务"></el-input>
              </el-form-item>

              <el-form-item label="部门负责人"  prop="isleader">
                <el-radio-group v-model="rowData.isleader" @change="((label)=>{radioChange(label,'add')})">
                  <el-radio :label= '0' >否</el-radio>
                  <el-radio :label= '1' >是</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="负责部门" prop="leaddepts"  v-if="rowData.isleader==1">
                <div style="max-height: 70%!important;overflow: auto">
                  <template  >
                    <el-dropdown size="mini" split-button type="primary" style="margin-left: 4px"  v-for="(item, index) in rowData.leaddepts" :key="index" @command="(command) => {setLeadDeptUser(command,index,'add')}">
                      {{ item.name }}
                      <el-dropdown-menu slot="dropdown">
<!--                        <el-dropdown-item command="D">删除</el-dropdown-item>-->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
<!--                  <el-button type="warning" style="margin-left: 4px" size="mini" @click="chooseDeptUser(rowData.leaddepts,'add','lead')" >修改</el-button>-->
                </div>
              </el-form-item>

              <el-form-item label="直属上级" prop="direct_leader">
                <el-input  type="text" v-model="rowData.direct_leader" placeholder="直属上级"></el-input>
              </el-form-item>

              <el-form-item label="是否禁用"  prop="enable" >
                <el-radio-group v-model="rowData.enable"  >
                  <el-radio :label = '0' >禁用</el-radio>
                  <el-radio :label = '1' >启用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item>
<!--                <el-button type="primary" @click="submitAddUserForm">提交</el-button>-->
                <el-button @click="closeUserDia()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
      <el-dialog title="编辑用户"  :before-close="closeDialog"   :visible.sync="editUserDialog" width="50%" :show-overflow-tooltip=true >
        <el-form :model="user" disabled :label-position="labelPosition" :rules="rules" ref="editUserForm" label-width="85px" :show-overflow-tooltip=true >
          <el-row>
            <el-col :span="3">
              <el-upload
                  class="avatar-uploader"
                  action="lei"
                  :on-change="handleChange"
                  :show-file-list="false"
                  :http-request="httpRequest"><!--覆盖默认上传-->
                <img v-if="user.avatar" :src="user.avatar" class="avatar" width="100%">

                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-form-item label="头像照片路径" prop="avatar" hidden>
                <el-input v-model="user.avatar"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="姓名" prop="name">
                <el-input  type="text" v-model="user.name" placeholder="姓名"></el-input>
              </el-form-item>

              <el-form-item label="别名" prop="alias">
                <el-input  type="text" v-model="user.alias" placeholder="别名"></el-input>
              </el-form-item>

              <el-form-item label="账号" prop="userid">
                <el-input  type="text" v-model="user.userid" placeholder="成员唯一标识，不支持修改"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-radio-group v-model="user.gender">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="16">
              <el-form-item label="手机" prop="mobile">
                <el-input  type="text" v-model="user.mobile" placeholder="请输入用户手机"></el-input>
              </el-form-item>
              <el-form-item label="座机" prop="telephone">
                <el-input  type="text" v-model="user.telephone" placeholder="座机"></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="address">
                <el-input  type="text" v-model="user.address" placeholder="请输入用户别名"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input  type="text" v-model="user.email" placeholder="请输入用户别名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row >
            <el-col :span="16">
              <el-form-item label="部门" prop="depts" >
                <div style="max-height: 70%!important;overflow: auto">
                  <template  >
                    <el-dropdown size="mini" split-button type="primary" style="margin-left: 4px"  v-for="(item, index) in user.depts" :key="index" @command="(command) => {setChoosedDeptUser(command,index,'edit',item.id)}">
                      {{ item.name }}<a  v-if="item.id==user.mainDepartment">(主)</a>
                      <el-dropdown-menu slot="dropdown">
                        <template v-if="item.id!=user.mainDepartment" >
<!--                          <el-dropdown-item command="M">设为主部门</el-dropdown-item>-->
                        </template>
<!--                        <el-dropdown-item command="D">删除</el-dropdown-item>-->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <el-button type="warning" style="margin-left: 4px" size="mini" @click="chooseDeptUser(user.depts,'edit','dept')" >修改</el-button>
                </div>
              </el-form-item>
<!--              <el-form-item label="标签" >-->
<!--                <el-tag :key="index" v-for="(item,index) in user.title" closable :disable-transitions="false">-->
<!--                  {{item.name}}-->
<!--                </el-tag>-->
<!--                <el-button type="warning" size="mini"> 修改</el-button>-->
<!--              </el-form-item>-->

              <el-form-item label="职务" prop="position" >
                <el-input  type="text" v-model="user.position" placeholder="职务"></el-input>
              </el-form-item>

              <el-form-item label="部门负责人"  prop="isleader" >
                <el-radio-group v-model="user.isleader"  @change="((label)=>{radioChange(label,'edit')})">
                  <el-radio :label = '0' >否</el-radio>
                  <el-radio :label = '1' >是</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="负责部门" prop="leaddepts"  v-if="user.isleader==1">
                <div style="max-height: 70%!important;overflow: auto">
                  <template  >
                    <el-dropdown size="mini" split-button type="primary" style="margin-left: 4px"  v-for="(item, index) in user.leaddepts" :key="index" @command="(command) => {setLeadDeptUser(command,index,'edit')}">
                      {{ item.name }}
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="D">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <el-button type="warning" style="margin-left: 4px" size="mini" @click="chooseDeptUser(user.leaddepts,'edit','lead')" >修改</el-button>
                </div>
              </el-form-item>


              <el-form-item label="直属上级" prop="direct_leader">
                <el-input  type="text" v-model="user.direct_leader" placeholder="直属上级"></el-input>
              </el-form-item>
              <el-form-item label="是否禁用" prop="enable" >
                <el-radio-group v-model="user.enable" >
                  <el-radio label = '0'>禁用</el-radio>
                  <el-radio label = '1'>启用</el-radio>
                </el-radio-group>
              </el-form-item>


            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item>
                <el-button type="primary" @click="submitEditUserForm">提交</el-button>
                <el-button @click="closeUserDia()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <el-dialog title="选择成员所在部门" :before-close="closeChooseDeptDialog" :append-to-body="true" :visible.sync="chooseDeptUserDialog" width="35%">
        <template slot='title'>
          <span style="font-size:20px;">选择成员所在部门</span>
        </template>
        <el-row>
          <el-col :span="10">
            <el-tree v-loading="loading" default-expand-all  :data="roles" :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNode" node-key="id"
                     :default-expanded-keys="[]" @node-click="handleNodeClickDept"  :highlight-current="true" ref="treeDeptUser" >
            <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;" slot-scope="{ node, data }">
              <span class="el-icon-folder">{{ data.name }}</span>
            </span>
            </el-tree>
          </el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2" >
            <template  >
              <el-tag  closable disable-transitions	 :key="index"  style="margin-top: 5px;font-size: medium;" effect="plain" type="primary" v-for="(item,index) in this.deptParms"
                      @close="handleTagClose(index,'dept')" >{{ item.name }}</el-tag>
            </template>
          </el-col>
        </el-row>
        <el-row >
          <el-divider></el-divider>
          <el-button type="primary" @click="trueChooseDept('dept')"> 确定</el-button>
          <el-button type="info" @click="closeChooseDeptDia('dept')">取消</el-button>
        </el-row>
      </el-dialog>


      <el-dialog title="选择负责部门" :before-close="closeChooseLeadDeptDialog" :append-to-body="true" :visible.sync="chooseLeadDeptUserDialog" width="35%">
        <template slot='title'>
          <span style="font-size:20px;">选择负责部门</span>
        </template>
        <el-row>
          {{this.user.depts}}
          <el-col :span="10">
            <el-tree v-loading="loading" default-expand-all  :data="roles" :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNode" node-key="id"
                     :default-checked-keys="[]" @node-click="handleNodeClickLeadDept"  :highlight-current="true" ref="treeLeadDeptUser" >
            <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;" slot-scope="{ node, data }" >
              <span class="el-icon-folder">{{ data.id }}--{{ data.name }}</span>
            </span>
            </el-tree>
          </el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2" >
            <template  >
              <el-tag  closable disable-transitions	 :key="index"  style="margin-top: 5px;font-size: medium;" effect="plain" type="primary" v-for="(item,index) in this.leaddeptParms"
                       @close="handleTagClose(index,'lead')" >{{ item.name }}</el-tag>
            </template>
          </el-col>
        </el-row>
        <el-row >
          <el-divider></el-divider>
          <el-button type="primary" @click="trueChooseDept('lead')"> 确定</el-button>
          <el-button type="info" @click="closeChooseDeptDia('lead')">取消</el-button>
        </el-row>
      </el-dialog>



      <el-dialog title="添加成员 / 部门到权限组" :before-close="closeChooseTagDialog" :append-to-body="true" :visible.sync="chooseTagDialog" width="35%">
        <template slot='title'>
          <span style="font-size:20px;">添加成员 / 部门到权限组</span>
        </template>
        <el-form ref="tagform" :model="tag">
          <el-row v-show="false">
            <el-col :span="10">
              <el-form-item label="tagid">
                <el-input v-model="tag.tagid"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-tree v-loading="loading" :default-expand-all="false"  :data="tag_deptuser" :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNodeTag" node-key="bindid"
                       @node-click="handleNodeClickTagDeptUser"  :highlight-current="true" ref="treeTag" >
                <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;" slot-scope="{ node, data }">
                  <span v-if="data.bindtype=='0'" class="el-icon-folder">{{ data.name }}</span>
                  <span v-if="data.bindtype=='1'" class="el-icon-user">{{ data.name }}</span>
                </span>
              </el-tree>
            </el-col>
            <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
            <el-col :span="2" >
              <template  >
                <el-tag  closable disable-transitions	 :key="index"  style="margin-top: 5px;font-size: medium;" effect="plain" type="primary" v-for="(item,index) in tagDeptuserParms"
                         @close="handleTagClose(index,'tag')" >{{ item.name }}</el-tag>
              </template>
            </el-col>
          </el-row>
        </el-form>
        <el-row >
          <el-divider></el-divider>
          <el-button type="primary" @click="trueChooseTag('tag')"> 确定</el-button>
          <el-button type="info" @click="closeChooseTagDia('tag')">取消</el-button>
        </el-row>
      </el-dialog>

      <el-dialog title="添加权限组"  :before-close="closeTagDialog" :visible.sync="addTagDig"  width="50%" >
        <el-form :model="tagData" :label-position="labelPosition"  ref="addTagForm" label-width="85px">
          <el-row>
            <el-col :span="16">
              <el-form-item label="权限组ID" prop="tagid">
                <el-input  type="text" disabled v-model="tagData.tagid"></el-input>
              </el-form-item>
              <el-form-item label="权限组名称" prop="tagname">
                <el-input  type="text" v-model="tagData.tagname" placeholder="请输入用户名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item>
                <el-button type="primary" @click="submitAddTagForm">提交</el-button>
                <el-button @click="closeTagDialog()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <el-dialog title="编辑权限组"  :before-close="closeTagDialog" :visible.sync="editTagDig"  width="50%" >
        <el-form :model="tagData" :label-position="labelPosition"  ref="editTagForm" label-width="85px">
          <el-row>
            <el-col :span="16">
              <el-form-item label="权限组ID" prop="tagid">
                <el-input  type="text" disabled v-model="tagData.tagid"></el-input>
              </el-form-item>
              <el-form-item label="权限组名称" prop="tagname">
                <el-input  type="text" v-model="tagData.tagname" placeholder="请输入用户名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item>
                <el-button type="primary" @click="submitEditTagForm">提交</el-button>
                <el-button @click="closeTagDialog()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

    </div>

  </div>
</template>

<script scoped>

import {Message} from "element-ui";

export default {
  name: "index",
  watch: {
    filterText(val) {
      this.$refs.depttree.filter(val);
    },
    treeText(val) {
      this.$refs.usertree.filter(val);
    }
  },
  data() {
    return {
      errorMsgmobile:'',
      errorMsgemail:'',

      tableData:'',
      talbename:'所有成员',
      tablerow:'',
      btnBoolean:false,
      delarr:[],//存放删除的数据
      multipleSelection:[],//多选的数据
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      pmtag: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      user: {},
      datas: [],
      tagdatas:[],
      filterText:'',
      treeText:'',
      permissions: {},
      loading: false,
      loadingUser:false,
      menuPermissionsLoading: false,
      role: {
        id: '',
        name: '',
        parentId: '',
        parentName: '',
        name_en:'',
      },
      editDialogVisible: false,
      editUserDialog:false,
      addDialogVisible: false,

      labelPosition: 'right',
      //选择员工所在部门
      chooseDeptUserDialog:false,
      //选择员工负责部门
      chooseLeadDeptUserDialog:false,

      roles: [],
      activeName:'dept',
      deptRoles:[],
      tagRoles:[],
      defaultProps: {
        childre: 'children',
        label: 'name'
      },
      rules: {
        name_en: [{required: true, message: '必填', trigger: 'blur'}],

        id: [{required: true, message: '', trigger: 'blur'}],
        userid: [{required: true, message: '系统ID必填', trigger: 'blur'}],
        name: [{required: true, message: '用户必填', trigger: 'blur'}],
        parentId: [{required: true, message: '', trigger: 'blur'}],
        depts: [{required: true, message: '部门必填', trigger: 'blur'}],
        isleader: [{required: true, message: '必填', trigger: 'blur'}],
        enable: [{required: true, message: '必填', trigger: 'blur'}],
        email: [
          {required: false, message: "请输入邮箱", trigger: "blur"},
          {pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: "请输入正确的邮箱", trigger: "blur"},
        ],
        mobile:
            [
              {required: true, message: "请输入手机号", trigger: "blur"},
              // 这个只能验证手机号
            ],
        telephone:
            [
              {required: false, message: "请输入座机", trigger: "blur"},
            ]
      },


      defaultExpandedKeys: [1],
      menuPermissions: [],
      handleNode: {},
      menuChecked: [],
      handleMenuNode: {},

      //添加用户参数
      addUserDialog:false,
      rowData:{
        userid:"",name:"",alias:"",mobile:"",depts:[],leaddepts:[],position:"",gender:"",email:"",telephone:"",isLeaderInDept:'',enable: 1 ,mainDepartment:'',isleader : 0
      },

      //图片上传
      imageUrl: '',
      tempUrl: '',

      //编辑用户
      deptParms:[],
      deptParm:{
        id:'',
        name:'',
      },
      leaddeptParms:[],
      leaddeptParm:{
        id:'',
        name:'',
      },

      //标签
      tagname:"所有人",
      tag_deptuser: [],
      chooseTagDialog:false,

      tagDeptuserParms:[],
      tagDeptuserParm:{
        bindid:'',
        name:'',
        bindtype:''
      },
      tag:{
        tagid:'',
        taglist: []
      },
      tagbinddelarr:[],//存放删除的数据
      tagbindmultipleSelection:[],//多选的数据
      tagbtnBoolean:false,
      //添加标签
      addTagDig:false,
      editTagDig:false,
      tagData:{},

    }
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
    this.initDeptUser()
  }, methods: {
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init(dek) {
      if (this.permissions.GET) {
        this.menuPermissions = []
        this.loading = true
        this.getRequest("/sys/dept").then(data => {
          this.loading = false
          if (data) {
            this.roles = data.obj
            this.defaultExpandedKeys = [1, dek]
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }

      if (this.permissions.GET) {
        this.menuPermissions = []
        this.loading = true
        this.getRequest("/sys/dept/tag").then(data => {
          this.loading = false
          if (data) {
            this.tagRoles = data.obj
            this.defaultExpandedKeys = [1, dek]
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
      this.initTag(dek);

    },initDeptUser(){
      this.getRequest("/sys/dept/deptuser", this.pm).then(data => {
        this.loadingUser = false
        if (data.status == 200) {
          this.datas = data.obj.list
          this.pm.page = data.obj.page
          // this.talbename=''
        }
      })
    },
    initTag(){
      this.getRequest("/sys/dept/tagdeptuser").then(data => {
        this.loading = false
        if (data) {
          this.tag_deptuser = data.obj
        }
      })
    },
    filterNodeT(value, data) {
      if (!value) return true;
      return data.tagname.indexOf(value) !== -1;
    },
    filterNodeD(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterNode(value, data,type) {
      if (!value) return true;
      if (type=="tag"){
        return data.tagname.indexOf(value) !== -1;
      }
      if (type=="dept"){
        return data.name.indexOf(value) !== -1;
      }
    },
    handleClickTab(){
      console.log()
    },
    // 添加标签
    AddTag(){
      this.tagData={}
      this.addTagDig=true
    },
    submitAddTagForm(){
      this.postRequest("/sys/dept/tag", this.tagData).then(data => {
        if (data.status == 200) {
          Message.success("添加成功")
          this.addTagDig = false;
          this.init()
        } else {
          Message.error("添加失败（" + data.msg + "）")
        }
      })


    },
    // 编辑标签
    EditTag(row){
      this.tagData=row
      this.editTagDig=true
    },
    submitEditTagForm(){
      this.putRequest("/sys/dept/tag", this.tagData).then(data => {
        if (data.status == 200) {
          Message.success("修改成功")
          this.editTagDig = false;
          this.tagData = {};
          this.init()
        } else {
          Message.error("修改失败（" + data.msg + "）")
        }
      })
    },
    // 删除标签
    DeleteTag(row){
      this.$confirm('确认关闭？')
          .then(() => {
            this.deleteRequest("/sys/dept/tag", row).then(data => {
              if (data.status == 200) {
                Message.success("删除成功")
                this.init()
                this.tagdatas=[]
                this.tagname="所有人"
                this.tag={}
                this.pmtag={
                  pm: {},
                  page: {
                    pageNum: 1,
                        pageSize: 15,
                        pages: 0,
                        total: 0
                  }
                }
              } else {
                Message.error("删除失败（" + data.msg + "）")
              }
            })
          })
          .catch(() => {

          });
    },
    closeTagDialog(){
      this.addTagDig=false
      this.editTagDig=false
    },
    showEditView(data) {
      this.editDialogVisible = true
      this.role = {}
      this.role = data
    },
    handleSelectionChange(row) {
      this.multipleSelection = row;
      if (this.multipleSelection.length!=0)
      {
        this.btnBoolean=true;
      }else{
        this.btnBoolean=false;
      }
    },

    handleSelectionChangeTag(row) {
      this.tagbindmultipleSelection = row;
      if (this.tagbindmultipleSelection.length!=0)
      {
        this.tagbtnBoolean=true;
      }else{
        this.tagbtnBoolean=false;
      }
    },



    rowClick(row){
      if(row.bindtype==0){
        this.activeName="dept"
        this.$nextTick(function(){
          this.$refs.depttree.setCurrentKey(row.bindid);  //"treeList"是你自己在树形控件上设置的 ref="treeList" 的名称
        })
        this.delarr=[];
        this.multipleSelection=[];
        this.talbename=row.name;
        //获取用户信息
        this.pm.pm.department=row.bindid
        this.getRequest("/sys/dept/deptuser", this.pm).then(data => {
          this.loadingUser = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          }
        })

      }else{
        this.user={}
        this.getRequest("/sys/dept/tagUser", {bindid:row.bindid}).then(data => {
          if (data.status == 200) {
            this.user = data.obj
            this.editUserDialog = true
          }
        })
      }
    },

    //编辑用户信息
    showUserEditView(rowData){
      this.user={}
      this.user = rowData
      this.editUserDialog = true
    },
    //添加用户信息
    showUserAddView(){
      this.rowData={
        depts:[],
        leaddepts:[],
        mainDepartment:this.roles[0].id,
        isleader:0,
        enable:1,
      }

      this.leaddeptParm={}
      this.rowData.depts.push({id:this.roles[0].id,name:this.roles[0].name})
      this.deptParms.push({id:this.roles[0].id,name:this.roles[0].name})
      this.addUserDialog=true;

      // if (data==0){
      //   this.rowData.leaddepts=[];
      //   this.leaddeptParms=[];
      // }else if(data==1){
      //   this.leaddeptParms=[]
      //   for (let i=0;i<this.rowData.depts.length;i++){
      //     this.leaddeptParm={}
      //     this.leaddeptParm.id=this.user.depts[i].id
      //     this.leaddeptParm.name=this.user.depts[i].name
      //     this.leaddeptParms.push(this.leaddeptParm);
      //   }
      //   this.rowData.leaddepts=this.leaddeptParms;
      // }


    },
    closeDialog() {
      this.$confirm('确认关闭？')
          .then(() => {
            this.user={};
            this.deptParms=[];
            this.rowData={
              depts:[],
              leaddepts:[],
              mainDepartment:this.roles[0].id,
              isleader:0,
              enable:1,
            }
            this.addUserDialog = false
            this.editUserDialog = false
            this.errorMsgemail=''
            this.errorMsgmobile=''
            this.initDeptUser();
          })
          .catch(() => {

          });
    },

    radioChange(data,page){
      if ("add"==page){
        if (data==0){
          this.rowData.leaddepts=[];
          this.leaddeptParms=[];
        }else if(data==1){
          this.leaddeptParms=[]
          for (let i=0;i<this.rowData.depts.length;i++){
            this.leaddeptParm={}
            this.leaddeptParm.id=this.rowData.depts[i].id
            this.leaddeptParm.name=this.rowData.depts[i].name
            this.leaddeptParms.push(this.leaddeptParm);
          }
          this.rowData.leaddepts=this.leaddeptParms;
        }
      }else if("edit"==page){
        if (data==0){
          this.user.leaddepts=[];
          this.leaddeptParms=[];
        }else if(data==1){
          this.leaddeptParms=[]
          for (let i=0;i<this.user.depts.length;i++){
            this.leaddeptParm={}
            this.leaddeptParm.id=this.user.depts[i].id
            this.leaddeptParm.name=this.user.depts[i].name
            this.leaddeptParms.push(this.leaddeptParm);
          }
          this.user.leaddepts=this.leaddeptParms;
        }
      }

    },

    //选择成员所在部门
    chooseDeptUser(dept,pagetype,type){
      if (pagetype=='add'){
        console.log()
      }
      if (pagetype=='edit'){
        console.log()
      }
      if ('dept'==type){
        this.deptParms=dept;
        this.chooseDeptUserDialog=true;
      }
      if ('lead'==type){
        this.leaddeptParms=dept;
        this.chooseLeadDeptUserDialog=true;
      }

    },

    closeChooseDeptDia(type){
      if (type=='dept'){
        if (this.deptParms.length==1){
          this.user.mainDepartment=this.deptParms[0].id
          this.rowData.mainDepartment=this.deptParms[0].id
        }
        this.deptParms=[];
        this.chooseDeptUserDialog=false;
      }else{
        this.leaddeptParms=[];
        this.chooseLeadDeptUserDialog=false;
      }

    },
    closeChooseDeptDialog(){
      this.$confirm('确认关闭？')
          .then(() => {
            if (this.deptParms.length==1){
              this.user.mainDepartment=this.deptParms[0].id
              this.rowData.mainDepartment=this.deptParms[0].id
            }
            this.deptParms=[];
            this.chooseDeptUserDialog=false;
          })
          .catch(() => {

          });
    },
    closeChooseLeadDeptDialog(){
      this.$confirm('确认关闭？')
          .then(() => {
            this.chooseLeadDeptUserDialog=false;
          })
          .catch(() => {

          });
    },

    trueChooseDept(type){
      if (type=='dept'){
        this.user.depts=this.deptParms;
        this.rowData.depts=this.deptParms;
        if (this.deptParms.length==1){
          this.user.mainDepartment=this.deptParms[0].id;
          this.rowData.mainDepartment=this.deptParms[0].id;
        }
        this.deptParms=[];
        this.chooseDeptUserDialog=false;
      }else if(type=='lead'){
        this.user.leaddepts=this.leaddeptParms;
        this.rowData.leaddepts=this.leaddeptParms;
        this.leaddeptParms=[];
        this.chooseLeadDeptUserDialog=false;
      }


    },
    //删除用户信息
    deleteDeptUser() {
      this.delarr=[];
      const length = this.multipleSelection.length;
      if (length>0){
        this.btnBoolean=true//启用批量按钮
        for (let i = 0; i < length; i++) {
          this.delarr.push(this.multipleSelection[i].userid+"")
        }
        this.$confirm("确认删除？")
            .then(() => {
              this.deleteRequest("/sys/dept/deptuser", {'userids':this.delarr.join(",")}).then(data => {
                if (data.status == 200) {
                  Message.success("成功")
                  this.initDeptUser();
                } else {
                  Message.error({message: data.msg + '(' + data.status + ')'})
                }
              })
            }).catch(() => {
          this.multipleSelection=[];
          this.delarr=[];
        })
      }else {
        Message.error({message: '请选中信息'})
      }
    },
    //添加部门用户
    submitAddUserForm(){
      this.$refs["addUserForm"].validate(valid => {
        if (valid) {
          this.postRequest("/sys/dept/deptuser", this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addUserDialog = false;
              this.initDeptUser();
            } else {
              Message.error("添加失败（" + data.status + "）")
            }
          })
        } else return false;
      })
    },

    //修改部门用户
    submitEditUserForm(){
      this.$refs["editUserForm"].validate((valid) => {
        if (valid) {
          this.putRequest('/sys/dept/deptuser', this.user).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editUserDialog = false
              this.initDeptUser();
            } else {
              Message.error("修改失败（" + data.status + "）")
            }
          })
        } else return false
      })

      this.errorMsgemail=''
      this.errorMsgmobile=''
    },


    //修改部门
    submitEditForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.role.children = null
          this.putRequest('/sys/dept', this.role).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialogVisible = false
              this.init(this.role.id)
              this.role = {}
            } else {
              Message.error("修改失败（" + data.status + "）")
            }
          })
        } else return false
      })
    },
    //添加子部门
    showAddView(data) {
      this.addDialogVisible = true
      this.role = {}
      this.role.parentName = data.name
      this.role.parentId = data.id
    }, submitAddForm() {
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          this.postRequest("/sys/dept", this.role).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialogVisible = false;
              this.init(this.role.parentId)
              this.role = {}
            } else {
              Message.error("添加失败（" + data.status + "）")
            }
          })
        } else return false;
      })
    }, deleteRow(data) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/dept", {id: data.id}).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    }, close() {
      //部门操作
      this.role = {}
      this.editDialogVisible = false
      this.addDialogVisible = false
    },closeUserDia(){
      //用户操作
      // this.user={}
      // this.rowData={}
      // this.deptParm={}
      // this.deptParms=[]
      this.addUserDialog=false
      this.editUserDialog=false
      this.errorMsgmobile=''
      this.errorMsgemail=''
      this.initDeptUser();
    },handleNodeClick(data) {
      this.delarr=[];
      this.multipleSelection=[];
      this.talbename=data.name;
      //获取用户信息
      this.pm.pm.role_id=data.id
      this.pm.pm.department=data.id
      this.clickdept=data.id;
      this.getRequest("/sys/dept/deptuser", this.pm).then(data => {
        this.loadingUser = false
        if (data.status == 200) {
          this.datas = data.obj.list
          this.pm.page = data.obj.page
        }
      })
    },
    handleNodeClickTag(data){
      this.pmtag.pm.tagid=data.tagid
      this.tag.tagid=data.tagid
      this.tagname=data.tagname
      this.getRequest("/sys/dept/tagBind", this.pmtag).then(data => {
        this.loadingUser = false
        if (data.status == 200) {
          this.tagdatas = data.obj.list
          this.pmtag.page = data.obj.page
        }
      })
    },
    AddTagDeptUser(tag){
      if (tag.tagid=='' || tag.tagid=="" ){
        Message.warning("请选择要添加的权限组");
      }else {
        this.initTag();
        this.chooseTagDialog=true;
      }
    },
    //移除标签绑定
    deleteTagBind() {
      this.tagbinddelarr=[];
      const length = this.tagbindmultipleSelection.length;
      if (length>0){
        this.tagbtnBoolean=true//启用批量按钮
        for (let i = 0; i < length; i++) {
          this.tagbinddelarr.push(this.tagbindmultipleSelection[i])
        }
        this.$confirm("确认删除？")
            .then(() => {
              this.postRequest("/sys/dept/deleteTagBind", {'tagid':this.tag.tagid,'tagbindlist':this.tagbinddelarr}).then(data => {
                if (data.status == 200) {
                  Message.success("成功")
                  this.handleNodeClickTag(this.tag);
                } else {
                  Message.error({message: data.msg + '(' + data.status + ')'})
                }
              })
            }).catch(() => {
          this.tagbindmultipleSelection=[];
          this.tagbinddelarr=[];
        })
      }else {
        Message.error({message: '请选中信息'})
      }
    },


    closeChooseTagDialog(){
      this.chooseTagDialog = false;
    },
    handleNodeClickTagDeptUser(data){
      this.tagDeptuserParm.bindid=data.bindid+"";
      this.tagDeptuserParm.name=data.name;
      this.tagDeptuserParm.bindtype=data.bindtype;

      this.tagDeptuserParms.push(this.tagDeptuserParm);
      for (let i = 0; i < this.tagDeptuserParms.length - 1; i++) {
        for (let j = this.tagDeptuserParms.length - 1; j > i; j--) {
          if (this.tagDeptuserParms[i].bindid==this.tagDeptuserParms[j].bindid) {
            this.tagDeptuserParms.splice(j, 1);
          }
        }
      }
      this.tagDeptuserParm= {};
    },
    trueChooseTag(){
      this.tag.taglist=this.tagDeptuserParms
      this.postRequest("/sys/dept/tagBind", this.tag).then(data => {
        if (data.status == 200) {
          Message.success("添加成功")
          let data={
            tagid:this.tag.tagid
          }
          this.chooseTagDialog=false;

          this.tagDeptuserParms=[];
          this.tagDeptuserParm={
            bindid:'',
            name:'',
            bindtype:''
          };
          this.tag={
            tagid:'',
            taglist: []
          }
          this.handleNodeClickTag(data);
        } else {
          Message.error("添加失败（" + data.msg + "）")
        }
      })
    },
    closeChooseTagDia(){
      let data={
        tagid:this.tag.tagid
      }
      this.chooseTagDialog=false;
      this.tagDeptuserParms=[];
      this.tagDeptuserParm={
        bindid:'',
        name:'',
        bindtype:''
      };
      this.tag={
        tagid:'',
        taglist: []
      }
      this.handleNodeClickTag(data);
    },
    filterNodeTag(){

    },
    handleNodeClickDept(data){
      this.deptParm.id=data.id;
      this.deptParm.name=data.name;
      this.deptParms.push(this.deptParm);
      for (let i = 0; i < this.deptParms.length - 1; i++) {
        for (let j = this.deptParms.length - 1; j > i; j--) {
          if (this.deptParms[i].id==this.deptParms[j].id) {
            this.deptParms.splice(j, 1);
          }
        }
      }
      this.deptParm= {};
    },
    handleNodeClickLeadDept(data){
      let id=data.id;
      for(let i=0;i<this.user.depts.length;i++){
        if(id===this.user.depts[i].id){
          this.leaddeptParm.id=data.id;
          this.leaddeptParm.name=data.name;
          this.leaddeptParms.push(this.leaddeptParm);
          for (let i = 0; i < this.leaddeptParms.length - 1; i++) {
            for (let j = this.leaddeptParms.length - 1; j > i; j--) {
              if (this.leaddeptParms[i].id==this.leaddeptParms[j].id) {
                this.leaddeptParms.splice(j, 1);
              }
            }
          }
        }
      }
      this.leaddeptParm= {};
    }
    ,handleTagClose(index,type){
      if ('lead'==type){
        if (this.leaddeptParms.length==1){
          console.log()
        }else{
          this.leaddeptParms.splice(index, 1);
        }
      }else if('dept'==type){
        if (this.deptParms.length==1){
          console.log()
        }else{
          this.deptParms.splice(index, 1);
        }
      }else if('tag'==type){
        this.tagDeptuserParms.splice(index, 1);
      }

    },
    //设置所属部门
    setChoosedDeptUser(command,index,page,id){
      //删除
      if ("D"==command){
        if (page=='add'){
          if (this.rowData.depts.length>1){
            let removeid0=this.rowData.depts[index].id;
            for (let i=0;i<this.rowData.leaddepts.length;i++){
              if(removeid0==this.rowData.leaddepts[i].id){
                this.rowData.leaddepts.splice(i,1);
                if (this.rowData.leaddepts.length==0){
                  this.rowData.isleader=0;
                }
              }
            }
            this.rowData.depts.splice(index, 1);
            if (removeid0==this.rowData.mainDepartment){
              this.rowData.mainDepartment=this.rowData.depts[0].id
            }
          }else if(this.rowData.depts.length==1){
            Message.error("至少选一个部门")
          }
        }else{
          if (this.user.depts.length>1) {
            let removeid1 = this.user.depts[index].id;
            for (let i=0;i<this.user.leaddepts.length;i++){
              if(removeid1==this.user.leaddepts[i].id){
                this.user.leaddepts.splice(i,1);
                if (this.user.leaddepts.length==0){
                  this.user.isleader=0;
                }
              }
            }
            this.user.depts.splice(index, 1);
            if (removeid1 == this.user.mainDepartment) {
              this.user.mainDepartment = this.user.depts[0].id
            }
          }else if(this.user.depts.length==1){
            Message.error("至少选一个部门")
          }
        }
      }
      //设为主部门
      if ("M"==command){
        if (page=='add'){
          this.rowData.mainDepartment=id;
        }else{
          this.user.mainDepartment=id;
        }
      }

    },
    //设置负责部门
    setLeadDeptUser(command,index,page){
      //删除
      if ("D"==command){
        if (page=='add'){
          if (this.rowData.leaddepts.length>1){
            this.rowData.leaddepts.splice(index, 1);
          }else if(this.rowData.leaddepts.length==1 && this.rowData.isleader==1){
            Message.error("至少选一个负责部门")
          }
        }else{
          if (this.user.leaddepts.length>1) {
            this.user.leaddepts.splice(index, 1);
          }else if(this.user.leaddepts.length==1 && this.user.isleader==1){
            Message.error("至少选一个负责部门")
          }
        }
      }
    },
    submitMenuPermissions() {
      let halfCheckedKeys = this.$refs.menuTree.getHalfCheckedKeys()
      let checkedKeys = this.$refs.menuTree.getCheckedKeys()
      let checked = halfCheckedKeys.concat(checkedKeys)
      this.putRequest("/sys/role/menuPermissions", {
        id: this.handleNode.id,
        parentId: this.handleNode.parentId,
        checked: checked
      }).then(data => {
        if (data.status == 200) {
          Message.success("配置成功")
          this.handleNodeClick(this.handleNode)
        }
      })

    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.initDeptUser()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.initDeptUser()
    },
    //将上传图片的原路径赋值给临时路径
    handleChange(file) {
      this.tempUrl = URL.createObjectURL(file.raw);
    },
    //实现图片上传功能
    httpRequest(item) {
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        // post上传图片
        let App = this;
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        App.getRequest(mf, function (result) {
          if (result.result == "true") {
            App.$notify.success({
              title: '温馨提示：',
              message: result.message,
            });
            //将临时文件路径赋值给显示图片路径（前端显示的图片）
            App.imageUrl = App.tempUrl;
            //将后台传来的数据库图片路径赋值给car对象的图片路径
            App.car.carImg = result.imgUrl;
          } else {
            App.$notify.error({
              title: '温馨提示：',
              message: result.message,
            });
          }
        })
      }
    },
    // 添加/编辑用户校验手机号码是否重复
    checkMobile(page,type) {
      let param = { mobile: '' };
      this.errorMsgmobile = ""; //校验前需情况错误信息
      if (page=='add'){
        if (type=='mobile'&& this.rowData.mobile){
          param.mobile=this.rowData.mobile
          this.postMes(param);
        }
      }else if(page=='edit' && this.user.mobile){
        if (type=='mobile'&& this.user.mobile){
          param.mobile=this.user.mobile
          this.postMes(param);
        }
      }
    },
    checkEmail(page,type) {
      let param = {email:'' };
      this.errorMsgemail = ""; //校验前需情况错误信息
      if (page=='add'){
        if (type=='email' && this.rowData.email){
          param.email=this.rowData.email
          this.postMes(param);
        }
      }else if(page=='edit' && this.user.mobile){
        if (type=='email' && this.user.email){
          param.email=this.user.email
          this.postMes(param);
        }
      }
    }
    ,postMes(param){
      this.postRequest("/sys/dept/checkRules", param).then(data => {
        if (data.status != 200) {
          if (param.mobile){
            this.errorMsgmobile = data.msg; //后台返回错误信息
          }
          if (param.email){
            this.errorMsgemail = data.msg; //后台返回错误信息
          }
        }
      })
    },
    synchDept(){
      this.$confirm("确认同步？")
          .then(() => {
            this.getRequest("/sys/dept/synchDept").then(data => {
              if (data.status == 200) {
                Message.success("部门同步成功")
                this.init();
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})

              }
            })
          }).catch(() => {
      })
    },
    synchTag(){
      this.$confirm("确认同步？")
          .then(() => {
            this.getRequest("/sys/dept/synchTag").then(data => {
              if (data.status == 200) {
                Message.success("标签同步成功")
                this.init();
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})

              }
            })
          }).catch(() => {
      })
    },
    synchUser(){
      this.$confirm("确认同步？")
          .then(() => {
            this.getRequest("/sys/dept/synchUser").then(data => {
              if (data.status == 200) {
                Message.success("用户同步成功")
                this.init();
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})

              }
            })
          }).catch(() => {
      })
    }

  }
}
</script>

<style>


/* 点击树结构项的选中颜色 */
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color:  #DCDFE6;
}


.depBtn {
  padding: 2px;
}

/*.custom-tree-node {*/
/*  flex: 1;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  font-size: 14px;*/
/*  padding-right: 8px;*/
/*}*/

.avatar-uploader {
  margin-top: 8px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: flex;
}
.el-divider--vertical {
  height: 25em;
  margin-left: 20px;
}

</style>
