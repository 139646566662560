<template>
  <div class="MerTmpAffirm">
    <div style="display: flex;justify-content: flex-start;"  >
      <el-steps :active="index" direction="vertical">
        <el-step title="基本信息" size="mini"></el-step>
        <el-step title="结算信息"></el-step>
        <el-step title="费率信息"></el-step>
        <el-step title="门店信息"></el-step>
        <el-step title="证件图片信息"></el-step>
        <el-step title="其他信息"></el-step>
      </el-steps>
      <div style="margin-left: 50px;display: flex;justify-content: center;align-items: center;width: 100%;">
        <div v-show="index==0">
          <el-form :model="rowData" ref="addForm0" >
            <el-divider content-position="left">基本信息</el-divider>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="商户名称" prop="merName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merName" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="商户简称" prop="shortName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.shortName" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="客服电话" prop="servicePhone">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.servicePhone" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="所属行业" prop="mccidName">
                  <el-select v-model="rowData.mccidName" filterable remote reserve-keyword placeholder="请输入关键词"  :loading="mccloading">
                    <el-option v-for="item in mccoptions" :key="item.value" :label="item.label" :value="item.value" >
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>

              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="商户类型" prop="merType">
                  <el-select disabled v-model="rowData.merType" >
                    <el-option
                        v-for="item in DICKV.mer_type"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">

                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="商户来源" prop="merSource">
                  <el-select disabled v-model="rowData.merSource" >
                    <el-option
                        v-for="item in DICKV.mer_source"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="所属代理商" prop="belongAgencyNo">
                  <el-select disabled v-model="rowData.belongAgencyNo"  >
                    <el-option
                        v-for="item in allAgency"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                    </el-option>
                  </el-select>


                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="商户负责人" prop="contactPerson">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.contactPerson" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="商户负责人电话" prop="contactPhone">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.contactPhone" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="拓展人" prop="expandMan">
                  <el-select  disabled v-model="rowData.expandMan"  >
                    <el-option
                        v-for="item in saleperoptions"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value">
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="业务联系人" prop="businessContactName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.businessContactName" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="业务联系人电话" prop="businessContactPhone">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.businessContactPhone" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="业务场景" prop="businessFlag">
                  <el-select disabled v-model="rowData.businessFlag" >
                    <el-option
                        v-for="item in DICKV.business_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="无纸化标识" prop="agreement">
                  <el-select disabled v-model="rowData.agreement" >
                    <el-option
                        v-for="item in DICKV.agreement"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="受益人标识" prop="bnfDetail">
                  <el-select disabled v-model="rowData.bnfDetail" >
                    <el-option
                        v-for="item in DICKV.bnf_detail"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结算方式" prop="clearMode">
                  <el-select disabled v-model="rowData.clearMode" >
                    <el-option
                        v-for="item in DICKV.clear_mode"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="商户标识" prop="merFlag">
                  <el-select disabled v-model="rowData.merFlag" >
                    <el-option
                        v-for="item in DICKV.mer_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="所属父客户" prop="parentId">
                  <el-select disabled v-model="rowData.parentId" clearable >
                    <el-option
                        v-for="item in meroptions"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value">
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="注册地址" prop="address">
                  <el-cascader disabled
                      size="large"
                      :options="areoptions"
                      v-model="rowData.addressCode"
                      >
                  </el-cascader>
                </el-form-item>
                <el-input autocomplete="off" type="text" v-model="rowData.address" style="display: none" ></el-input>
                <el-input autocomplete="off" type="text" v-model="rowData.addressCode" style="display: none" ></el-input>
              </el-col>
            </el-row>
            <el-divider content-position="left">法人信息</el-divider>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="法人姓名" prop="legalName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.legalName" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="证件类型" prop="legalIdType">
                  <el-select disabled v-model="rowData.legalIdType" >
                    <el-option
                        v-for="item in DICKV.legal_id_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="证件号" prop="legalIdNo">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.legalIdNo" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="证件有效期" prop="legalIdExpire">
                  <el-date-picker disabled
                      v-model="rowData.legalIdExpire"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="长期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="法人手机号" prop="legalPhone">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.legalPhone" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-show="index==1" >
          <el-form :model="rowData.settleInfoTmp"  ref="addForm1" >
            <el-divider content-position="left">结算账户信息</el-divider>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="卡折类型" prop="acctNoType">
                  <el-select disabled v-model="rowData.settleInfoTmp.acctNoType"  >
                    <el-option
                        v-for="item in DICKV.acct_no_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="账户类型" prop="acctType">
                  <el-select disabled v-model="rowData.settleInfoTmp.acctType"  >
                    <el-option
                        v-for="item in DICKV.acct_type"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="账户名" prop="acctName">
                  <el-input disabled autocomplete="off"  type="text" v-model="rowData.settleInfoTmp.acctName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="账户号"  prop="acctId">
                  <el-input disabled autocomplete="off"  type="text" v-model="rowData.settleInfoTmp.acctId" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="渠道电子账户号" prop="chanBankNo">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.settleInfoTmp.chanBankNo" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结算人身份证号" prop="settlerIdNo">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.settleInfoTmp.settlerIdNo" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="所属总行" prop="bankCode">
                  <el-select disabled v-model="rowData.settleInfoTmp.bankCode" filterable  >
                    <el-option
                        v-for="item in bankoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="所属市区" prop="accresCode">
                  <el-select disabled v-model="rowData.settleInfoTmp.accresCode" filterable >
                    <el-option
                        v-for="item in bankcityoptions"
                        :key="item.label"
                        :label="item.value"
                        :value="item.value">
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="所属支行" prop="cnapsCode">
                  <el-select disabled v-model="rowData.settleInfoTmp.cnapsCode" filterable>
                    <el-option
                        v-for="item in bankcnapsoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-show="index==2" >
          <el-table :data="rmenus" row-key="id" border :tree-props="{children: 'children',hasChildren:'hasChildren'}" style="width: 700px">
            <el-table-column width="150" prop="sysTradeTypeId" label="产品类型"></el-table-column>
            <el-table-column prop="sysTradeTypeDescribe" label="交易描述"></el-table-column>
            <el-table-column prop="productStatus" label="状态">
              <template slot-scope="scope" >
                {{ getDICKV(scope.row.productStatus, 'product_status') }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button v-if="scope.row.level!=0"
                           size="mini" type="primary" @click="showEditProRate(scope.row)">查看 </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog :close-on-click-modal="false"
                     v-loading="merproRateloading"
                     element-loading-text="拼命加载中"
                     element-loading-spinner="el-icon-loading"
                     element-loading-background="rgba(0, 0, 0, 0.8)"
                     :before-close="closeMerRateDialog" title="" :visible.sync="merRateDialog"
                     width="37%" append-to-body>
            <template slot='title'>
              <span style="font-size:20px;">费率信息配置--{{merRate.sysTradeTypeDescribe}}</span>
            </template>
            <el-form :model="merRate"  ref="merRateForm" label-width="180px">
              <el-row>
                <el-col :span="15">
                  <el-form-item prop="feeRate" label="借记卡费率(‰)">
                    <el-input-number size="medium" v-model="merRate.feeRate" disabled :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                  </el-form-item>
                  <el-form-item prop="creditRate" label="贷记卡费率(‰)">
                    <el-input-number size="medium" v-model="merRate.creditRate" disabled :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                  </el-form-item>
                </el-col>
                <el-col :span="15" v-if="merRate.sysTradeTypeId=='JSP534'">
                  <el-form-item prop="feePen" label="元/笔" >
                    <el-input-number size="medium" v-model="merRate.feePen" disabled :precision="0" :step="1" :min="0" :max="100"/>
                  </el-form-item>
                </el-col>
                <el-col :span="15">
                  <el-form-item prop="lowLimit" label="借记单笔保底">
                    <el-input-number size="medium" v-model="merRate.lowLimit" disabled :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                  </el-form-item>
                  <el-form-item prop="topLimit" label="借记单笔封顶">
                    <el-input-number size="medium" v-model="merRate.topLimit" disabled :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                  </el-form-item>
                </el-col>
                <el-col :span="15">
                  <el-form-item label="贷记单笔保底" prop="lowLimitCredit">
                    <el-input-number size="medium" v-model="merRate.lowLimitCredit" disabled :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                  </el-form-item>
                  <el-form-item label="贷记单笔封顶" prop="topLimitCredit">
                    <el-input-number size="medium" v-model="merRate.topLimitCredit" disabled :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                  </el-form-item>
                </el-col>
                <el-col :span="15" >
                  <el-form-item prop="feeCycle" label="收费周期">
                    <el-select  v-model="merRate.feeCycle" disabled >
                      <el-option
                          v-for="item in DICKV.fee_cycle"
                          :key="item.k"
                          :label="item.k"
                          :value="item.v"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="20">
                  <el-form-item>
                    <el-button @click="closeMerRateDialog()">取消</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-dialog>
        </div>
        <div v-show="index==3">
<!--          <el-button type="primary" size="mini" @click="AddBranch">添加门店信息</el-button>-->
          <el-table highlight-current-row element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)" :data="rowData.merBranchInfoTmp" style="width: 100%">
            <el-table-column width="50" show-overflow-tooltip label="ID" prop="id" ></el-table-column>
            <el-table-column width="50" show-overflow-tooltip label="门店编号" prop="branchNo" ></el-table-column>
            <el-table-column width="120" show-overflow-tooltip label="门店名称" prop="branchName" ></el-table-column>
            <el-table-column width="80" show-overflow-tooltip label="状态" prop="status" >
              <template slot-scope="scope">
                {{ getDICKV(scope.row.status, 'product_status') }}
              </template>
            </el-table-column>
            <el-table-column width="100" show-overflow-tooltip label="门店联系人" prop="contactPerson" ></el-table-column>
            <el-table-column width="90" show-overflow-tooltip label="门店联系人电话" prop="contactPersonPhone" ></el-table-column>
            <el-table-column width="50" show-overflow-tooltip label="打印联数" prop="printNum" ></el-table-column>
            <el-table-column width="50" show-overflow-tooltip label="门店终端数" prop="termNum" ></el-table-column>
            <el-table-column width="120" show-overflow-tooltip label="门店地址" prop="branchAddress" ></el-table-column>
            <el-table-column label="操作" fixed="right" width="300" v-if="permissions.GET" >
              <template slot-scope="scope" >
                <el-button type="success" size="mini" v-if="permissions.GET" @click="eduitProRow(scope.row)" >查看门店产品配置</el-button>
                <el-button type="success" size="mini" v-if="permissions.GET" @click="eduitTermRow(scope.row)">查看绑定终端</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="flex: 1">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[10, 20, 50, 100]"
                           :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 7px">
            </el-pagination>
          </div>
          <el-dialog :close-on-click-modal="false" :before-close="closeBranchDia" title="添加门店" :visible.sync="merBranchDialog" width="50%" append-to-body>
            <el-form :model="rowBranch"  ref="addBranchForm" label-width="100px">
              <el-row>
                <el-col :span="10">
                  <el-form-item label="门店名称" prop="branchName">
                    <el-input autocomplete="off" type="text" v-model="rowBranch.branchName" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="状态" prop="status">
                    <el-select  v-model="rowBranch.status"  >
                      <el-option
                          v-for="item in DICKV.product_status"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="打印联数" prop="printNum">
                    <el-input-number disabled size="medium" v-model="rowBranch.printNum" :precision="0" :step="1" :min="1" :max="99"/>                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="门店终端数" prop="termNum">
                    <el-input-number disabled size="medium" v-model="rowBranch.termNum" :precision="0" :step="1" :min="1" :max="99"/>                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="门店联系人" prop="contactPerson">
                    <el-input disabled autocomplete="off" type="text" v-model="rowBranch.contactPerson" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="联系人电话" prop="contactPersonPhone">
                    <el-input disabled autocomplete="off" type="text" v-model="rowBranch.contactPersonPhone" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="门店所在地区" prop="districtCode">
                    <el-cascader disabled
                        size="large"
                        :options="areoptions"
                        v-model="rowBranch.districtCode"
                        >
                    </el-cascader>
                  </el-form-item>
                  <el-input disabled autocomplete="off" type="text" v-model="rowBranch.districtName" style="display: none" ></el-input>
                  <el-input disabled autocomplete="off" type="text" v-model="rowBranch.districtCode" style="display: none" ></el-input>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="门店详细地址" prop="branchAddress">
                    <el-input autocomplete="off" type="text" v-model="rowBranch.branchAddress" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="20">
                  <el-form-item>
                    <el-button type="primary" @click="submitAddBranchForm">提交</el-button>
                    <el-button @click="closeBranchDia()">取消</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-dialog>

          <!--门店绑定终端-->
          <el-dialog :close-on-click-modal="false"
                     v-loading="merTermloading"
                     element-loading-text="拼命加载中"
                     element-loading-spinner="el-icon-loading"
                     element-loading-background="rgba(0, 0, 0, 0.8)"
                     :before-close="close" title="" :visible.sync="merTermDialog"
                     width="40%" append-to-body>
            <template slot='title'>
              <span style="font-size:20px;">门店终端管理</span>
            </template>
            <MerTmpTerm ref="merterm" ></MerTmpTerm>
          </el-dialog>

        </div>
        <div v-show="index==4">
          <el-form :model="rowData"  ref="addForm4" >
            <el-row :gutter="24">
              <el-col :span="4">
                <span style="line-height: 20px">营业执照照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[0]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <el-image v-if="IMGList[0].imageUrl" :src="IMGList[0].imageUrl" class="avatar"></el-image>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">法人身份证正面照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[1]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[1].imageUrl" :src="IMGList[1].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">法人身份证反面照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[2]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[2].imageUrl" :src="IMGList[2].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>

              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">经营者手持身份证照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[3]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[3].imageUrl" :src="IMGList[3].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>

              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">商户门头照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[4]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[4].imageUrl" :src="IMGList[4].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">经营内景照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[5]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[5].imageUrl" :src="IMGList[5].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="经营内景照片" prop="picPath" hidden>
                  <el-input v-model="IMGList[5].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="4">
                <span style="line-height: 20px">经营场所证明文件</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[6]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[6].imageUrl" :src="IMGList[6].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>

              <el-col :span="4">
                <span style="line-height: 20px">其他审核材料</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[7]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[7].imageUrl" :src="IMGList[7].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">银行卡正面照</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[8]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[8].imageUrl" :src="IMGList[8].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="银行卡正面照" prop="picPath" hidden>
                  <el-input v-model="IMGList[8].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">开户许可证照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[9]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[9].imageUrl" :src="IMGList[9].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="开户许可证照片" prop="picPath" hidden>
                  <el-input v-model="IMGList[9].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">纸质协议首页</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[10]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[10].imageUrl" :src="IMGList[10].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="纸质协议首页" prop="picPath" hidden>
                  <el-input v-model="IMGList[10].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">纸质协议尾页</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[11]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[11].imageUrl" :src="IMGList[11].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="纸质协议尾页" prop="picPath" hidden>
                  <el-input v-model="IMGList[11].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="4">
                <span style="line-height: 20px">经营者与店铺门头合照</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[12]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[12].imageUrl" :src="IMGList[12].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>

              <el-col :span="4">
                <span style="line-height: 20px">组织机构代码证照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[13]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[13].imageUrl" :src="IMGList[13].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">注册登记表</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[14]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[14].imageUrl" :src="IMGList[14].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="注册登记表" prop="picPath" hidden>
                  <el-input v-model="IMGList[14].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">签字授权书</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[15]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[15].imageUrl" :src="IMGList[15].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="签字授权书" prop="picPath" hidden>
                  <el-input v-model="IMGList[15].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">事业单位法人证书</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[16]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[16].imageUrl" :src="IMGList[16].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="事业单位法人证书" prop="picPath" hidden>
                  <el-input v-model="IMGList[16].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <span style="line-height: 20px">结算账户授权书</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data='IMGList[17]'
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[17].imageUrl" :src="IMGList[17].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="结算账户授权书" prop="picPath" hidden>
                  <el-input v-model="IMGList[17].picPath" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="4">
                <!--  S:税务登记证照片 T:其他审核材料-->
                <span style="line-height: 20px">税务登记证照片</span>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[18]"
                    disabled
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[18].imageUrl" :src="IMGList[18].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-col>

              <el-col :span="4">
                <span style="line-height: 20px">其他审核材料</span>
                <el-upload
                    disabled
                    class="avatar-uploader"
                    action="lei"
                    :data="IMGList[19]"
                    :show-file-list="false"
                    ><!--覆盖默认上传-->
                  <img v-if="IMGList[19].imageUrl" :src="IMGList[19].imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-form-item label="其他审核材料" prop="picPath" hidden>
                  <el-input v-model="IMGList[19].picPath" clearable></el-input>
                </el-form-item>

              </el-col>

            </el-row>
          </el-form>

        </div>
        <div v-show="index==5" >
          <el-form :model="rowData.merInfoOtherTmp"  ref="addForm5" label-position="left">
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="营业执照名称" prop="corpBusName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.corpBusName" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="统一社会信用代码证" prop="creditCode">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.creditCode" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="社会信用代码证有效期" prop="creditCodeExpire">
                  <el-date-picker disabled
                      v-model="rowData.merInfoOtherTmp.creditCodeExpire"
                      type="date"
                      value-format="yyyy-MM-dd"
                      >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="控股股东姓名" prop="holderName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.holderName" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="控股股东身份证" prop="holderIdNo">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.holderIdNo" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="控股股东有效期" prop="holderExpire">
                  <el-date-picker disabled
                      v-model="rowData.merInfoOtherTmp.holderExpire"
                      type="date"
                      value-format="yyyy-MM-dd"
                      >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="注册资本" prop="registerFund">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.registerFund"  >
                    <el-option
                        v-for="item in DICKV.register_fund"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="员工人数" prop="stafftotal">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.stafftotal"  >
                    <el-option
                        v-for="item in DICKV.stafftotal"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="经营区域" prop="operateLimit">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.operateLimit"  >
                    <el-option
                        v-for="item in DICKV.operate_limit"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="经营地段" prop="inspect">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.inspect"  >
                    <el-option
                        v-for="item in DICKV.inspect"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否三证合一" prop="thrcertFlag">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.thrcertFlag"  >
                    <el-option
                        v-for="item in DICKV.thrcert_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="组织机构代码证号" prop="organcode">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.organcode" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="组织机构代码证有效期" prop="organexpire">
                  <el-date-picker disabled
                      v-model="rowData.merInfoOtherTmp.organexpire"
                      type="date"
                      value-format="yyyy-MM-dd"
                      >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="对公账户号" prop="pubAcctNo">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.pubAcctNo" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="支付行号" prop="cnapsCode">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.cnapsCode" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="对公账户名称" prop="pubAcctName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.pubAcctName" ></el-input>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="网站/应用名称" prop="merWebName">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.merWebName" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="网站url" prop="merUrl">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.merUrl" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="国籍" prop="nationality">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.nationality" >
                    <el-option-group
                        v-for="group in country"
                        :key="group.label"
                        :label="group.label">
                      <el-option
                          v-for="item in group.options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="性别" prop="sex">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.sex"  >
                    <el-option
                        v-for="item in DICKV.gender"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="职业" prop="occupation">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.occupation"  >
                    <el-option
                        v-for="item in DICKV.occupation"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="住址" prop="personaddr">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.personaddr" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="rowData.bnfDetail == 1" >
              <el-divider content-position="left">受益人信息</el-divider>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="受益人姓名" prop="bnfName" >
                    <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfName"  ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人性别" prop="bnfSex" >
                    <el-select disabled  v-model="rowData.merInfoOtherTmp.bnfSex"  >
                      <el-option
                          v-for="item in DICKV.gender"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人手机号" prop="bnfPhone" >
                    <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfPhone" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人国籍" prop="bnfNationality" >
                    <el-select disabled v-model="rowData.merInfoOtherTmp.bnfNationality" >
                      <el-option-group
                          v-for="group in country"
                          :key="group.label"
                          :label="group.label">
                        <el-option
                            v-for="item in group.options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="受益人证件类型" prop="bnfIdType" >
                    <el-select disabled v-model="rowData.merInfoOtherTmp.bnfIdType" >
                      <el-option
                          v-for="item in DICKV.legal_id_type"
                          :key="item.k"
                          :label="item.k"
                          :value="item.v"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人证件号" prop="bnfIdNo" >
                    <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfIdNo" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人证件有效期" prop="bnfExpire" >
                    <el-date-picker disabled
                        v-model="rowData.merInfoOtherTmp.bnfExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="受益人职业" prop="bnfOccupation" >
                    <el-select disabled v-model="rowData.merInfoOtherTmp.bnfOccupation"  >
                      <el-option
                          v-for="item in DICKV.occupation"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人住址" prop="bnfPersonaddr" >
                    <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfPersonaddr" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人职级(是否高管)" prop="bnfFrnmngFlag" >
                    <el-select disabled v-model="rowData.merInfoOtherTmp.bnfFrnmngFlag"  >
                      <el-option
                          v-for="item in DICKV.thrcert_flag"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="税务登记号码" prop="taxRegCode">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.taxRegCode" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="税务登记证日期" prop="taxCodeExpire">
                  <el-date-picker disabled
                      v-model="rowData.merInfoOtherTmp.taxCodeExpire"
                      type="date"
                      value-format="yyyy-MM-dd"
                      >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="经营内容" prop="taxCodePic">
                  <el-input disabled autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.taxCodePic" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="经营场所" prop="businessPlace">
                  <el-select disabled v-model="rowData.merInfoOtherTmp.businessPlace"  >
                    <el-option
                        v-for="item in DICKV.business_place"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="6">
                <el-form-item label="经营地址" prop="busaddressCode">
                  <el-cascader disabled
                      size="large"
                      :options="areoptions"
                      v-model="rowData.merInfoOtherTmp.busaddressCode">
                  </el-cascader>
                </el-form-item>
                <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.businessAddress" style="display: none" ></el-input>
                <!--                display: none-->
                <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.busaddressCode" style="display: none" ></el-input>
                <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.districtCode" style="display: none" ></el-input>
                <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.districtName" style="display: none" ></el-input>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <div style="display: flex;align-items: center;justify-content: center;padding: 0px;margin: 0px;">
      <el-button round size="mini" v-if="index!=0" @click="index--">上一步</el-button>
      <el-button type="primary" round size="mini" @click="next" v-text="index==5?'提交审核':'下一步'"></el-button>
    </div>


    <el-dialog :close-on-click-modal="false" :before-close="close" title="审核" :visible.sync="affirmDialog"  width="30%" append-to-body>
      <el-form :model="affirmData" ref="affirmForm" :rules="affirmrules" label-width="150px">
        <el-row>
          <el-col :span="21">
            <el-form-item label="审核状态" prop="auditStatus">
              <el-select v-model="affirmData.auditStatus" placeholder="请选择">
                <el-option
                    v-for="item in audit_status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="备注" prop="auditNote">
              <el-input autocomplete="off" type="textarea" v-model="affirmData.auditNote" placeholder="请输入审核备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAffirmForm">提交审核</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>



    <el-dialog :close-on-click-modal="false"
               v-loading="merProCloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="merProCDialog"
               width="50%" append-to-body>
      <template slot='title'>
        <span style="font-size:20px;">产品控制配置</span>
      </template>
      <MerTmpProC ref="MTPC"></MerTmpProC>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";
import MerTmpProC from "./MerTmpProC";
import {regionData} from 'element-china-area-data';
import MerTmpTerm from "./MerTmpTerm";

export default {
  name: "MerTmpEduit",
  components: {MerTmpProC,MerTmpTerm},
  data(){
    return{
      DICKV:{},
      allAgency:{},
      permissions:{},
      rowData:{
        merInfoOtherTmp:{},
        settleInfoTmp:{},
        merBranchInfoTmp:[],
        picList: [],
      },

      index:0,
      /*费率信息*/
      tableRadio:{},
      rmenus:[],
      merRate:{},
      merRateDialog:false,
      merproRateloading:false,

      //门店信息
      merBranchDialog:false,
      rowBranch:{},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 10,
          pages: 0,
          total: 0
        }
      },
      merProCDialog:false,
      merProCloading:false,

      /*图片信息*/
      imageUrl:'',
      IMGList: [
        {type:'A',imageUrl:'',tempUrl:''},
        {type:'B',imageUrl:'',tempUrl:''},
        {type:'C',imageUrl:'',tempUrl:''},
        {type:'D',imageUrl:'',tempUrl:''},
        {type:'E',imageUrl:'',tempUrl:''},
        {type:'F',imageUrl:'',tempUrl:''},

        {type:'G',imageUrl:'',tempUrl:''},
        {type:'H',imageUrl:'',tempUrl:''},
        {type:'I',imageUrl:'',tempUrl:''},
        {type:'J',imageUrl:'',tempUrl:''},
        {type:'K',imageUrl:'',tempUrl:''},
        {type:'L',imageUrl:'',tempUrl:''},

        {type:'M',imageUrl:'',tempUrl:''},
        {type:'N',imageUrl:'',tempUrl:''},
        {type:'O',imageUrl:'',tempUrl:''},
        {type:'P',imageUrl:'',tempUrl:''},
        {type:'Q',imageUrl:'',tempUrl:''},
        {type:'R',imageUrl:'',tempUrl:''},
        {type:'S',imageUrl:'',tempUrl:''},
        {type:'T',imageUrl:'',tempUrl:''},
      ],
      dialogImageUrl: '',
      headers: {
        // 'Content-Type': 'multipart/form-data', // 默认值
        'Authorization':window.sessionStorage.getItem("authorization"),
        // 'user':encodeURIComponent(window.sessionStorage.getItem("user")),
      },
      dialogVisible: false,
      fileList: [],

      //mcc
      mccoptions:[{}],
      mccloading:false,
      mcclist:[],

      areoptions: regionData,
      selectedOptions: [],

      //sett_bank
      bankoptions:this.initBank(),
      bankcityoptions:[{}],
      bankcnapsoptions:[{}],

      //客户经理
      saleperoptions:[{}],
      //所属父商户
      meroptions:[{}],

      //国籍
      country:[{
        label: '热门国家',
        options: [{
          value: 'China',
          label: '中国'
        } ]
      }, {
        label: '其他国家',
        options: [ ]
      }],

      merTermDialog:false,
      merTermloading:false,


      //审核页面
      affirmrules: {
        auditStatus: [{required: true, message: '请选择审核状态', trigger: 'blur'}],
        auditNote: [{required: true, message: '请输入审核备注', trigger: 'blur'}]
      },
      affirmData:{},
      affirmDialog:false,
      audit_status:[{
        value: 'S2',
        label: '审核通过'
      }, {
        value: 'S3',
        label: '审核打回'
      }, {
        value: 'S4',
        label: '关闭'
      }]
    }
  },mounted() {
    this.permissions = this.$store.state.permissions;
    this.DICKV=this.$store.state.DICKV;
    this.initAgency();
    this.mount();
  },
  methods:{
    mount(){
      this.$on('init',(res)=>{
        this.tableRadio.id=res
        this.init()
      })
    },

    cleanAll(){
      this.index =0;
      this.rowData={}
      this.rowData.merInfoOtherTmp={}
      this.rowData.settleInfoTmp={}
      this.rowData.merBranchInfoTmp=[]
      this.rowData.picList= []
    },
    init(){
      this.cleanAll();
      this.getRequest("/sys/mertmp/getOneAll", {id:this.tableRadio.id}).then(resp=> {
        let data = resp;
        if (data && data.status == 200) {
          this.rowData=data.obj
          this.mccoptions[0].value=data.obj.mccid
          this.mccoptions[0].label=data.obj.mccidName

          this.bankcityoptions[0].value=data.obj.settleInfoTmp.accresCode
          this.bankcityoptions[0].label=data.obj.settleInfoTmp.accresName

          this.bankcnapsoptions[0].value=data.obj.settleInfoTmp.cnapsCode
          this.bankcnapsoptions[0].label=data.obj.settleInfoTmp.cnapsName
        }else{
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
        this.baseIMG();
      });
    },
    baseIMG(){
      let IMGList=this.rowData.picList;
      for (let i = 0; i < IMGList.length; i++) {
        if (IMGList[i].picPath){
          this.getRequestBlob('/sys/fileStorage/baseIMG?path='+IMGList[i].picPath+'&type=MERTMP&time=' + new Date()).then(data => {
            this.IMGList[i].imageUrl = window.URL.createObjectURL(data);
            this.IMGList[i].picPath = IMGList[i].picPath;
          })
        }else{
          this.IMGList[i].imageUrl=''
          this.IMGList[i].picPath = '';
        }
      }
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = parseInt(this.DICKV[key][i].v); //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    initBank() {
      this.getRequest("/sys/bank/asyn",{type:0}).then(resp=> {
        if (resp && resp.status == 200) {
          this.bankoptions=resp.obj
        }
      });
    },
    showEditProRate(row){
      this.merRateDialog=true
      this.merRate=row
      this.merRate.relationId=this.tableRadio.id
    },
    closeMerRateDialog(){
      this.merRateDialog=false
    },
    initAgency(){
      this.getRequest("/sys/mertmp/agency").then(resp=> {
        if (resp && resp.status == 200) {
          this.allAgency=resp.obj
        }
      });
    },

    initR(parm){
      if (this.permissions.GET == 1) {
        this.rateloading = true
        this.getRequest("/sys/mertmp/merRateMenu", parm).then(data => {
          if (data) {
            this.rmenus = data.obj
            this.rateloading = false
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
    },
    AddBranch(){
      this.rowBranch={}
      this.merBranchDialog=true
    },
    closeBranchDia(){
      this.rowBranch={}
      this.merBranchDialog=false
      this.initBranch()
    },
    submitAddBranchForm(){
      this.rowBranch.relationId=this.rowData.id
      this.$refs.addBranchForm.validate((valid) => {
        if (valid) {
          this.postRequest("/sys/mertmp/merBranch", this.rowBranch).then(resp=> {
            let data = resp.data;
            if (resp && resp.status == 200) {
              Message.success("成功")
              this.merBranchDialog=false
              this.initBranch();
            }else{
              Message.error({message: data.msg + '(' + data.status + ')'})
              return false;
            }
          });
        }else{
          console.log(111)
        }
      })
    },
    initBranch(){
      this.pm.pm.relationId=this.rowData.id
      this.getRequest('/sys/mertmp/merBranch', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.rowData.merBranchInfoTmp = data.obj.list
          this.pm.page = data.obj.page
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })

    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.initBranch()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.initBranch()
    },
    eduitProRow(row) {
      row.htmlFromType="affirm"
      this.merProCDialog = true
      this.$nextTick(() => {
        this.$refs.MTPC.$emit('initProC', row)
      })
    },
    close(){
      this.merProCDialog=false
      this.merTermDialog=false
      this.affirmDialog=false
    },
    //门店绑定终端
    eduitTermRow(row){
      row.expandMan=this.rowData.expandMan
      row.agencyNo=this.rowData.belongAgencyNo
      row.htmlFromType="affirm"
      this.merTermDialog=true
      this.$nextTick(() => {
        this.$refs.merterm.$emit('init', row)
      })
    },
    next(){
      if(this.index==5){
        this.affirmDialog=true
      }else{
        this.index ++;
        if (this.index==2) {
          this.tableRadio.relationId = this.rowData.id;
          this.tableRadio.id= this.rowData.id;
          this.tableRadio.belongAgencyNo= this.rowData.belongAgencyNo;
          this.initR(this.tableRadio)
        }
        if(this.index==3){
          this.rowData.relationId = this.rowData.id;
          this.initBranch()
        }
      }
    },
    submitAffirmForm(){
      this.rowData.auditNote=this.affirmData.auditNote
      this.rowData.auditStatus=this.affirmData.auditStatus
      this.postRequest("/sys/mertmpverify/merAudit", this.rowData).then(resp=> {
        if (resp && resp.status == 200) {
          Message.success("成功")
          this.affirmDialog=false;
          this.index =0;
          this.rowData={}
          this.affirmData={}
          this.rowData.merInfoOtherTmp={}
          this.rowData.settleInfoTmp={}
          this.rowData.merBranchInfoTmp=[]
          this.rowData.picList= []
          this.$emit('colseFaDia');
        }else {
          Message.error({message: resp.msg + '(' + resp.status + ')'})
        }
      });
    }
  }
}
</script>


<style scoped>
.avatar-uploader {
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 150px;
  height: 150px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
}

</style>
