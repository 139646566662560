<template>
  <div>
    <div v-if="permissions.GET" style="margin-top: 0px">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="4">
                <el-select v-model="value">
                  <el-option
                      v-for="item in optionss"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>

              <el-col :span="6">
                <el-form-item v-if="value==0" label="交易日期" prop="stockDate">
                  <el-date-picker autocomplete="off"
                                  v-model="info.dayDate"
                                  type="date"
                                  value-format='yyyy-MM-dd'
                                  :time-arrow-control="true">
                  </el-date-picker>
                </el-form-item>
                <el-form-item v-if="value==1" label="交易日期">
                  <el-date-picker autocomplete="off"
                                  v-model="info.monthDate"
                                  type="month"
                                  value-format='yyyy-MM'
                                  :time-arrow-control="true">
                  </el-date-picker>
                </el-form-item>
                <el-form-item v-if="value==2" label="交易日期">
                  <el-date-picker autocomplete="off"
                                  v-model="info.localTime"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期">
                    value-format='yyyy-MM-dd'
                  </el-date-picker>

                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="交易类型">
                  <el-select v-model="queryTransType"   multiple
                             collapse-tags
                             clearable
                             style="margin-left: 20px;" placeholder="(全部)">
                    <el-option
                        v-for="item in transtype"
                        :key="item.value"
                        :label="item.lable"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="商户">
                  <el-select v-model="pm.merid" placeholder="(全部)"  filterable
                             remote
                             clearable
                             :remote-method="getMerid">
                    <el-option
                        v-for="item in Merinfo"
                        :key="item.merid"
                        :label="item.label"
                        :value="item.merid">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.merid }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
			  
			  <el-col :span="4">
				<el-form-item label="代理机构">
				  <el-select v-model="pm.pm.agencyNo" clearable placeholder="(全部)">
					<el-option
						v-for="item in agencyNos"
						:key="item.agencyNo"
						:label="item.agencyName"
						:value="item.agencyNo">
					</el-option>
				  </el-select>
				</el-form-item>
			  </el-col>
			  
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini" v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini" type="warning" @click="handleExport" v-if="permissions.GET">导出</el-button>
          <el-button size="mini" v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">

      <!--      <el-button size="mini" type="warning"  @click="handleExport" v-if="permissions.GET"  >导出</el-button>-->
      <!--      <el-button size="mini" type="warning" v-if="permissions.GET"  >汇总导出</el-button>-->

      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%; margin-top: 20px"
                :summary-method="getSummaries" show-summary>
        <el-table-column width="120" show-overflow-tooltip label="统计日期" prop="localDate"></el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="商户号" prop="merId"></el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="渠道商户号" prop="merIdChannel"></el-table-column>
        <el-table-column width="200" show-overflow-tooltip label="门店名称" prop="branchName"></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="终端号" prop="termCode"></el-table-column>
        <el-table-column width="250" show-overflow-tooltip label="交易类型" prop="transType"></el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="成功交易笔数"  prop="count">
        </el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="成功交易金额"  align="right" prop="amount">
          <template slot-scope="scope">
            {{handleScopeFormat(scope.row.amount)}}
          </template>
        </el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="手续费" align="right" prop="fee">
          <template slot-scope="scope">
            {{handleScopeFormat(scope.row.fee)}}
          </template>
        </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="结算金额" align="right"  prop="settlement">
          <template slot-scope="scope">
            {{handleScopeFormat(scope.row.settlement)}}
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination  @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100]"
                        :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 7px" >
        </el-pagination>
      </div>
    </div>

  </div>
</template>
<script>
import {Message} from "element-ui";
import fileDownload from "js-file-download";

export default {
  name: "TotalQuery",
  data() {
    return {
      DICKV: {},
      Merinfo: [],
      transtype: [],
      queryTransType:[],
      retrieveActiveNames: '1',
      permissions: {},
      loading: false,
      datas: [],
	  agencyNos:[],
      pm: {
        pm:{},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        },
      },

      info: {
        dayDate: this.getDaydate(), //日期
        monthDate: this.getMonthDate(),  //日期
        localTime: [this.getDaydate(), this.getEnd()],
      },
      optionss: [{
        value: '0',
        label: '按天'
      }, {
        value: '1',
        label: '按月'
      }, {
        value: '2',
        label: '自定义'
      }],
      value: '0'
    }
  },
  addForm: {
    createDate: new Date() // 只需要在这里声明的时候直接值赋为 new Date()
  },
  created() {

  },

  mounted() {
    this.DICKV = this.$store.state.DICKV;
    this.permissions = this.$store.state.permissions;
    // this.getMerid();
    this.getTrantype();
	this.getAgencyNos();
	
  }, methods: {
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计：';
          return;
        }
        if (index === 1 || index === 2 || index === 3 || index === 4 ) {
          sums[index] = '--';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr ;
            } else {
              return prev;
            }
          }, 0);
          //console.log("sums[index]=="+sums[index])
          //此处结合了方式一的判断处理，实现多种条件处理
          switch(column.property) {
            case "amount":
            case "fee":
            case "settlement":
              return sums[index] = this.handleScopeFormat(Math.round(sums[index] *100)/100) + " 元" ;
            case "count":
              return sums[index] = sums[index] + " 笔" ;
          }
        } else {
          sums[index] = '--'; //如果列的值有一项不是数字，就显示这个自定义内容
        }
      });

      return sums;
    },

    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      this.pm.pm.merId = this.$store.state.user.belongMerid
      if (this.permissions.GET) {
        this.loading = true
        if (this.localTime != null && this.localTime.length > 0) {
          this.pm.pm.startTime = this.localTime[0]
          this.pm.pm.endTime = this.localTime[1]
        }
        if (this.value != null) {
          this.pm.pm.type = this.value
        }
        if (this.value == '0') {
          this.pm.pm.daydate = this.info.dayDate
        }
        if (this.value == '1') {
          this.pm.pm.monthDate = this.info.monthDate
        }
        if (this.value == '2') {
          if (this.info.localTime != null && this.info.localTime.length > 0) {
            this.pm.pm.startTime = this.info.localTime[0]
            this.pm.pm.endTime = this.info.localTime[1]
          }
        }
        this.pm.pm.Tradtype = this.queryTransType+'';
        this.pm.pm.merid = this.pm.merid;
        this.getRequest('/sys/dealsum/allsum',  this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }

    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
      this.localTime = []
      this.pm.merId = ''
    },

    download() {
      this.dialogVisible = true
      this.showFileName = true
      this.operationType = 'download'
    },

    getDaydate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate() - 1; //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      return defaultDate;
    },

    getMonthDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      var MonthDate = `${year}-${month}`;
      return MonthDate
    },

    getEnd() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate() - 1; //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      return defaultDate
    },

    handleExport() {
      this.downloadingDialog = true

      this.getRequestBlob('/sys/dealsum/sumexport', this.pm).then(data => {
        if (!data) {
          return
        }
        fileDownload(data, this.getDaydate() + '汇总'+'.csv')
        // let url = window.URL.createObjectURL(new Blob([data]))
        // let link = document.createElement('a');
        // link.style.display = 'none';
        // link.href = url
        // link.setAttribute('download', rowdata.fileName)
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
        this.downloadingDialog = false
      })
    },

    getTrantype() {
      this.loading = true
      this.getRequest('/sys/deal/AllTransType', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.transtype = data.obj
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
	
	getAgencyNos() {
      this.loading = true
      this.getRequest('/sys/agency', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.agencyNos = data.obj.list
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },

    getMerid(val) {
      // this.pm.merId = this.$store.state.user.belongMerid
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/deal/getMerid', {parm:val}).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.Merinfo = data.obj
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    }
  },


  // handleExport(){
  //   exportRole().then(response => {
  //     downloadFile(response, "role", 'xlsx')
  //   })
  // },

  // handleExport() {
  //   // const queryParams = this.pm;
  //   this.$confirm('是否确认导出数据项?', "警告", {
  //     confirmButtonText: "确定",
  //     cancelButtonText: "取消",
  //     type: "warning"
  //   }).then(function() {
  //     //   this.getRequest('/sys/deal/export', queryParams).then(data => {
  //     //   this.loading = false
  //     //   if (data.status == 200) {
  //     //     this.datas = data.obj
  //     //   } else {
  //     //     Message.error({message: data.msg + '(' + data.status + ')'})
  //     //   }
  //     // })
  //
  //     this.getRequest('/sys/deal/export', this.pm).then(data => {
  //       this.loading = false
  //       if (data.status == 200) {
  //         this.datas = data.obj
  //       } else {
  //         Message.error({message: data.msg + '(' + data.status + ')'})
  //       }
  //     })
  //
  //     // exportPost(queryParams);
  //   }).then(response => {
  //     this.download(response.msg);
  //   })
  // }


}

</script>

<style scoped>

</style>
