<template>
  <div>
    <div class="custom-tree-node" style="display:flex;">
      <div class="block">
        <div style="margin-top: 10px;">
          <el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
          <el-tree v-loading="loading" style="width: 500px" :data="roles" :props="defaultProps" :expand-on-click-node="false" :filter-node-method="filterNode" node-key="id"  :default-expanded-keys="defaultExpandedKeys" @node-click="handleNodeClick"  :highlight-current="true" ref="tree" >
            <span class="custom-tree-node" style="display: flex;justify-content: space-between;width: 100%;" slot-scope="{ node, data }">
              <span>{{ data.name }}</span>
              <span>
                <el-button v-if="permissions.POST" type="primary" size="mini" class="depBtn" @click="showAddView(data)" >添加子部门</el-button>
                <el-button v-if="permissions.PUT" type="primary" size="mini" class="depBtn" @click="showEditView(data)" >编辑</el-button>
                <el-button v-if="(data.children==null||data.children.length<1)&&data.id!=1&&permissions.DELETE" type="danger" size="mini" class="depBtn" @click="deleteRow(data)" >删除</el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
      <div style="margin-left: 20px;margin-top: 10px;width: 100%" >
        <div>
          <span>{{talbename}}({{pm.page.total}}人)</span>
        </div>
        <div style="margin-top: 10px;">
          <el-button type="primary" @click="showUserAddView" >添加成员</el-button>
          <el-button type="warning">批量导入/导出</el-button>
          <el-button size="mini" type="danger" v-if="permissions.DELETEdeptuser" :disabled="btnBoolean ? false : true" @click="deleteDeptUser()" >删除成员</el-button>

          <el-table :data="datas" border style="width: 100%;margin-top: 10px" v-loading="loadingUser"   ref="checkTable" @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55"></el-table-column>
            <el-table-column prop="userid" show-overflow-tooltip label="用户ID"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="position" label="职务"></el-table-column>
            <el-table-column prop="depts" label="部门" show-overflow-tooltip>
              <template slot-scope="scope" >
                <el-tag type="primary"  style="margin-right: 5px" v-for="(item, index) in scope.row.depts" :key="index">{{ item.name }}<a style="padding-left: 10px;color: #505458" v-if="item.id==scope.row.mainDepartment">(主)</a></el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column label="操作" fixed="right" width="150" >
              <template slot-scope="scope">
                <el-button type="success" size="mini"  @click="showUserEditView(scope.row)">编辑 </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="flex: 1">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pm.page.page"
                :page-sizes="[15, 50, 100, 200]"
                :page-size="pm.page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pm.page.total"
                style="text-align:center">
            </el-pagination>
          </div>
        </div>
      </div>

      <el-dialog title="修改部门名称" :visible.sync="editDialogVisible" width="50%" >
        <el-form
            :model="role" :rules="rules" ref="editForm" label-width="180px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="部门名称" prop="name">
                <el-input  type="text" v-model="role.name" placeholder="请输入部门名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="英文名称" prop="name_en">
                <el-input  type="text" v-model="role.name_en" placeholder="请输入英文名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button type="primary" @click="submitEditForm">提交</el-button>
                <el-button @click="close()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
      <el-dialog title="添加子部门" :visible.sync="addDialogVisible" width="50%" >
        <el-form
            :model="role" :rules="rules" ref="addForm" label-width="180px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="上级部门">
                <el-tag>{{ role.parentName }}</el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="部门名称" prop="name_en">
                <el-input  type="text" v-model="role.name" placeholder="请输入部门名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="英文名称" prop="name_en">
                <el-input  type="text" v-model="role.name_en" placeholder="请输入英文名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button type="primary" @click="submitAddForm">提交</el-button>
                <el-button @click="close()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <el-dialog title="添加用户"  :before-close="closeDialog" :visible.sync="addUserDialog"  width="50%" >
        <el-form :model="rowData"  :label-position="labelPosition" :rules="rules" ref="addUserForm" label-width="80px">
          <el-row>
            <el-col :span="16">
              <el-form-item label="用户名称" prop="name">
                <el-input  type="text" v-model="rowData.name" placeholder="请输入用户名称"></el-input>
              </el-form-item>

              <el-form-item label="用户别名" prop="alias">
                <el-input  type="text" v-model="rowData.alias" placeholder="别名"></el-input>
              </el-form-item>

              <el-form-item label="账号" prop="userid">
                <el-input  type="text" v-model="rowData.userid" placeholder="成员唯一标识，不支持修改"></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="rowData.gender">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="16">
              <el-form-item label="手机" prop="mobile">
                <el-input  type="text" v-model="rowData.mobile" ></el-input>
              </el-form-item>
              <el-form-item label="座机" prop="telephone">
                <el-input  type="text" v-model="rowData.telephone" ></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="address">
                <el-input  type="text" v-model="rowData.address" ></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input  type="text" v-model="rowData.email" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row>
            <el-col :span="16">

              <el-form-item label="部门" prop="depts" >
                <template  >
                  <el-tag type="primary"  closable style="margin-right: 5px" v-for="(item, index) in rowData.depts" :key="index"   @close="removeChoosedDeptUser(index,0)" >{{ item.name }}<span style="padding-left: 10px" v-if="item.id==item.mainDepartment">(主)</span></el-tag>
                </template>
                <el-button type="warning" size="mini" @click="chooseDeptUser(rowData.depts,'')" >修改</el-button>
              </el-form-item>


<!--              <el-form-item label="标签" prop="title" >-->
<!--                <template  >-->
<!--                  <el-tag type="primary"  closable style="margin-right: 5px"></el-tag>-->
<!--                </template>-->
<!--                <el-button type="warning" size="mini"  >修改</el-button>-->
<!--              </el-form-item>-->

              <el-form-item label="部门负责" prop="is_leader_in_dept">
                <el-radio-group v-model="rowData.is_leader_in_dept">
                  <el-radio label="0">否</el-radio>
                  <el-radio label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="直属上级" prop="direct_leader">
                <el-input  type="text" v-model="rowData.direct_leader" placeholder="直属上级"></el-input>
              </el-form-item>

              <el-form-item label="是否禁用" prop="enable">
                <el-radio-group v-model="rowData.enable">
                  <el-radio label=0>禁用</el-radio>
                  <el-radio label=1>启用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item>
                <el-button type="primary" @click="submitAddUserForm">提交</el-button>
                <el-button @click="closeUserDia()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
      <el-dialog title="编辑用户"  :before-close="closeDialog"   :visible.sync="editUserDialog" width="50%" >
        <el-form :model="user"  :label-position="labelPosition" ref="editUserForm" label-width="80px">
          <el-row>
            <el-col :span="3">
              <el-upload
                  class="avatar-uploader"
                  action="lei"
                  :on-change="handleChange"
                  :show-file-list="false"
                  :http-request="httpRequest"><!--覆盖默认上传-->
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-form-item label="头像照片路径" prop="avatar" hidden>
                <el-input v-model="user.avatar" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="姓名" prop="name">
                <el-input  type="text" v-model="user.name" placeholder="姓名"></el-input>
              </el-form-item>

              <el-form-item label="别名" prop="alias">
                <el-input  type="text" v-model="user.alias" placeholder="别名"></el-input>
              </el-form-item>

              <el-form-item label="账号" prop="userid">
                <el-input  type="text" v-model="user.userid" placeholder="成员唯一标识，不支持修改"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-radio-group v-model="user.gender">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>

          <el-row>
            <el-col :span="16">
              <el-form-item label="手机" prop="mobile">
                <el-input  type="text" v-model="user.mobile" placeholder="请输入用户手机"></el-input>
              </el-form-item>
              <el-form-item label="座机" prop="telephone">
                <el-input  type="text" v-model="user.telephone" placeholder="座机"></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="address">
                <el-input  type="text" v-model="user.address" placeholder="请输入用户别名"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input  type="text" v-model="user.email" placeholder="请输入用户别名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-divider></el-divider>
          <el-row>
            <el-col :span="16">
              <el-form-item label="部门" prop="depts">
                <template  >
                  <el-tag type="primary"  style="margin-right: 5px" closable v-for="(item, index) in user.depts" :key="index" @close="removeChoosedDeptUser(index,1)" >{{ item.name }}<span style="padding-left: 10px" v-if="item.id==user.mainDepartment">(主)</span></el-tag>
                </template>
                <el-button type="warning" size="mini" @click="chooseDeptUser(user.depts,user.mainDepartment)" >修改</el-button>
              </el-form-item>
<!--              <el-form-item label="标签" >-->
<!--                <el-tag type="primary" closable ></el-tag>-->
<!--                <el-button type="warning" size="mini"> 修改</el-button>-->
<!--              </el-form-item>-->
              <el-form-item label="部门负责人" prop="is_leader_in_dept">
                <el-radio-group v-model="user.is_leader_in_dept">
                  <el-radio label="0">否</el-radio>
                  <el-radio label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="直属上级" prop="direct_leader">
                <el-input  type="text" v-model="user.direct_leader" placeholder="直属上级"></el-input>
              </el-form-item>
              <el-form-item label="是否禁用" prop="enable">
                <el-radio-group v-model="user.enable">
                  <el-radio label="0">禁用</el-radio>
                  <el-radio label="1">启用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item>
                <el-button type="primary" @click="submitEditUserForm">提交</el-button>
                <el-button @click="closeUserDia()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

      <el-dialog title="" :before-close="closeChooseDeptDialog" :append-to-body="true" :visible.sync="chooseDeptUserDialog" width="35%">
        <template slot='title'>
          <span style="font-size:20px;">选择成员所在部门</span>
        </template>
        <el-row>
          <el-col :span="10">
            <el-tree
                :data="roles"
                node-key="id"
                default-expand-all
                :default-checked-keys="[]"
                :props="defaultProps" :expand-on-click-node="false" @node-click="handleNodeClickDept"  :highlight-current="true" ref="treeDeptUser" >
            </el-tree>
          </el-col>
          <el-col :span="1"><el-divider direction="vertical"></el-divider></el-col>
          <el-col :span="2" >
            <template  >
              <el-tag closable disable-transitions	 :key="index"  style="margin-top: 5px;font-size: medium;" effect="plain" type="primary" v-for="(item,index) in this.deptParms"
                      @close="handleTagClose(index)"
                      @click.native="setMainDept(item.id)">{{ item.name }} <span style="padding-left: 10px" v-if="item.id==user.mainDepartment">(主)</span></el-tag>
              <!--              <span style="padding-left: 10px" v-if="item.id==user.mainDepartment">(主)</span>-->

            </template>
          </el-col>
        </el-row>
        <el-row >
          <el-divider></el-divider>
          <el-button type="primary" @click="trueChooseDept()"> 确定</el-button>
          <el-button type="info" @click="closeChooseDeptDia()">取消</el-button>
        </el-row>
      </el-dialog>
    </div>





  </div>
</template>

<script scoped>

import {Message} from "element-ui";

export default {
  name: "index",
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    treeText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      tableData:'',
      talbename:'所有成员',
      tablerow:'',
      btnBoolean:false,
      delarr:[],//存放删除的数据
      multipleSelection:[],//多选的数据
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      user: {},
      datas: [],
      filterText:'',
      treeText:'',
      permissions: {},
      loading: false,
      loadingUser:false,
      menuPermissionsLoading: false,
      role: {
        id: '',
        name: '',
        parentId: '',
        parentName: '',
        name_en:'',
      },
      editDialogVisible: false,
      editUserDialog:false,
      addDialogVisible: false,

      labelPosition: 'right',
      //选择员工所在部门
      chooseDeptUserDialog:false,


      roles: [],
      defaultProps: {
        childre: 'children',
        label: 'name'
      },
      rules: {
        id: [{required: true, message: '', trigger: 'blur'}],
        userid: [{required: true, message: '系统ID必填', trigger: 'blur'}],
        name: [{required: true, message: '用户必填', trigger: 'blur'}],
        parentId: [{required: true, message: '', trigger: 'blur'}],
        depts: [{required: true, message: '部门必填', trigger: 'blur'}],
        email: [{required: true, message: '邮箱必填', trigger: 'blur'}],
        mobile: [{required: true, message: '电话必填', trigger: 'blur'}],
      },
      defaultExpandedKeys: [1],
      menuPermissions: [],
      handleNode: {},
      menuChecked: [],
      handleMenuNode: {},

      //添加用户参数
      addUserDialog:false,
      rowData:{
        userid:"",name:"",alias:"",mobile:"",depts:[],position:"",gender:"",email:"",telephone:"",is_leader_in_dept:"",enable:0,mainDepartment:'',
      },

      //图片上传
      imageUrl: '',
      tempUrl: '',

      //编辑用户
      dynamicTags:[],
      deptParms:[],
      deptParm:{
        id:'',
        name:'',
      },
      //主部们
      mainDepartment:'',

    }
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
    this.initDeptUser()
  }, methods: {
    init(dek) {
      if (this.permissions.GET) {
        this.menuPermissions = []
        this.loading = true
        this.getRequest("/sys/dept").then(data => {
          console.log(data);
          this.loading = false
          if (data) {
            this.roles = data.obj
            this.defaultExpandedKeys = [1, dek]
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }

    },initDeptUser(){
      this.getRequest("/sys/dept/deptuser", this.pm.pm).then(data => {
        this.loadingUser = false
        if (data.status == 200) {
          this.datas = data.obj.list
          this.pm.page = data.obj.page
          // this.talbename=''
        }
      })
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    showEditView(data) {
      this.editDialogVisible = true
      this.role = {}
      this.role = data
    },
    handleSelectionChange(row) {
      this.multipleSelection = row;
      if (this.multipleSelection.length!=0)
      {
        this.btnBoolean=true;
      }else{
        this.btnBoolean=false;
      }
    },
    //编辑用户信息
    showUserEditView(rowData){
      this.user={}
      this.user = rowData
      this.editUserDialog = true
    },
    //添加用户信息
    showUserAddView(){
      this.rowData={}
      this.rowData.depts=[];
      this.addUserDialog=true;
    },
    closeDialog() {
      this.$confirm('确认关闭？')
          .then(() => {
            this.user={};
            this.deptParms=[];
            this.addUserDialog = false
            this.editUserDialog = false
          })
          .catch(() => {

          });
    },
    //选择成员所在部门
    chooseDeptUser(dept,maindept){
      this.deptParms=dept;
      this.mainDepartment=maindept;
      this.chooseDeptUserDialog=true;
    },
    closeChooseDeptDia(){
      this.deptParms=[];
      this.chooseDeptUserDialog=false;
    },
    closeChooseDeptDialog(){
      this.$confirm('确认关闭？')
          .then(() => {
            this.deptParms=[];
            console.log(this.deptParms);
            this.chooseDeptUserDialog=false;
          })
          .catch(() => {

          });
    },
    trueChooseDept(){
      this.user.depts=this.deptParms;
      this.rowData.depts=this.deptParms;
      this.deptParms=[];
      this.chooseDeptUserDialog=false;
    },
    //删除用户信息
    deleteDeptUser() {
      this.delarr=[];
      const length = this.multipleSelection.length;
      if (length>0){
        this.btnBoolean=true//启用批量按钮
        for (let i = 0; i < length; i++) {
          this.delarr.push(this.multipleSelection[i].userid+"")
        }
        this.$confirm("确认删除？")
            .then(() => {
              this.deleteRequest("/sys/dept/deptuser", {'userids':this.delarr.join(",")}).then(data => {
                if (data.status == 200) {
                  Message.success("成功")
                  this.initDeptUser()
                } else {
                  Message.error({message: data.msg + '(' + data.status + ')'})
                }
              })
            }).catch(() => {
          this.multipleSelection=[];
          this.delarr=[];
        })
      }else {
        Message.error({message: '请选中信息'})
      }
    },
    //添加部门用户
    submitAddUserForm(){
      this.$refs["addUserForm"].validate(valid => {
        if (valid) {
          this.postRequest("/sys/dept/deptuser", this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addUserDialog = false;
              this.initDeptUser()
            } else {
              Message.error("添加失败（" + data.status + "）")
            }
          })
        } else return false;
      })
    },

    //修改部门用户
    submitEditUserForm(){
      this.$refs["editUserForm"].validate((valid) => {
        if (valid) {
          this.putRequest('/sys/dept/deptuser', this.user).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editUserDialog = false
              this.initDeptUser(this.rowData.depts)
            } else {
              Message.error("修改失败（" + data.status + "）")
            }
          })
        } else return false
      })

    },


    //修改部门
    submitEditForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.role.children = null
          this.putRequest('/sys/dept', this.role).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialogVisible = false
              this.init(this.role.id)
              this.role = {}
            } else {
              Message.error("修改失败（" + data.status + "）")
            }
          })
        } else return false
      })
    },
    //添加子部门
    showAddView(data) {
      this.addDialogVisible = true
      this.role = {}
      this.role.parentName = data.name
      this.role.parentId = data.id
    }, submitAddForm() {
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          this.postRequest("/sys/dept", this.role).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialogVisible = false;
              this.init(this.role.parentId)
              this.role = {}
            } else {
              Message.error("添加失败（" + data.status + "）")
            }
          })
        } else return false;
      })
    }, deleteRow(data) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/dept", {id: data.id}).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    }, close() {
      //部门操作
      this.role = {}
      this.editDialogVisible = false
      this.addDialogVisible = false
    },closeUserDia(){
      console.log("close")
      //用户操作
      this.user={}
      this.rowData={}
      this.deptParm={}
      this.deptParms=[]
      this.addUserDialog=false
      this.editUserDialog=false
    },handleNodeClick(data) {
      this.delarr=[];
      this.multipleSelection=[];
      this.talbename=data.name;
      //获取用户信息
      this.pm.pm.role_id=data.id
      this.pm.pm.department=data.id
      this.getRequest("/sys/dept/deptuser", this.pm.pm).then(data => {
        this.loadingUser = false
        if (data.status == 200) {
          this.datas = data.obj.list
          this.pm.page = data.obj.page
        }
      })
    },
    handleNodeClickDept(data){
      this.deptParm.id=data.id;
      this.deptParm.name=data.name;
      this.deptParms.push(this.deptParm);
      for (let i = 0; i < this.deptParms.length - 1; i++) {
        for (let j = this.deptParms.length - 1; j > i; j--) {
          if (this.deptParms[i].id==this.deptParms[j].id) {
            this.deptParms.splice(j, 1);
          }
        }
      }
      console.log(this.deptParms);
      this.deptParm= {};
    },
    //设置主部门
    setMainDept(id){
      this.mainDepartment=id;
    }
    ,handleTagClose(index){
      // let deptid=this.deptParms[index].id;
      this.deptParms.splice(index, 1);
      // if (deptid==this.mainDepartment) {
      //   this.mainDepartment=this.deptParms[0].id;
      // }
      //
      // console.log(this.deptParms);
      // console.log(this.mainDepartment);

    },removeChoosedDeptUser(index,type){
      console.log(index)
      if (type==0){
        this.rowData.depts.splice(index, 1);
        console.log(this.rowData.depts);
      }else{
        this.user.depts.splice(index, 1);
        console.log(this.user.depts);
      }

    },
    submitMenuPermissions() {
      let halfCheckedKeys = this.$refs.menuTree.getHalfCheckedKeys()
      let checkedKeys = this.$refs.menuTree.getCheckedKeys()
      let checked = halfCheckedKeys.concat(checkedKeys)
      this.putRequest("/sys/role/menuPermissions", {
        id: this.handleNode.id,
        parentId: this.handleNode.parentId,
        checked: checked
      }).then(data => {
        if (data.status == 200) {
          Message.success("配置成功")
          this.handleNodeClick(this.handleNode)
        }
      })

    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    //将上传图片的原路径赋值给临时路径
    handleChange(file) {
      this.tempUrl = URL.createObjectURL(file.raw);
    },
    //实现图片上传功能
    httpRequest(item) {
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        // post上传图片
        let App = this;
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        App.getRequest(mf, function (result) {
          if (result.result == "true") {
            App.$notify.success({
              title: '温馨提示：',
              message: result.message,
            });
            //将临时文件路径赋值给显示图片路径（前端显示的图片）
            App.imageUrl = App.tempUrl;
            //将后台传来的数据库图片路径赋值给car对象的图片路径
            App.car.carImg = result.imgUrl;
          } else {
            App.$notify.error({
              title: '温馨提示：',
              message: result.message,
            });
          }
        })
      }
    }



  }
}
</script>

<style scoped>
.depBtn {
  padding: 2px;
}

/*.custom-tree-node {*/
/*  flex: 1;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  font-size: 14px;*/
/*  padding-right: 8px;*/
/*}*/

.avatar-uploader {
  margin-top: 8px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: flex;
}
.el-divider--vertical {
  height: 25em;
  margin-left: 20px;
}
</style>
