<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="管控人">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.riskName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="管控卡">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.riskCard"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="管控状态">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.riskStatus"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="添加人">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.riskAddman"></el-input>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="ID" prop="id" >
          <template slot-scope="scope">
            <el-popover placement="top-start" title="详情" width="500" trigger="hover">
              <div>
                <el-row type="flex"  justify="space-between">
                  <el-col :span="suspension_label"><span>ID</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.id}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>管控人</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskName}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>管控卡</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskCard}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>管控备注</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskNote}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>管控状态</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskStatus}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>添加时间</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskAddtime}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>添加人</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskAddman}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>管控起始时间</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskLimitStarttime}}</span>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="space-between">
                  <el-col :span="suspension_label"><span>管控结束时间</span></el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical"></el-divider>
                  </el-col>
                  <el-col :span="suspension_data">
                    <span>{{ scope.row.riskLimitEndtime}}</span>
                  </el-col>
                </el-row>

              </div>
              <span slot="reference">{{scope.row.id}}</span>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="管控人" prop="riskName" ></el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="管控卡" prop="riskCard" ></el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="管控备注" prop="riskNote" ></el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="管控状态" prop="riskStatus" >
          <template scope="scope">
            <el-tag type="success" v-if="scope.row.riskStatus==0">启用</el-tag>
            <el-tag type="danger" v-else>停用</el-tag>
          </template>
        </el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="添加时间" prop="riskAddtime" ></el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="添加人" prop="riskAddman" ></el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="管控起始时间" prop="riskLimitStarttime" ></el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="管控结束时间" prop="riskLimitEndtime" ></el-table-column>

        <el-table-column label="操作" fixed="right" width="150" v-if="permissions.PUT||permissions.DELETE" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>
            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 7px" >
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="管控人" prop="riskName">
              <el-input autocomplete="off" type="text" v-model="rowData.riskName" placeholder="请输入管控人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控卡" prop="riskCard">
              <el-input autocomplete="off" type="text" v-model="rowData.riskCard" placeholder="请输入管控卡"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控备注" prop="riskNote">
              <el-input autocomplete="off" type="text" v-model="rowData.riskNote" placeholder="请输入管控备注"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控状态" prop="riskStatus">
              <el-input autocomplete="off" type="text" v-model="rowData.riskStatus" placeholder="请输入管控状态"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加时间" prop="riskAddtime">
              <el-input autocomplete="off" type="text" v-model="rowData.riskAddtime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加人" prop="riskAddman">
              <el-input autocomplete="off" type="text" v-model="rowData.riskAddman" placeholder="请输入添加人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控起始时间" prop="riskLimitStarttime">
              <el-input autocomplete="off" type="text" v-model="rowData.riskLimitStarttime" placeholder="请输入管控起始时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控结束时间" prop="riskLimitEndtime">
              <el-input autocomplete="off" type="text" v-model="rowData.riskLimitEndtime" placeholder="请输入管控结束时间"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="管控人" prop="riskName">
              <el-input autocomplete="off" type="text" v-model="rowData.riskName" placeholder="请输入管控人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控卡" prop="riskCard">
              <el-input autocomplete="off" type="text" v-model="rowData.riskCard" placeholder="请输入管控卡"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控备注" prop="riskNote">
              <el-input autocomplete="off" type="text" v-model="rowData.riskNote" placeholder="请输入管控备注"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控状态" prop="riskStatus">
              <el-input autocomplete="off" type="text" v-model="rowData.riskStatus" placeholder="请输入管控状态"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加时间" prop="riskAddtime">
              <el-input autocomplete="off" type="text" v-model="rowData.riskAddtime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加人" prop="riskAddman">
              <el-input autocomplete="off" type="text" v-model="rowData.riskAddman" placeholder="请输入添加人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控起始时间" prop="riskLimitStarttime">
              <el-input autocomplete="off" type="text" v-model="rowData.riskLimitStarttime" placeholder="请输入管控起始时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="管控结束时间" prop="riskLimitEndtime">
              <el-input autocomplete="off" type="text" v-model="rowData.riskLimitEndtime" placeholder="请输入管控结束时间"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {

        riskName: [{required: true, message: '请输入管控人', trigger: 'blur'}],

        riskCard: [{required: true, message: '请输入管控卡', trigger: 'blur'}],

        riskNote: [{required: true, message: '请输入管控备注', trigger: 'blur'}],

        riskStatus: [{required: true, message: '请输入管控状态', trigger: 'blur'}],

        riskAddtime: [{required: true, message: '请输入添加时间', trigger: 'blur'}],

        riskAddman: [{required: true, message: '请输入添加人', trigger: 'blur'}],

        riskLimitStarttime: [{required: true, message: '请输入管控起始时间', trigger: 'blur'}],

        riskLimitEndtime: [{required: true, message: '请输入管控结束时间', trigger: 'blur'}],

      }
    }
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/risk', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/risk', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.rowData = {}
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/risk', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/risk", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>

</style>
