<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="流水号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.serial"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="系统商户号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.merId"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="操作类型">
                  <el-select v-model="pm.pm.operationType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.operation_type"
                        :key="item.v"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column width="400" show-overflow-tooltip label="流水号" prop="serial" ></el-table-column>
        <el-table-column width="400" show-overflow-tooltip label="系统商户号" prop="merId" ></el-table-column>
        <el-table-column width="400" show-overflow-tooltip label="操作类型" prop="operationType" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.operationType, 'operation_type') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="90" v-if="permissions.GET" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.GET" @click="showEditView(scope.row.serial)">查看报文 </el-button>
<!--            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>-->
<!--            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除 </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="报文详细" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-collapse>
        <el-collapse-item v-for="(item,index) in jsonData" :key="index" :title="getDICKV(item.logType, 'log_type')+'日志----'+'时间：'+item.addTime"  :name="item.logType">
          <json-viewer :value="item.log" boxed :expanded="expanded" :preview-mode="expanded"></json-viewer>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="日志类型：1：请求；2：应答" prop="logType">
              <el-input autocomplete="off" type="text" v-model="rowData.logType" placeholder="请输入日志类型：1：请求；2：应答"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="操作类型" prop="operationType">
              <el-input autocomplete="off" type="text" v-model="rowData.operationType" placeholder="请输入操作类型"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="日志：json" prop="log">
              <el-input autocomplete="off" type="text" v-model="rowData.log" placeholder="请输入日志：json"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="null" prop="addTime">
              <el-input autocomplete="off" type="text" v-model="rowData.addTime" placeholder="请输入null"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="系统商户号" prop="merId">
              <el-input autocomplete="off" type="text" v-model="rowData.merId" placeholder="请输入系统商户号"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";
export default {
  name: "APILog",
  data() {
    return {
      expanded:true,
      DICKV:{},
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: { },
      jsonData:[],
    }
  },created(){
    this.DICKV=this.$store.state.DICKV;
  }, mounted() {
      this.permissions = this.$store.state.permissions;
      this.init()
    }, methods: {
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = parseInt(this.DICKV[key][i].v); //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
      init() {
        if (this.permissions.GET) {
          this.loading = true
          this.getRequest('/sys/apiLog', this.pm).then(data => {
            this.loading = false
            if (data.status == 200) {
              this.datas = data.obj.list
              this.pm.page = data.obj.page
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          Message.error({message: '无查询权限'})
        }
      },
      queryForm() {
        this.pm.page.pageNum = 1
        this.init()
      },
      clearQueryForm() {
        this.pm.pm = {}
      },
      handleSizeChange(val) {
        this.pm.page.pageNum = 1
        this.pm.page.pageSize = val
        this.init()
      },
      handleCurrentChange(val) {
        this.pm.page.pageNum = val
        this.init()
      },
      showEditView(serial) {
        this.rowData = {}
        this.jsonData=[]
        this.getRequest('/sys/apiLog/ReqResp', {serial:serial}).then(data => {
          if (data.status == 200) {
            this.jsonData = data.obj
            for (let i=0;i<this.jsonData.length;i++){
              if (this.isJSON(this.jsonData[i].log)){
                this.jsonData[i].log=JSON.parse(this.jsonData[i].log)
              }
            }


          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })



        // if (this.isJSON(rowData.log)){
        //   this.jsonData=JSON.parse(rowData.log)
        // }else{
        //   this.jsonData=rowData.log
        // }
        this.editDialog = true
      },
        isJSON(str) {
          if (typeof str == 'string') {
            try {
              var obj=JSON.parse(str);
              if(typeof obj == 'object' && obj ){
                return true;
              }else{
                return false;
              }
            } catch(e) {
              return false;
            }
          }
        },

      submitEditForm() {
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            this.putRequest('/sys/apiLog', this.rowData).then(data => {
              if (data.status == 200) {
                Message.success("修改成功")
                this.editDialog = false
                this.init();
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})

              }
            })
          } else {
            return false;
          }
        })
      },
      showAddView() {
        this.rowData = {}
        this.addDialog = true;
      },
      submitAddForm() {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            this.postRequest('/sys/apiLog', this.rowData).then(data => {
              if (data.status == 200) {
                Message.success("添加成功")
                this.addDialog = false
                this.init();
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})

              }
            })
          } else {
            return false;
          }
        })
      },
      deleteRow(rowData) {
        this.$confirm("确认删除？")
            .then(() => {
              this.deleteRequest("/sys/apiLog", rowData).then(data => {
                if (data.status == 200) {
                  Message.success("成功")
                  this.init(data.parentId)
                } else {
                  Message.error({message: data.msg + '(' + data.status + ')'})
                }
              })
            }).catch(() => {
        })
      },
      close() {
          this.editDialog = false
          this.addDialog = false
          this.rowData = {}
          this.jsonData={}
      }
    }
  }
</script>

<style scoped>

</style>
