<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="5">
                <el-form-item label="终端编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.termCode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="终端型号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.termModel"></el-input>
                </el-form-item>
              </el-col>

<!--              <el-col :span="5" v-show="true">-->
<!--                <el-form-item label="终端类型">-->
<!--                  <el-select v-model="pm.pm.terType" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in DICKV.term_type"-->
<!--                        :key="item.k"-->
<!--                        :label="item.k"-->
<!--                        :value="item.v"-->
<!--                        :disabled="item.disabled=='1'?false:true">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--              </el-col>-->

              <el-col :span="5">
                <el-form-item label="厂商编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.vendorNum"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="分配状态">
                <el-select v-model="pm.pm.status" placeholder="请选择">
                  <el-option
                      v-for="item in DICKV.divide_status"
                      :key="item.k"
                      :label="item.k"
                      :value="item.v"
                      :disabled="item.disabled=='1'?false:true">
                  </el-option>
                </el-select>
                </el-form-item>
              </el-col>


              <el-col :span="5">
                <el-form-item label="批次号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.batchno"></el-input>
                </el-form-item>
              </el-col>


              <el-col :span="5">
                <el-form-item label="添加人">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.addMan"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="添加时间">
                  <el-date-picker
                      v-model="pm.pm.addTime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="分配人">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.distrMan"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="分配时间">
                  <el-date-picker
                      v-model="pm.pm.distrTime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-button size="mini" type="primary" v-if="permissions.POSTdownexel" @click="TermExel">终端导出</el-button>
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="180" show-overflow-tooltip label="终端编号" prop="termCode" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="终端类型" prop="terType" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.terType, 'term_type') }}
          </template>
        </el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="终端型号" prop="termModel" ></el-table-column>
        <el-table-column width="140"  show-overflow-tooltip label="校验值" prop="checkval" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="厂商编号" prop="vendorNum" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="分配状态" prop="status">
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'divide_status') }}
          </template>
        </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="批次" prop="batchno" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="添加人" prop="addMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="添加时间" prop="addTime" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="分配人" prop="distrMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="分配时间" prop="distrTime" ></el-table-column>

<!--        <el-table-column label="操作" fixed="right" width="150" v-if="permissions.PUT||permissions.DELETE" >-->
<!--          <template slot-scope="scope" >-->
<!--            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>-->
<!--            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除-->
<!--            </el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->

        <el-table-column label="操作" fixed="right" width="100" v-if="permissions.PUT" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT && scope.row.terType=='1'" @click="show_qrcode(scope.row)">查看码牌</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>


<!--    <el-dialog :close-on-click-modal="false" :before-close="close" title="终端查看" :visible.sync="qrcodeDia" width="30%" >-->
<!--      <img :src="qrcodeurl" width="50%">-->
<!--    </el-dialog>-->


    <el-dialog width="30%" :show-close="false" :center="true" title="查看码牌" @close="closeCode" :visible.sync="qrCodeDialog" append-to-body>
      <!--      &lt;!&ndash; 用一个div套一个div给外层div加边框 &ndash;&gt;-->
      <!--      <div id="canva" class="paycode" style="width: 295px;height: 295px;border:20px solid #fff;margin: 0 auto;">-->
      <!--        &lt;!&ndash; 这里是放二维码图片的地方 &ndash;&gt;-->
      <!--        <div id="qrCodeImg" ref="qrcode"></div>-->
      <!--      </div>-->
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--         <el-button >下载</el-button>-->
      <!--         <el-button type="primary" >关闭</el-button>-->
      <!--      </span>-->
<!--      <TermQrCode ref="merqrcode"></TermQrCode>-->
      <div class="demo-image__placeholder">
        <div class="block">
          <span class="demonstration"></span>
          <el-image :src="qrcodeurl">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>

    </el-dialog>


    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button icon="el-icon-plus" v-if="permissions.POST" @click="showAddView" type="primary" size="mini">POS录入</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="1:扫码立牌" prop="terType">
              <el-input autocomplete="off" type="text" v-model="rowData.terType" placeholder="请输入1:扫码立牌"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="终端型号" prop="termModel">
              <el-input autocomplete="off" type="text" v-model="rowData.termModel" placeholder="请输入终端型号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="厂商编号" prop="vendorNum">
              <el-input autocomplete="off" type="text" v-model="rowData.vendorNum" placeholder="请输入厂商编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="状态 0:未分配 1:已分配" prop="status">
              <el-input autocomplete="off" type="text" v-model="rowData.status" placeholder="请输入状态 0:未分配 1:已分配"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加人" prop="addMan">
              <el-input autocomplete="off" type="text" v-model="rowData.addMan" placeholder="请输入添加人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加时间" prop="addTime">
              <el-input autocomplete="off" type="text" v-model="rowData.addTime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="分配人" prop="distrMan">
              <el-input autocomplete="off" type="text" v-model="rowData.distrMan" placeholder="请输入分配人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="分配时间" prop="distrTime">
              <el-input autocomplete="off" type="text" v-model="rowData.distrTime" placeholder="请输入分配时间"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="POS录入"
               v-loading="addloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :visible.sync="addDialog" width="30%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">
        <el-row>
          <el-col :span="13">
            <el-form-item label="厂商编号" prop="vendorNum">
              <el-select v-model="rowData.vendorNum" placeholder="请选择厂商编号" @change="(val) => changTermM(val)">
                <el-option
                    v-for="item in termfirm"
                    :key="item.value"
                    :label="item.value"
                    :value="item" >
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.lable }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="13">
            <el-form-item label="终端类型" prop="terType">
              <el-select v-model="rowData.terType" disabled placeholder="请选择">
                <el-option
                    v-for="item in DICKV.term_type"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="13">
            <el-form-item label="终端型号" prop="termModel">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.termModel" placeholder="请输入终端型号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="13">
            <el-form-item label="终端数量" prop="vendorSize">
              <el-input-number size="medium" v-model="rowData.termSize"  :min="1" :max="1000" :precision="0" :step="1"  label="请输入数量"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="批次号" prop="batchno">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.batchno" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :before-close="close" title="终端导出" :visible.sync="termExelDia" width="30%" >
      <el-form :model="termExe"  ref="termExelForm" label-width="110px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="批次号" prop="batchno" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
              <el-input autocomplete="off" type="text" v-model="termExe.batchno" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="终端编号区间" required>
            <el-col :span="11">
              <el-form-item prop="termbeg" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                <el-input autocomplete="off" type="text" v-model="termExe.termbeg" ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="termend" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                <el-input autocomplete="off" type="text" v-model="termExe.termend" ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="termExelDown">导出</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>







  </div>
</template>
<script>
import {Message} from "element-ui";
import fileDownload from "js-file-download";

export default {
  name: "Index",
  data() {
    return {
      DICKV:{},
      termfirm:this.allTermFirm(),
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      addloading:false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {
          terType:'0'
        },
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        terType: [{required: true, message: '请输入终端类型', trigger: 'blur'}],
        termModel: [{required: true, message: '请输入终端型号', trigger: 'blur'}],
        vendorNum: [{required: true, message: '请输入厂商编号', trigger: 'blur'}],
        termSize: [{required: true, message: '请输入终端数量', trigger: 'blur'}],
      },
      qrCodeDialog:false,
      //终端导出
      termExelDia:false,
      termExe:{
        termType: '0'
      },
      selectTerm:[],
      qrcodeDia:false,
      qrcodeurl:''
    }
  },created(){
    this.DICKV = this.$store.state.DICKV;
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    handleSelectionChange(val) {
      const  list= [];
      val.forEach((val) => {
        list.push({termCode:val.termCode,terType:val.terType,termModel:val.termModel});
      })
      this.selectTerm=list
    },
    TermExel(){
      if (this.selectTerm.length>0){
        this.postRequestBlob('/sys/cabinp/downexel',{termList:this.selectTerm}).then(data => {
          this.loading = false
          if (data) {
            fileDownload(data, this.filename()+'.csv')
            this.termExe={termType: '0'}
            this.termExelDia=false
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      }else{
        this.termExelDia=true
      }

    },
    termExelDown(){
      this.$refs.termExelForm.validate((valid) => {
        if (valid) {
          this.postRequestBlob('/sys/cabinp/downexel', this.termExe).then(data => {
            this.loading = false
            if (data) {
              fileDownload(data, this.filename()+'.csv')
              this.termExe={termType: '0'}
              this.termExelDia=false
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
    filename() {
      var timest=new Date().getTime()
      return timest;
    },
    show_qrcode (row) {
      //控制dialog
      this.qrCodeDialog = true
      this.getRequestBlob('/sys/qrcode', {termCode:row.termCode}).then(data => {
        this.loading = false
        this.qrcodeurl = window.URL.createObjectURL(data);
      })

      // this.$nextTick(() => {
      //   this.$refs.merqrcode.$emit('createQrcode', row)
      // })

    },
    // 关闭弹框,清除已经生成的二维码
    closeCode () {
      // 清除生成的二维码，每次打开后重新生成
      //注意这里的$ref，html部分也要加ref
      // this.$refs.merqrcode.innerHTML = ''
      this.qrCodeDialog = false
    },

    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/cabinp', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    allTermFirm(){
      this.getRequest('/sys/cabinp/termFirm',{termType:'0'}).then(data => {
        if (data.status == 200) {
          this.termfirm = data.obj
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    changTermM(val){
      this.rowData.vendorNum=val.value
      this.rowData.termModel=val.model
      this.rowData.terType=val.type
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {
        terType:'0'
      }
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/cabinp', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.rowData = {}
      this.rowData.batchno=this.orderCode();
      this.addDialog = true;
    },
    orderCode() {
      var orderCode='';
      for (var i = 0; i < 6; i++) //6位随机数，用以加在时间戳后面。
      {
        orderCode += Math.floor(Math.random() * 10);
      }
      orderCode = this.getDate() + orderCode;  //时间戳，用来生成订单号。
      return orderCode;
    },
    //当前日期 返回yyyy-MM-dd
    getDate(){
      var myDate = new Date();
      myDate.getFullYear();    //获取完整的年份(4位,1970-????)
      myDate.getMonth();       //获取当前月份(0-11,0代表1月)
      myDate.getDate();        //获取当前日(1-31)
      myDate.getHours();       // 时

      var day = myDate.getDate()>=10?myDate.getDate():"0"+myDate.getDate();
      var month= myDate.getMonth()+1>=10?myDate.getMonth()+1:"0"+(myDate.getMonth()+1);
      var hour = myDate.getHours(); // 时
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      myDate.toLocaleDateString();     //获取当前日期
      return myDate.getFullYear()+""+(month)+""+day+""+hour;
    },

    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addloading = true
          this.postRequest('/sys/cabinp', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.addloading = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
              this.addloading = false
            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/cabinp", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.termExelDia=false
            this.init()
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>

</style>
