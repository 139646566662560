import { render, staticRenderFns } from "./MerProC.vue?vue&type=template&id=5ea13ed6&scoped=true&"
import script from "./MerProC.vue?vue&type=script&lang=js&"
export * from "./MerProC.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ea13ed6",
  null
  
)

export default component.exports