<template>
  <div>
    <div  v-if="permissions.GET">
      <el-form label-width="90px">
        <el-collapse v-model="retrieveActiveNames0">
          <el-collapse-item name="1">
          <el-row>
            <el-col :span="4">
              <el-form-item label="系统商户号">
                <el-input autocomplete="off" type="text" v-model="pm.pm.merId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="渠道商户号">
                <el-input autocomplete="off" type="text" v-model="pm.pm.merIdChannel"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="商户名称">
                <el-input autocomplete="off" type="text" v-model="pm.pm.merName"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="签约状态">
                <el-select v-model="pm.pm.qyStatus" placeholder="请选择">
                  <el-option
                      v-for="item in DICKV.qy_status"
                      :key="item.k"
                      :label="item.k"
                      :value="item.v">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="合规审核状态">
                <el-select v-model="pm.pm.cusrgcStatus" placeholder="请选择">
                  <el-option
                      v-for="item in DICKV.cusrgc_status"
                      :key="item.k"
                      :label="item.k"
                      :value="item.v">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="创建时间">
                <el-date-picker
                    v-model="pm.pm.addTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          </el-collapse-item>
        </el-collapse>
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="高级检索" name="1">
          <template slot="title">
            <span style="color: #1986D2">高级检索<i class="header-icon el-icon-info mini" style="color: #1986D2"></i></span>
          </template>
            <el-row>

<!--              <el-col :span="4">-->
<!--                <el-form-item label="所属行业">-->
<!--                  <el-input autocomplete="off" type="text" v-model="pm.pm.mccid"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->

              <el-col :span="4">
                <el-form-item label="所属行业" prop="mccidName">
                  <el-select v-model="pm.pm.mccidName" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethodMcc" :loading="mccloading" @change="changeMcc($event)">
                    <el-option v-for="item in mccoptions" :key="item.value" :label="item.label" :value="item" >
                      <span style="float: left">{{ item.value }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
<!--                <el-form-item label="所属行业">-->
<!--                  <el-select v-model="pm.pm.mccid" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in DICKV.mer_type"-->
<!--                        :key="item.k"-->
<!--                        :label="item.k"-->
<!--                        :value="item.v">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
              </el-col>



              <el-col :span="4">
                <el-form-item label="商户类型">
                  <el-select v-model="pm.pm.merType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.mer_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

<!--              <el-col :span="4">-->
<!--                <el-form-item label="拓展人">-->
<!--                  <el-input autocomplete="off" type="text" v-model="pm.pm.expandMan"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->

              <el-col :span="4">
                <el-form-item label="联系人姓名">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.businessContactName"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="联系人电话">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.businessContactPhone"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="业务场景">
                  <el-select v-model="pm.pm.businessFlag" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.business_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="商户来源">
                  <el-select v-model="pm.pm.merSource" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.mer_source"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="商户状态">
                  <el-select v-model="pm.pm.status" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.mer_status"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>

<!--              <el-col :span="4">-->
<!--                <el-form-item label="审核状态">-->
<!--                  <el-select v-model="pm.pm.auditStatus" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in DICKV.audit_status"-->
<!--                        :key="item.k"-->
<!--                        :label="item.k"-->
<!--                        :value="item.v"-->
<!--                        :disabled="item.disabled=='1'?false:true">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col :span="4">
                <el-form-item label="所属合作方">
<!--                  <el-input autocomplete="off" type="text" v-model="pm.pm.belongAgencyNo"></el-input>-->


<!--                  <el-select v-model="rowData.belongAgencyNo" placeholder="请选择"  >-->
<!--                    <el-option-->
<!--                        v-for="item in allAgency"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                      <span style="float: left">{{ item.label }}</span>-->
<!--                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>-->
<!--                    </el-option>-->
<!--                  </el-select>-->


                    <el-select
                        v-model="pm.pm.belongAgencyNo"
                        filterable
                        clearable
                        remote
                        reserve-keyword
                        placeholder="请输入关键词"
                        :remote-method="remoteQuAgency"
                        :loading="loading">
                      <el-option
                          v-for="item in agencyOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                      </el-option>
                    </el-select>
<!--                  <el-select v-model="pm.pm.belongAgencyNo" filterable placeholder="请选择" @change="changeWx(rowData.userid)">-->
<!--                    <el-option-->
<!--                        v-for="item in users"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                      <span style="float: left">{{ item.value }}</span>-->
<!--                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>-->
<!--                    </el-option>-->
<!--                  </el-select>-->


                </el-form-item>
              </el-col>
            </el-row>

        </el-collapse-item>
      </el-collapse>
      </el-form>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>

    <div style="margin-top: 5px">
<!--      <el-button size="mini" type="warning" v-if="permissions.GET && permissions.PUT" :disabled="btnBoolean ? false : true" @click="edit_merInfo()">基本信息配置</el-button>-->
<!--      <el-button size="mini" type="warning" v-if="permissions.GET && permissions.PUT" :disabled="btnBoolean ? false : true" @click="edit_merSettle()">结算信息配置</el-button>-->
<!--      <el-button size="mini" type="warning" v-if="permissions.GET && permissions.PUT" :disabled="btnBoolean ? false : true" @click="edit_merRate()">费率信息配置</el-button>-->
<!--      <el-button size="mini" type="warning" v-if="permissions.GET && permissions.PUT" :disabled="btnBoolean ? false : true" @click="edit_merPro()">门店信息配置</el-button>-->
<!--      <el-button size="mini" type="warning" v-if="permissions.GET && permissions.PUT" :disabled="btnBoolean ? false : true" @click="edit_merOther()">其他信息配置</el-button>-->
<!--      <el-button size="mini" type="warning" v-if="permissions.GET && permissions.PUT" :disabled="btnBoolean ? false : true" @click="edit_merPic()">图片信息配置</el-button>-->

      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" @current-change="clickChange" >
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio v-model="tableRadio" :label="scope.row"><i></i></el-radio>
          </template>
        </el-table-column>

        <el-table-column width="80" show-overflow-tooltip label="系统商户号" prop="merId" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="渠道商户号" prop="merIdChannel" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="商户名称" prop="merName" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="所属合作方" prop="belongAgencyNo" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="拓展人" prop="expandMan" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="商户来源" prop="merSource" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.merSource, 'mer_source') }}
          </template>
        </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="商户状态" prop="status" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'mer_status') }}
          </template>
        </el-table-column>
<!--        <el-table-column width="80" show-overflow-tooltip label="审核状态" prop="auditStatus" >-->
<!--          <template slot-scope="scope">-->
<!--            {{ getDICKV(scope.row.auditStatus, 'audit_status') }}-->
<!--          </template>-->
<!--        </el-table-column>-->

        <el-table-column width="100" show-overflow-tooltip label="渠道审核信息" prop="auditMsg" ></el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="合规审核状态" prop="cusrgcStatus" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.cusrgcStatus, 'cusrgc_status') }}
          </template>
        </el-table-column>
        <el-table-column width="100" show-overflow-tooltip label="合规审核信息" prop="cusrgcMsg" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="签约状态" prop="qyStatus" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.qyStatus, 'qy_status') }}
          </template>
        </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="审核意见" prop="handlestate" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.handlestate, 'handlestate') }}
          </template>
        </el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="创建时间" prop="addTime" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="最后修改时间" prop="lastTime" ></el-table-column>

        <el-table-column label="操作" fixed="right" v-if="permissions.PUT||permissions.DELETE" >
          <template slot-scope="scope" >
            <el-button type="primary" size="mini" width="130" v-if="permissions.POST && scope.row.qyStatus!='0'" @click="sendMess(scope.row.merId)">重发签约短信</el-button>
            <el-button type="warning" size="mini" width="130" v-if="permissions.GET && permissions.PUT"  @click="edit_merInfo(scope.row)">商户信息配置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>
    </div>
    <!--    商户查看-->
    <el-dialog :close-on-click-modal="false"
               v-loading="merSeeloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="clossNoDia" title="" :visible.sync="merSeeDialog"
               width="70%">
      <template slot='title'>
        <span style="font-size:20px;">商户信息查看</span>
      </template>
      <div style="text-align: left" >
        <MerSee ref="mersee" v-on:colseFaDia="colseFaDia()" ></MerSee>
      </div>
    </el-dialog>

    <!--    商户编辑-->
    <el-dialog :close-on-click-modal="false"
               :before-close="close" title="" :visible.sync="editMerInfoDialog"
               width="75%">
      <template slot='title'>
        <span style="font-size:20px;">商户信息编辑</span>
      </template>
      <div style="text-align: left" >
        <MerEduit ref="mereduit"
                  v-on:colseFaDia="colseFaDia()"
                  v-on:loadFaDiaOpen="loadFaDiaOpen()"
                  v-on:loadFaDiaClose="loadFaDiaClose()"
                  v-loading="merEduitloading"
                  element-loading-text="拼命加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)" ></MerEduit>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false"
               v-loading="merbranchloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="editBranInfoDialog"
               width="68%">
      <template slot='title'>
        <span style="font-size:20px;">门店信息编辑</span>
      </template>
      <div style="text-align: left" >
        <MerBracnch ref="merbranch" v-on:colseFaDia="colseFaDia()" ></MerBracnch>
      </div>
    </el-dialog>



  </div>
</template>
<script>
import {Message} from "element-ui";
import MerSee from "@/views/sys/merch/MerSee";
import MerBracnch from "@/views/sys/merch/MerBracnch";
import MerEduit from "./MerEduit";

export default {
  name: "Index",
  components:{MerEduit, MerSee,MerBracnch},
  data() {
    return {
      DICKV:{},
      tableRadio: {},
      btnBoolean: false,//禁用批量按钮
      retrieveActiveNames:'0',
      retrieveActiveNames0:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      datas: [],
      rowData: {},
      urowData: {merName:'1'},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        changeAuditStatus: [{required: true, message: '请输入修改信息后的审核状态', trigger: 'blur'}],
        addMan: [{required: true, message: '请输入添加人', trigger: 'blur'}],
        lastMan: [{required: true, message: '请输入最后修改人', trigger: 'blur'}],
        auditName: [{required: true, message: '请输入审核人', trigger: 'blur'}],
        id: [{required: true, message: '请输入null', trigger: 'blur'}],
        merId: [{required: true, message: '请输入系统商户号', trigger: 'blur'}],
        merIdChannel: [{required: true, message: '请输入渠道商户号', trigger: 'blur'}],
        merName: [{required: true, message: '请输入商户名称', trigger: 'blur'}],
        shortName: [{required: true, message: '请输入商户简称', trigger: 'blur'}],
        servicePhone: [{required: true, message: '请输入客服电话', trigger: 'blur'}],
        mccid: [{required: true, message: '请输入所属行业', trigger: 'blur'}],
        merType: [{required: true, message: '请输入商户类型', trigger: 'blur'}],
        legalName: [{required: true, message: '请输入法人姓名', trigger: 'blur'}],
        legalIdType: [{required: true, message: '请输入法人代表证件类型', trigger: 'blur'}],
        legalIdNo: [{required: true, message: '请输入法人代表证件号', trigger: 'blur'}],
        legalIdExpire: [{required: true, message: '请输入法人身份证有效期', trigger: 'blur'}],
        address: [{required: true, message: '请输入注册地址', trigger: 'blur'}],
        contactPerson: [{required: true, message: '请输入商户负责人', trigger: 'blur'}],
        contactPhone: [{required: true, message: '请输入商户负责人电话', trigger: 'blur'}],
        clearMode: [{required: true, message: '请输入"0-商户自主提现', trigger: 'blur'}],
        expandMan: [{required: true, message: '请输入拓展人', trigger: 'blur'}],
        parentId: [{required: true, message: '请输入所在机构资金归集时，该字段不能为空', trigger: 'blur'}],
        businessContactName: [{required: true, message: '请输入业务联系人姓名', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '请输入业务联系人电话', trigger: 'blur'}],
        businessFlag: [{required: true, message: '请输入线上线下业务场景', trigger: 'blur'}],
        agreement: [{required: true, message: '请输入无纸化标识"1:电子协议', trigger: 'blur'}],
        bnfDetail: [{required: true, message: '请输入受益人标识', trigger: 'blur'}],
        chanBankNo: [{required: true, message: '请输入渠道电子账户号', trigger: 'blur'}],
        merFlag: [{required: true, message: '请输入商户标识 1：TL通联商户；XX其他商户', trigger: 'blur'}],
        merSource: [{required: true, message: '请输入商户来源：1:H5；2:WEB；3:api；', trigger: 'blur'}],
        status: [{required: true, message: '请输入商户开通状态', trigger: 'blur'}],
        auditStatus: [{required: true, message: '请输入商户进件时的审核状态', trigger: 'blur'}],
        auditTime: [{required: true, message: '请输入审核终态时间', trigger: 'blur'}],
        commitAuditTime: [{required: true, message: '请输入提交审核时间', trigger: 'blur'}],
        lastTime: [{required: true, message: '请输入最后修改时间', trigger: 'blur'}],
        addTime: [{required: true, message: '请输入商户创建时间', trigger: 'blur'}],
        changeSerial: [{required: true, message: '请输入更新流水号。对应临时表中的流水号', trigger: 'blur'}],
        belongAgencyNo: [{required: true, message: '请输入所属合作方编号', trigger: 'blur'}],
      },

      //代理商列表
      agencyOptions: {},

      //商户查看
      merSeeDialog:false,
      merSeeloading:false,

      //基本信息编辑
      merEduitloading:false,
      editMerInfoDialog:false,
      allAgency:{},
      //客户经理
      saleperoptions:[{}],
      //门店信息管理
      editBranInfoDialog:false,
      merbranchloading:false,

      mccoptions:[{}],
      mccloading:false,
      mcclist:[],



  }
  }, mounted() {
      this.permissions = this.$store.state.permissions;
      this.DICKV = this.$store.state.DICKV;
      this.init()
    }, methods: {
      remoteMethodMcc(query) {
        // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
        if (query !== "") {
          this.mccloading = true; // 开始拿数据喽
          // 这里模拟发请求，res就当成发请求返回来的数据吧。
          this.getRequest("/sys/mcc/asyn",{parm:query}).then(resp=> {
            if (resp && resp.status == 200) {
              this.mcclist=resp.obj
              this.mccloading = false // 拿到数据喽
              this.mccoptions = resp.obj
              // this.mcclist.filter((item)=>{
              //   // indexOf等于0代表只要首个字匹配的，如：搜索 王 王小虎数据会被过滤出来，但是 小虎王的数据不会被过滤出来。因为indexOf等于0代表以什么开头
              //   // return item.label.toLowerCase().indexOf(query.toLowerCase()) == 0
              //   // indexOf大于-1代表的是，只要有这个字出现的即可，如：搜索 王 王小虎、小虎王、小王虎都会被过滤出来。因为indexOf找不到才会返回-1，
              //   // 大于-1说明只要有就行，不论是不是开头也好，中间也好，或者结尾也好
              //   return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
              // })
            }
          });

        } else {
          this.mccoptions = [];
        }
      },
      changeMcc(val){
        this.pm.pm.mccid=val.value
        this.pm.pm.mccidName=val.label
      },

      colseFaDia(){
        this.merEduitDialog=false
        this.editMerInfoDialog=false
        this.merSeeDialog=false
        this.editBranInfoDialog=false
        this.merEduitloading=false
        this.init()
      },
      loadFaDiaOpen(){
        this.merEduitloading=true
      },
      loadFaDiaClose(){
        this.merEduitloading=false
      },
      clickChange(item) {
        if (item){
          this.tableRadio = item
          this.btnBoolean = true;
        }
      },
      getDICKV(e, type) {
        for (const key in this.DICKV) {
          if (key == type) {
            for (let i = 0; i < this.DICKV[key].length; i++) {
              let j = this.DICKV[key][i].v; //j 就是数值类型了
              if (j == e ) { //dictValue，dictLabel保持和上面定义一致
                return this.DICKV[key][i].k;
              }
            }
          }
        }
      },
      init() {
      this.btnBoolean=false;
        if (this.permissions.GET) {
          this.loading = true
          this.getRequest('/sys/merch', this.pm).then(data => {
            this.loading = false
            if (data.status == 200) {
              this.datas = data.obj.list
              this.pm.page = data.obj.page
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          Message.error({message: '无查询权限'})
        }
      },
      queryForm() {
        this.pm.page.pageNum = 1
        this.init()
      },
      clearQueryForm() {
        this.pm.pm = {}
      },
      handleSizeChange(val) {
        this.pm.page.pageNum = 1
        this.pm.page.pageSize = val
        this.init()
      },
      handleCurrentChange(val) {
        this.pm.page.pageNum = val
        this.init()
      },
      showSeeView(rowData) {
        this.rowData = {}
        this.rowData = rowData
        this.merSeeDialog = true
        this.$nextTick(()=>{
          this.$refs.mersee.$emit('init', this.rowData.merId)
        })
      },
      submitEditForm() {
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            this.putRequest('/sys/merch', this.rowData).then(data => {
              if (data.status == 200) {
                Message.success("修改成功")
                this.editDialog = false
                this.init();
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})

              }
            })
          } else {
            return false;
          }
        })
      },
      showAddView() {
        this.rowData = {}
      },
    clossNoDia(){
      this.merSeeDialog=false
      this.index=0
      this.rowData = {}
      this.init()
    },
      close() {
        this.$confirm('确认取消？')
            .then(() => {
              this.editMerInfoDialog=false
              this.editBranInfoDialog=false
              this.rowData = {}
              this.init()
            })
            .catch(() => {
            });
      },

    // showEditView(rowData) {
    //   this.rowData = {}
    //   this.rowData = rowData
    //   this.merEduitDialog = true
    //   this.$nextTick(()=>{
    //     this.$refs.mereduit.$emit('init', this.rowData.id)
    //   })
    // },


    /*重发签约短信*/
    sendMess(merId){
      this.postRequest('/sys/merch/sendMess', {merId:merId}).then(data => {
        if (data.status == 200) {
          Message.success("短信重发成功")
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    edit_merInfo(row){
      this.editMerInfoDialog=true
      this.$nextTick(()=>{
        this.$refs.mereduit.$emit('init', row.merId)
      })
      // this.editMerInfoDialog=true
      // this.getRequest("/sys/merch/MerInfoByType",{merId: this.tableRadio.merId}).then(resp=> {
      //   if (resp && resp.status == 200) {
      //     this.rowData=resp.obj;
      //   }
      // });
    },
    submitMerInfoEditForm(){
      this.postRequest("/sys/merch/MerInfoByType",this.rowData).then(resp=> {
        if (resp && resp.status == 200) {
          this.rowData=resp.obj;
        }
      });
      this.editMerInfoDialog=false
    },
    edit_merPro(){
      this.editBranInfoDialog=true
      this.$nextTick(()=>{
        this.$refs.merbranch.$emit('init', this.tableRadio.merId)
      })
    },

    remoteQuAgency(val){
      if (val !== '') {
        this.getRequest("/sys/merch/queryAgency",{param:val}).then(resp=>{
          if (resp && resp.status == 200) {
            this.agencyOptions=resp.obj;
          }
        })
      }
    }

    }
  }
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>
