<template>
  <div>
    <div v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="4">
                <el-form-item label="系统流水号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.serial"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="商户">
                  <el-select v-model="pm.merid" placeholder="(全部)"
                             filterable
                             remote
                             clearable
                             :remote-method="getMerid"
                  >
                    <el-option
                        v-for="item in Merinfo"
                        :key="item.merid"
                        :label="item.label"
                        :value="item.merid">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.merid }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="渠道商户号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.merIdChannel"></el-input>
                </el-form-item>
              </el-col>


              <el-col :span="4">
                <el-form-item label="商户单号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.merOrder"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="交易类型">
                  <el-select v-model="queryTransType" multiple
                             collapse-tags
                             style="margin-left: 20px;" placeholder="请选择">
                    <el-option
                        v-for="item in transtype"
                        :key="item.value"
                        :label="item.lable"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>


              <el-col :span="4">
                <el-form-item label="支付类型">
                  <el-select v-model="pm.pm.payType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.paytype"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="交易状态">
                  <el-select v-model="pm.pm.status" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.status"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="交易时间">
                  <el-date-picker autocomplete="off"
                                  v-model="info.localTime"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  value-format='yyyy-MM-dd'>
                  </el-date-picker>
                </el-form-item>
              </el-col>
			  
			  <el-col :span="4">
				<el-form-item label="代理机构">
				  <el-select v-model="pm.pm.agencyNo" clearable placeholder="(全部)">
					<el-option
						v-for="item in agencyNos"
						:key="item.agencyNo"
						:label="item.agencyName"
						:value="item.agencyNo">
					</el-option>
				  </el-select>
				</el-form-item>
			  </el-col>


              <!--          <el-col  :span="4">
                          <el-form-item label="原交易流水">
                            <el-input autocomplete="off" type="text" v-model="pm.pm.origserial"></el-input>
                          </el-form-item>
                        </el-col>

                        <el-col  :span="4">
                          <el-form-item label="通联流水号">
                            <el-input autocomplete="off" type="text" v-model="pm.pm.transSerial"></el-input>
                          </el-form-item>
                        </el-col>

                        <el-col  :span="4">
                          <el-form-item label="渠道交易单号">
                            <el-input autocomplete="off" type="text" v-model="pm.pm.channelSerial"></el-input>
                          </el-form-item>
                        </el-col>-->

            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini" v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini" type="warning" @click="handleExport" v-if="permissions.GET">导出</el-button>
          <el-button size="mini" v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%">
        <el-table-column width="130" show-overflow-tooltip label="系统流水号" prop="serial"></el-table-column>

        <el-table-column width="130" show-overflow-tooltip label="商户编号" prop="merId"></el-table-column>

        <el-table-column width="130" show-overflow-tooltip label="商户名称" prop="merName"></el-table-column>

        <el-table-column width="130" show-overflow-tooltip label="渠道商户号" prop="merIdChannel"></el-table-column>

        <el-table-column width="140" show-overflow-tooltip label="商户单号" prop="merOrder"></el-table-column>

        <el-table-column width="130" show-overflow-tooltip label="交易类型" prop="transType"></el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="支付类型" prop="payType">
          <template slot-scope="scope">
            {{ getDICKV(scope.row.payType, 'paytype') }}
          </template>
        </el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="交易金额" prop="amount"></el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="交易状态" prop="status">
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'status') }}
          </template>
        </el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="交易日期" prop="localDate"></el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="交易时间" prop="localTime"></el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="原交易流水" prop="origserial"></el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="应答码" prop="respcode"></el-table-column>

        <el-table-column width="100" show-overflow-tooltip label="应答信息" prop="respmsg"></el-table-column>

        <!--        <el-table-column width="100" show-overflow-tooltip label="通联流水号" prop="transSerial" ></el-table-column>-->

        <el-table-column width="100" show-overflow-tooltip label="渠道交易单号" prop="channelSerial"></el-table-column>

        <el-table-column label="操作" fixed="right" width="100" v-if="permissions.PUT||permissions.DELETE">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">详细</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="pm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>

    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="详细" :visible.sync="editDialog" width="50%">
      <el-form :model="rowData" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="商户编号" prop="merId">
              <el-input autocomplete="off" type="text" v-model="rowData.merId"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="渠道商户号" prop="merIdChannel">
              <el-input autocomplete="off" type="text" v-model="rowData.merIdChannel"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店编号" prop="branchNo">
              <el-input autocomplete="off" type="text" v-model="rowData.branchNo"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="终端编号" prop="termCode">
              <el-input autocomplete="off" type="text" v-model="rowData.termCode"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="商户单号" prop="merOrder">
              <el-input autocomplete="off" type="text" v-model="rowData.merOrder"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="产品编号" prop="producp">
              <el-input autocomplete="off" type="text" v-model="rowData.producp"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易类型" prop="transType">
              <el-input autocomplete="off" type="text" v-model="rowData.transType"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="支付类型" prop="payType">
              <el-select disabled v-model="rowData.payType">
                <el-option
                    v-for="item in DICKV.paytype"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易金额" prop="amount">
              <el-input autocomplete="off" type="text" v-model="rowData.amount"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易状态" prop="status">
              <el-select disabled v-model="rowData.status">
                <el-option
                    v-for="item in DICKV.status"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易日期" prop="localDate">
              <el-input autocomplete="off" type="text" v-model="rowData.localDate"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易时间" prop="localTime">
              <el-input autocomplete="off" type="text" v-model="rowData.localTime"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="付款信息" prop="payer">
              <el-input autocomplete="off" type="text" v-model="rowData.payer"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="订单标题" prop="orderTitle">
              <el-input autocomplete="off" type="text" v-model="rowData.orderTitle"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="订单备注" prop="remark">
              <el-input autocomplete="off" type="text" v-model="rowData.remark"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="原交易流水" prop="origserial">
              <el-input autocomplete="off" type="text" v-model="rowData.origserial"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="应答码" prop="respcode">
              <el-input autocomplete="off" type="text" v-model="rowData.respcode"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="应答信息" prop="respmsg">
              <el-input autocomplete="off" type="text" v-model="rowData.respmsg"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="通联流水号" prop="transSerial">
              <el-input autocomplete="off" type="text" v-model="rowData.transSerial"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="渠道交易单号" prop="channelSerial">
              <el-input autocomplete="off" type="text" v-model="rowData.channelSerial"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </el-dialog>

  </div>
</template>
<script>
import {Message} from "element-ui";
import fileDownload from "js-file-download";

export default {
  name: "Index",
  data() {
    return {
      DICKV: {},
      localTime: [],
      retrieveActiveNames: '1',
      suspension_label: 3,
      suspension_data: 14,
      Merinfo: [],
      transtype: [],
      queryTransType: [],
      permissions: {},
      loading: false,
      editDialog: false,
      datas: [],
      rowData: {},
	  agencyNos:[],
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      info: {
        localTime: [this.getDaydate(), this.getEnd()],
      }
    }
  }, created() {
    this.DICKV = this.$store.state.DICKV;
  }, mounted() {
    this.permissions = this.$store.state.permissions;

    this.init();
    // this.getMerid();
    this.getTrantype();
	this.getAgencyNos();

  }, methods: {

    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      if (this.permissions.GET) {
        this.loading = true
        if (this.localTime != null && this.localTime.length > 0) {
          this.pm.pm.startTime = this.localTime[0]
          this.pm.pm.endTime = this.localTime[1]
        }
        this.pm.pm.merId = this.pm.merid;
        this.pm.pm.transType = this.queryTransType + '';

        if (this.info.localTime != null && this.info.localTime.length > 0) {
          this.pm.pm.startTime = this.info.localTime[0]
          this.pm.pm.endTime = this.info.localTime[1]
        }
        this.getRequest('/sys/dealexport', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '1无查询权限'})
      }
    },

    handleExport() {
      this.downloadingDialog = true
      // this.pm.serial=this.pm.pm.serial;
      // this.pm.merId=this.pm.pm.merId;
      // this.pm.merIdChannel=this.pm.pm.merIdChannel;
      // this.pm.merOrder=this.pm.pm.merOrder;
      // this.pm.transType = this.queryTransType+'';
      // this.pm.payType=this.pm.pm.payType;
      // this.pm.status=this.pm.pm.status;
      // this.pm.localTime=this.pm.pm.localTime;
      this.getRequestBlob('/sys/dealexport/exportlist', this.pm).then(data => {
        if (!data) {
          return
        }
        fileDownload(data, this.filename()+'.csv')
        // let url = window.URL.createObjectURL(new Blob([data]))
        // let link = document.createElement('a');
        // link.style.display = 'none';
        // link.href = url
        // link.setAttribute('download', rowdata.fileName)
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
        this.downloadingDialog = false
      })
    },
    filename() {
      var timest=new Date().getTime()
      return timest;
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
      this.pm.merid = ''
      this.localTime = []
      this.queryTransType = []
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    getTrantype() {
      this.loading = true
      this.getRequest('/sys/deal/AllTransType', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.transtype = data.obj
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },

    getMerid(val) {
      // this.pm.merId = this.$store.state.user.belongMerid
      if (this.permissions.GET) {
        this.getRequest('/sys/deal/getMerid', {parm:val}).then(data => {
          if (data.status == 200) {
            this.Merinfo = data.obj
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      }
    },

    // getMerid() {
    //   this.pm.merId = this.$store.state.user.belongMerid
    //   if (this.permissions.GET) {
    //     this.loading = true
    //     this.getRequest('/sys/deal/getMerid', this.pm).then(data => {
    //       this.loading = false
    //       if (data.status == 200) {
    //         this.Merinfo = data.obj
    //       } else {
    //         Message.error({message: data.msg + '(' + data.status + ')'})
    //       }
    //     })
    //   }
    //   // else {
    //   //   Message.error({message: '无查询权限'})
    //   // }
    // },

    getDaydate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      return defaultDate;
    },
    getEnd() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      return defaultDate
    },
	
	getAgencyNos() {
      this.loading = true
      this.getRequest('/sys/agency', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.agencyNos = data.obj.list
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
	
    close() {
      this.editDialog = false
      this.rowData = {}
      this.init()

    },
  }


}
</script>

<style scoped>

</style>
