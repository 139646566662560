<template>
  <div>
    <div>
      <el-form label-width="80px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="用户名">
              <el-input auto-complete="off" type="text" v-model="pm.pm.username" placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="姓名">
              <el-input auto-complete="off" type="text" v-model="pm.pm.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="描述">
              <el-input auto-complete="off" type="text" v-model="pm.pm.information" placeholder="请输入描述"></el-input>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="7">-->
          <!--              <el-form-item label="角色" prop="roleId">-->
          <!--                &lt;!&ndash;                            <el-input autocomplete="off" type="text" v-model="user.roleName" placeholder="请输入用户名"></el-input>&ndash;&gt;-->
          <!--                <el-select v-model="pm.pm.roleId" clearable filterable placeholder="请选择角色">-->
          <!--                  <el-option-->
          <!--                      ref="roleSelect"-->
          <!--                      v-for="item in roles"-->
          <!--                      :key="item.id"-->
          <!--                      :label="item.name"-->
          <!--                      :value="item.id"-->
          <!--                  ></el-option>-->
          <!--                </el-select>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
        <el-row>
          <el-col>
            <el-button type="success" size="mini" @click="queryForm">查询</el-button>
            <el-button size="mini" @click="clearQueryForm">清空</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div  style="margin-top: 10px;">
      <el-table
          v-loading="loading"
          :data="datas"
          style="width: 100%"
      >
        <el-table-column
            label="id"
            prop="id"
            width="50"
        ></el-table-column>

        <el-table-column
            label="用户名"
            prop="username"
            width="100"
        ></el-table-column>
        <el-table-column
            label="姓名"
            prop="name"
            width="100"
        ></el-table-column>
        <el-table-column
            label="描述"
            prop="information"
            width="150"
        ></el-table-column>
        <el-table-column
            label="有效期起"
            prop="expStartDt"
            width="140"
        ></el-table-column>
        <el-table-column
            label="有效期止"
            prop="expEndDt"
            width="140"
        ></el-table-column>
        <el-table-column
            label="最后登录"
            prop="lastLoginDt"
            width="140"
        ></el-table-column>
        <el-table-column
            label="登录次数"
            prop="loginNum"
            width="80"
        ></el-table-column>
        <el-table-column
            label="编辑日期"
            prop="chgDt"
            width="140"
        ></el-table-column>
        <el-table-column
            label="编辑用户"
            prop="chgUserUsername"
            width="140"
        ></el-table-column>
        <el-table-column
            width="50"
            label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled==1">启用</el-tag>
            <el-tag type="danger" v-else>停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="角色"
            width="100">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.username!='sadmin'">{{ scope.row.roleName }}</el-tag>
            <el-tag v-if="scope.row.username=='sadmin'" type="danger">超级管理员</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            width="50"
            label="在线">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.isOnline==1">在线</el-tag>
            <el-tag type="info" v-else>离线</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="注册日期"
            prop="regDt"
            width="140"
        ></el-table-column>
        <el-table-column
            label="推荐人"
            prop="refereesUsername"
            width="100"
        ></el-table-column>

        <el-table-column
            label="电话"
            prop="telephone"
            width="130"
        ></el-table-column>
        <el-table-column
            label="手机"
            prop="mobile"
            width="100"
        ></el-table-column>
        <el-table-column
            label="邮箱"
            prop="email"
            width="150"
        ></el-table-column>
        <el-table-column
            label="地址"
            width="150"
            prop="address"
            show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            width="150"
        >
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="permissions.PUT"
                       @click="showEditView(scope.row)">编辑
            </el-button>
            <el-button type="danger" v-if="permissions.DELETE&&scope.row.username!='sadmin'"
                       @click="deleteRow(scope.row)" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pm.page.page"
            :page-sizes="[15, 50, 100, 200]"
            :page-size="pm.page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pm.page.total">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog
        @before-close="closeDialog"
        title="编辑用户"
        :visible.sync="editDialog"
        width="50%" >
      <el-form :model="user" ref="editForm" label-width="100px">
        <el-row>
          <el-col :span="15">
            <el-form-item label="用户名" prop="username">
              <el-input autocomplete="off" disabled type="text" v-model="user.username"
                        placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="姓名" prop="name">
              <el-input autocomplete="off" type="text" v-model="user.name"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="描述" prop="information">
              <el-input autocomplete="off" type="text" v-model="user.information"
                        placeholder="请输入描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="状态" prop="enabled">
              <!--              <el-input autocomplete="off" type="text" v-model="user.enabled" placeholder="请输入用户名"></el-input>-->
              <el-switch
                  style="display: block"
                  :active-value="1" :inactive-value="0"
                  v-model="user.enabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="启用"
                  inactive-text="停用">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="有效期" prop="expStartDt">
              <el-date-picker
                  v-model="user.expStartDt"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择起始时间">
              </el-date-picker>
              -
              <el-date-picker
                  v-model="user.expEndDt"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="电话" prop="telephone">
              <el-input autocomplete="off" type="text" v-model="user.telephone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="手机" prop="mobile">
              <el-input autocomplete="off" type="text" v-model="user.mobile"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="邮箱" prop="email">
              <el-input autocomplete="off" type="text" v-model="user.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="地址" prop="address">
              <el-input autocomplete="off" type="textarea" v-model="user.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="15">
            <el-form-item label="密码" prop="password">
              <el-input autocomplete="off" type="text" v-model="user.password"
                        placeholder="请输入密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="角色" prop="username">
              <!--                            <el-input autocomplete="off" type="text" v-model="user.roleName" placeholder="请输入用户名"></el-input>-->
<!--              <el-select v-model="user.roleId" clearable filterable placeholder="请选择角色">
                <el-option
                    ref="roleSelect"
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>-->


<!--              <el-select v-model="mineStatus" placeholder="请选择" multiple collapse-tags @change="selectChange">
                <el-option  :value="mineStatusValue" style="height: auto" :disabled="true">
                  <el-tree :data="roles" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps" @check-change="handleCheckChange"></el-tree>
                </el-option>
              </el-select>-->

              <TreeSelect :data="roles"
                           :defaultProps="defaultProps" multiple
                           :nodeKey="nodeKey" :checkedKeys="defaultCheckedKeys"
                           @popoverHide="popoverHide"></TreeSelect>





            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
        @before-close="closeDialog"
        title="添加"
        :visible.sync="addDialog"
        width="50%"
    >
      <el-form :model="user" :rules="rules" ref="addForm" label-width="100px">
        <!--        <el-row>-->
        <!--          <el-col :span="15">-->
        <!--            <el-form-item label="id" prop="id">-->
        <!--              <el-input autocomplete="off" type="number" v-model="user.id" placeholder="请输入用户id"></el-input>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->

        <el-row>
          <el-col :span="15">
            <el-form-item label="用户名" prop="username">
              <el-input autocomplete="off" type="text" v-model="user.username" placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="姓名" prop="name">
              <el-input autocomplete="off" type="text" v-model="user.name"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="描述" prop="information">
              <el-input autocomplete="off" type="text" v-model="user.information"
                        placeholder="请输入描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="状态" prop="enabled">
              <!--              <el-input autocomplete="off" type="text" v-model="user.enabled" placeholder="请输入用户名"></el-input>-->
              <el-switch
                  style="display: block"
                  :active-value="1" :inactive-value="0"
                  v-model="user.enabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="启用"
                  inactive-text="停用">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="有效期" prop="expStartDt">
              <el-date-picker
                  v-model="user.expStartDt"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择起始时间">
              </el-date-picker>
              -
              <el-date-picker
                  v-model="user.expEndDt"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="电话" prop="telephone">
              <el-input autocomplete="off" type="text" v-model="user.telephone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="手机" prop="mobile">
              <el-input autocomplete="off" type="text" v-model="user.mobile"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="邮箱" prop="email">
              <el-input autocomplete="off" type="text" v-model="user.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="地址" prop="address">
              <el-input autocomplete="off" type="textarea" v-model="user.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="角色" prop="roleId">
              <!--                            <el-input autocomplete="off" type="text" v-model="user.roleName" placeholder="请输入用户名"></el-input>-->
              <el-select v-model="user.roleId" clearable filterable placeholder="请选择角色">
                <el-option
                    ref="roleSelect"
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>



            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script scoped>
const {Message} = require("element-ui");
import TreeSelect from "../model/TreeSelect";
export default {
  name: "Index",
  components: { TreeSelect },
  data() {
    return {
      nodeKey: 'id',
      defaultCheckedKeys: [],

      mineStatus: "",
      mineStatusValue: [],
      permissions: {},

      loading: false,
      editDialog: false,
      addDialog: false,
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      }, datas: [],
      defaultProps: {
        childre: 'children',
        label: 'name'
      },
      user: {},
      roles: [],
      rules: {
        username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        roleId: [{required: true, message: '请选择角色', trigger: 'blur'}]
      }
    }
  },
  created () {
    // 注意：初始化defaultCheckedKeys时，在created里面
    // 父组件先执行自己的created，然后子组件开始执行自己的created和mounted，最后父组件再执行自己的mounted。
    // 因为此页面加载，先执行该组件自己的created，然后执行TreeSelect组件的created和mounted，最后执行该组件的mounted
    this.defaultCheckedKeys = [1000];
  },

  mounted() {
    // 此处初始化defaultCheckedKeys，没效果
    this.defaultCheckedKeys = [1001];
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    popoverHide (checkedIds, checkedData) {
      console.log(checkedIds);
      console.log(checkedData);
    },
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest("/sys/user", this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
      if (this.permissions.GETrole) {
        this.getRequest("/sys/role").then(data => {
          if (data.status == 200) {
            this.roles = data.obj
          }
        })
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    }, clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.user = {}
      this.user = rowData
      this.editDialog = true

    }, submitEditForm() {
      this.putRequest('/sys/user', this.user).then(data => {
        if (data.status == 200) {
          Message.success("修改成功")
          this.editDialog = false
          this.init();
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})

        }
      })
    }, showAddView() {
      this.user = {}
      this.user.enabled = 1
      this.addDialog = true;
    }, submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/user', this.user).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else return false
      })
    }, deleteRow(data) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/user", data).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    }, closeDialog() {

      this.init()
    },
    //select框值改变时候触发的事件
    selectChange(e){
      var arrNew = [];
      var dataLength = this.mineStatusValue.length;
      var eleng = e.length;
      for(let i = 0; i< dataLength ;i++){
        for(let j = 0; j < eleng; j++){
          if(e[j] === this.mineStatusValue[i].label){
            arrNew.push(this.mineStatusValue[i])
          }
        }
      }
      this.$refs.tree.setCheckedNodes(arrNew);//设置勾选的值
    },
    handleCheckChange() {
      let res = this.$refs.tree.getCheckedNodes(true, true); //这里两个true，1. 是否只是叶子节点 2. 是否包含半选节点（就是使得选择的时候不包含父节点）
      let arrLabel = [];
      let arr = [];
      res.forEach(item => {
        arrLabel.push(item.label);
        arr.push(item);
      });
      this.mineStatusValue = arr;
      this.mineStatus = arrLabel;
      console.log('arr:'+JSON.stringify(arr))
      console.log('arrLabel:'+arrLabel)
    }

  }
}
</script>


<style scoped>

</style>
