<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="130px">
            <el-row>
              <el-col :span="4">
                <el-form-item label="所属产品编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.productId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="所属系统产品编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.sysProductId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="交易类型编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.tradeTypeId"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="系统交易类型编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.sysTradeTypeId"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="开通状态">
                  <el-select v-model="pm.pm.status" placeholder="开通状态">
                    <el-option
                        v-for="item in DICKV.product_status"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="渠道标识">
                  <el-select v-model="pm.pm.channelFlag" placeholder="渠道标识">
                    <el-option
                        v-for="item in DICKV.channel_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="Number.parseInt(item.v)"
                        :disabled="item.disabled=='1'?false:true">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%" >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column width="50" show-overflow-tooltip label="编号" prop="id" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="所属产品编号" prop="productId" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="所属系统产品编号" prop="sysProductId" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="交易类型编号" prop="tradeTypeId" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="产品子类描述" prop="tradeTypeDescribe" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="系统交易类型编号" prop="sysTradeTypeId" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="系统交易类型描述" prop="sysTradeTypeDescribe" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="开通状态" prop="status" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.status, 'product_status') }}
          </template>
        </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="渠道标识" prop="channelFlag" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.channelFlag, 'channel_flag') }}
          </template>
        </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="是否借贷分离" prop="dcFlag" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.dcFlag, 'dc_flag') }}
          </template>
        </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="是否为正向交易" prop="rateFlag" >
          <template slot-scope="scope">
            {{ getDICKV(scope.row.rateFlag, 'rate_flag') }}
          </template>
        </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="添加人" prop="addMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="添加时间" prop="addTime" ></el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="最后修改人" prop="lastMan" ></el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="最后修改时间" prop="lastTime" ></el-table-column>

        <el-table-column label="操作" fixed="right" width="80" v-if="permissions.PUT" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑 </el-button>
<!--            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除 </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="渠道标识" prop="channelFlag">
              <el-select v-model="rowData.channelFlag" disabled placeholder="请选择渠道标识">
                <el-option
                    v-for="item in DICKV.channel_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="开通状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择开通状态">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所属产品编号"  prop="productId">
              <el-select v-model="rowData.productId"  disabled placeholder="请选择所属产品" >
                <el-option
                    v-for="item in chanPro"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属系统产品编号"  prop="sysProductId">
              <el-select v-model="rowData.sysProductId" disabled placeholder="请选择系统产品编号"  >
                <el-option
                    v-for="item in sysPro"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="系统交易类型编号" disabled prop="sysTradeTypeId">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.sysTradeTypeId" placeholder="请输入系统交易类型编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="系统交易类型描述" prop="sysTradeTypeDescribe">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.sysTradeTypeDescribe" placeholder="请输入系统交易类型描述"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="交易类型编号"  prop="tradeTypeId">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.tradeTypeId" placeholder="请输入交易类型id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="交易类型描述" prop="tradeTypeDescribe">
              <el-input autocomplete="off" type="text" v-model="rowData.tradeTypeDescribe" placeholder="请输入交易类型描述"></el-input>
            </el-form-item>
          </el-col>



          <el-col :span="10">
            <el-form-item label="是否借贷分离" prop="dcFlag">
              <el-select v-model="rowData.dcFlag" disabled placeholder="请选择借贷分离">
                <el-option
                    v-for="item in DICKV.dc_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="是否为正向交易" prop="rateFlag">
              <el-select v-model="rowData.rateFlag"  disabled placeholder="请选择是否为正向交易">
                <el-option
                    v-for="item in DICKV.rate_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="添加人" prop="addMan">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.addMan" placeholder="请输入添加人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="添加时间" prop="addTime">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.addTime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="最后修改人" prop="lastMan">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.lastMan" placeholder="请输入最后修改人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="最后修改时间" prop="lastTime">
              <el-input disabled autocomplete="off" type="text" v-model="rowData.lastTime" placeholder="请输入最后修改时间"></el-input>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="150px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="渠道标识" prop="channelFlag">
              <el-select v-model="rowData.channelFlag" placeholder="请选择渠道标识" @change="initSysPro('change',rowData.channelFlag)">
                <el-option
                    v-for="item in DICKV.channel_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="开通状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择开通状态">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属系统产品编号" prop="sysProductId">
              <el-select v-model="rowData.sysProductId" placeholder="请选择系统产品编号" @change="initChanPro('change',rowData.channelFlag,rowData.sysProductId) " >
                <el-option
                    v-for="item in sysPro"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属渠道产品编号" prop="productId">
              <el-select v-model="rowData.productId" placeholder="请选择渠道所属产品" @change="initSysProT('change',rowData.channelFlag,rowData.sysProductId,rowData.productId)">
                <el-option
                    v-for="item in chanPro"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="系统交易类型编号" prop="sysTradeTypeId">
              <el-select v-model="rowData.sysTradeTypeId" placeholder="请选择系统交易类型编号" @change="initChanProTData">
                <el-option
                    v-for="item in sysProT"
                    :key="item.value"
                    :label="item.value"
                    :value="item">
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="系统交易类型描述" prop="sysTradeTypeDescribe">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.sysTradeTypeDescribe" placeholder="请输入系统交易类型描述"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="交易类型编号" prop="tradeTypeId">
              <el-input autocomplete="off" type="text" v-model="rowData.tradeTypeId" placeholder="请输入交易类型id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="交易类型描述" prop="tradeTypeDescribe">
              <el-input autocomplete="off" type="text" v-model="rowData.tradeTypeDescribe" placeholder="请输入交易类型描述"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="是否借贷分离" prop="dcFlag">
              <el-select v-model="rowData.dcFlag" disabled placeholder="请选择借贷分离">
                <el-option
                    v-for="item in DICKV.dc_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="是否为正向交易" prop="rateFlag">
              <el-select v-model="rowData.rateFlag" disabled placeholder="请选择是否为正向交易">
                <el-option
                    v-for="item in DICKV.rate_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)"
                    :disabled="item.disabled=='1'?false:true">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";

export default {
  name: "ChanProType",
  data() {
    return {
      DICKV:{},
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        sysTradeTypeId: [{required: true, message: '请输入系统交易类型编号', trigger: 'blur'}],
        productId: [{required: true, message: '请输入所属产品编号', trigger: 'blur'}],
        tradeTypeId: [{required: true, message: '请输入交易类型编号', trigger: 'blur'}],
        tradeTypeDescribe: [{required: true, message: '请输入交易类型描述', trigger: 'blur'}],
        sysProductId: [{required: true, message: '请输入所属系统产品编号', trigger: 'blur'}],
        sysTradeTypeDescribe: [{required: true, message: '请输入系统交易类型描述', trigger: 'blur'}],
        status: [{required: true, message: '请输入开通状态', trigger: 'blur'}],
        channelFlag: [{required: true, message: '请输入渠道标识', trigger: 'blur'}],
        dcFlag: [{required: true, message: '请输入是否借贷分离', trigger: 'blur'}],
        rateFlag: [{required: true, message: '请输入是否为正向交易(是否需要设置费率)', trigger: 'blur'}],
      },


      sysPro:[],
      chanPro:[],
      sysProT:[]
    }
  },created(){
    this.DICKV = this.$store.state.DICKV;
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/chanprdt', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
      this.initSysPro('init',this.rowData.channelFlag)
      this.initChanPro('init',this.rowData.channelFlag,this.rowData.sysProductId)

    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/chanprdt', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.sysPro = {}
      this.sysProT = {}
      this.chanPro = {}
      this.rowData = {}
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/chanprdt', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/chanprdt", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    },
    initSysPro(type,channelFlag){
      if ("change"==type){
        this.rowData.sysProductId=''
        this.rowData.productId=''
        this.rowData.sysTradeTypeId=''
        this.rowData.sysTradeTypeDescribe=''
        this.rowData.dcFlag=''
        this.rowData.rateFlag=''
        this.sysProT=[]
        this.chanPro=[]
      }

      let getchanType={
        getType:0,
        channelFlag:channelFlag,
      }
      this.getRequest('/sys/chanprdt/getPro', getchanType).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.sysPro=data.obj
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }

      })
    },
    initChanPro(type,channelFlag,sysProductId){
      if ("change"==type){
        this.rowData.productId=''
        this.rowData.sysTradeTypeId=''
        this.rowData.sysTradeTypeDescribe=''
        this.rowData.dcFlag=''
        this.rowData.rateFlag=''
        this.sysProT=[]

      }
      let getchanType={
        getType:1,
        channelFlag:channelFlag,
        sysProductId:sysProductId
      }
      this.getRequest('/sys/chanprdt/getPro', getchanType).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.chanPro=data.obj
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
        this.$forceUpdate();
      })
    },
    initSysProT(type,channelFlag,sysProductId,productId){
      // if ("change"==type){
      //   this.rowData.productId=''
      // }
      let getchanType={
        getType:2,
        channelFlag:channelFlag,
        sysProductId:sysProductId,
        productId:productId,
      }
      this.getRequest('/sys/chanprdt/getPro', getchanType).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.sysProT=data.obj
          console.log(this.sysProT)
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
        this.$forceUpdate();
      })
    },
    initChanProTData(val){
      this.rowData.sysTradeTypeId=val.value
      this.rowData.sysTradeTypeDescribe=val.label
      this.rowData.dcFlag=val.dcFlag
      this.rowData.rateFlag=val.rateFlag
      this.$forceUpdate();
    },
    initCnapsDOM(){
      this.$forceUpdate();
    },

  }
}
</script>

<style scoped>

</style>
