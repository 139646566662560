<template>
  <div>
    <div class="headerTool">
      <el-button v-if="permissions.POSTbutton" type="primary" @click="showAddView">添加按钮</el-button>
      <el-button type="success" icon="el-icon-refresh" v-if="permissions.GETbutton" @click="init(emp.id)">刷新</el-button>

    </div>
    <div>
      <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" :data="buttons" stripe>
        <!--        <el-table-column-->
        <!--            prop="id"-->
        <!--            label="id"-->
        <!--        ></el-table-column>-->
        <el-table-column prop="name" label="名称" ></el-table-column>
        <!--        <el-table-column-->
        <!--            prop="menuId"-->
        <!--            label="菜单id"-->
        <!--        ></el-table-column>-->
        <el-table-column
            prop="method"
            label="提交方式"
        ></el-table-column>
        <el-table-column
            prop="subPath"
            label="子路径"
        ></el-table-column>
        <el-table-column
            label="启用状态"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled">启用</el-tag>
            <el-tag type="danger" v-else>停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="permissions.PUTbutton" size="mini" @click="showEditView(scope.row)">编辑</el-button>
            <el-button v-if="permissions.DELETEbutton" size="mini" type="danger" @click="deleteRow(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        title="添加按钮"
        :visible.sync="addDialog"
        :close-on-click-modal="false"
        width="50%"
        append-to-body
    >
      <el-form :model="button" :rules="rules" ref="addForm" label-width="180px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="按钮名称" prop="name">
              <el-input autocomplete="off" type="text" property="true" v-model="button.name"
                        placeholder="请输入按钮名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="提交方式" prop="method">
              <!--              <el-input autocomplete="off" type="text" property="true" v-model="button.method"-->
              <!--                        placeholder="请输入按钮名称"></el-input>-->
              <el-select v-model="button.method" placeholder="请选择提交方式">
                <el-option label="GET" value="GET"></el-option>
                <el-option label="PUT" value="PUT"></el-option>
                <el-option label="POST" value="POST"></el-option>
                <el-option label="DELETE" value="DELETE"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="子路径" prop="subPath">
              <el-input autocomplete="off" type="text" property="true" v-model="button.subPath"
                        placeholder="请输入子路径"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="启用状态" prop="enabled">
              <el-switch :active-value="1" :inactive-value="0" v-model="button.enabled"></el-switch>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
        title="编辑按钮"
        :visible.sync="editDialog"
        :close-on-click-modal="false"
        width="50%"
        append-to-body
    >
      <el-form :model="button" :rules="rules" ref="editForm" label-width="180px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="按钮名称" prop="name">
              <el-input autocomplete="off" type="text" property="true" v-model="button.name"
                        placeholder="请输入按钮名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="提交方式" prop="method">
              <!--              <el-input autocomplete="off" type="text" property="true" v-model="button.method"-->
              <!--                        placeholder="请输入按钮名称"></el-input>-->
              <el-select v-model="button.method" placeholder="请选择提交方式">
                <el-option label="GET" value="GET"></el-option>
                <el-option label="PUT" value="PUT"></el-option>
                <el-option label="POST" value="POST"></el-option>
                <el-option label="DELETE" value="DELETE"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="子路径" prop="subPath">
              <el-input autocomplete="off" type="text" property="true" v-model="button.subPath"
                        placeholder="请输入子路径"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="启用状态" prop="enabled">
              <el-switch :active-value="1" :inactive-value="0" v-model="button.enabled"></el-switch>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "MerButtonView",
  data() {
    return {
      permissions: {},
      addDialog: false,
      editDialog: false,
      loading: false,
      button: {},
      buttons: {},
      rules: {
        name: [{required: true, message: '', trigger: 'blur'}],
        method: [{required: true, message: '', trigger: 'blur'}],
      }
    }
  },
  props: ['emp'],
  mounted() {
    this.permissions = this.$store.state.permissions;
  },
  methods: {
    init(id) {
      this.buttons=[]
      if (this.permissions.GETbutton) {
        this.loading = true;
        this.getRequest("/sys/merMenu/button", {menuId: id}).then(data => {
          this.loading = false;
          if (data) {
            if (data.status == 200) {
              this.buttons = data.obj
            } else {
              Message.error("查询失败")
            }
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    showAddView() {
      this.button = {}
      this.button.menuId = this.emp.id
      this.button.enabled = 1
      this.addDialog = true
    }, submitAddForm() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          this.postRequest("/sys/merMenu/button", this.button).then(data => {
            if (data.status == 200) {
              Message.success("成功")
              this.button = {};
              this.addDialog = false
              this.init(this.emp.id)
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false
        }
      })
    }, showEditView(rowdata) {
      this.button = rowdata;
      this.editDialog = true
    }, submitEditForm() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          this.putRequest("/sys/merMenu/button", this.button).then(data => {
            if (data.status == 200) {
              Message.success("成功")
              this.button = {};
              this.editDialog = false
              this.init(this.emp.id)
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false
        }
      })
    }, deleteRow(rowdata) {
      this.$confirm('确认删除?').then(() => {
        this.deleteRequest("/sys/merMenu/button", {id: rowdata.id}).then(data => {
          if (data.status == 200) {
            Message.success("成功")
            this.init(this.emp.id)
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      }).catch(() => {
      })
    },
    close() {
      this.addDialog=false;
      this.editDialog=false;
      this.button={}
    }
  }
}
</script>

<style scoped>

</style>
