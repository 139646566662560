<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="4">
                <el-form-item label="商户编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.merId"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="商户单号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.merOrder"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="交易类型">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.transType"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="支付类型">
                  <el-select v-model="pm.pm.payType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.paytype"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>



              <el-col :span="4">
                <el-form-item label="交易状态">
                  <el-select v-model="pm.pm.status" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.status"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="交易日期">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.localDate"></el-input>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
          <el-row style="margin-top: 5px">
            <el-col>
              <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
              <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>

    </div>
    <div style="margin-top: 5px">
      <el-table
          highlight-current-row
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="datas"
          style="width: 100%" >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="系统流水号" prop="serial" > </el-table-column>
        <el-table-column width="80" show-overflow-tooltip label="商户编号" prop="merId" > </el-table-column>

        <el-table-column width="80" show-overflow-tooltip label="终端编号" prop="termCode" ></el-table-column>

        <el-table-column width="80" show-overflow-tooltip label="商户单号" prop="merOrder" > </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="交易类型"
            prop="transType"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="支付类型"
            prop="payType"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="交易金额"
            prop="amount"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="交易状态"
            prop="status"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="交易日期"
            prop="localDate"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="交易时间"
            prop="localTime"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="付款信息"
            prop="payer"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="订单标题"
            prop="orderTitle"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="原交易流水"
            prop="origserial"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="交易备注"
            prop="transNote"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="渠道商户号"
            prop="merIdChannel"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="应答码"
            prop="respcode"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="应答信息"
            prop="respmsg"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="通联流水号"
            prop="transSerial"
        >
        </el-table-column>

        <el-table-column
            width="80"
            show-overflow-tooltip
            label="渠道交易单号"
            prop="channelSerial"
        >
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="80" v-if="permissions.PUT||permissions.DELETE">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">查看 </el-button>
            <!--            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除 </el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pm.page.page"
            :page-sizes="[15, 50, 100, 200]"
            :page-size="pm.page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pm.page.total">
        </el-pagination>
      </div>
      <!--      <div class="block" style="align-self:flex-end">-->
      <!--        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>-->
      <!--      </div>-->
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :before-close="close"
        title="编辑"
        :visible.sync="editDialog"
        :rules="rules"
        width="50%"
    >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="商户编号" prop="merId">
              <el-input autocomplete="off" type="text" v-model="rowData.merId" placeholder="请输入商户编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="终端编号" prop="termCode">
              <el-input autocomplete="off" type="text" v-model="rowData.termCode" placeholder="请输入终端编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="商户单号" prop="merOrder">
              <el-input autocomplete="off" type="text" v-model="rowData.merOrder" placeholder="请输入商户单号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易类型" prop="transType">
              <el-input autocomplete="off" type="text" v-model="rowData.transType" placeholder="请输入交易类型"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="支付类型" prop="payType">
              <el-input autocomplete="off" type="text" v-model="rowData.payType" placeholder="请输入支付类型"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易金额" prop="amount">
              <el-input autocomplete="off" type="text" v-model="rowData.amount" placeholder="请输入交易金额"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易状态" prop="status">
              <el-input autocomplete="off" type="text" v-model="rowData.status" placeholder="请输入交易状态"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易日期" prop="localDate">
              <el-input autocomplete="off" type="text" v-model="rowData.localDate" placeholder="请输入交易日期"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易时间" prop="localTime">
              <el-input autocomplete="off" type="text" v-model="rowData.localTime" placeholder="请输入交易时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="付款信息" prop="payer">
              <el-input autocomplete="off" type="text" v-model="rowData.payer" placeholder="请输入付款信息"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="订单标题" prop="orderTitle">
              <el-input autocomplete="off" type="text" v-model="rowData.orderTitle" placeholder="请输入订单标题"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="原交易流水" prop="origserial">
              <el-input autocomplete="off" type="text" v-model="rowData.origserial" placeholder="请输入原交易流水"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="交易备注" prop="transNote">
              <el-input autocomplete="off" type="text" v-model="rowData.transNote" placeholder="请输入交易备注"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="渠道商户号" prop="merIdChannel">
              <el-input autocomplete="off" type="text" v-model="rowData.merIdChannel" placeholder="请输入渠道商户号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="应答码" prop="respcode">
              <el-input autocomplete="off" type="text" v-model="rowData.respcode" placeholder="请输入应答码"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="应答信息" prop="respmsg">
              <el-input autocomplete="off" type="text" v-model="rowData.respmsg" placeholder="请输入应答信息"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="通联流水号" prop="transSerial">
              <el-input autocomplete="off" type="text" v-model="rowData.transSerial" placeholder="请输入通联流水号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="渠道交易单号" prop="channelSerial">
              <el-input autocomplete="off" type="text" v-model="rowData.channelSerial" placeholder="请输入渠道交易单号"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!--    <el-dialog-->
    <!--        :close-on-click-modal="false"-->
    <!--        :before-close="close"-->
    <!--        title="添加"-->
    <!--        :visible.sync="addDialog"-->
    <!--        width="50%"-->
    <!--    >-->
    <!--      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">-->
    <!--        <el-row>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="商户编号" prop="merId">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.merId" placeholder="请输入商户编号"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="终端编号" prop="termCode">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.termCode" placeholder="请输入终端编号"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="商户单号" prop="merOrder">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.merOrder" placeholder="请输入商户单号"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="交易类型" prop="transType">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.transType" placeholder="请输入交易类型"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="支付类型" prop="paymentType">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.paymentType" placeholder="请输入支付类型"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="交易金额" prop="amount">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.amount" placeholder="请输入交易金额"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="交易状态" prop="status">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.status" placeholder="请输入交易状态"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="交易日期" prop="localDate">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.localDate" placeholder="请输入交易日期"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="交易时间" prop="localTime">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.localTime" placeholder="请输入交易时间"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="付款信息" prop="payer">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.payer" placeholder="请输入付款信息"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="订单标题" prop="orderTitle">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.orderTitle" placeholder="请输入订单标题"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="原交易流水" prop="origserial">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.origserial" placeholder="请输入原交易流水"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="交易备注" prop="transNote">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.transNote" placeholder="请输入交易备注"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="渠道商户号" prop="merIdChannel">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.merIdChannel" placeholder="请输入渠道商户号"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="应答码" prop="respcode">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.respcode" placeholder="请输入应答码"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="应答信息" prop="respmsg">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.respmsg" placeholder="请输入应答信息"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="通联流水号" prop="transSerial">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.transSerial" placeholder="请输入通联流水号"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--          <el-col :span="10">-->
    <!--            <el-form-item label="渠道交易单号" prop="channelSerial">-->
    <!--              <el-input autocomplete="off" type="text" v-model="rowData.channelSerial" placeholder="请输入渠道交易单号"></el-input>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->

    <!--        </el-row>-->
    <!--        <el-row>-->
    <!--          <el-col :span="20">-->
    <!--            <el-form-item>-->
    <!--              <el-button type="primary" @click="submitAddForm">提交</el-button>-->
    <!--              <el-button @click="close()">取消</el-button>-->
    <!--            </el-form-item>-->
    <!--          </el-col>-->
    <!--        </el-row>-->
    <!--      </el-form>-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      //数据字典
      DICKV:[],
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      // addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {

        merId: [{required: true, message: '请输入商户编号', trigger: 'blur'}],

        termCode: [{required: true, message: '请输入终端编号', trigger: 'blur'}],

        merOrder: [{required: true, message: '请输入商户单号', trigger: 'blur'}],

        transType: [{required: true, message: '请输入交易类型', trigger: 'blur'}],

        payType: [{required: true, message: '请输入支付类型', trigger: 'blur'}],

        amount: [{required: true, message: '请输入交易金额', trigger: 'blur'}],

        status: [{required: true, message: '请输入交易状态', trigger: 'blur'}],

        localDate: [{required: true, message: '请输入交易日期', trigger: 'blur'}],

        localTime: [{required: true, message: '请输入交易时间', trigger: 'blur'}],

        payer: [{required: true, message: '请输入付款信息', trigger: 'blur'}],

        orderTitle: [{required: true, message: '请输入订单标题', trigger: 'blur'}],

        origserial: [{required: true, message: '请输入原交易流水', trigger: 'blur'}],

        transNote: [{required: true, message: '请输入交易备注', trigger: 'blur'}],

        merIdChannel: [{required: true, message: '请输入渠道商户号', trigger: 'blur'}],

        respcode: [{required: true, message: '请输入应答码', trigger: 'blur'}],

        respmsg: [{required: true, message: '请输入应答信息', trigger: 'blur'}],

        transSerial: [{required: true, message: '请输入通联流水号', trigger: 'blur'}],

        channelSerial: [{required: true, message: '请输入渠道交易单号', trigger: 'blur'}],

      }
    }
  },created() {
    this.DICKV = this.$store.state.DICKV;
  },
  mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/deal', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/deal', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    // showAddView() {
    //   this.rowData = {}
    //   this.addDialog = true;
    // },
    // submitAddForm() {
    //   this.$refs.addForm.validate((valid) => {
    //     if (valid) {
    //       this.postRequest('/sys/deal', this.rowData).then(data => {
    //         if (data.status == 200) {
    //           Message.success("添加成功")
    //           this.addDialog = false
    //           this.init();
    //         } else {
    //           Message.error({message: data.msg + '(' + data.status + ')'})
    //
    //         }
    //       })
    //     } else {
    //       return false;
    //     }
    //   })
    // },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/deal", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            // this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    }

  }
}
</script>

<style scoped>

</style>
