import { render, staticRenderFns } from "./MerTmpRate.vue?vue&type=template&id=3b4555d5&scoped=true&"
import script from "./MerTmpRate.vue?vue&type=script&lang=js&"
export * from "./MerTmpRate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b4555d5",
  null
  
)

export default component.exports