<template>
  <div>
    <div v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <template slot="title">
            检索<i class="header-icon el-icon-info"></i>
          </template>
          <el-form label-width="90px">
            <el-row>
              <el-col :span="4">
                <el-form-item label="机构编号">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.agencyNo"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="机构类型">
                  <el-select v-model="pm.pm.agencyType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.agency_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="API接入">
                  <el-select v-model="pm.pm.apiFlag" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.api_flag"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
<!--              <el-col :span="4">-->
<!--                <el-form-item label="拓展人">-->
<!--                  <el-input autocomplete="off" type="text" v-model="pm.pm.expandMan"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->

              <el-col :span="4">
                <el-form-item label="客服电话">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.servicePhone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="业务场景">
                  <!--              0  线下（终端）1  线上（扫码立牌）-->
                  <el-input autocomplete="off" type="text" v-model="pm.pm.businessFlag"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="企业性质">
                  <el-select v-model="pm.pm.agencyAusType" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.agency_aus_type"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
<!--              <el-col :span="4">
                <el-form-item label="审核状态">
                  <el-select v-model="pm.pm.auditStatus" placeholder="请选择">
                    <el-option
                        v-for="item in DICKV.audit_status"
                        :key="item.k"
                        :label="item.k"
                        :value="item.v">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>-->
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <el-row style="margin-top: 5px">
        <el-col>
          <el-button type="success" size="mini" v-if="permissions.GET" @click="queryForm">查询</el-button>
          <el-button size="mini" v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 5px">
<!--      <el-button size="mini" type="warning" v-if="permissions.GET" :disabled="btnBoolean ? false : true" @click="edit_protype()">产品控制配置</el-button>
      <el-button size="mini" type="warning" v-if="permissions.GET" :disabled="btnBoolean ? false : true" @click="edit_rate()">费率信息配置</el-button>
      <el-button size="mini" type="warning" v-if="permissions.GET" :disabled="btnBoolean ? false : true" @click="edit_settle()">结算信息配置</el-button>
      <el-button size="mini" type="warning" v-if="permissions.GET" :disabled="btnBoolean ? false : true" @click="showAddView(tableRadio.agencyNo)">添加下级机构</el-button>-->

      <el-table highlight-current-row v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%"
                @current-change="clickChange">
        <!--        <el-table-column type="selection" width="55"> </el-table-column>-->
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio v-model="tableRadio" :label="scope.row"><i></i></el-radio>
          </template>
        </el-table-column>
        <el-table-column width="130" show-overflow-tooltip label="机构编号" prop="agencyNo"></el-table-column>
        <el-table-column width="130" show-overflow-tooltip label="机构名称" prop="agencyName"></el-table-column>
        <el-table-column width="130" show-overflow-tooltip label="机构类型" prop="agencyType">
          <template slot-scope="scope">
            {{ getDICKV(scope.row.agencyType, 'agency_type') }}
          </template>
        </el-table-column>
        <el-table-column width="130" show-overflow-tooltip label="是否API接入" prop="apiFlag">
          <template slot-scope="scope">
            {{ getDICKV(scope.row.apiFlag, 'api_flag') }}
          </template>
        </el-table-column>
        <el-table-column width="130" show-overflow-tooltip label="所属上级机构" prop="belongAgencyNo"></el-table-column>
        <el-table-column width="130" show-overflow-tooltip label="级别" prop="agencyLevel">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.agencyLevel==0" >{{ getDICKV(scope.row.agencyLevel, 'agency_level') }}</el-tag>
            <el-tag v-else type="info" >{{ getDICKV(scope.row.agencyLevel, 'agency_level') }}</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column width="80" show-overflow-tooltip label="分润开关" prop="benefitOff">-->
<!--          <template slot-scope="scope">-->
<!--            {{ getDICKV(scope.row.benefitOff, 'benefit_off') }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="130" show-overflow-tooltip label="拓展人" prop="expandMan"></el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="客服电话" prop="servicePhone"></el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="业务场景" prop="businessFlag">-->
<!--          <template slot-scope="scope">-->
<!--            {{ getDICKV(scope.row.businessFlag, 'business_flag') }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="无纸化标识" prop="agreement">-->
<!--          <template slot-scope="scope">-->
<!--            {{ getDICKV(scope.row.agreement, 'agreement') }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="企业性质" prop="agencyAusType">-->
<!--          <template slot-scope="scope">-->
<!--            {{ getDICKV(scope.row.agencyAusType, 'agency_aus_type') }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="证件类型" prop="legalIdType">-->
<!--          <template slot-scope="scope">-->
<!--            {{ getDICKV(scope.row.legalIdType, 'legal_id_type') }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="证件号" prop="legalIdNo"></el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="身份证有效期" prop="legalIdExpire">-->
<!--          <template slot-scope="scope">-->
<!--            <el-tag type="primary" v-if="scope.row.legalIdExpire!=''&&scope.row.legalIdExpire!=null">{{ scope.row.legalIdExpire }}</el-tag>-->
<!--            <el-tag v-if="scope.row.legalIdExpire==''||scope.row.legalIdExpire==null" type="danger">长期</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="结算方式" prop="clearMode">-->
<!--          <template slot-scope="scope">-->
<!--            {{ getDICKV(scope.row.clearMode, 'clear_mode') }}-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column width="130" show-overflow-tooltip label="审核状态" prop="auditStatus">
          <template slot-scope="scope">
            {{ getDICKV(scope.row.auditStatus, 'audit_status') }}
          </template>
        </el-table-column>
<!--        <el-table-column width="80" show-overflow-tooltip label="审核时间" prop="auditTime"></el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="最后修改时间" prop="lastTime"></el-table-column>-->
<!--        <el-table-column width="80" show-overflow-tooltip label="提交审核时间" prop="commitAuditTime"></el-table-column>-->
        <el-table-column width="130" show-overflow-tooltip label="审核标志" prop="auditFlag">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.auditFlag==0" >{{ getDICKV(scope.row.auditFlag, 'audit_flag') }}</el-tag>
            <el-tag v-else type="info" >{{ getDICKV(scope.row.auditFlag, 'audit_flag') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" show-overflow-tooltip label="机构状态" prop="status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status==1" >{{ getDICKV(scope.row.status, 'mer_status') }}</el-tag>
            <el-tag v-else type="danger" >{{ getDICKV(scope.row.status, 'mer_status') }}</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column width="80" show-overflow-tooltip label="更新流水号" prop="changeSerial"></el-table-column>-->
        <el-table-column width="200" show-overflow-tooltip label="添加时间" prop="addTime"></el-table-column>

        <el-table-column label="操作" fixed="right" width="150" v-if="permissions.PUT||permissions.DELETE">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">详细</el-button>
            <el-button type="primary" v-if="permissions.PUT && (scope.row.auditStatus !='00') " @click="showExamineView(scope.row)" size="mini">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pm.page.page" :page-sizes="[15, 50, 100, 200]"
                       :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="pm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
<!--        <el-button v-if="permissions.POST" @click="showAddView('')" type="primary" size="mini">添加机构</el-button>-->
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :before-close="close" title="详细" :visible.sync="editDialog" :rules="rules" width="50%">
      <el-form :model="rowData"  ref="editForm" label-width="150px" >
<!--        :rules="rules"-->
        <el-row>
          <el-col :span="10">
            <el-form-item label="机构编号" prop="agencyNo">
              <el-input autocomplete="off" type="text"  v-model="rowData.agencyNo"
                        placeholder="请输入机构编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="姓名" prop="agencyName">
              <el-input autocomplete="off" type="text" v-model="rowData.agencyName" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="机构类型" prop="agencyType">
              <el-select v-model="rowData.agencyType" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.agency_type"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="是否API接入" prop="apiFlag">

              <el-select v-model="rowData.apiFlag" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.api_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所属上级机构 "  prop="belongAgencyNo">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.belongAgencyNo"
                        placeholder="请输入所属上级机构 "></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="分润开关 " prop="benefitOff">
              <el-select v-model="rowData.benefitOff" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.benefit_off"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="拓展人" prop="expandMan">
              <el-input autocomplete="off" type="text" v-model="rowData.expandMan" placeholder="请输入拓展人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="客服电话" prop="servicePhone">
              <el-input autocomplete="off" type="text" v-model="rowData.servicePhone" placeholder="请输入客服电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="业务场景" prop="businessFlag">
              <el-select v-model="rowData.businessFlag" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.business_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="无纸化标识" prop="agreement">
              <el-select v-model="rowData.agreement" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.agreement"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="企业性质" prop="agencyAusType">
              <el-select v-model="rowData.agencyAusType" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.agency_aus_type"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="证件类型" prop="legalIdType">
              <el-select v-model="rowData.legalIdType" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.legal_id_type"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="证件号" prop="legalIdNo">
              <el-input autocomplete="off" type="text" v-model="rowData.legalIdNo" placeholder="请输入证件号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="身份证有效期" prop="legalIdExpire">
              <el-date-picker
                  v-model="rowData.legalIdExpire"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="长期">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="结算方式" prop="clearMode">
              <el-select v-model="rowData.clearMode" disabled placeholder="请选择">
                <el-option
                    v-for="item in DICKV.clear_mode"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="审核状态" prop="auditStatus">
              <el-select v-model="rowData.auditStatus" disabled placeholder="请选择">
                <el-option
                    v-for="item in DICKV.audit_status"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="添加时间" prop="addTime">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.addTime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="审核时间" prop="auditTime">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.auditTime"
                        placeholder="请输入审核时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="最后修改时间" prop="lastTime">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.lastTime"
                        placeholder="请输入最后修改时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="提交审核时间" disabled prop="commitAuditTime">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.commitAuditTime"
                        placeholder="请输入提交审核时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="免审标志" prop="auditFlag">
              <el-select v-model="rowData.auditFlag" placeholder="请选择">
                <el-option
                    v-for="item in DICKV.audit_flag"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="机构状态" prop="status">
              <el-select v-model="rowData.status" disabled placeholder="请选择">
                <el-option
                    v-for="item in DICKV.mer_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="更新流水号" prop="changeSerial">
              <el-input autocomplete="off" disabled type="text" v-model="rowData.changeSerial"
                        placeholder="请输入更新流水号"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
<!--              <el-button type="primary" @click="submitEditForm">提交</el-button>-->
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

<!--    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%">-->
<!--      <el-form :model="rowData"  ref="addForm" label-width="100px">-->
<!--&lt;!&ndash;        :rules="rules"&ndash;&gt;-->
<!--        <el-row>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="姓名" prop="agencyName">-->
<!--              <el-input autocomplete="off" type="text" v-model="rowData.agencyName" placeholder="请输入姓名"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="机构类型" prop="agencyType">-->
<!--              <el-select v-model="rowData.agencyType" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in DICKV.agency_type"-->
<!--                    :key="item.k"-->
<!--                    :label="item.k"-->
<!--                    :value="item.v">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="是否API接入" prop="apiFlag">-->
<!--              <el-select v-model="rowData.apiFlag" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in DICKV.api_flag"-->
<!--                    :key="item.k"-->
<!--                    :label="item.k"-->
<!--                    :value="item.v">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="所属上级代理 "  prop="belongAgencyNo">-->
<!--              <el-input autocomplete="off" type="text" disabled v-model="rowData.belongAgencyNo"-->
<!--                        placeholder="请输入所属上级代理 "></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="分润开关 " prop="benefitOff">-->
<!--              <el-input autocomplete="off" type="text" v-model="rowData.benefitOff" placeholder="请输入分润开关 "></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="拓展人" prop="expandMan">-->
<!--              <el-input autocomplete="off" type="text" v-model="rowData.expandMan" placeholder="请输入拓展人"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="客服电话" prop="servicePhone">-->
<!--              <el-input autocomplete="off" type="text" v-model="rowData.servicePhone" placeholder="请输入客服电话"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="业务场景" prop="businessFlag">-->
<!--              <el-select v-model="rowData.businessFlag" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in DICKV.business_flag"-->
<!--                    :key="item.k"-->
<!--                    :label="item.k"-->
<!--                    :value="item.v">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="无纸化标识 " prop="agreement">-->
<!--              <el-input autocomplete="off" type="text" v-model="rowData.agreement" placeholder="请输入无纸化标识 "></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="企业性质" prop="agencyAusType">-->
<!--              <el-select v-model="rowData.agencyAusType" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in DICKV.agency_aus_type"-->
<!--                    :key="item.k"-->
<!--                    :label="item.k"-->
<!--                    :value="item.v">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="证件类型" prop="legalIdType">-->
<!--              <el-select v-model="rowData.legalIdType" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in DICKV.legal_id_type"-->
<!--                    :key="item.k"-->
<!--                    :label="item.k"-->
<!--                    :value="item.v">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="证件号" prop="legalIdNo">-->
<!--              <el-input autocomplete="off" type="text" v-model="rowData.legalIdNo" placeholder="请输入证件号"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="身份证有效期" prop="legalIdExpire">-->
<!--              <el-date-picker-->
<!--                  v-model="rowData.legalIdExpire"-->
<!--                  type="date"-->
<!--                  value-format="yyyy-MM-dd"-->
<!--                  placeholder="长期">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="结算方式" prop="clearMode">-->
<!--              <el-input autocomplete="off" type="text" v-model="rowData.clearMode" placeholder="请输入结算方式"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

<!--          <el-col :span="10">-->
<!--            <el-form-item label="免审标志" prop="auditFlag">-->
<!--              <el-select v-model="rowData.auditFlag" placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in DICKV.audit_flag"-->
<!--                    :key="item.k"-->
<!--                    :label="item.k"-->
<!--                    :value="item.v">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="20">-->
<!--            <el-form-item>-->
<!--              <el-button type="primary" @click="submitAddForm">提交</el-button>-->
<!--              <el-button @click="close()">取消</el-button>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-form>-->
<!--    </el-dialog>-->


    <!--    结算信息配置-->
    <el-dialog :close-on-click-modal="false"
               v-loading="settle_loading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="settleDialog"
               width="50%">
      <template slot='title'>
        <span style="font-size:20px;">结算信息配置-{{tableRadio.agencyNo}}</span>
      </template>
      <el-form :model="settleData" :rules="settleRules" ref="settleForm" label-width="100px">
        <el-row>
          <el-col :span="10"  >
            <el-form-item  label="机构编号" prop="merId">
              <el-input  autocomplete="off" type="text" disabled v-model="settleData.merId" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账户号 " prop="acctId">
              <el-input autocomplete="off" type="text" v-model="settleData.acctId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账户名 " prop="acctName">
              <el-input autocomplete="off" type="text" v-model="settleData.acctName" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账户类型" prop="acctType">
              <el-select  v-model="settleData.acctType"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.acct_type"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="卡折类型 " prop="acctNoType">
              <el-select  v-model="settleData.acctNoType"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.acct_no_type"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账号状态 " prop="status">
              <el-input autocomplete="off" type="text" disabled v-model="settleData.status" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属银行" prop="bankCode">
              <el-select filterable v-model="settleData.bankCode" placeholder="请选择" @change="changeBank">
                <el-option
                    v-for="item in BANKCODE"
                    :key="item.bankCode"
                    :label="item.bankName"
                    :value="item.bankCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="所属支行" >
              <el-select filterable remote v-model="settleData.cnapsCode" placeholder="请选择"  :remote-method="getBankCnaps" >
                <el-option
                    v-for="item in BANKCNAPS"
                    :key="item.cnapsCode"
                    :label="item.bankName"
                    :value="item.cnapsCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="支付行号 " prop="cnapsCode">
              <el-input autocomplete="off" type="text" v-model="settleData.cnapsCode" placeholder="支付行号 "></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="结算附言 " prop="settRemark">
              <el-input autocomplete="off" type="textarea" v-model="settleData.settRemark" placeholder="请输入无纸化标识 "></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitSettleForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!--    产品信息配置-->
    <el-dialog :close-on-click-modal="false"
               v-loading="protypeloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="protypeDialog"
               width="50%">
      <template slot='title'>
        <span style="font-size:20px;">产品信息配置-{{tableRadio.agencyNo}}</span>
      </template>

      <el-table v-loading="proCtrlloading" :data="pmenus" row-key="id" border :tree-props="{children: 'children',hasChildren:'hasChildren'}" >
        <el-table-column width="150" prop="sysTradeTypeId" label="产品类型"></el-table-column>
        <el-table-column prop="sysTradeTypeDescribe" label="交易描述"></el-table-column>
        <el-table-column prop="productStatus" label="状态">
          <template slot-scope="scope" >
            {{ getDICKV(scope.row.productStatus, 'product_status') }}
          </template>
        </el-table-column>
        <el-table-column width="80" label="隐藏状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="!scope.row.hidden">不隐藏</el-tag>
            <el-tag type="danger" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button v-if="(scope.row.children.length<1 && scope.row.fatherMap && scope.row.agencyNo) || (tableRadio.belongAgencyNo==0 && scope.row.children.length<1 && scope.row.agencyNo) && scope.row.level!=0"
                       size="mini" type="primary" @click="showEditProCtrl(scope.row)">编辑 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :close-on-click-modal="false"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="closeAgcProCtrlDialog" title="" :visible.sync="agcProCtrlDialog"
               width="37%">
      <template slot='title'>
        <span style="font-size:20px;">下级结算点区间控制--{{agcProCtrl.sysTradeTypeDescribe}}</span>
      </template>
      <el-form :model="agcProCtrl" :rules="agcProrules" ref="agcProCtrlForm" label-width="180px">

        <el-row >
          <el-col :span="15" v-if="agcProCtrl.rateFlag==1">
            <el-form-item label="结算点上下限" >
              <el-form-item prop="settLow" >
                <el-input-number size="medium"   v-model="agcProCtrl.settLow"  :precision="5" :step="0.001" :min="0.0" :max="100.0"/>~
              </el-form-item>
              <el-form-item prop="settTop">
                <el-input-number size="medium"  v-model="agcProCtrl.settTop" :precision="5" :step="0.001" :min="0.0" :max="100.0"/>
              </el-form-item>
            </el-form-item>
          </el-col>
          <el-col :span="15" v-if="agcProCtrl.rateFlag==1">
            <el-form-item label="贷记结算点上下限" >
              <el-form-item prop="settLowCredit">
                <el-input-number size="medium" v-model="agcProCtrl.settLowCredit" :precision="5" :step="0.001" :min="0.0" :max="100.0"/>~
              </el-form-item>
              <el-form-item prop="settTopCredit">
                <el-input-number size="medium"   v-model="agcProCtrl.settTopCredit" :precision="5" :step="0.001" :min="0.0" :max="100.0"/>
              </el-form-item>
            </el-form-item>
          </el-col>

          <el-col :span="15" v-if="agcProCtrl.rateFlag==1">
            <el-form-item label="单笔封顶结算上下限" prop="lowLimit">
              <el-form-item prop="lowLimit">
                <el-input-number size="medium"   v-model="agcProCtrl.lowLimit" :precision="5" :step="0.001" :min="0.0" :max="100.0"/>~
              </el-form-item>
              <el-form-item prop="topLimit">
                <el-input-number size="medium"  v-model="agcProCtrl.topLimit" :precision="5" :step="0.001" :min="0.0" :max="100.0"/>
              </el-form-item>
            </el-form-item>
          </el-col>

          <el-col :span="15" v-if="agcProCtrl.rateFlag==1">
            <el-form-item label="贷记单笔封顶结算上下限" prop="lowLimitCredit">
              <el-form-item prop="lowLimitCredit">
                <el-input-number size="medium"  v-model="agcProCtrl.lowLimitCredit" :precision="5" :step="0.001" :min="0.0" :max="100.0"/>~
              </el-form-item>
              <el-form-item prop="topLimitCredit">
                <el-input-number size="medium"  v-model="agcProCtrl.topLimitCredit" :precision="5" :step="0.001" :min="0.0" :max="100.0"/>
              </el-form-item>
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item  label="交易开通状态" prop="productStatus">
              <el-select  v-model="agcProCtrl.productStatus"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <template v-if="agcProCtrl.fatherMap || tableRadio.belongAgencyNo==0">
                <el-button type="primary"  @click="submitAgcProCtrlForm">提交</el-button>
              </template>
              <template v-else>
                <el-button type="primary" disabled @click="submitAgcProCtrlForm">提交</el-button>
              </template>
              <el-button @click="closeAgcProCtrlDialog()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>

    <!--    机构审核-->
    <el-dialog title="审核确认" :visible.sync="dialogExamine" width="30%">
      <el-form :model="examForm" :rules="examRules" ref="examForm">
        <el-row >
          <el-col :span="15">
            <el-form-item label="商户编号" prop="agencyNo">
              <el-input v-model="examForm.agencyNo" disabled autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="15">
            <el-form-item label="审核状态" prop="auditStatus">
              <el-select v-model="examForm.auditStatus" placeholder="请选择">
                <el-option
                    v-for="item in agcstatus"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v"
                    >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="15">
            <el-form-item label="备注信息" >
              <el-input type="textarea" v-model="examForm.notes" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExamine = false">取 消</el-button>
        <el-button type="primary" @click="toExamin">确 定</el-button>
      </div>
    </el-dialog>

    <!--    机构费率设置-->
    <el-dialog :close-on-click-modal="false"
               v-loading="rateloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="rateDialog"
               width="50%">
      <template slot='title'>
        <span style="font-size:20px;">机构费率信息配置-{{tableRadio.agencyNo}}</span>
      </template>
      <AgcRate :rmenus="rmenus" :redio="redio" ></AgcRate>
    </el-dialog>

  </div>
</template>
<script>
import {Message} from "element-ui";
import AgcRate from "@/views/sys/agency/AgcRate";

export default {
  name: "Index",
  components: {AgcRate},
  data() {
    return {
      DICKV: {},
      BANKCODE:[],
      BANKCNAPS:[],
      cnapsName:'',

      agcstatus: [{
        v: '02',
        k: '审核通过'
      }, {
        v: '03',
        k: '审核失败'
      }],


      btnBoolean: false,//禁用批量按钮
      tableRadio: '',

      //产品控制
      protypeDialog:false,
      pmenus: [],
      protypeloading:false,
      proCtrlloading:false,
      agcProCtrlDialog:false,
      agcProCtrl:{
        fatherMap:{
          id:'',agencyNo:'',sysProductId:'',sysTradeTypeId:'',settLow:0,settTop:0,productStatus:0,settLowCredit:0,settTopCredit:0,lowLimit:0,topLimit:0,
          lowLimitCredit:0,topLimitCredit:0,status:'',dcFlag:0,rateFlag:0
        }
      },

      dialogExamine:false,
      examForm: {
        agencyNo: '',
        auditStatus: '',
        notes: ''
      },
      examRules:{
        auditStatus: [{required: true, message: '请选择审核状态', trigger: 'blur'}],
        agencyNo: [{required: true, message: '请输入机构编号', trigger: 'blur'}],
      },

      //费率信息设置
      rmenus:[],
      redio: {},
      rateDialog:false,
      rateloading:false,

      //结算信息
      settleDialog: false,
      settle_loading:false,
      retrieveActiveNames: '1',
      suspension_label: 3,
      suspension_data: 14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      settleData: {
        merId: '',
      },
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        agencyType: [{required: true, message: '请输入机构类型0：合作方；1：机构；', trigger: 'blur'}],
        apiFlag: [{required: true, message: '请输入是否API接入0：非；1：是；', trigger: 'blur'}],
        benefitOff: [{required: true, message: '请输入分润开关0：不分润；1：正常分润；', trigger: 'blur'}],
        expandMan: [{required: true, message: '请输入拓展人', trigger: 'blur'}],
        servicePhone: [{required: true, message: '请输入客服电话', trigger: 'blur'}],
        businessFlag: [{required: true, message: '请输入线上线下业务场景0  线下（终端）1  线上（扫码立牌）', trigger: 'blur'}],
        agreement: [{required: true, message: '请输入无纸化标识（签约方式，暂定全都是纸质协议）1:电子协议2:纸质协议', trigger: 'blur'}],
        agencyAusType: [{required: true, message: '请输入性质1.企业3.个体户4.个人6.事业单位', trigger: 'blur'}],
        legalIdType: [{required: true, message: '请输入证件类型01身份证04港澳通行证05台湾通行证', trigger: 'blur'}],
        legalIdNo: [{required: true, message: '请输入证件号', trigger: 'blur'}],
        clearMode: [{required: true, message: '请输入结算方式0-商户自主提现1-结算到银行卡2-结算到通联电子账户', trigger: 'blur'}],
        auditFlag: [{required: true, message: '请输入免审标志；0：免审；1：需要人工审核； 免审机构进件时直接上送渠道', trigger: 'blur'}],
        agencyName: [{required: true, message: '请输入姓名', trigger: 'blur'}],
      },
      agcProrules :{
        settLowCredit:[{required: true, message: '', trigger: 'blur'}],
        settTopCredit:[{required: true, message: '', trigger: 'blur'}],
        settLow:[{required: true, message: '', trigger: 'blur'}],
        settTop:[{required: true, message: '', trigger: 'blur'}]
      },
      settleRules:{
        merId:[{required: true, message: '', trigger: 'blur'}],
        acctId:[{required: true, message: '', trigger: 'blur'}],
        acctName:[{required: true, message: '', trigger: 'blur'}],
        acctType:[{required: true, message: '', trigger: 'blur'}],
        bankCode:[{required: true, message: '', trigger: 'blur'}],
        cnapsCode:[{required: true, message: '', trigger: 'blur'}],
        acctNoType:[{required: true, message: '', trigger: 'blur'}],
        status:[{required: true, message: '', trigger: 'blur'}]

      },
      examineRules:{
        aduitStatus:[{required: true, message: '', trigger: 'blur'}]
      }
    }
  }, created() {
    this.DICKV = this.$store.state.DICKV;
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      this.pm.pm.auditStatus='01'
      if (this.permissions.GET) {
        this.loading = true

        this.getRequest('/sys/agency', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },

    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    /*submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/agency', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },*/
    showAddView(agencyNo) {
      this.rowData = {}
      this.addDialog = true;
      if (agencyNo!=''){
        this.rowData.belongAgencyNo=agencyNo
      }
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/agency', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/agency", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.settleDialog = false
            this.tableRadio = ''
            this.btnBoolean = false
            this.protypeDialog=false
            this.rowData = {}
            this.rateDialog=false
            this.init()
          })
          .catch(() => {
          });
    },
    clickChange(item) {
      if (item){
        this.settleData = {}
        this.tableRadio = item
        this.btnBoolean = true;
      }

    },
    edit_settle() {
      this.settleDialog = true;
      this.settle_loading = true;
      this.getRequest("/sys/agency/bankCode").then(data => {
        if (data.status == 200) {
          this.BANKCODE = data.obj;
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })

      this.getRequest('/sys/agency/settleinfo', this.tableRadio).then(data => {
        this.settle_loading = false
        this.btnBoolean=false
        if (data.status == 200) {
          this.settleData = data.obj
          this.settleData.merId=this.tableRadio.agencyNo
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    submitSettleForm() {
      this.$refs.settleForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/agency/settleinfo', this.settleData).then(data => {
            if (data.status == 200) {
              Message.success("更新成功")
              this.settle_loading = false
              this.settleDialog=false
              this.BANKCNAPS= []
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
    async getBankCnaps(cnapsName){
      let pa={bankCode:this.settleData.bankCode,cnapsName:cnapsName}
      this.getRequest("/sys/agency/bankCnaps",pa).then(data => {
        if (data.status == 200) {
          this.BANKCNAPS = data.obj;
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    changeBank(){
      this.BANKCNAPS= []
    },
    edit_protype(){
      this.protypeDialog=true;
      if (this.permissions.GET == 1) {
        this.protypeloading = true
        this.getRequest("/sys/agency/proTypeMenu", this.tableRadio).then(data => {
          if (data) {
            this.pmenus = data.obj
            this.protypeloading = false
            this.proCtrlloading=false
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
    },
    showEditProCtrl(row){
      this.agcProCtrlDialog=true
      this.agcProCtrl=row
    },
    closeAgcProCtrlDialog(){
      this.agcProCtrlDialog=false
      this.agcProCtrl={}
      this.edit_protype()
    },
    submitAgcProCtrlForm(){
      this.postRequest('/sys/agency/agcProCtrl', this.agcProCtrl).then(data => {
        if (data.status == 200) {
          Message.success("更新成功")
          this.agcProCtrlDialog=false
          this.agcProCtrl={}
          this.edit_protype()
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })

    },

    //费率设置
    edit_rate(){
      this.rateDialog=true;
      if (this.permissions.GET == 1) {
        this.rateloading = true
        this.getRequest("/sys/agency/agcRateMenu", this.tableRadio).then(data => {
          if (data) {
            this.rmenus = data.obj
            this.redio=this.tableRadio
            this.rateloading = false
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
    },
    showExamineView(row){
      this.examForm = {}
      this.examForm.agencyNo = row.agencyNo
      this.dialogExamine = true
    },
    toExamin(){
      this.$refs.examForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/agency/toExaminSH', this.examForm).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.dialogExamine = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })

    }

  }
}
</script>

<style scoped>

</style>

