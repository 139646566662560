<template>
<div class="MerEduit">
      <div style="display: flex;justify-content: flex-start;"  >
        <div style="display: flex;justify-content: center;align-items: center;width: 100%;">
          <el-form :model="rowData" :rules="rules" ref="addForm" style="width: 95%">
            <el-form :model="rowData.merInfo" :rules="rules" ref="addForm0" :disabled="rowData.merChanStates.merState=='00' || rowData.merChanStates.merState=='01'">
              <el-divider content-position="left">基本信息</el-divider>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户名称" prop="merName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.merName" placeholder="商户名称" > </el-input>
<!--                    v-compare:merName="tmpRowData.merInfo"-->
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商户简称" prop="shortName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.shortName" placeholder="商户简称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="客服电话" prop="servicePhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.servicePhone" placeholder="客服电话"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="所属行业" prop="mccidName">
                    <el-select v-model="rowData.merInfo.mccidName" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="mccloading" @change="changeMcc($event)">
                      <el-option v-for="item in mccoptions" :key="item.value" :label="item.label" :value="item" >
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户类型" prop="merType">
                    <el-select v-model="rowData.merInfo.merType" disabled placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.mer_type"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商户来源" prop="merSource">
                    <el-select v-model="rowData.merInfo.merSource" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.mer_source"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="所属代理商" prop="belongAgencyNo">
                    <el-select v-model="rowData.merInfo.belongAgencyNo" disabled placeholder="请选择"  >
                      <el-option
                          v-for="item in allAgency"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商户负责人" prop="contactPerson">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.contactPerson" placeholder="商户负责人"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户负责人电话" prop="contactPhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.contactPhone" placeholder="商户负责人电话"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="拓展人" prop="expandMan">
                    <el-select v-model="rowData.merInfo.expandMan" placeholder="请选择" >
                      <el-option
                          v-for="item in saleperoptions"
                          :key="item.value"
                          :label="item.value"
                          :value="item.value">
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>


                <el-col :span="6">
                  <el-form-item label="业务联系人" prop="businessContactName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.businessContactName" placeholder="业务联系人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="业务联系人电话" prop="businessContactPhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.businessContactPhone" placeholder="业务联系人电话"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="业务场景" prop="businessFlag">
                    <el-select v-model="rowData.merInfo.businessFlag" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.business_flag"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="无纸化标识" prop="agreement">
                    <el-select v-model="rowData.merInfo.agreement" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.agreement"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人标识" prop="bnfDetail">
                    <el-select v-model="rowData.merInfo.bnfDetail" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.bnf_detail"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="结算方式" prop="clearMode">
                    <el-select v-model="rowData.merInfo.clearMode" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.clear_mode"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户标识" prop="merFlag">
                    <el-select v-model="rowData.merInfo.merFlag" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.mer_flag"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="所属父客户" prop="parentId">
                    <el-select v-model="rowData.merInfo.parentId" clearable placeholder="请选择" >
                      <el-option
                          v-for="item in meroptions"
                          :key="item.value"
                          :label="item.value"
                          :value="item.value">
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="注册地址" prop="address">
                    <el-cascader size="large" :options="areoptions" v-model="rowData.merInfo.addressCode" @change="areaHandleChange"> </el-cascader>
                  </el-form-item>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfo.address" style="display: none" ></el-input>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfo.addressCode" style="display: none" ></el-input>
                </el-col>
              </el-row>
              <el-divider content-position="left">法人信息</el-divider>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="法人姓名" prop="legalName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.legalName" placeholder="法人姓名"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="证件类型" prop="legalIdType">
                    <el-select v-model="rowData.merInfo.legalIdType" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.legal_id_type"
                          :key="item.k"
                          :label="item.k"
                          :value="item.v"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="证件号" prop="legalIdNo">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.legalIdNo" placeholder="法人证件号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="证件有效期" prop="legalIdExpire">
                    <el-date-picker
                        v-model="rowData.merInfo.legalIdExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="长期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="法人手机号" prop="legalPhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfo.legalPhone" placeholder="法人手机号"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-divider content-position="left">门店信息</el-divider>
              <el-button type="primary" size="mini" @click="AddBranch" :disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'">添加门店信息</el-button>
              <el-form :rules="rules.branch" :model="rowData"  ref="addFormBranch" class="demo-ruleForm">
                <el-table highlight-current-row element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                          element-loading-background="rgba(0, 0, 0, 0.8)" :data="rowData.merBranchInfo" style="width: 100%" >
                  <el-table-column width="50" show-overflow-tooltip label="ID" prop="id" > </el-table-column>
                  <el-table-column width="50" show-overflow-tooltip label="门店编号" prop="branchNo" ></el-table-column>
                  <el-table-column width="120" show-overflow-tooltip label="门店名称" prop="branchName" >
                    <template slot-scope="scope">
                      <el-form-item :prop="'merBranchInfo.' + scope.$index + '.branchName'" :rules='rules.branch.branchName'>
                        <el-input v-model="scope.row.branchName"  v-bind:disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'" ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column width="80" show-overflow-tooltip label="状态" prop="status" >
                    <template slot-scope="scope" >
                      <el-form-item >
                        <el-select  v-model="scope.row.status"  placeholder="请选择" v-bind:disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'">
                          <el-option
                              v-for="item in DICKV.product_status"
                              :key="item.k"
                              :label="item.k"
                              :value="Number.parseInt(item.v)"
                              :disabled="item.disabled=='1'?false:true">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" show-overflow-tooltip label="门店联系人" prop="contactPerson" >
                    <template slot-scope="scope">
                      <el-form-item :prop="'merBranchInfo.' + scope.$index + '.contactPerson'" :rules='rules.branch.contactPerson'>
                        <el-input v-model="scope.row.contactPerson" v-bind:disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column width="90" show-overflow-tooltip label="门店联系人电话" prop="" >
                    <template slot-scope="scope">
                      <el-form-item :prop="'merBranchInfo.' + scope.$index + '.contactPersonPhone'" :rules='rules.branch.contactPersonPhone'>
                        <el-input v-model="scope.row.contactPersonPhone" v-bind:disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column width="80" show-overflow-tooltip label="打印联数" prop="printNum" >
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-input size="medium" v-model="scope.row.printNum" disabled :precision="0" :step="0" :min="0" :max="0"/>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column width="80" show-overflow-tooltip label="门店终端数" prop="termNum" >
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-input size="medium" v-model="scope.row.termNum" disabled :precision="0" :step="0" :min="0" :max="0"/>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" show-overflow-tooltip label="门店所在区" prop="districtCode" >
                    <template slot-scope="scope">
                      <el-form-item :prop="'merBranchInfo.' + scope.$index + '.districtCode'" :rules='rules.branch.districtCode'>
                        <el-cascader
                            size="large"
                            :options="areoptions"
                            v-model="scope.row.districtCode"
                            @change="branchAreaHandleChange" v-bind:disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'">
                        </el-cascader>
                      </el-form-item>
                    </template>
                  </el-table-column>

                  <el-table-column width="100" show-overflow-tooltip label="门店地址" prop="branchAddress" >
                    <template slot-scope="scope">
                      <el-form-item :prop="'merBranchInfo.' + scope.$index + '.branchAddress'" :rules='rules.branch.branchAddress'>
                        <el-input v-model="scope.row.branchAddress" v-bind:disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" fixed="right" width="300" v-if="permissions.PUT||permissions.DELETE"  >
                    <template slot-scope="scope" >
                      <!--                    <el-button type="primary" size="mini" v-if="permissions.GET"    @click="eduitBranchRow(scope.row)">编辑</el-button>-->
                      <el-tooltip :content="scope.row.changeFlag=='2'?'移除':'正常'" placement="top" style="margin-right: 10px">
                        <el-switch
                            v-model="scope.row.changeFlag"
                            :disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'"
                            active-color="#ff4949"
                            inactive-color="#13ce66"
                            active-value="2"
                            inactive-value="1"
                            @change='changeBranchFlag($event,scope.row,scope.$index)'>
                        </el-switch>
                      </el-tooltip>

<!--                      <el-button type="danger"  size="mini" v-if="permissions.DELETE " :disabled="rowData.merChanStates.branchState=='00' || rowData.merChanStates.branchState=='01'" @click="deleteBranchRow(scope.$index)">移除 </el-button>-->
                      <el-button type="warning" size="mini" v-if="scope.row.branchNo && permissions.PUT"    @click="eduitProRow(scope.row)" >门店产品配置</el-button>
                      <el-button type="success" size="mini" v-if="scope.row.branchNo && permissions.PUT"    @click="eduitTermRow(scope.row)">绑定终端</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form>
<!--              <div class="block" style="flex: 1">-->
<!--                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[5, 10, 20, 50]"-->
<!--                               :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 5px;margin-bottom: 5px">-->
<!--                </el-pagination>-->
<!--              </div>-->
              <el-dialog :close-on-click-modal="false" :before-close="closeBranchDia" title="添加门店" :visible.sync="merBranchDialog" width="50%" append-to-body>
                <el-form :model="rowBranch" :rules="rules" ref="addBranchForm" label-width="100px">
                  <el-row>
                    <el-col :span="10">
                      <el-form-item label="门店名称" prop="branchName">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.branchName" placeholder="请输入门店名称"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="状态" prop="status">
                        <el-select  v-model="rowBranch.status"  placeholder="请选择">
                          <el-option
                              v-for="item in DICKV.product_status"
                              :key="item.k"
                              :label="item.k"
                              :value="Number.parseInt(item.v)"
                              :disabled="item.disabled=='1'?false:true">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="打印联数" prop="printNum">
                        <el-input-number size="medium" v-model="rowBranch.printNum" :precision="0" :step="0" :min="0" :max="0"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店终端数" prop="termNum">
                        <el-input-number size="medium" v-model="rowBranch.termNum" :precision="0" :step="0" :min="0" :max="0"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店联系人" prop="contactPerson">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.contactPerson" placeholder="请输入门店联系人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="联系人电话" prop="contactPersonPhone">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.contactPersonPhone" placeholder="请输入门店联系人电话"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店所在地区" prop="districtCode">
                        <el-cascader
                            size="large"
                            :options="areoptions"
                            v-model="rowBranch.districtCode"
                            @change="branchAreaHandleChange">
                        </el-cascader>
                      </el-form-item>
                      <el-input autocomplete="off" type="text" v-model="rowBranch.districtName" style="display: none" ></el-input>
                      <el-input autocomplete="off" type="text" v-model="rowBranch.districtCode" style="display: none" ></el-input>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店详细地址" prop="branchAddress">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.branchAddress" placeholder="请输入门店详细地址"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item>
                        <el-button type="primary" @click="submitAddBranchForm">提交</el-button>
                        <el-button @click="closeBranchDia()">取消</el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-dialog>
              <el-dialog :close-on-click-modal="false" :before-close="closeBranchDia" title="编辑门店" :visible.sync="eduitBranchDialog" width="50%" append-to-body>
                <el-form :model="rowBranch" :rules="rules" ref="eduitBranchForm" label-width="100px">
                  <el-row>
                    <el-col :span="10">
                      <el-form-item label="门店名称" prop="branchName">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.branchName" placeholder="请输入门店名称"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="状态" prop="status">
                        <el-select  v-model="rowBranch.status"  placeholder="请选择">
                          <el-option
                              v-for="item in DICKV.product_status"
                              :key="item.k"
                              :label="item.k"
                              :value="Number.parseInt(item.v)"
                              :disabled="item.disabled=='1'?false:true">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="打印联数" prop="printNum">
                        <el-input-number size="medium" v-model="rowBranch.printNum" :precision="0" :step="1" :min="1" :max="99"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店终端数" prop="termNum">
                        <el-input-number size="medium" v-model="rowBranch.termNum" :precision="0" :step="1" :min="1" :max="99"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店联系人" prop="contactPerson">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.contactPerson" placeholder="请输入门店联系人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="联系人电话" prop="contactPersonPhone">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.contactPersonPhone" placeholder="请输入门店联系人电话"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店所在地区" prop="districtCode">
                        <el-cascader
                            size="large"
                            :options="areoptions"
                            v-model="rowBranch.districtCode"
                            @change="branchAreaHandleChange">
                        </el-cascader>
                      </el-form-item>
                      <el-input autocomplete="off" type="text" v-model="rowBranch.districtName" style="display: none" ></el-input>
                      <el-input autocomplete="off" type="text" v-model="rowBranch.districtCode" style="display: none" ></el-input>
                    </el-col>
                    <el-col :span="10">
                      <el-form-item label="门店详细地址" prop="branchAddress">
                        <el-input autocomplete="off" type="text" v-model="rowBranch.branchAddress" placeholder="请输入门店详细地址"></el-input>
                      </el-form-item>
                    </el-col>


                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item>
                        <el-button type="primary" @click="submitEduitBranchForm">提交</el-button>
                        <el-button @click="closeBranchDia()">取消</el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-dialog>
              <!--门店绑定终端-->
              <el-dialog :close-on-click-modal="false"
                         v-loading="merTermloading"
                         element-loading-text="拼命加载中"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(0, 0, 0, 0.8)"
                         :before-close="close" title="" :visible.sync="merTermDialog"
                         width="50%" append-to-body>
                <template slot='title'>
                  <span style="font-size:20px;">门店终端管理</span>
                </template>
                <MerTerm ref="merterm" ></MerTerm>
              </el-dialog>
            <el-divider content-position="left">结算信息</el-divider>
              <el-form :model="rowData.settleInfo" :rules="rules" ref="addForm1" :disabled="rowData.merChanStates.merState=='00' || rowData.merChanStates.merState=='01'">
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="卡折类型" prop="acctNoType">
                      <el-select  v-model="rowData.settleInfo.acctNoType"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.acct_no_type"
                            :key="item.k"
                            :label="item.k"
                            :value="item.v">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="账户类型" prop="acctType">
                      <el-select  v-model="rowData.settleInfo.acctType"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.acct_type"
                            :key="item.k"
                            :label="item.k"
                            :value="item.v">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="账户名" prop="acctName">
                      <el-input autocomplete="off"  type="text" v-model="rowData.settleInfo.acctName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="账户号"  prop="acctId">
                      <el-input autocomplete="off"  type="text" v-model="rowData.settleInfo.acctId" ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="渠道电子账户号" prop="chanBankNo">
                      <el-input autocomplete="off" type="text" v-model="rowData.settleInfo.chanBankNo" placeholder="渠道电子账户号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="结算人身份证号" prop="settlerIdNo">
                      <el-input autocomplete="off" type="text" v-model="rowData.settleInfo.settlerIdNo" ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="所属总行" prop="bankCode">
                      <el-select  v-model="rowData.settleInfo.bankCode" filterable @change="initCity()" >
                        <el-option
                            v-for="item in bankoptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          <span style="float: left">{{ item.value }}</span>
                          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="6">
                    <el-form-item label="所属市区" prop="accresCode">
                      <el-select  v-model="rowData.settleInfo.accresCode" filterable @change="initCnaps()">
                        <el-option
                            v-for="item in bankcityoptions"
                            :key="item.label"
                            :label="item.value"
                            :value="item.value">
                          <span style="float: left">{{ item.value }}</span>
                          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="所属支行" prop="cnapsCode">
                      <el-select  v-model="rowData.settleInfo.cnapsCode" filterable @change="initCnapsDOM()">
                        <el-option
                            v-for="item in bankcnapsoptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          <span style="float: left">{{ item.value }}</span>
                          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            <el-divider content-position="left">费率信息</el-divider>
            <el-form :rules="rules.prod" :model="rowData"  ref="addFormProd" >
              <el-table :data="rowData.rateList" row-key="id" border :tree-props="{children: 'children',hasChildren:'hasChildren'}" >
                <el-table-column width="120" prop="sysTradeTypeId" label="产品类型"></el-table-column>
                <el-table-column width="120" prop="sysTradeTypeDescribe" label="交易描述"></el-table-column>
                <el-table-column width="80" prop="productStatus" label="状态" >
                  <template slot-scope="scope" >
                    {{ getDICKV(scope.row.productStatus, 'product_status') }}
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="feeRate" label="借记卡费率(‰)" >
                  <template slot-scope="scope" v-if="scope.row.level!=0">
                    <el-form-item :prop="'rateList[0].children['+(scope.$index-1)+'].feeRate'" :rules="rules.prod.feeRate" >
                      <el-input-number controls-position="right" v-model="scope.row.feeRate" :precision="2" :step="0.01" :min="0.0" :max="100.0" style="width: 95px"
                                       v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="creditRate" label="贷记卡费率(‰)">
                  <template slot-scope="scope" v-if="scope.row.level!=0 && scope.row.dcFlag==1">
                    <el-form-item :prop="'rateList[0].children['+(scope.$index-1)+'].creditRate'" :rules="rules.prod.creditRate" >
                      <el-input-number controls-position="right" v-model="scope.row.creditRate" :precision="2" :step="0.01" :min="0.0" :max="100.0" style="width: 95px"
                                     v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="feePen" label="元/笔">
                  <template slot-scope="scope" v-if="scope.row.level!=0  && scope.row.sysTradeTypeId=='JSP534'">
                    <el-form-item :prop="'rateList[0].children['+(scope.$index-1)+'].feePen'" :rules="rules.prod.feePen" >
                      <el-input-number controls-position="right" v-model="scope.row.feePen" :precision="0" :step="1" :min="0" :max="100" style="width: 95px"
                                     v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="lowLimit" label="借记单笔保底">
                  <template slot-scope="scope" v-if="scope.row.level!=0 && scope.row.limitFlag==1">
                    <el-form-item :prop="'rateList[0].children['+(scope.$index-1)+'].lowLimit'"  :rules="rules.prod.lowLimit" >
                      <el-input-number controls-position="right" v-model="scope.row.lowLimit" :precision="2" :step="0.01" :min="0.0" :max="100.0" style="width: 95px"
                                     v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="topLimit" label="借记单笔封顶">
                  <template slot-scope="scope" v-if="scope.row.level!=0 && scope.row.limitFlag==1">
                    <el-form-item :prop="'rateList[0].children['+(scope.$index-1)+'].topLimit'" :rules="rules.prod.topLimit" >
                      <el-input-number controls-position="right" v-model="scope.row.topLimit" :precision="2" :step="0.01" :min="0.0" :max="100.0" style="width: 95px"
                                     v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="lowLimitCredit" label="贷记单笔保底">
                  <template slot-scope="scope" v-if="scope.row.level!=0" >
                    <el-form-item >
<!--                                   prop="lowLimitCredit" :rules="rules.prod.lowLimitCredit"-->
                      <el-input-number controls-position="right" disabled v-model="scope.row.lowLimitCredit" :precision="2" :step="0.01" :min="0.0" :max="100.0" style="width: 95px"/>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="topLimitCredit" label="贷记单笔封顶">
                  <template slot-scope="scope" v-if="scope.row.level!=0">
                    <el-form-item >
<!--                                   prop="topLimitCredit"  :rules="rules.prod.topLimitCredit"-->
                      <el-input-number controls-position="right" disabled v-model="scope.row.topLimitCredit" :precision="2" :step="0.01" :min="0.0" :max="100.0" style="width: 95px"/>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="feeCycle" label="收费周期">
                  <template slot-scope="scope" >
                    <el-form-item :prop="'rateList[0].children['+(scope.$index-1)+'].feeCycle'" v-if="scope.row.level!=0">
                      <el-select  v-model="scope.row.feeCycle" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'">
                        <el-option
                            v-for="item in DICKV.fee_cycle"
                            :key="item.k"
                            :label="item.k"
                            :value='item.v'
                            :disabled="item.disabled=='1'?false:true">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>

                <!--                <el-table-column fixed="right" label="操作">-->
                <!--                  <template slot-scope="scope">-->
                <!--                    <el-button v-if="scope.row.level!=0" size="mini" type="primary" @click="showEditProRate(scope.row,scope.$index)">编辑 </el-button>-->
                <!--                  </template>-->
              </el-table>
            </el-form>
              <el-dialog :close-on-click-modal="false"
                         v-loading="merproRateloading"
                         element-loading-text="拼命加载中"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(0, 0, 0, 0.8)"
                         :before-close="closeMerRateDialog" title="" :visible.sync="merRateDialog"
                         width="37%" append-to-body>
                <template slot='title'>
                  <span style="font-size:20px;">费率信息配置--{{merRate.sysTradeTypeDescribe}}</span>
                </template>
                <el-form :model="merRate" :rules="rules.prod" ref="merRateForm" label-width="180px">
                  <el-row>
                    <el-col :span="15">
                      <el-form-item prop="feeRate" label="借记卡费率(‰)">
                        <el-input-number  size="medium" v-model="merRate.feeRate" :precision="2" :step="0.01" :min="0.0" :max="100.0" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                      </el-form-item>
                      <el-form-item prop="creditRate" label="贷记卡费率(‰)" v-if="merRate.dcFlag==1">
                        <el-input-number size="medium" v-model="merRate.creditRate" :precision="2" :step="0.01" :min="0.0" :max="100.0" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="15" v-if="merRate.sysTradeTypeId=='JSP534'">
                      <el-form-item prop="feePen" label="元/笔" >
                        <el-input-number size="medium" v-model="merRate.feePen" :precision="0" :step="1" :min="0.0" :max="100.0" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="15" v-if="merRate.limitFlag==1">
                      <el-form-item prop="lowLimit" label="借记单笔保底">
                        <el-input-number size="medium" v-model="merRate.lowLimit" :precision="2" :step="0.01" :min="0.0" :max="100.0"v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                      </el-form-item>
                      <el-form-item prop="topLimit" label="借记单笔封顶">
                        <el-input-number size="medium" v-model="merRate.topLimit" :precision="2" :step="0.01" :min="0.0" :max="100.0" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="15" v-if="false">
                      <el-form-item label="贷记单笔保底" prop="lowLimitCredit">
                        <el-input-number size="medium" v-model="merRate.lowLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                      </el-form-item>
                      <el-form-item label="贷记单笔封顶" prop="topLimitCredit">
                        <el-input-number size="medium" v-model="merRate.topLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="15" >
                      <el-form-item prop="feeCycle" label="收费周期">
                        <el-select  v-model="merRate.feeCycle"  placeholder="请选择" v-bind:disabled="rowData.merChanStates.productState=='00' || rowData.merChanStates.productState=='01'">
                          <el-option
                              v-for="item in DICKV.fee_cycle"
                              :key="item.k"
                              :label="item.k"
                              :value="item.v"
                              :disabled="item.disabled=='1'?false:true">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="20">
                      <el-form-item>
                        <template>
                          <el-button type="primary"  @click="submitMerRateForm">提交</el-button>
                        </template>
                        <el-button @click="closeMerRateDialog()">取消</el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-dialog>
            <el-divider content-position="left">图片信息</el-divider>
              <el-form :model="rowData" :rules="rules" ref="addForm4" >
                <el-row>
                  <el-col :span="4" v-if="IMGList[0].imageUrl">
                    <span style="line-height: 20px">营业执照照片</span>
                    <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                    <el-upload
                        class="avatar-uploader"
                        action="lei"
                        :data="IMGList[0]"
                        :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[0])}"
                        :show-file-list="false"
                        :http-request="httpRequest"><!--覆盖默认上传-->
                      <img v-if="IMGList[0].imageUrl" :src="IMGList[0].imageUrl" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-col>
                  <el-col :span="4" v-if="IMGList[1].imageUrl">
                    <span style="line-height: 20px">法人身份证正面照片</span>
                    <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                    <img v-if="IMGList[1].imageUrl" :src="IMGList[1].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[2].imageUrl">
                    <span style="line-height: 20px">法人身份证反面照片</span>
                    <img v-if="IMGList[2].imageUrl" :src="IMGList[2].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[3].imageUrl">
                    <span style="line-height: 20px">经营者手持身份证照片</span>
                    <img v-if="IMGList[3].imageUrl" :src="IMGList[3].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[4].imageUrl">
                    <span style="line-height: 20px">商户门头照片</span>
                    <img v-if="IMGList[4].imageUrl" :src="IMGList[4].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[5].imageUrl">
                    <span style="line-height: 20px">经营内景照片</span>
                    <img v-if="IMGList[5].imageUrl" :src="IMGList[5].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[6].imageUrl">
                    <span style="line-height: 20px">经营场所证明文件</span>
                    <img v-if="IMGList[6].imageUrl" :src="IMGList[6].imageUrl" class="avatar">
                  </el-col>

                  <el-col :span="4" v-if="IMGList[7].imageUrl">
                    <span style="line-height: 20px">其他审核材料</span>
                    <img v-if="IMGList[7].imageUrl" :src="IMGList[7].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[8].imageUrl">
                    <span style="line-height: 20px">银行卡正面照</span>
                    <img v-if="IMGList[8].imageUrl" :src="IMGList[8].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[9].imageUrl">
                    <span style="line-height: 20px">开户许可证照片</span>
                    <img v-if="IMGList[9].imageUrl" :src="IMGList[9].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[10].imageUrl">
                    <span style="line-height: 20px">纸质协议首页</span>
                    <img v-if="IMGList[10].imageUrl" :src="IMGList[10].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[11].imageUrl">
                    <span style="line-height: 20px">纸质协议尾页</span>
                    <img v-if="IMGList[11].imageUrl" :src="IMGList[11].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[12].imageUrl">
                    <!--
                                    M:经营者与店铺门头合照
                                    N:组织机构代码证照片
                                    O:注册登记表
                                    P:签字授权书
                                    Q:事业单位法人证书
                                    R:结算账户授权书-->

                    <span style="line-height: 20px">经营者与店铺门头合照</span>
                    <img v-if="IMGList[12].imageUrl" :src="IMGList[12].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[13].imageUrl">
                    <span style="line-height: 20px">组织机构代码证照片</span>
                    <img v-if="IMGList[13].imageUrl" :src="IMGList[13].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[14].imageUrl">
                    <span style="line-height: 20px">注册登记表</span>
                    <img v-if="IMGList[14].imageUrl" :src="IMGList[14].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[15].imageUrl">
                    <span style="line-height: 20px">签字授权书</span>
                    <img v-if="IMGList[15].imageUrl" :src="IMGList[15].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[16].imageUrl">
                    <span style="line-height: 20px">事业单位法人证书</span>
                    <img v-if="IMGList[16].imageUrl" :src="IMGList[16].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[17].imageUrl">
                    <span style="line-height: 20px">结算账户授权书</span>
                    <img v-if="IMGList[17].imageUrl" :src="IMGList[17].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[18].imageUrl">
                    <!--  S:税务登记证照片 T:其他审核材料-->
                    <span style="line-height: 20px">税务登记证照片</span>
                    <img v-if="IMGList[18].imageUrl" :src="IMGList[18].imageUrl" class="avatar">
                  </el-col>
                  <el-col :span="4" v-if="IMGList[19].imageUrl">
                    <span style="line-height: 20px">其他审核材料</span>
                    <img v-if="IMGList[19].imageUrl" :src="IMGList[19].imageUrl" class="avatar">
                  </el-col>
                </el-row>
            </el-form>
            <el-divider content-position="left">其他信息</el-divider>
              <el-form :model="rowData.merInfoOther" :rules="rules.other" ref="addForm5" label-position="left" :disabled="rowData.merChanStates.merState=='00' || rowData.merChanStates.merState=='01'">
              <!--              <el-row :gutter="24">-->
              <!--                <el-col :span="6">-->
              <!--                  <el-form-item label="商户类型" prop="merType">-->
              <!--                    <el-select v-model="rowData.merType" placeholder="请选择" @change="handleMTChange()">-->
              <!--                      <el-option-->
              <!--                          v-for="item in DICKV.mer_type"-->
              <!--                          :key="item.k"-->
              <!--                          :label="item.k"-->
              <!--                          :value="Number.parseInt(item.v)"-->
              <!--                          :disabled="item.disabled=='1'?false:true">-->
              <!--                      </el-option>-->
              <!--                    </el-select>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <!--              </el-row>-->
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item v-bind:label="'营业执照名称'" prop="corpBusName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.corpBusName" placeholder="营业执照名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item v-bind:label="'统一社\n会信用\n代码证'"  prop="creditCode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.creditCode" placeholder="统一社会信用代码证"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="社会信用代码证有效期" prop="creditCodeExpire">
                    <el-date-picker
                        v-model="rowData.merInfoOther.creditCodeExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="控股股东姓名" prop="holderName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.holderName" placeholder="控股股东姓名"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="控股股东身份证" prop="holderIdNo">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.holderIdNo" placeholder="控股股东身份证"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="控股股东有效期" prop="holderExpire">
                    <el-date-picker
                        v-model="rowData.merInfoOther.holderExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="注册资本" prop="registerFund">
                    <el-select  v-model="rowData.merInfoOther.registerFund"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.register_fund"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>

                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="员工人数" prop="stafftotal">
                    <el-select  v-model="rowData.merInfoOther.stafftotal"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.stafftotal"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="经营区域" prop="operateLimit">
                    <el-select  v-model="rowData.merInfoOther.operateLimit"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.operate_limit"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>

                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经营地段" prop="inspect">
                    <el-select  v-model="rowData.merInfoOther.inspect"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.inspect"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否三证合一" prop="thrcertFlag">
                    <el-select  v-model="rowData.merInfoOther.thrcertFlag"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.thrcert_flag"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="组织机构代码证号" prop="organcode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.organcode" placeholder="组织机构代码证号"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="组织机构代码证有效期" prop="organexpire">
                    <el-date-picker
                        v-model="rowData.merInfoOther.organexpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="对公账户号" prop="pubAcctNo">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.pubAcctNo" placeholder="对公账户号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="支付行号" prop="cnapsCode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.cnapsCode" placeholder="支付行号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="对公账户名称" prop="pubAcctName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.pubAcctName" placeholder="对公账户名称"></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="网站/应用名称" prop="merWebName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.merWebName" placeholder="网站/应用名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="网站url" prop="merUrl">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.merUrl" placeholder="网站url"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="国籍" prop="nationality">
                    <el-select v-model="rowData.merInfoOther.nationality" placeholder="请选择">
                      <el-option-group
                          v-for="group in country"
                          :key="group.label"
                          :label="group.label">
                        <el-option
                            v-for="item in group.options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-option-group>
                    </el-select>


                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="性别" prop="sex">
                    <el-select  v-model="rowData.merInfoOther.sex"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.gender"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="职业" prop="occupation">
                    <el-select  v-model="rowData.merInfoOther.occupation"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.occupation"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="住址" prop="personaddr">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.personaddr" placeholder="住址"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-if="rowData.bnfDetail == 1" >
                <el-divider content-position="left">受益人信息</el-divider>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="受益人姓名" prop="bnfName" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.bnfName"  placeholder="受益人姓名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人性别" prop="bnfSex" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-select  v-model="rowData.merInfoOther.bnfSex"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.gender"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人手机号" prop="bnfPhone" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.bnfPhone" placeholder="受益人手机号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人国籍" prop="bnfNationality" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-select v-model="rowData.merInfoOther.bnfNationality" placeholder="请选择">
                        <el-option-group
                            v-for="group in country"
                            :key="group.label"
                            :label="group.label">
                          <el-option
                              v-for="item in group.options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="受益人证件类型" prop="bnfIdType" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-select v-model="rowData.bnfIdType" placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.legal_id_type"
                            :key="item.k"
                            :label="item.k"
                            :value="item.v"
                            :disabled="item.disabled=='1'?false:true">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人证件号" prop="bnfIdNo" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.bnfIdNo" placeholder="受益人证件号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人证件有效期" prop="bnfExpire" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-date-picker
                          v-model="rowData.merInfoOther.bnfExpire"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="受益人职业" prop="bnfOccupation" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-select  v-model="rowData.merInfoOther.bnfOccupation"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.occupation"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人住址" prop="bnfPersonaddr" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.bnfPersonaddr" placeholder="受益人住址"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人职级(是否高管)" prop="bnfFrnmngFlag" :rules="{required: true, message: '必填', trigger: 'blur'}">
                      <el-select  v-model="rowData.merInfoOther.bnfFrnmngFlag"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.thrcert_flag"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="税务登记号码" prop="taxRegCode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.taxRegCode" placeholder="税务登记号码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="税务登记证日期" prop="taxCodeExpire">
                    <el-date-picker
                        v-model="rowData.merInfoOther.taxCodeExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经营内容" prop="busDetail">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.busDetail" placeholder="经营内容"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经营场所" prop="businessPlace">
                    <el-select  v-model="rowData.merInfoOther.businessPlace"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.business_place"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="经营地址" prop="busaddressCode">
                    <el-cascader
                        size="large"
                        :options="areoptions"
                        v-model="rowData.merInfoOther.busaddressCode"
                        @change="otherAreaHandleChange">
                    </el-cascader>
                  </el-form-item>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.businessAddress" style="display: none" ></el-input>
                  <!--                display: none-->
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.busaddressCode" style="display: none" ></el-input>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.districtCode" style="display: none" ></el-input>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOther.districtName" style="display: none" ></el-input>
                </el-col>


              </el-row>
            </el-form>
            <el-divider content-position="left">其他审核材料 </el-divider>
              <el-form-item label="审核材料" prop="productImg">
                <el-upload
                    :class="{ hideShow: hideUp }"
                    ref="upload"
                    action=""
                    :multiple="false"
                    :data='IMGList[19]'
                    list-type="picture-card"
                    :on-change="(file, fileList) => {handleChange(file, fileList)}"
                    :file-list="fileList"
                    :on-remove="handleDeletePic"
                    :http-request="httpRequest"
                    accept="image/png, image/jpeg"
                    :limit="5" >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
          </el-form>
        </div>
      </div>
    <div style="display: flex;align-items: center;justify-content: center;padding: 0px;margin: 0px;">
        <el-button type="primary" @click="submitMerInfoEditForm">确认变更</el-button>
        <el-button @click="colseFaDia()">取消变更</el-button>
    </div>

  <el-dialog :close-on-click-modal="false"
             v-loading="merProCloading"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             :before-close="close" title="" :visible.sync="merProCDialog"
             width="50%" append-to-body>
    <template slot='title'>
      <span style="font-size:20px;">产品控制配置</span>
    </template>
    <MerProC ref="MTPC"></MerProC>
  </el-dialog>
</div>
</template>

<script>
import {Message} from "element-ui";
import MerProC from "./MerProC";
import {regionData,CodeToText} from 'element-china-area-data';
import MerTerm from "./MerTerm";

export default {
  name: "MerEduit",
  components: {MerProC,MerTerm},
  data(){
    return{
      disable:true,
      DICKV:{},
      allAgency:{},
      permissions:{},

      tmpRowData:{
        merInfo:{
          merName:"test",
        }
      },
      rowData:{
        merInfo:{},//基本信息
        merChanStates:{},//商户渠道审核状态
        merInfoOther:{},//其他信息
        settleInfo:{},//结算信息
        merBranchInfo:[],//门店信息
        picList: [],//图片信息
        rateList:[],//费率信息
        fileList:[]//补录图片信息
      },
      rules: {
        merName: [{required: true, message: '请输入商户名称', trigger: 'blur'}],
        shortName: [{required: true, message: '请输入商户简称', trigger: 'blur'}],
        servicePhone: [{required: true, message: '请输入客服电话', trigger: 'blur'}],
        mccid: [{required: true, message: '请输入所属行业', trigger: 'blur'}],
        merType: [{required: true, message: '请输入商户类型', trigger: 'blur'}],
        legalName: [{required: true, message: '请输入法人姓名', trigger: 'blur'}],
        legalIdType: [{required: true, message: '请输入法人代表证件类型', trigger: 'blur'}],
        legalIdNo: [{required: true, message: '请输入法人代表证件号', trigger: 'blur'}],
        legalPhone: [{required: true, message: '请输入法人代表手机号', trigger: 'blur'}],
        address: [{required: true, message: '请输入注册地址', trigger: 'blur'}],
        contactPerson: [{required: true, message: '请输入商户负责人', trigger: 'blur'}],
        contactPhone: [{required: true, message: '请输入商户负责人电话', trigger: 'blur'}],
        clearMode: [{required: true, message: '请输入结算方式', trigger: 'blur'}],
        expandMan: [{required: true, message: '请输入拓展人', trigger: 'blur'}],
        businessContactName: [{required: true, message: '请输入业务联系人姓名', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '请输入业务联系人电话', trigger: 'blur'}],
        businessFlag: [{required: true, message: '请输入线上线下业务场景', trigger: 'blur'}],
        agreement: [{required: true, message: '无纸化标识', trigger: 'blur'}],
        bnfDetail: [{required: true, message: '请输入受益人标识0一致（取法人相关信息）1 不一致取不常用表信息', trigger: 'blur'}],
        merFlag: [{required: true, message: '请输入商户标识 ', trigger: 'blur'}],
        merSource: [{required: true, message: '请输入商户来源：1:H5；2:WEB；3:api；', trigger: 'blur'}],
        belongAgencyNo: [{required: true, message: '请选择所属代理商', trigger: 'blur'}],
        //结算信息
        acctId: [{required: true, message: '必填', trigger: 'blur'}],
        acctName: [{required: true, message: '必填', trigger: 'blur'}],
        acctType: [{required: true, message: '必填', trigger: 'blur'}],
        acctNoType: [{required: true, message: '必填', trigger: 'blur'}],
        settlerIdNo: [{required: true, message: '必填', trigger: 'blur'}],
        bankCode: [{required: true, message: '必填', trigger: 'blur'}],
        accresCode: [{required: true, message: '必填', trigger: 'blur'}],
        cnapsCode: [{required: true, message: '必填', trigger: 'blur'}],
        //其他信息
        other:{

        },
        //门店信息
        branch:{
          branchNo: [{required: true, message: '必填', trigger: 'blur'}],
          branchName: [{required: true, message: '必填', trigger: 'blur'}],
          status: [{required: true, message: '必填', trigger: 'blur'}],
          contactPersonPhone: [{required: true, message: '必填', trigger: 'blur'}],
          printNum: [{required: true, message: '必填', trigger: 'blur'}],
          termNum: [{required: true, message: '必填', trigger: 'blur'}],
          contactPerson: [{required: true, message: '必填', trigger: 'blur'}],
          districtCode: [{required: true, message: '必填', trigger: 'blur'}],
          branchAddress: [{required: true, message: '必填', trigger: 'blur'}]
        },
        prod:{
          feeRate:[{required: true, message: '必填', trigger: 'blur'}],
          creditRate:[{required: true, message: '必填', trigger: 'blur'}],
          feePen:[{required: true, message: '必填', trigger: 'blur'}],
          feeCycle:[{required: true, message: '必填', trigger: 'blur'}],
          lowLimit:[{required: true, message: '必填', trigger: 'blur'}],
          topLimit:[{required: true, message: '必填', trigger: 'blur'}],
          lowLimitCredit:[{required: true, message: '必填', trigger: 'blur'}],
          topLimitCredit:[{required: true, message: '必填', trigger: 'blur'}]
        }
      },
      index:0,
      /*费率信息*/
      tableRadio:{},
      rmenus:[],
      merRate:{},
      merRateDialog:false,
      merproRateloading:false,

      //门店信息
      merBranchDialog:false,
      eduitBranchDialog:false,
      rowBranch:{},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 5,
          pages: 0,
          total: 0
        }
      },
      merProCDialog:false,
      merProCloading:false,

      /*图片信息*/
      imageUrl:'',
      IMGList: [
        {type:'A',imageUrl:'',tempUrl:''},
        {type:'B',imageUrl:'',tempUrl:''},
        {type:'C',imageUrl:'',tempUrl:''},
        {type:'D',imageUrl:'',tempUrl:''},
        {type:'E',imageUrl:'',tempUrl:''},
        {type:'F',imageUrl:'',tempUrl:''},

        {type:'G',imageUrl:'',tempUrl:''},
        {type:'H',imageUrl:'',tempUrl:''},
        {type:'I',imageUrl:'',tempUrl:''},
        {type:'J',imageUrl:'',tempUrl:''},
        {type:'K',imageUrl:'',tempUrl:''},
        {type:'L',imageUrl:'',tempUrl:''},

        {type:'M',imageUrl:'',tempUrl:''},
        {type:'N',imageUrl:'',tempUrl:''},
        {type:'O',imageUrl:'',tempUrl:''},
        {type:'P',imageUrl:'',tempUrl:''},
        {type:'Q',imageUrl:'',tempUrl:''},
        {type:'R',imageUrl:'',tempUrl:''},
        {type:'S',imageUrl:'',tempUrl:''},
        {type:'T',imageUrl:'',tempUrl:''},
      ],
      dialogImageUrl: '',
      headers: {
        // 'Content-Type': 'multipart/form-data', // 默认值
        'Authorization':window.sessionStorage.getItem("authorization"),
        // 'user':encodeURIComponent(window.sessionStorage.getItem("user")),
      },
      dialogVisible: false,
      fileList: [],
      picList: [],
      hideUp:false,


      //mcc
      mccoptions:[{}],
      mccloading:false,
      mcclist:[],

      areoptions: regionData,
      selectedOptions: [],

      //sett_bank
      bankoptions:this.initBank(),
      bankcityoptions:[{}],
      bankcnapsoptions:[{}],

      merTypeRules1:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
        creditCode: [{required: true, message: '社会信用代码证必填', trigger: 'blur'}],
        creditCodeExpire:[{required: true, message: '社会信用代码证有效期必填', trigger: 'blur'}],
        businessContactName: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '业务联系人电话必填', trigger: 'blur'}],
        holderName:[{required: true, message: '控股股东姓名必填', trigger: 'blur'}],
        holderIdNo: [{required: true, message: '控股股东身份证必填', trigger: 'blur'}],
        holderExpire:[{required: true, message: '控股股东有效期必填', trigger: 'blur'}],
        registerFund: [{required: true, message: '注册资本必填', trigger: 'blur'}],
        stafftotal: [{required: true, message: '员工人数必填', trigger: 'blur'}],
        operateLimit: [{required: true, message: '经营区域必填', trigger: 'blur'}],
        inspect: [{required: true, message: '经营地段必填', trigger: 'blur'}],
      },
      merTypeRules3:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
        creditCode: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        creditCodeExpire:[{required: true, message: '社会信用代码证有效期必填', trigger: 'blur'}],
        businessContactName: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '业务联系人电话必填', trigger: 'blur'}],
        holderName:[{required: true, message: '控股股东姓名必填', trigger: 'blur'}],
        holderIdNo: [{required: true, message: '控股股东身份证必填', trigger: 'blur'}],
        holderExpire:[{required: true, message: '控股股东有效期必填', trigger: 'blur'}],
        registerFund: [{required: true, message: '注册资本必填', trigger: 'blur'}],
        stafftotal: [{required: true, message: '员工人数必填', trigger: 'blur'}],
        operateLimit: [{required: true, message: '经营区域必填', trigger: 'blur'}],
        inspect: [{required: true, message: '经营地段必填', trigger: 'blur'}],

      },
      merTypeRules4:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
      },
      merTypeRules6:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
        creditCode: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        creditCodeExpire:[{required: true, message: '必填', trigger: 'blur'}],
        businessContactName: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '业务联系人电话必填', trigger: 'blur'}],
      },

      //客户经理
      saleperoptions:[{}],
      //所属父商户
      meroptions:[{}],

      //国籍
      country:[{
        label: '热门国家',
        options: [{
          value: 'China',
          label: '中国'
        } ]
      }, {
        label: '其他国家',
        options: [ ]
      }],
      merTermDialog:false,
      merTermloading:false,
    }
  },created() {
    this.permissions = this.$store.state.permissions;
    this.DICKV=this.$store.state.DICKV;
    this.initAgency();
    this.mount();
  },
  methods:{
    mount(){
      this.$on('init',(res)=>{
        this.tableRadio.merId=res
        this.init()
      })
    },

    // addFile(file, fileList) {
    //   this.files = fileList;//主要用于接收存放所有的图片信息
    //   //限制上传文件为2M
    //   var sizeIsSatisfy = file.size < 2 * 1024 * 1024 ? true : false;
    //   if (sizeIsSatisfy) {
    //     return true;
    //   } else {
    //     this.fileSizeIsSatisfy = true;
    //     return false;
    //   }
    // },

    // submitForm(formName) {//提交form表单
    //   this.$refs[formName].validate(valid => {
    //     if (valid) {
    //       if (this.files.length <= 0) {
    //         this.$message.error("请至少上传一个文件！");
    //         return;
    //       }
    //       if (this.fileSizeIsSatisfy) {
    //         this.$message.error("上传失败！存在文件大小超过2M！");
    //         return;
    //       }
    //       this.processFile();//处理files的数据变成键值对的形式   返回newFiles这个数组
    //       var param = new FormData(); // FormData 对象
    //       newFiles.forEach(fileItem => {
    //         var list = fileItem;
    //         var file = list.imgFile;
    //         param.append("files", file); // 文件对象
    //       });
    //       getImgUrl(param).then(
    //           msg => {
    //             console.log(msg);//拿到想要的图片地址
    //           },
    //           error => {
    //             console.log(error);
    //           }
    //       );
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },
//全部的图片添加到 newFiles中
    processFile() {
      this.files.forEach(item => {
        let objFile = {};
        objFile.title = "files";
        objFile.imgFile = item.raw;
        this.newFiles.push(objFile);
      });
    },

    cleanAll(){
      this.index =0;
      this.rowData={}
      this.rowData.merInfo={}
      this.rowData.merChanStates={}
      this.rowData.merInfoOther={}
      this.rowData.settleInfo={}
      this.rowData.merBranchInfo=[]
      this.rowData.picList= []
      this.rowData.rateList=[]
    },
    init(){
      this.cleanAll();
      this.getRequest("/sys/merch/getOneAll",{merId:this.tableRadio.merId}).then(resp=> {
        let data = resp;
        if (data && data.status == 200) {
          this.rowData=data.obj
          // this.IMGList=data.obj.picList
          // this.baseIMG1(this.IMGList);
          this.mccoptions[0].value=data.obj.merInfo.mccid
          this.mccoptions[0].label=data.obj.merInfo.mccidName

          this.bankcityoptions[0].value=data.obj.settleInfo.accresCode
          this.bankcityoptions[0].label=data.obj.settleInfo.accresName

          this.bankcnapsoptions[0].value=data.obj.settleInfo.cnapsCode
          this.bankcnapsoptions[0].label=data.obj.settleInfo.cnapsName

        }else{
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
        this.baseIMG();
        this.tableRadio.relationId = this.rowData.merInfo.id;
        this.tableRadio.id= this.rowData.merInfo.id;
        this.tableRadio.belongAgencyNo= this.rowData.merInfo.belongAgencyNo;
        // this.initR(this.tableRadio)
        this.initSaPeAndMer(0);

        // this.initBranch();

        //商户类型
        this.handleMTChange(this.rowData.merInfo.merType);

      });

    },
    handleMTChange(mertype){
      switch (Number.parseInt(mertype)) {
        case 1:
          this.rules.other=this.merTypeRules1
          break;
        case 3:
          this.rules.other=this.merTypeRules3
          break;
        case 4:
          this.rules.other=this.merTypeRules4
          break;
        case 6:
          this.rules.other=this.merTypeRules6
          break;
      }
    },

    baseIMG(){
      let IMGList=this.rowData.picList;
      for (let i = 0; i < IMGList.length; i++) {
        if (IMGList[i].picPath){
          this.getRequestBlob('/sys/fileStorage/baseIMG?path='+IMGList[i].picPath+'&type=MER&time=' + new Date()).then(data => {
            this.IMGList[i].imageUrl = window.URL.createObjectURL(data);
            this.IMGList[i].picPath = IMGList[i].picPath;
          })
        }else{
          this.IMGList[i].imageUrl=''
          this.IMGList[i].picPath = '';
        }
      }
    },
    initSaPeAndMer(type){
      this.getRequest("/sys/merch/salePer",{belongAgencyNo:this.rowData.merInfo.belongAgencyNo}).then(resp=> {
        if (resp && resp.status == 200) {
          this.saleperoptions=resp.obj
          if (type==1){
            this.rowData.merInfo.expandMan=''
          }
        }
      });
      this.getRequest("/sys/merch/merInfo",{belongAgencyNo:this.rowData.merInfo.belongAgencyNo}).then(resp=> {
        if (resp && resp.status == 200) {
          this.meroptions=resp.obj
          if (type==1){
            this.rowData.merInfo.parentId=''
          }
        }
      });

    },
    initBank() {
      this.getRequest("/sys/bank/asyn",{type:0}).then(resp=> {
        if (resp && resp.status == 200) {
          this.bankoptions=resp.obj
        }
      });
    },
    initCity() {
      this.bankcnapsoptions=[{}]
      this.rowData.settleInfo.accresCode=''
      this.rowData.settleInfo.cnapsCode=''
      this.getRequest("/sys/bank/asyn",{type:1,bankCode:this.rowData.settleInfo.bankCode}).then(resp=> {
        if (resp && resp.status == 200) {
          this.bankcityoptions=resp.obj
        }
      });
    },
    initCnaps() {
      this.rowData.settleInfo.cnapsCode = ''
      this.getRequest("/sys/bank/asyn", {
        type: 2,
        bankCode: this.rowData.settleInfo.bankCode,
        accresCode: this.rowData.settleInfo.accresCode
      }).then(resp => {
        if (resp && resp.status == 200) {
          this.bankcnapsoptions = resp.obj
        }
      });
    },
    initCnapsDOM(){
      this.$forceUpdate();
    },
    areaHandleChange (value) {
      //这里可以把获取到的value值赋值给后台字段做存储使用
      //value是数组，如 绑定值：[ "330000", "330100", "330106" ]
      //只需要把区存到后台数据库即可，即取到value[2]即可，在数据回显时，便可以自动显示整个省市区
      //非常方便简介
      //CodeToText是个大对象，属性是区域码，属性值是汉字 用法例如：CodeToText['110000']输出北京市
      //TextToCode是个大对象，属性是汉字，属性值是区域码 用法例如：TextToCode['北京市'].code输出110000,TextToCode['北京市']['市辖区'].code输出110100,TextToCode['北京市']['市辖区']['朝阳区'].code输出110105
      //想获取到省市区中文的话，就这样写：CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
      if(value!=undefined){
        this.rowData.merInfo.addressCode=value[2];
        this.rowData.merInfo.address=CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
      }
    },
    branchAreaHandleChange (value) {
      if(value!=undefined){
        this.rowBranch.districtCode=value[2];
        this.rowBranch.districtName=CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
      }
    },
    otherAreaHandleChange(value) {
      if(value!=undefined){
        this.rowData.merInfoOther.busaddressCode=value[2];
        this.rowData.merInfoOther.districtCode=value[2];
        this.rowData.merInfoOther.businessAddress=CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
        this.rowData.merInfoOther.districtName=CodeToText[value[2]];
      }
    },

    // 当用户输入内容开始远程搜索模糊查询的时候，会触发remoteMethod方法
    remoteMethod(query) {
      // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
      if (query !== "") {
        this.mccloading = true; // 开始拿数据喽
        // 这里模拟发请求，res就当成发请求返回来的数据吧。
        this.getRequest("/sys/mcc/asyn",{parm:query}).then(resp=> {
          if (resp && resp.status == 200) {
            this.mcclist=resp.obj
            this.mccloading = false // 拿到数据喽
            this.mccoptions = resp.obj
            // this.mcclist.filter((item)=>{
            //   // indexOf等于0代表只要首个字匹配的，如：搜索 王 王小虎数据会被过滤出来，但是 小虎王的数据不会被过滤出来。因为indexOf等于0代表以什么开头
            //   // return item.label.toLowerCase().indexOf(query.toLowerCase()) == 0
            //   // indexOf大于-1代表的是，只要有这个字出现的即可，如：搜索 王 王小虎、小虎王、小王虎都会被过滤出来。因为indexOf找不到才会返回-1，
            //   // 大于-1说明只要有就行，不论是不是开头也好，中间也好，或者结尾也好
            //   return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
            // })
          }
        });

      } else {
        this.mccoptions = [];
      }
    },
    changeMcc(val){
      this.rowData.merInfo.mccid=val.value
      this.rowData.merInfo.mccidName=val.label
    },

    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = parseInt(this.DICKV[key][i].v); //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    showEditProRate(row,index){
      console.log(index);
      this.merRateDialog=true
      this.merRate=row
      this.merRate.relationId=this.tableRadio.id
      this.merRate.merId=this.tableRadio.merId
    },
    closeMerRateDialog(){
      this.merRateDialog=false
    },
    submitMerRateForm(){
      let Rate=this.merRate
      if (Rate.lowLimit>Rate.topLimit){
        Message.error("借记区间设置错误："+Rate.lowLimit+"--"+Rate.topLimit)
        return false;
      }
      if (Rate.lowLimitCredit>Rate.topLimitCredit){
        Message.error("贷记区间设置错误："+Rate.lowLimitCredit+"--"+Rate.topLimitCredit)
        return false;
      }
      this.$refs.merRateForm.validate((valid) => {
        if (valid) {
          // this.postRequest('/sys/merch/merRate', this.merRate).then(data => {
          //   if (data.status == 200) {
          //     Message.success("更新成功")
          //     this.merRateDialog=false
          //     this.merRate={}
          //   } else {
          //     Message.error({message: data.msg + '(' + data.status + ')'})
          //   }
          // })

          // this.rowData.rateList[].
        } else {
          return false;
        }
      })
    },

    initAgency(){
      this.getRequest("/sys/merch/agency").then(resp=> {
        if (resp && resp.status == 200) {
          this.allAgency=resp.obj
        }
      });
    },


    // initR(parm){
    //   if (this.permissions.GET == 1) {
    //     this.rateloading = true
    //     this.getRequest("/sys/merch/merRateMenu", parm).then(data => {
    //       if (data) {
    //         this.rmenus = data.obj
    //         this.rateloading = false
    //       }
    //     })
    //   } else {
    //     Message.error("权限不足，请联系管理员")
    //   }
    // },
    AddBranch(){
      this.rowBranch={
        relationId:this.rowData.merInfo.id,
        merId:this.rowData.merInfo.merId,
        id:'',
        branchNo:'',
        branchName:'',
        status:1,
        contactPerson:'',
        termNum:0,
        printNum:0,
        branchAddress:'',
        contactPersonPhone:'',
        districtCode:'',
        changeFlag:0
      }
      this.getRequest('/sys/merch/branchSeq').then(data => {
        this.loading = false
        if (data.status == 200) {
          this.rowBranch.id = data.obj.bid
          // this.rowBranch.branchNo = data.obj.bno
          this.rowData.merBranchInfo.push( this.rowBranch )
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })

      // this.merBranchDialog=true
    },
    eduitBranchRow(rowData) {
      this.rowBranch = {}
      this.rowBranch = rowData
      this.eduitBranchDialog = true
    },
    closeBranchDia(){
      this.rowBranch={}
      this.merBranchDialog=false
      this.eduitBranchDialog=false
      this.initBranch()
    },
    submitAddBranchForm(){
      this.rowBranch.relationId=this.rowData.merInfo.id
      this.$refs.addBranchForm.validate((valid) => {
        if (valid) {
          this.postRequest("/sys/merch/merBranch", this.rowBranch).then(resp=> {
            let data = resp.data;
            if (resp && resp.status == 200) {
              Message.success("成功")
              this.merBranchDialog=false
              this.initBranch();
            }else{
              Message.error({message: data.msg + '(' + data.status + ')'})
              return false;
            }
          });
        }else{
          return false;
        }
      })
    },
    submitEduitBranchForm(){
      this.rowBranch.relationId=this.rowData.merInfo.id
      this.$refs.eduitBranchForm.validate((valid) => {
        if (valid) {
          this.putRequest("/sys/merch/merBranch", this.rowBranch).then(resp=> {
            let data = resp.data;
            if (resp && resp.status == 200) {
              Message.success("成功")
              this.eduitBranchDialog=false
              this.initBranch();
            }else{
              Message.error({message: data.msg + '(' + data.status + ')'})
              return false;
            }
          });
        }else{
          return false;
        }
      })
    },
    initBranch(){
      this.pm.pm.merId=this.rowData.merInfo.merId
      this.getRequest('/sys/merch/merBranch', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.rowData.merBranchInfo = data.obj.list
          this.pm.page = data.obj.page
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })

    },
    deleteBranchRow(index) {
      if (this.rowData.merBranchInfo.length==1){
        Message.error("至少保留一个门店")
      }else{
        // this.rowData.merBranchInfo.splice(index,1);
        // this.rowData.merBranchInfo[index].changeFlag=2;
        console.log(this.rowData.merBranchInfo[index])
      }

      // this.deleteRequest('/sys/merch/merBranch', row).then(data => {
      //   this.loading = false
      //   if (data.status == 200) {
      //     Message.success(data.msg)
      //     this.initBranch();
      //   } else {
      //     Message.error({message: data.msg + '(' + data.status + ')'})
      //   }
      // })
    },
    changeBranchFlag($event,row,index){
      if ($event=='2'){
        // 门店移除
        this.deleteRequest('/sys/merch/merBranch', row).then(data => {
          this.loading = false
          if (data.status == 200) {
            Message.success("移除成功")
          } else {
            Message.error({message: data.msg})
            this.rowData.merBranchInfo[index].changeFlag='1';
          }
        })
      }
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.initBranch()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.initBranch()
    },
    eduitProRow(row) {
      row.htmlFromType="ediut"
      this.merProCDialog = true
      this.$nextTick(() => {
        this.$refs.MTPC.$emit('initProC', row)
      })
    },
    close(){
      this.merProCDialog=false
      this.merTermDialog=false
    },
    colseFaDia(){
      this.$confirm('确认取消？')
          .then(() => {
            this.$emit('colseFaDia');
          })
          .catch(() => {
          });
    },
    eduitAffirm(){
      let ref=this.$refs.addForm5
      ref.validate((valid) => {
        if (valid) {

          this.postRequest("/sys/merch/merCommit", this.rowData).then(resp=> {
            if (resp && resp.status == 200) {
              Message.success("成功提交")
              this.index =0;
              this.rowData={}
              this.rowData.merChanStates={}
              this.rowData.merInfo={}
              this.rowData.merInfoOther={}
              this.rowData.settleInfo={}
              this.rowData.merBranchInfo=[]
              this.rowData.picList= []
              this.rowData.rateList=[]
              this.$emit('colseFaDia');
            }else{
              Message.error({message: resp.msg})
            }
          });

        } else {
          Message.error({message: '信息未完整录入'})
          return false;
        }
      })

    },
    //门店绑定终端
    eduitTermRow(row){
      row.expandMan=this.rowData.merInfo.expandMan
      row.agencyNo=this.rowData.merInfo.belongAgencyNo
      row.htmlFromType="ediut"
      this.merTermDialog=true
      this.$nextTick(() => {
        this.$refs.merterm.$emit('init', row)
      })
    },
    // next(){
    //   if (this.index == 3 && this.rowData.merBranchInfo.length==0) {
    //     Message.error({message: '请添加部门' })
    //     return false;
    //   }
    //   else {
    //     let ref=this.$refs.addForm0
    //     switch (this.index){
    //       case 0:
    //         ref=this.$refs.addForm0
    //         break;
    //       case 1:
    //         ref=this.$refs.addForm1
    //         break;
    //         // case 2:
    //         //   ref=this.$refs.addForm2
    //         //   break;
    //         // case 3:
    //         //   ref=this.$refs.addForm3
    //         //   break;
    //       case 4:
    //         ref=this.$refs.addForm4
    //         this.rowData.picList = this.IMGList;
    //         break;
    //       case 5:
    //         ref=this.$refs.addForm5
    //         break;
    //     }
    //     if (this.index<=5){
    //       ref.validate((valid) => {
    //         if (valid) {
    //           this.rowData.index = this.index;
    //           this.postRequest("/sys/merch", this.rowData).then(resp=> {
    //             if (resp && resp.status == 200) {
    //               Message.success("成功")
    //               this.dialogVisible = false;
    //               this.rowData.id=resp.obj
    //               if (this.index==5){
    //                 this.index =0;
    //                 this.rowData={}
    // this.rowData.merChanStates={}

    //                 this.rowData.merInfoOther={}
    //                 this.rowData.settleInfo={}
    //                 this.rowData.merBranchInfo=[]
    //                 this.rowData.picList= []
    //                 this.$emit('colseFaDia');
    //               }else{
    //                 this.index ++;
    //                 if (this.index==2) {
    //                   this.tableRadio.relationId = this.rowData.id;
    //                   this.tableRadio.id= this.rowData.id;
    //                   this.tableRadio.belongAgencyNo= this.rowData.belongAgencyNo;
    //                   this.initR(this.tableRadio)
    //                 }
    //               }
    //             }else{
    //               Message.error({message: resp.msg + '(' + resp.status + ')'})
    //               return false;
    //             }
    //           });
    //         } else {
    //           Message.error({message: '信息未完整录入'})
    //           return false;
    //         }
    //       })
    //       if(this.index==3){
    //         this.rowData.relationId = this.rowData.id;
    //         this.initBranch()
    //       }
    //     }else{
    //       this.index ++;
    //     }
    //   }
    // },


    // 选择文件时，往fileList里添加
    handleChange(file, fileList) {
      this.hideUp= fileList.length>=5;
    },
    handleDeletePic(file) {
      this.hideUp=false
      for (const i in this.picList) {
        if (this.picList[i].key === file.uid) {
          this.picList.splice(i, 1)
        }
      }
    },
    //实现图片上传功能
    httpRequest(item) {
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size < 1800 * 1024;/// 1024 / 1024 < 2
      if (!isJPG) {
        Message.error('上传图片只能是 JPG 或 PNG 格式!')
        return false
      }
      if (!isLt2M) {
        Message.error( '上传图片大小不能超过 1.8MB!')
        return false

      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        // post上传图片
        let App = this;
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        mf.append('type', item.data.type);
        mf.append('path', 'prod');
        mf.append('relationId', this.rowData.merInfo.id);
        App.postRequest('/sys/fileStorage/img',mf).then(resp => {
          if (resp && resp.result == 'true') {
            this.picList.push({ key:item.file.uid ,type: 'T', picPath: resp.picPath})
            Message.success( resp.message )
          }else{
            this.handleDeletePic(item.file);
            Message.error(resp.message)
            this.$forceUpdate();
          }
        })
      }
    },

    submitMerInfoEditForm(){
      this.$emit('loadFaDiaOpen');
      this.rowData.fileList=this.picList;
      let ok=false;
      this.$refs.addFormBranch.validate((valid0) => {
        if (!valid0) {
          Message.error("门店信息不可为空");
          ok=false;
          return false;
        }else{
          ok=true;
        }
      })
      if (ok){
        this.$refs.addFormProd.validate((valid1) => {
          if (!valid1) {
            Message.error("费率信息不可为空值");
            ok=false;
            return false;
          }else {
            ok=true;
          }
        })
      }
      if (ok){
        this.$refs.addForm0.validate((valid2) => {
          if (valid2) {
            this.postRequest("/sys/merch/editMer",this.rowData).then(resp=>{
              if (resp && resp.status == 200) {
                Message.success(resp.msg)
                this.$emit('colseFaDia');
              }else {
                Message.error(resp.msg)
              }
            });
          } else {
            Message.error({message: '信息未完整录入'})
            return false;
          }
        })
      }

      this.$emit('loadFaDiaClose');
    }
  }
}
</script>

<style scoped>

.hideShow >>>.el-upload--picture-card{
  display: none;
}
.el-select {
  width: 100%;
}
.el-input-number.is-without-controls .el-input__inner {
  width: 10px;
  line-height: 30px;
  height: 28px;
}
.avatar-uploader {
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 150px;
  height: 150px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
}

</style>
