<template>
  <div>
    <div  v-if="permissions.GET">
      <el-collapse v-model="retrieveActiveNames">
        <el-collapse-item title="检索" name="1">
          <el-form label-width="90px">
            <el-row>
              <el-col :span="5">
                <el-form-item label="类型">
                  <el-input autocomplete="off" type="text" v-model="pm.pm.type"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-button type="success" size="mini"  v-if="permissions.GET" @click="queryForm">查询</el-button>
                <el-button size="mini"  v-if="permissions.GET" @click="clearQueryForm">清空</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>

    </div>
    <div style="margin-top: 5px">
      <el-table highlight-current-row  v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="150" sortable show-overflow-tooltip label="id" prop="id"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="类型" prop="type"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="K" prop="k"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="V" prop="v"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="状态" prop="status">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status==1">启用</el-tag>
            <el-tag  type="danger"  v-if="scope.row.status==0">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="下拉框-启用/禁用" prop="disabled">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.disabled==1">启用</el-tag>
            <el-tag type="danger"  v-if="scope.row.disabled==0">禁用</el-tag>
          </template>
        </el-table-column>

        <el-table-column width="150" show-overflow-tooltip label="创建时间" prop="creakdate"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="修改时间" prop="ureakdate"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="创建人" prop="creakman"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="最终修改人" prop="ureakman"> </el-table-column>
        <el-table-column width="150" show-overflow-tooltip label="备注" prop="nots"> </el-table-column>


        <el-table-column label="操作" fixed="right" width="150" v-if="permissions.PUT||permissions.DELETE" >
          <template slot-scope="scope" >
            <el-button type="success" size="mini" v-if="permissions.PUT" @click="showEditView(scope.row)">编辑</el-button>
            <el-button type="danger" v-if="permissions.DELETE" @click="deleteRow(scope.row)" size="mini">删除</el-button>
          </template>
        </el-table-column>


      </el-table>
    </div>
    <div style="display:flex;">
      <div class="block" style="flex: 1">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pm.page.page"
            :page-sizes="[15, 50, 100, 200]"
            :page-size="pm.page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pm.page.total" style="text-align:center;margin-top: 7px">
        </el-pagination>
      </div>
      <div class="block" style="align-self:flex-end">
        <el-button v-if="permissions.POST" @click="showAddView" type="primary" size="mini">添加</el-button>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :before-close="close"
        title="编辑"
        :visible.sync="editDialog"
        :rules="rules"
        width="50%"
    >
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>

          <el-col :span="10">
            <el-form-item label="类型" prop="type">
              <el-input autocomplete="off" type="text" v-model="rowData.type" placeholder="请输入类型"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="K" prop="k">
              <el-input autocomplete="off" type="text" v-model="rowData.k" placeholder="请输入K"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="V" prop="v">
              <el-input autocomplete="off" type="text" v-model="rowData.v" placeholder="请输入V"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择">
                <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="下拉框选项状态" prop="disabled">
              <el-select v-model="rowData.disabled" placeholder="请选择">
                <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="备注" prop="nots">
              <el-input autocomplete="off" type="textarea" v-model="rowData.nots" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
        :close-on-click-modal="false"
        :before-close="close"
        title="添加"
        :visible.sync="addDialog"
        width="35%"
    >
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="70px">
        <el-row>

          <el-col :span="16">
            <el-form-item label="类型" prop="type">
              <el-input autocomplete="off" type="text" v-model="rowData.type" placeholder="请输入类型"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <el-form-item label="K" prop="k">
              <el-input autocomplete="off" type="text" v-model="rowData.k" placeholder="请输入K"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <el-form-item label="V" prop="v">
              <el-input autocomplete="off" type="text" v-model="rowData.v" placeholder="请输入V"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <el-form-item label="状态" prop="status">
              <el-select v-model="rowData.status" placeholder="请选择">
                <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <el-form-item label="下拉框选项状态" prop="disabled">
              <el-select v-model="rowData.disabled" placeholder="请选择">
                <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="16">
            <el-form-item label="备注" prop="nots">
              <el-input autocomplete="off" type="textarea" v-model="rowData.nots" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "Index",
  data() {
    return {
      retrieveActiveNames:'1',
      suspension_label:3,
      suspension_data:14,
      permissions: {},
      loading: false,
      addDialog: false,
      editDialog: false,
      datas: [],
      rowData: {},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 15,
          pages: 0,
          total: 0
        }
      },
      rules: {
        type: [{required: true, message: '请输入类型', trigger: 'blur'}],
        k: [{required: true, message: '请输入K', trigger: 'blur'}],
        v: [{required: true, message: '请输入V', trigger: 'blur'}],

        disabled: [{required: true, message: '必填', trigger: 'blur'}],
        status: [{required: true, message: '必填', trigger: 'blur'}],
      },
      statusOptions: [{
        value: 0,
        label: '禁用'
      }, {
        value: 1,
        label: '启用',
      }]



    }
  }, mounted() {
    this.permissions = this.$store.state.permissions;
    this.init()
  }, methods: {
    init() {
      if (this.permissions.GET) {
        this.loading = true
        this.getRequest('/sys/dic', this.pm).then(data => {
          this.loading = false
          if (data.status == 200) {
            this.datas = data.obj.list
            this.pm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    queryForm() {
      this.pm.page.pageNum = 1
      this.init()
    },
    clearQueryForm() {
      this.pm.pm = {}
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.init()
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/dic', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    showAddView() {
      this.rowData = {}
      this.addDialog = true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/dic', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    deleteRow(rowData) {
      this.$confirm("确认删除？")
          .then(() => {
            this.deleteRequest("/sys/dic", rowData).then(data => {
              if (data.status == 200) {
                Message.success("成功")
                this.init(data.parentId)
              } else {
                Message.error({message: data.msg + '(' + data.status + ')'})
              }
            })
          }).catch(() => {
      })
    },
    close() {
      this.$confirm('确认取消？')
          .then(() => {
            this.editDialog = false
            this.addDialog = false
            this.rowData = {}
            this.init()
          })
          .catch(() => {
          });
    }
  }
}
</script>

<style scoped>

</style>
