<template>
     <div>
       <div id="posterHtml" :style="{backgroundImage: 'url('+posterHtmlBg+')'}" v-show="Sqshow">
         <vue-qr ref="qr" :text="text" :size="170" :margin='10'></vue-qr>
       </div>
       <div class="sqcode-img" v-show="!Sqshow">
         <img :src="posterImg">
       </div>
     </div>
</template>

<script>
import {Message} from "element-ui";
import vueQr from 'vue-qr';
import html2canvas from "html2canvas";
export default {
  data () {
    return {
      // imageUrl: require("../../../assets/images/background.png"),
      posterHtmlBg:require('../../../assets/images/background.png'),
      posterImg: '', // 最终生成的海报图片
      Sqshow:true,
      loading:true,
      text:'',
    };
  },
  components:{
    vueQr
  },
  created(){

  },mounted() {
    this.mount();
  },
  methods: {
    mount(){
      this.$on('createQrcode',(res)=>{
        this.getRequest('/sys/cabin/showQrcode', res).then(data => {
          if (data.status == 200) {
            this.text=data.obj
            this.createQrcode()
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      })
    },
    // 生成海报
    createQrcode() {
      const that = this;
      setTimeout(()=>{
        const domObj = document.getElementById('posterHtml')
        html2canvas(domObj, {
          useCORS: true,
          allowTaint: false,
          logging: false,
          letterRendering: true,
          onclone(doc) {
            let e = doc.querySelector('#posterHtml')
            e.style.display = 'block'
          }
        }).then(function(canvas) {
          // 在微信里,可长按保存或转发
          that.posterImg = canvas.toDataURL('image/png');
          that.Sqshow=false;
          that.loading=false;

          // console.log(that.posterImg)

          // that.downs(that.posterImg)

        })

      },0)

    },

    // 下载照片

    downs(img){
      let link = document.createElement('a')
      link.href = img
      link.download = 'qrCode.png'
      link.click();
    },

  }

}

</script>

<style scoped>

 #posterHtml{
    height: 81vh;
    width: 80vh;
    background-size: 100% 100%;
    position: relative;
   }
 #posterHtml img{
    position: absolute;
    top: 25%; /*二维码高度*/
    left: 33%;/*二维码左边距*/
    width: 17rem;
    height: 17rem;
   }
 .sqcode-img{
    width: 100%;
    height: 50vh;
   }
 .sqcode-img img{
    width: 100%;
    height: 100%;
   }

</style>
