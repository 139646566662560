<template>
  <div class="AgcRate">
    <el-table :data="rmenus" row-key="id" border :tree-props="{children: 'children',hasChildren:'hasChildren'}" >
      <el-table-column width="150" prop="sysTradeTypeId" label="产品类型"></el-table-column>
      <el-table-column prop="sysTradeTypeDescribe" label="交易描述"></el-table-column>
      <el-table-column prop="productStatus" label="状态">
        <template slot-scope="scope" >
          {{ getDICKV(scope.row.productStatus, 'product_status') }}
        </template>
      </el-table-column>
      <el-table-column width="80" label="隐藏状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="!scope.row.hidden">不隐藏</el-tag>
          <el-tag type="danger" v-else>隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button v-if="(scope.row.children.length<1 && scope.row.merId)
                            || (redio.belongAgencyNo==0 && scope.row.children.length<1 && scope.row.merId)
                            && scope.row.level!=0"
                     size="mini" type="primary" @click="showEditProRate(scope.row)">编辑 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :close-on-click-modal="false"
               v-loading="proRateloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="closeAgcRateDialog" title="" :visible.sync="agcRateDialog"
               width="37%" append-to-body>
      <template slot='title'>
        <span style="font-size:20px;">费率信息配置--{{agcRate.sysTradeTypeDescribe}}</span>
      </template>
      <el-form :model="agcRate" :rules="agcRateRules" ref="agcRateForm" label-width="180px">
        <el-row>
          <el-col :span="15">
              <el-form-item prop="feeRate" label="借记卡费率(‰)">
                <el-input-number size="medium" v-model="agcRate.feeRate" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
              </el-form-item>
              <el-form-item prop="creditRate" label="贷记卡费率(‰)" v-if="agcRate.dcFlag==1">
                <el-input-number size="medium" v-model="agcRate.creditRate" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
              </el-form-item>
          </el-col>

          <el-col :span="15" v-if="agcRate.sysTradeTypeId=='JSP534'">
            <el-form-item prop="feePen" label="元/笔" >
              <el-input-number size="medium" v-model="agcRate.feePen" :precision="0" :step="1" :min="0" :max="100"/>
            </el-form-item>
          </el-col>
          <el-col :span="15" >
            <el-form-item prop="feeCycle" label="收费周期">
              <el-select  v-model="agcRate.feeCycle"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.fee_cycle"
                    :key="item.k"
                    :label="item.k"
                    :value="item.v">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="15" v-if="agcRate.limitFlag==1 ">
            <el-form-item label="借记单笔保底" prop="lowLimit">
              <el-form-item prop="lowLimit">
                <el-input-number size="medium" v-model="agcRate.lowLimit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>~
              </el-form-item>
              <el-form-item prop="topLimit">
                <el-input-number size="medium" v-model="agcRate.topLimit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
              </el-form-item>
            </el-form-item>
          </el-col>

          <el-col :span="15" v-if="false">
            <el-form-item label="贷记单笔封顶" prop="lowLimitCredit">
              <el-form-item prop="lowLimitCredit">
                <el-input-number size="medium" v-model="agcRate.lowLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>~
              </el-form-item>
              <el-form-item prop="topLimitCredit">
                <el-input-number size="medium" v-model="agcRate.topLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
              </el-form-item>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <template v-if="agcRate.merId &&(agcRate.fatherMap || redio.belongAgencyNo==0)">
                <el-button type="primary"  @click="submitAgcRateForm">提交</el-button>
              </template>
              <template v-else>
                <el-button type="primary" disabled @click="submitAgcRateForm">提交</el-button>
              </template>
              <el-button @click="closeAgcRateDialog()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import {Message} from "element-ui";

export default {
  name: "AgcRate",
  props: {rmenus:Array,redio:Object},
  data(){
    return{
      permissions: {},
      DICKV:{},
      proRateloading:false,
      agcRateDialog:false,
      agcRate:{},
      agcRateRules :{
        feeRate:[{required: true, message: '', trigger: 'blur'}],
        creditRate:[{required: true, message: '', trigger: 'blur'}],
        feePen:[{required: true, message: '', trigger: 'blur'}],
        feeCycle:[{required: true, message: '', trigger: 'blur'}],
        lowLimit:[{required: true, message: '', trigger: 'blur'}],
        topLimit:[{required: true, message: '', trigger: 'blur'}],
        lowLimitCredit:[{required: true, message: '', trigger: 'blur'}],
        topLimitCredit:[{required: true, message: '', trigger: 'blur'}]
      },
    }
  },mounted() {
    this.DICKV=this.$store.state.DICKV;
    this.permissions = this.$store.state.permissions;
  },
  methods:{
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = this.DICKV[key][i].v; //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    showEditProRate(row){
      this.agcRateDialog=true
      this.agcRate=row
    },
    closeAgcRateDialog(){
      this.$confirm('确认取消？')
          .then(() => {
            this.agcRateDialog=false
            this.agcRate={}
          })
          .catch(() => {
          });
    },
    submitAgcRateForm(){
      let Rate=this.agcRate
      if (Rate.lowLimit>Rate.topLimit){
        Message.error("借记区间设置错误："+Rate.lowLimit+"--"+Rate.topLimit)
        return false;
      }
      if (Rate.lowLimitCredit>Rate.topLimitCredit){
        Message.error("贷记区间设置错误："+Rate.lowLimitCredit+"--"+Rate.topLimitCredit)
        return false;
      }
      this.$refs.agcRateForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/agency/agcRate', this.agcRate).then(data => {
            if (data.status == 200) {
              Message.success("更新成功")
              this.agcRateDialog=false
              this.agcRate={}
              this.edit_rate()
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
