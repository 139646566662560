<template>
  <div class="MerBracnch">
<!--    <el-button type="primary" size="mini" @click="AddBranch">添加门店信息</el-button>-->
    <el-table highlight-current-row element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)" :data="datas" style="width: 100%">
      <el-table-column width="50" show-overflow-tooltip label="ID" prop="id" ></el-table-column>
      <el-table-column width="50" show-overflow-tooltip label="门店编号" prop="branchNo" ></el-table-column>
      <el-table-column width="120" show-overflow-tooltip label="门店名称" prop="branchName" ></el-table-column>
      <el-table-column width="80" show-overflow-tooltip label="状态" prop="status" >
        <template slot-scope="scope" >
          {{ getDICKV(scope.row.status, 'product_status') }}
        </template>
      </el-table-column>
      <el-table-column width="100" show-overflow-tooltip label="门店联系人" prop="contactPerson" ></el-table-column>
      <el-table-column width="120" show-overflow-tooltip label="门店联系人电话" prop="contactPersonPhone" ></el-table-column>
      <el-table-column width="50" show-overflow-tooltip label="打印联数" prop="printNum" ></el-table-column>
      <el-table-column width="50" show-overflow-tooltip label="门店终端数" prop="termNum" ></el-table-column>
      <el-table-column width="150" show-overflow-tooltip label="门店地址" prop="branchAddress" ></el-table-column>
      <el-table-column label="操作" fixed="right" width="180" v-if="permissions.PUT||permissions.DELETE"  >
        <template slot-scope="scope" >
<!--          <el-button type="primary" size="mini" v-if="permissions.GET"    @click="eduitBranchRow(scope.row)">编辑</el-button>-->
<!--          <el-button type="danger"  size="mini" v-if="permissions.DELETE" @click="deleteBranchRow(scope.row)">移除 </el-button>-->
<!--          <el-button type="warning" size="mini" v-if="permissions.GET"    @click="eduitProRow(scope.row)" >门店产品配置</el-button>-->
          <el-button type="success" size="mini" v-if="permissions.GET"    @click="eduitTermRow(scope.row)">绑定终端</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block" style="flex: 1">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="sppm.page.page" :page-sizes="[10, 20, 50, 100]"
                     :page-size="sppm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="sppm.page.total" style="text-align:center;margin-top: 5px;margin-bottom: 5px">
      </el-pagination>
    </div>

    <el-dialog :close-on-click-modal="false" :before-close="close" title="添加" :visible.sync="addDialog" width="50%" append-to-body>
      <el-form :model="rowData" :rules="rules" ref="addForm" label-width="100px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="门店编号" prop="branchNo">
              <el-input autocomplete="off" type="text" v-model="rowData.branchNo" placeholder="请输入门店编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店名称" prop="branchName">
              <el-input autocomplete="off" type="text" v-model="rowData.branchName" placeholder="请输入门店名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店地址" prop="branchAddress">
              <el-input autocomplete="off" type="text" v-model="rowData.branchAddress" placeholder="请输入门店地址"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店联系人" prop="contactPerson">
              <el-input autocomplete="off" type="text" v-model="rowData.contactPerson" placeholder="请输入门店联系人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店联系人电话" prop="contactPersonPhone">
              <el-input autocomplete="off" type="text" v-model="rowData.contactPersonPhone" placeholder="请输入门店联系人电话"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所在区" prop="districtCode">
              <el-input autocomplete="off" type="text" v-model="rowData.districtCode" placeholder="请输入所在区"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="打印联数" prop="printNum">
              <el-input autocomplete="off" type="text" v-model="rowData.printNum" placeholder="请输入打印联数"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店终端数" prop="termNum">
              <el-input autocomplete="off" type="text" v-model="rowData.termNum" placeholder="请输入门店终端数"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="所在区名称" prop="districtName">
              <el-input autocomplete="off" type="text" v-model="rowData.districtName" placeholder="请输入所在区名称"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="状态" prop="status">
              <el-select  v-model="rowData.status"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitAddForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :before-close="close" title="编辑" :visible.sync="editDialog" :rules="rules" width="50%" append-to-body>
      <el-form :model="rowData" :rules="rules" ref="editForm" label-width="150px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="门店编号" prop="branchNo">
              <el-input autocomplete="off" type="text" v-model="rowData.branchNo" placeholder="请输入门店编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店名称" prop="branchName">
              <el-input autocomplete="off" type="text" v-model="rowData.branchName" placeholder="请输入门店名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店地址" prop="branchAddress">
              <el-input autocomplete="off" type="text" v-model="rowData.branchAddress" placeholder="请输入门店地址"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店联系人" prop="contactPerson">
              <el-input autocomplete="off" type="text" v-model="rowData.contactPerson" placeholder="请输入门店联系人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店联系人电话" prop="contactPersonPhone">
              <el-input autocomplete="off" type="text" v-model="rowData.contactPersonPhone" placeholder="请输入门店联系人电话"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所在区" prop="districtCode">
              <el-input autocomplete="off" type="text" v-model="rowData.districtCode" placeholder="请输入所在区"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="打印联数" prop="printNum">
              <el-input autocomplete="off" type="text" v-model="rowData.printNum" placeholder="请输入打印联数"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="门店终端数" prop="termNum">
              <el-input autocomplete="off" type="text" v-model="rowData.termNum" placeholder="请输入门店终端数"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="添加时间" prop="addTime">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.addTime" placeholder="请输入添加时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="最后修改时间" prop="lastTime">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.lastTime" placeholder="请输入最后修改时间"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="最后修改人" prop="lastMan">
              <el-input autocomplete="off" type="text" disabled v-model="rowData.lastMan" placeholder="请输入最后修改人"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="所在区名称" prop="districtName">
              <el-input autocomplete="off" type="text" v-model="rowData.districtName" placeholder="请输入所在区名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="状态" prop="status">
              <el-select  v-model="rowData.status"  placeholder="请选择">
                <el-option
                    v-for="item in DICKV.product_status"
                    :key="item.k"
                    :label="item.k"
                    :value="Number.parseInt(item.v)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitEditForm">提交</el-button>
              <el-button @click="close()">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog :close-on-click-modal="false"
               v-loading="merProCloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="merProCDialog"
               width="50%" append-to-body>
      <template slot='title'>
        <span style="font-size:20px;">产品控制配置</span>
      </template>
      <MerProC ref="MTPC"></MerProC>
    </el-dialog>

    <!--门店绑定终端-->
    <el-dialog :close-on-click-modal="false"
               v-loading="merTermloading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :before-close="close" title="" :visible.sync="merTermDialog"
               width="50%" append-to-body>
      <template slot='title'>
        <span style="font-size:20px;">门店终端管理</span>
      </template>
      <MerTerm ref="merterm" ></MerTerm>
    </el-dialog>



  </div>
</template>

<script>
import {Message} from "element-ui";
import MerProC from "./MerProC";
import MerTerm from "./MerTerm";


export default {
  name: "MerBracnch",
  components: {MerProC,MerTerm},
  data() {
    return {
      loading:false,
      permissions: {},
      DICKV:{},
      datas:[],
      rowData:{},
      addDialog:false,
      editDialog:false,
      agencyNo:'',
      rules: {

      },
      sppm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 10,
          pages: 1,
          total: 0
        }
      },
      merProCloading:false,
      merProCDialog:false,

      //门店绑定
      merTermDialog:false,
      merTermloading:false

    }
  },mounted(){
    this.DICKV=this.$store.state.DICKV;
    this.permissions = this.$store.state.permissions;
    this.mount()
  },
  methods : {
    mount(){
      this.$on('init',(res)=>{
        this.sppm.pm.merId=res
        this.init()
      })
    },
    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = parseInt(this.DICKV[key][i].v); //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    init() {
      if (this.permissions.GET) {
        this.getRequest('/sys/merch/merBranch', this.sppm).then(data => {
          if (data.status == 200) {
            this.datas = data.obj.list
            this.sppm.page = data.obj.page
          } else {
            Message.error({message: data.msg + '(' + data.status + ')'})
          }
        })
      } else {
        Message.error({message: '无查询权限'})
      }
    },
    handleSizeChange(val) {
      this.sppm.page.pageNum = 1
      this.sppm.page.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.sppm.page.pageNum = val
      this.init()
    },
    showAddView(){
      this.addDialog=true;
    },
    submitAddForm() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.rowData.relationId=this.sppm.pm.merId
          this.postRequest('/sys/merch/merBranch', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("添加成功")
              this.addDialog = false
              this.rowData={}
              this.init();
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
    showEditView(rowData) {
      this.rowData = {}
      this.rowData = rowData
      this.editDialog = true
    },
    submitEditForm() {
      this.rowData.relationId=this.sppm.pm.relationId;
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/merch/merBranch', this.rowData).then(data => {
            if (data.status == 200) {
              Message.success("修改成功")
              this.editDialog = false
              this.rowData={}
              this.init()
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})

            }
          })
        } else {
          return false;
        }
      })
    },
    close() {

    this.merProCDialog=false
    this.merTermDialog=false

    this.editDialog = false
    this.addDialog = false
    this.merProCDialog=false
    this.rowData = {}
    this.init()

    },
    edit_merPro(row){
      this.merProCDialog=true
      this.$nextTick(()=>{
        this.$refs.MTPC.$emit('initProC', row)
      })
    },
    //门店绑定终端
    eduitTermRow(row){
      row.htmlFromType="ediut"
      this.merTermDialog=true
      this.$nextTick(() => {
        this.$refs.merterm.$emit('init', row)
      })
    },
  },
}
</script>

<style scoped>

</style>
