<template>
  <!-- 封装 导航栏 动态循环  element多层导航菜单-->
  <div class="sidebar">
    <el-menu>
    <template v-for="(item,index) in list">
      <!-- 标题 -->
        <el-submenu v-if="item.children.length>0&&item.parentId>0" :key="index" :data="item" :index="item.name">
          <template slot="title" >
            <!-- 图标 -->
            <i :class="item.iconCls"></i>
            <span :index="item.path">{{item.name}}</span>
          </template>
          <!-- 递归循环 -->
          <el-menu-item-group>
            <VModel :list="item.children" ></VModel>
          </el-menu-item-group>
        </el-submenu>
      <!-- 选项 -->
      <template v-else>
        <el-menu-item v-if="item.children.length==0&&item.parentId==0" :key="index" :data="item" :index="item.name">
          <!-- 图标 -->
          <i :class="item.iconCls"></i>
          <span :index="item.path" >{{item.name}}</span>
        </el-menu-item>
      </template>

    </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "VModel",
  props: {
    list: Array
  },
  components: {

  },
  methods:{
    topash(path){
      console.log(path)
    }
  }

};
</script>
<style>


</style>

