<template>
<div class="MerTmpEduit">
      <div style="display: flex;justify-content: flex-start;"  >
        <el-steps :active="index" finish-status="success" direction="vertical">
          <el-step title="基本信息" size="mini"></el-step>
          <el-step title="结算信息"></el-step>
          <el-step title="费率信息"></el-step>
          <el-step title="门店信息"></el-step>
          <el-step title="证件图片信息"></el-step>
          <el-step title="其他信息"></el-step>
        </el-steps>
        <div style="margin-left: 50px;display: flex;justify-content: center;align-items: center;width: 100%;">
          <div v-show="index==0">
            <el-form :model="rowData" :rules="rules" ref="addForm0" >
              <el-divider content-position="left">基本信息</el-divider>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户名称" prop="merName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merName" placeholder="商户名称" v-compare:merName="updaterowData"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商户简称" prop="shortName">
                    <el-input autocomplete="off" type="text" v-model="rowData.shortName" placeholder="商户简称" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="客服电话" prop="servicePhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.servicePhone" placeholder="客服电话"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="所属行业" prop="mccidName">
                    <el-select v-model="rowData.mccidName" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="mccloading" @change="changeMcc($event)">
                      <el-option v-for="item in mccoptions" :key="item.value" :label="item.label" :value="item" >
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户类型" prop="merType">
                    <el-select v-model="rowData.merType" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.mer_type"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商户来源" prop="merSource">
                    <el-select v-model="rowData.merSource" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.mer_source"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="所属代理商" prop="belongAgencyNo">
                    <el-select v-model="rowData.belongAgencyNo" placeholder="请选择"  @change="initSaPeAndMer(1)">
                      <el-option
                          v-for="item in allAgency"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                      </el-option>
                    </el-select>


                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商户负责人" prop="contactPerson">
                    <el-input autocomplete="off" type="text" v-model="rowData.contactPerson" placeholder="商户负责人"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户负责人电话" prop="contactPhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.contactPhone" placeholder="商户负责人电话"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="拓展人" prop="expandMan">
                    <el-select v-model="rowData.expandMan" placeholder="请选择" @change="initCnapsDOM()">
                      <el-option
                          v-for="item in saleperoptions"
                          :key="item.value"
                          :label="item.value"
                          :value="item.value">
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>


                <el-col :span="6">
                  <el-form-item label="业务联系人" prop="businessContactName">
                    <el-input autocomplete="off" type="text" v-model="rowData.businessContactName" placeholder="业务联系人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="业务联系人电话" prop="businessContactPhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.businessContactPhone" placeholder="业务联系人电话"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="业务场景" prop="businessFlag">
                    <el-select v-model="rowData.businessFlag" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.business_flag"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="无纸化标识" prop="agreement">
                    <el-select v-model="rowData.agreement" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.agreement"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="受益人标识" prop="bnfDetail">
                    <el-select v-model="rowData.bnfDetail" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.bnf_detail"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="结算方式" prop="clearMode">
                    <el-select v-model="rowData.clearMode" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.clear_mode"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="商户标识" prop="merFlag">
                    <el-select v-model="rowData.merFlag" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.mer_flag"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="所属父客户" prop="parentId">
                    <el-select v-model="rowData.parentId" clearable placeholder="请选择" @change="initCnapsDOM()">
                      <el-option
                          v-for="item in meroptions"
                          :key="item.value"
                          :label="item.value"
                          :value="item.value">
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="注册地址" prop="address">
                    <el-cascader
                        size="large"
                        :options="areoptions"
                        v-model="rowData.addressCode"
                        @change="areaHandleChange">
                    </el-cascader>
                  </el-form-item>
                  <el-input autocomplete="off" type="text" v-model="rowData.address" style="display: none" ></el-input>
                  <el-input autocomplete="off" type="text" v-model="rowData.addressCode" style="display: none" ></el-input>
                </el-col>
              </el-row>
              <el-divider content-position="left">法人信息</el-divider>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="法人姓名" prop="legalName">
                    <el-input autocomplete="off" type="text" v-model="rowData.legalName" placeholder="法人姓名"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="证件类型" prop="legalIdType">
                    <el-select v-model="rowData.legalIdType" placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.legal_id_type"
                          :key="item.k"
                          :label="item.k"
                          :value="item.v"
                          :disabled="item.disabled=='1'?false:true">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="证件号" prop="legalIdNo">
                    <el-input autocomplete="off" type="text" v-model="rowData.legalIdNo" placeholder="法人证件号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="证件有效期" prop="legalIdExpire">
                    <el-date-picker
                        v-model="rowData.legalIdExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="长期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="法人手机号" prop="legalPhone">
                    <el-input autocomplete="off" type="text" v-model="rowData.legalPhone" placeholder="法人手机号"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div v-show="index==1" >
            <el-form :model="rowData.settleInfoTmp" :rules="rules" ref="addForm1" >
              <el-divider content-position="left">结算账户信息</el-divider>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="卡折类型" prop="acctNoType">
                    <el-select  v-model="rowData.settleInfoTmp.acctNoType"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.acct_no_type"
                          :key="item.k"
                          :label="item.k"
                          :value="item.v">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="账户类型" prop="acctType">
                    <el-select  v-model="rowData.settleInfoTmp.acctType"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.acct_type"
                          :key="item.k"
                          :label="item.k"
                          :value="item.v">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="账户名" prop="acctName">
                    <el-input autocomplete="off"  type="text" v-model="rowData.settleInfoTmp.acctName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="账户号"  prop="acctId">
                    <el-input autocomplete="off"  type="text" v-model="rowData.settleInfoTmp.acctId" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="渠道电子账户号" prop="chanBankNo">
                    <el-input autocomplete="off" type="text" v-model="rowData.settleInfoTmp.chanBankNo" placeholder="渠道电子账户号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="rowData.settleInfoTmp.acctType==0">
                  <el-form-item label="结算人身份证号" prop="settlerIdNo"  :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.settleInfoTmp.settlerIdNo" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-else>
                  <el-form-item label="结算人身份证号" prop="settlerIdNo" >
                    <el-input autocomplete="off" type="text" v-model="rowData.settleInfoTmp.settlerIdNo" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="所属总行" prop="bankCode">
                    <el-select  v-model="rowData.settleInfoTmp.bankCode" filterable @change="initCity()" >
                      <el-option
                          v-for="item in bankoptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="所属市区" prop="accresCode">
                    <el-select  v-model="rowData.settleInfoTmp.accresCode" filterable @change="initCnaps()">
                      <el-option
                          v-for="item in bankcityoptions"
                          :key="item.label"
                          :label="item.value"
                          :value="item.value">
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="所属支行" prop="cnapsCode">
                    <el-select  v-model="rowData.settleInfoTmp.cnapsCode" filterable @change="initCnapsDOM()">
                      <el-option
                          v-for="item in bankcnapsoptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        <span style="float: left">{{ item.value }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div v-show="index==2" >
            <el-table :data="rmenus" row-key="id" border :tree-props="{children: 'children',hasChildren:'hasChildren'}" style="width: 700px">
              <el-table-column width="150" prop="sysTradeTypeId" label="产品类型"></el-table-column>
              <el-table-column prop="sysTradeTypeDescribe" label="交易描述"></el-table-column>
              <el-table-column prop="productStatus" label="状态">
                <template slot-scope="scope" >
                  {{ getDICKV(scope.row.productStatus, 'product_status') }}
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.level!=0"
                             size="mini" type="primary" @click="showEditProRate(scope.row)">编辑 </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-dialog :close-on-click-modal="false"
                       v-loading="merproRateloading"
                       element-loading-text="拼命加载中"
                       element-loading-spinner="el-icon-loading"
                       element-loading-background="rgba(0, 0, 0, 0.8)"
                       :before-close="closeMerRateDialog" title="" :visible.sync="merRateDialog"
                       width="37%" append-to-body>
              <template slot='title'>
                <span style="font-size:20px;">费率信息配置--{{merRate.sysTradeTypeDescribe}}</span>
              </template>
              <el-form :model="merRate" :rules="merRateRules" ref="merRateForm" label-width="180px">
                <el-row>
                  <el-col :span="15">
                    <el-form-item prop="feeRate" label="借记卡费率(‰)">
                      <el-input-number size="medium" v-model="merRate.feeRate" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                    </el-form-item>
                    <el-form-item prop="creditRate" label="贷记卡费率(‰)"  v-if="merRate.dcFlag==1">
                      <el-input-number size="medium" v-model="merRate.creditRate" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15" v-if="merRate.sysTradeTypeId=='JSP534'">
                    <el-form-item prop="feePen" label="元/笔" >
                      <el-input-number size="medium" v-model="merRate.feePen" :precision="0" :step="1" :min="0" :max="100"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15" v-if="merRate.limitFlag==1">
                    <el-form-item prop="lowLimit" label="借记单笔保底">
                      <el-input-number size="medium" v-model="merRate.lowLimit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                    </el-form-item>
                    <el-form-item prop="topLimit" label="借记单笔封顶">
                      <el-input-number size="medium" v-model="merRate.topLimit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15" v-if="false">
                    <el-form-item label="贷记单笔保底" prop="lowLimitCredit">
                      <el-input-number size="medium" v-model="merRate.lowLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                    </el-form-item>
                    <el-form-item label="贷记单笔封顶" prop="topLimitCredit">
                      <el-input-number size="medium" v-model="merRate.topLimitCredit" :precision="1" :step="0.1" :min="0.0" :max="100.0"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="15" >
                    <el-form-item prop="feeCycle" label="收费周期">
                      <el-select  v-model="merRate.feeCycle"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.fee_cycle"
                            :key="item.k"
                            :label="item.k"
                            :value="item.v"
                            :disabled="item.disabled=='1'?false:true">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="20">
                    <el-form-item>
                      <template>
                        <el-button type="primary"  @click="submitMerRateForm">提交</el-button>
                      </template>
                      <el-button @click="closeMerRateDialog()">取消</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-dialog>
          </div>
          <div v-show="index==3">
            <el-button type="primary" size="mini" @click="AddBranch">添加门店信息</el-button>
            <el-table highlight-current-row element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.8)" :data="rowData.merBranchInfoTmp" style="width: 100%">
              <el-table-column width="50" show-overflow-tooltip label="ID" prop="id" ></el-table-column>
              <el-table-column width="50" show-overflow-tooltip label="门店编号" prop="branchNo" ></el-table-column>
              <el-table-column width="120" show-overflow-tooltip label="门店名称" prop="branchName" ></el-table-column>
              <el-table-column width="80" show-overflow-tooltip label="状态" prop="status" >
                <template slot-scope="scope" >
                  {{ getDICKV(scope.row.status, 'product_status') }}
                </template>
              </el-table-column>
              <el-table-column width="100" show-overflow-tooltip label="门店联系人" prop="contactPerson" ></el-table-column>
              <el-table-column width="90" show-overflow-tooltip label="门店联系人电话" prop="contactPersonPhone" ></el-table-column>
              <el-table-column width="50" show-overflow-tooltip label="打印联数" prop="printNum" ></el-table-column>
              <el-table-column width="50" show-overflow-tooltip label="门店终端数" prop="termNum" ></el-table-column>
              <el-table-column width="120" show-overflow-tooltip label="门店地址" prop="branchAddress" ></el-table-column>
              <el-table-column label="操作" fixed="right" width="350" v-if="permissions.PUT||permissions.DELETE"  >
                <template slot-scope="scope" >
                  <el-button type="primary" size="mini" v-if="permissions.GET"    @click="eduitBranchRow(scope.row)">编辑</el-button>
                  <el-button type="danger"  size="mini" v-if="permissions.DELETE" @click="deleteBranchRow(scope.row)">移除 </el-button>
                  <el-button type="warning" size="mini" v-if="permissions.GET"    @click="eduitProRow(scope.row)" >门店产品配置</el-button>
                  <el-button type="success" size="mini" v-if="permissions.GET"    @click="eduitTermRow(scope.row)">绑定终端</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="block" style="flex: 1">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pm.page.page" :page-sizes="[10, 20, 50, 100]"
                             :page-size="pm.page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pm.page.total" style="text-align:center;margin-top: 5px;margin-bottom: 5px">
              </el-pagination>
            </div>
            <el-dialog :close-on-click-modal="false" :before-close="closeBranchDia" title="添加门店" :visible.sync="merBranchDialog" width="50%" append-to-body>
              <el-form :model="rowBranch" :rules="rules" ref="addBranchForm" label-width="100px">
                <el-row>
                  <el-col :span="10">
                    <el-form-item label="门店名称" prop="branchName">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.branchName" placeholder="请输入门店名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="状态" prop="status">
                      <el-select  v-model="rowBranch.status"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.product_status"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)"
                            :disabled="item.disabled=='1'?false:true">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="打印联数" prop="printNum">
                      <el-input-number size="medium" v-model="rowBranch.printNum" :precision="0" :step="0" :min="0" :max="0"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店终端数" prop="termNum">
                      <el-input-number size="medium" v-model="rowBranch.termNum" :precision="0" :step="0" :min="0" :max="0"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店联系人" prop="contactPerson">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.contactPerson" placeholder="请输入门店联系人"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="联系人电话" prop="contactPersonPhone">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.contactPersonPhone" placeholder="请输入门店联系人电话"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店所在地区" prop="districtCode">
                      <el-cascader
                          size="large"
                          :options="areoptions"
                          v-model="rowBranch.districtCode"
                          @change="branchAreaHandleChange">
                      </el-cascader>
                    </el-form-item>
                    <el-input autocomplete="off" type="text" v-model="rowBranch.districtName" style="display: none" ></el-input>
                    <el-input autocomplete="off" type="text" v-model="rowBranch.districtCode" style="display: none" ></el-input>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店详细地址" prop="branchAddress">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.branchAddress" placeholder="请输入门店详细地址"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="20">
                    <el-form-item>
                      <el-button type="primary" @click="submitAddBranchForm">提交</el-button>
                      <el-button @click="closeBranchDia()">取消</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-dialog>

            <el-dialog :close-on-click-modal="false" :before-close="closeBranchDia" title="编辑门店" :visible.sync="eduitBranchDialog" width="50%" append-to-body>
              <el-form :model="rowBranch" :rules="rules" ref="eduitBranchForm" label-width="100px">
                <el-row>
                  <el-col :span="10">
                    <el-form-item label="门店名称" prop="branchName">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.branchName" placeholder="请输入门店名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="状态" prop="status">
                      <el-select  v-model="rowBranch.status"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.product_status"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)"
                            :disabled="item.disabled=='1'?false:true">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="打印联数" prop="printNum">
                      <el-input-number size="medium" v-model="rowBranch.printNum" :precision="0" :step="1" :min="1" :max="99"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店终端数" prop="termNum">
                      <el-input-number size="medium" v-model="rowBranch.termNum" :precision="0" :step="1" :min="1" :max="99"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店联系人" prop="contactPerson">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.contactPerson" placeholder="请输入门店联系人"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="联系人电话" prop="contactPersonPhone">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.contactPersonPhone" placeholder="请输入门店联系人电话"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店所在地区" prop="districtCode">
                      <el-cascader
                          size="large"
                          :options="areoptions"
                          v-model="rowBranch.districtCode"
                          @change="branchAreaHandleChange">
                      </el-cascader>
                    </el-form-item>
                    <el-input autocomplete="off" type="text" v-model="rowBranch.districtName" style="display: none" ></el-input>
                    <el-input autocomplete="off" type="text" v-model="rowBranch.districtCode" style="display: none" ></el-input>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="门店详细地址" prop="branchAddress">
                      <el-input autocomplete="off" type="text" v-model="rowBranch.branchAddress" placeholder="请输入门店详细地址"></el-input>
                    </el-form-item>
                  </el-col>


                </el-row>
                <el-row>
                  <el-col :span="20">
                    <el-form-item>
                      <el-button type="primary" @click="submitEduitBranchForm">提交</el-button>
                      <el-button @click="closeBranchDia()">取消</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-dialog>

            <!--门店绑定终端-->
            <el-dialog :close-on-click-modal="false"
                       v-loading="merTermloading"
                       element-loading-text="拼命加载中"
                       element-loading-spinner="el-icon-loading"
                       element-loading-background="rgba(0, 0, 0, 0.8)"
                       :before-close="close" title="" :visible.sync="merTermDialog"
                       width="50%" append-to-body>
              <template slot='title'>
                <span style="font-size:20px;">门店终端管理</span>
              </template>
              <MerTmpTerm ref="merterm" ></MerTmpTerm>
            </el-dialog>

          </div>
          <div v-show="index==4">
            <el-form :model="rowData" :rules="rules" ref="addForm4" >
              <el-row :gutter="24">
                <el-col :span="4">
                  <span style="line-height: 20px">营业执照照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[0]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[0])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[0].imageUrl" :src="IMGList[0].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">法人身份证正面照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[1]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[1])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[1].imageUrl" :src="IMGList[1].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">法人身份证反面照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[2]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[2])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[2].imageUrl" :src="IMGList[2].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>

                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">经营者手持身份证照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[3]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[3])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[3].imageUrl" :src="IMGList[3].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>

                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">商户门头照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[4]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[4])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[4].imageUrl" :src="IMGList[4].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">经营内景照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[5]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[5])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[5].imageUrl" :src="IMGList[5].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="经营内景照片" prop="picPath" hidden>
                    <el-input v-model="IMGList[5].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="4">
                  <span style="line-height: 20px">经营场所证明文件</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[6]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[6])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[6].imageUrl" :src="IMGList[6].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>

                <el-col :span="4">
                  <span style="line-height: 20px">其他审核材料</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[7]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[7])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[7].imageUrl" :src="IMGList[7].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">银行卡正面照</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[8]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[8])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[8].imageUrl" :src="IMGList[8].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="银行卡正面照" prop="picPath" hidden>
                    <el-input v-model="IMGList[8].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">开户许可证照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[9]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[9])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[9].imageUrl" :src="IMGList[9].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="开户许可证照片" prop="picPath" hidden>
                    <el-input v-model="IMGList[9].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">纸质协议首页</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[10]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[10])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[10].imageUrl" :src="IMGList[10].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="纸质协议首页" prop="picPath" hidden>
                    <el-input v-model="IMGList[10].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">纸质协议尾页</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[11]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[11])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[11].imageUrl" :src="IMGList[11].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="纸质协议尾页" prop="picPath" hidden>
                    <el-input v-model="IMGList[11].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="4">

                  <!--
                                  M:经营者与店铺门头合照
                                  N:组织机构代码证照片
                                  O:注册登记表
                                  P:签字授权书
                                  Q:事业单位法人证书
                                  R:结算账户授权书-->

                  <span style="line-height: 20px">经营者与店铺门头合照</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[12]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[12])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[12].imageUrl" :src="IMGList[12].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>

                <el-col :span="4">
                  <span style="line-height: 20px">组织机构代码证照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[13]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[13])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[13].imageUrl" :src="IMGList[13].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">注册登记表</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[14]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[14])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[14].imageUrl" :src="IMGList[14].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="注册登记表" prop="picPath" hidden>
                    <el-input v-model="IMGList[14].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">签字授权书</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[15]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[15])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[15].imageUrl" :src="IMGList[15].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="签字授权书" prop="picPath" hidden>
                    <el-input v-model="IMGList[15].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">事业单位法人证书</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[16]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[16])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[16].imageUrl" :src="IMGList[16].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="事业单位法人证书" prop="picPath" hidden>
                    <el-input v-model="IMGList[16].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span style="line-height: 20px">结算账户授权书</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data='IMGList[17]'
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[17])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[17].imageUrl" :src="IMGList[17].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="结算账户授权书" prop="picPath" hidden>
                    <el-input v-model="IMGList[17].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="4">
                  <!--  S:税务登记证照片 T:其他审核材料-->
                  <span style="line-height: 20px">税务登记证照片</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[18]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[18])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[18].imageUrl" :src="IMGList[18].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>

                <el-col :span="4">
                  <span style="line-height: 20px">其他审核材料</span>
                  <!--不使用action 符号#   用http-request实现自定义上传  :http-request="httpRequest"  :on-change="handleChange"-->
                  <el-upload
                      class="avatar-uploader"
                      action="lei"
                      :data="IMGList[19]"
                      :on-change="(file, fileList) => {handleChange(file, fileList, IMGList[19])}"
                      :show-file-list="false"
                      :http-request="httpRequest"><!--覆盖默认上传-->
                    <img v-if="IMGList[19].imageUrl" :src="IMGList[19].imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-form-item label="其他审核材料" prop="picPath" hidden>
                    <el-input v-model="IMGList[19].picPath" clearable></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
            </el-form>

          </div>
          <div v-show="index==5" >
            <el-form :model="rowData.merInfoOtherTmp" ref="addForm5" label-position="left" >
<!--              <el-row :gutter="24">-->
<!--                <el-col :span="6">-->
<!--                  <el-form-item label="商户类型" prop="merType">-->
<!--                    <el-select v-model="rowData.merType" placeholder="请选择" @change="handleMTChange()">-->
<!--                      <el-option-->
<!--                          v-for="item in DICKV.mer_type"-->
<!--                          :key="item.k"-->
<!--                          :label="item.k"-->
<!--                          :value="Number.parseInt(item.v)"-->
<!--                          :disabled="item.disabled=='1'?false:true">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--              </el-row>-->
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item v-bind:label="'营业执照名称'" v-if="rowData.merType!=4" prop="corpBusName" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.corpBusName" placeholder="营业执照名称"></el-input>
                  </el-form-item>
                  <el-form-item v-bind:label="'营业执照名称'" v-else prop="corpBusName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.corpBusName" placeholder="营业执照名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item v-bind:label="'统一社会信用代码证'" v-if="rowData.merType!=4" prop="creditCode" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.creditCode" placeholder="统一社会信用代码证"></el-input>
                  </el-form-item>
                  <el-form-item v-bind:label="'统一社会信用代码证'" v-else  prop="creditCode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.creditCode" placeholder="统一社会信用代码证"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="社会信用代码证有效期" v-if="rowData.merType!=4" prop="creditCodeExpire" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-date-picker
                        v-model="rowData.merInfoOtherTmp.creditCodeExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="社会信用代码证有效期" v-else prop="creditCodeExpire">
                    <el-date-picker
                        v-model="rowData.merInfoOtherTmp.creditCodeExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="控股股东姓名" v-if="rowData.merType==1 || rowData.merType==3" prop="holderName" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.holderName" placeholder="控股股东姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="控股股东姓名" v-else prop="holderName" >
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.holderName" placeholder="控股股东姓名"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="控股股东身份证" v-if="rowData.merType==1 || rowData.merType==3" prop="holderIdNo" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.holderIdNo" placeholder="控股股东身份证"></el-input>
                  </el-form-item>
                  <el-form-item label="控股股东身份证" v-else prop="holderIdNo" >
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.holderIdNo" placeholder="控股股东身份证"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="控股股东有效期" v-if="rowData.merType==1 || rowData.merType==3" prop="holderExpire" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-date-picker
                        v-model="rowData.merInfoOtherTmp.holderExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="控股股东有效期" v-else prop="holderExpire" >
                    <el-date-picker
                        v-model="rowData.merInfoOtherTmp.holderExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="注册资本" v-if="rowData.merType==1 || rowData.merType==3" prop="registerFund" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-select  v-model="rowData.merInfoOtherTmp.registerFund"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.register_fund"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>

                  </el-form-item>
                  <el-form-item label="注册资本" v-else prop="registerFund" >
                    <el-select  v-model="rowData.merInfoOtherTmp.registerFund"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.register_fund"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>

                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="员工人数" v-if="rowData.merType==1 || rowData.merType==3" prop="stafftotal" :rules="[{required: true, message: '必填', trigger: 'blur'}]" >
                    <el-select  v-model="rowData.merInfoOtherTmp.stafftotal"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.stafftotal"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="员工人数" v-else prop="stafftotal"  >
                    <el-select  v-model="rowData.merInfoOtherTmp.stafftotal"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.stafftotal"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="经营区域" v-if="rowData.merType==1 || rowData.merType==3" prop="operateLimit" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-select  v-model="rowData.merInfoOtherTmp.operateLimit"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.operate_limit"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="经营区域" v-else prop="operateLimit" >
                    <el-select  v-model="rowData.merInfoOtherTmp.operateLimit"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.operate_limit"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经营地段" v-if="rowData.merType==1 || rowData.merType==3" prop="inspect" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-select  v-model="rowData.merInfoOtherTmp.inspect"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.inspect"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="经营地段" v-else prop="inspect" >
                    <el-select  v-model="rowData.merInfoOtherTmp.inspect"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.inspect"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否三证合一" prop="thrcertFlag">
                    <el-select  v-model="rowData.merInfoOtherTmp.thrcertFlag"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.thrcert_flag"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="组织机构代码证号" prop="organcode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.organcode" placeholder="组织机构代码证号"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="组织机构代码证有效期" prop="organexpire">
                    <el-date-picker
                        v-model="rowData.merInfoOtherTmp.organexpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="对公账户号" v-if="rowData.merType==1" prop="pubAcctNo" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.pubAcctNo" placeholder="对公账户号"></el-input>
                  </el-form-item>
                  <el-form-item label="对公账户号" v-else prop="pubAcctNo" >
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.pubAcctNo" placeholder="对公账户号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="支付行号" v-if="rowData.merType==1" prop="cnapsCode" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.cnapsCode" placeholder="支付行号"></el-input>
                  </el-form-item>
                  <el-form-item label="支付行号" v-else prop="cnapsCode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.cnapsCode" placeholder="支付行号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="对公账户名称" v-if="rowData.merType==1" prop="pubAcctName" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.pubAcctName" placeholder="对公账户名称"></el-input>
                  </el-form-item>
                  <el-form-item label="对公账户名称" v-else prop="pubAcctName">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.pubAcctName" placeholder="对公账户名称"></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="网站/应用名称" prop="merWebName" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.merWebName" placeholder="网站/应用名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="网站url" prop="merUrl" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.merUrl" placeholder="网站url"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="国籍" prop="nationality" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-select v-model="rowData.merInfoOtherTmp.nationality" placeholder="请选择">
                      <el-option-group
                          v-for="group in country"
                          :key="group.label"
                          :label="group.label">
                        <el-option
                            v-for="item in group.options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.label">
                        </el-option>
                      </el-option-group>
                    </el-select>


                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="性别" prop="sex" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-select  v-model="rowData.merInfoOtherTmp.sex"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.gender"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="职业" prop="occupation" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-select  v-model="rowData.merInfoOtherTmp.occupation"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.occupation"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="住址" prop="personaddr" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.personaddr" placeholder="住址"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-if="rowData.bnfDetail == 1" >
                <el-divider content-position="left">受益人信息</el-divider>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="受益人姓名" prop="bnfName" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfName"  placeholder="受益人姓名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人性别" prop="bnfSex" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-select  v-model="rowData.merInfoOtherTmp.bnfSex"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.gender"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人手机号" prop="bnfPhone" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfPhone" placeholder="受益人手机号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人国籍" prop="bnfNationality" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-select v-model="rowData.merInfoOtherTmp.bnfNationality" placeholder="请选择">
                        <el-option-group
                            v-for="group in country"
                            :key="group.label"
                            :label="group.label">
                          <el-option
                              v-for="item in group.options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.label">
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="受益人证件类型" prop="bnfIdType" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-select v-model="rowData.merInfoOtherTmp.bnfIdType" placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.legal_id_type"
                            :key="item.k"
                            :label="item.k"
                            :value="item.v"
                            >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人证件号" prop="bnfIdNo" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfIdNo" placeholder="受益人证件号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人证件有效期" prop="bnfExpire" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-date-picker
                          v-model="rowData.merInfoOtherTmp.bnfExpire"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item label="受益人职业" prop="bnfOccupation" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-select  v-model="rowData.merInfoOtherTmp.bnfOccupation"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.occupation"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人住址" prop="bnfPersonaddr" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.bnfPersonaddr" placeholder="受益人住址"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="受益人职级(是否高管)" prop="bnfFrnmngFlag" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                      <el-select  v-model="rowData.merInfoOtherTmp.bnfFrnmngFlag"  placeholder="请选择">
                        <el-option
                            v-for="item in DICKV.thrcert_flag"
                            :key="item.k"
                            :label="item.k"
                            :value="Number.parseInt(item.v)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="税务登记号码" prop="taxRegCode">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.taxRegCode" placeholder="税务登记号码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="税务登记证日期" prop="taxCodeExpire">
                    <el-date-picker
                        v-model="rowData.merInfoOtherTmp.taxCodeExpire"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经营内容" prop="busDetail" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.busDetail" placeholder="经营内容"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经营场所" prop="businessPlace" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-select  v-model="rowData.merInfoOtherTmp.businessPlace"  placeholder="请选择">
                      <el-option
                          v-for="item in DICKV.business_place"
                          :key="item.k"
                          :label="item.k"
                          :value="Number.parseInt(item.v)">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <el-form-item label="经营地址" prop="busaddressCode" :rules="[{required: true, message: '必填', trigger: 'blur'}]">
                    <el-cascader
                        size="large"
                        :options="areoptions"
                        v-model="rowData.merInfoOtherTmp.busaddressCode"
                        @change="otherAreaHandleChange">
                    </el-cascader>
                  </el-form-item>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.businessAddress" style="display: none" ></el-input>
                  <!--                display: none-->
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.busaddressCode" style="display: none" ></el-input>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.districtCode" style="display: none" ></el-input>
                  <el-input autocomplete="off" type="text" v-model="rowData.merInfoOtherTmp.districtName" style="display: none" ></el-input>
                </el-col>


              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    <div style="display: flex;align-items: center;justify-content: center;padding: 0px;margin: 0px;">
      <el-button round size="mini" v-if="index!=0" @click="index--">上一步</el-button>
      <el-button type="primary" round size="mini" @click="next" v-text="index==5?'保存':'下一步'"></el-button>
      <el-button v-if="index==5" type="primary" round size="mini" @click="eduitAffirm()"  >提交审核</el-button>
    </div>

  <el-dialog :close-on-click-modal="false"
             v-loading="merProCloading"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             :before-close="close" title="" :visible.sync="merProCDialog"
             width="50%" append-to-body>
    <template slot='title'>
      <span style="font-size:20px;">产品控制配置</span>
    </template>
    <MerTmpProC ref="MTPC"></MerTmpProC>
  </el-dialog>
</div>
</template>

<script>
import {Message} from "element-ui";
import MerTmpProC from "./MerTmpProC";
import {regionData,CodeToText} from 'element-china-area-data';
import MerTmpTerm from "./MerTmpTerm";

export default {
  name: "MerTmpEduit",
  components: {MerTmpProC,MerTmpTerm},
  data(){
    return{
      DICKV:{},
      allAgency:{},
      permissions:{},
      rowData:{
        merInfoOtherTmp:{},
        settleInfoTmp:{},
        merBranchInfoTmp:[],
        picList: [],
      },
      updaterowData:{
        merInfoOtherTmp:{},
        settleInfoTmp:{},
        merBranchInfoTmp:[],
        picList: [],
      },
      rules: {
        merName: [{required: true, message: '请输入商户名称', trigger: 'blur'}],
        shortName: [{required: true, message: '请输入商户简称', trigger: 'blur'}],
        servicePhone: [{required: true, message: '请输入客服电话', trigger: 'blur'}],
        mccid: [{required: true, message: '请输入所属行业', trigger: 'blur'}],
        merType: [{required: true, message: '请输入商户类型', trigger: 'blur'}],
        legalName: [{required: true, message: '请输入法人姓名', trigger: 'blur'}],
        legalIdType: [{required: true, message: '请输入法人代表证件类型', trigger: 'blur'}],
        legalIdNo: [{required: true, message: '请输入法人代表证件号', trigger: 'blur'}],
        legalPhone: [{required: true, message: '请输入法人代表手机号', trigger: 'blur'}],
        address: [{required: true, message: '请输入注册地址', trigger: 'blur'}],
        contactPerson: [{required: true, message: '请输入商户负责人', trigger: 'blur'}],
        contactPhone: [{required: true, message: '请输入商户负责人电话', trigger: 'blur'}],
        clearMode: [{required: true, message: '请输入结算方式', trigger: 'blur'}],
        expandMan: [{required: true, message: '请输入拓展人', trigger: 'blur'}],
        businessContactName: [{required: true, message: '请输入业务联系人姓名', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '请输入业务联系人电话', trigger: 'blur'}],
        businessFlag: [{required: true, message: '请输入线上线下业务场景', trigger: 'blur'}],
        agreement: [{required: true, message: '无纸化标识', trigger: 'blur'}],
        bnfDetail: [{required: true, message: '请输入受益人标识0一致（取法人相关信息）1 不一致取不常用表信息', trigger: 'blur'}],
        merFlag: [{required: true, message: '请输入商户标识 ', trigger: 'blur'}],
        merSource: [{required: true, message: '请输入商户来源：1:H5；2:WEB；3:api；', trigger: 'blur'}],
        belongAgencyNo: [{required: true, message: '请选择所属代理商', trigger: 'blur'}],
        //结算信息
        acctId: [{required: true, message: '必填', trigger: 'blur'}],
        acctName: [{required: true, message: '必填', trigger: 'blur'}],
        acctType: [{required: true, message: '必填', trigger: 'blur'}],
        acctNoType: [{required: true, message: '必填', trigger: 'blur'}],
        bankCode: [{required: true, message: '必填', trigger: 'blur'}],
        accresCode: [{required: true, message: '必填', trigger: 'blur'}],
        cnapsCode: [{required: true, message: '必填', trigger: 'blur'}],
        //门店信息
        branchNo: [{required: true, message: '必填', trigger: 'blur'}],
        branchName: [{required: true, message: '必填', trigger: 'blur'}],
        status: [{required: true, message: '必填', trigger: 'blur'}],
        contactPersonPhone: [{required: true, message: '必填', trigger: 'blur'}],
        printNum: [{required: true, message: '必填', trigger: 'blur'}],
        termNum: [{required: true, message: '必填', trigger: 'blur'}],
      },
      index:0,
      /*费率信息*/
      tableRadio:{},
      rmenus:[],
      merRate:{},
      merRateDialog:false,
      merproRateloading:false,
      merRateRules:{
        feeRate:[{required: true, message: '必填', trigger: 'blur'}],
        creditRate:[{required: true, message: '必填', trigger: 'blur'}],
        feePen:[{required: true, message: '必填', trigger: 'blur'}],
        feeCycle:[{required: true, message: '必填', trigger: 'blur'}],
        lowLimit:[{required: true, message: '必填', trigger: 'blur'}],
        topLimit:[{required: true, message: '必填', trigger: 'blur'}],
        lowLimitCredit:[{required: true, message: '必填', trigger: 'blur'}],
        topLimitCredit:[{required: true, message: '必填', trigger: 'blur'}]
      },

      //门店信息
      merBranchDialog:false,
      eduitBranchDialog:false,
      rowBranch:{},
      pm: {
        pm: {},
        page: {
          pageNum: 1,
          pageSize: 10,
          pages: 0,
          total: 0
        }
      },
      merProCDialog:false,
      merProCloading:false,

      /*图片信息*/
      imageUrl:'',
      IMGList: [
        {type:'A',imageUrl:'',tempUrl:''},
        {type:'B',imageUrl:'',tempUrl:''},
        {type:'C',imageUrl:'',tempUrl:''},
        {type:'D',imageUrl:'',tempUrl:''},
        {type:'E',imageUrl:'',tempUrl:''},
        {type:'F',imageUrl:'',tempUrl:''},

        {type:'G',imageUrl:'',tempUrl:''},
        {type:'H',imageUrl:'',tempUrl:''},
        {type:'I',imageUrl:'',tempUrl:''},
        {type:'J',imageUrl:'',tempUrl:''},
        {type:'K',imageUrl:'',tempUrl:''},
        {type:'L',imageUrl:'',tempUrl:''},

        {type:'M',imageUrl:'',tempUrl:''},
        {type:'N',imageUrl:'',tempUrl:''},
        {type:'O',imageUrl:'',tempUrl:''},
        {type:'P',imageUrl:'',tempUrl:''},
        {type:'Q',imageUrl:'',tempUrl:''},
        {type:'R',imageUrl:'',tempUrl:''},
        {type:'S',imageUrl:'',tempUrl:''},
        {type:'T',imageUrl:'',tempUrl:''},
      ],
      dialogImageUrl: '',
      headers: {
        // 'Content-Type': 'multipart/form-data', // 默认值
        'Authorization':window.sessionStorage.getItem("authorization"),
        // 'user':encodeURIComponent(window.sessionStorage.getItem("user")),
      },
      dialogVisible: false,
      fileList: [],

      //mcc
      mccoptions:[{}],
      mccloading:false,
      mcclist:[],

      areoptions: regionData,
      selectedOptions: [],

      //sett_bank
      bankoptions:this.initBank(),
      bankcityoptions:[{}],
      bankcnapsoptions:[{}],

      merTypeRules1:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
        creditCode: [{required: true, message: '社会信用代码证必填', trigger: 'blur'}],
        creditCodeExpire:[{required: true, message: '社会信用代码证有效期必填', trigger: 'blur'}],
        businessContactName: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '业务联系人电话必填', trigger: 'blur'}],
        holderName:[{required: true, message: '控股股东姓名必填', trigger: 'blur'}],
        holderIdNo: [{required: true, message: '控股股东身份证必填', trigger: 'blur'}],
        holderExpire:[{required: true, message: '控股股东有效期必填', trigger: 'blur'}],
        registerFund: [{required: true, message: '注册资本必填', trigger: 'blur'}],
        stafftotal: [{required: true, message: '员工人数必填', trigger: 'blur'}],
        operateLimit: [{required: true, message: '经营区域必填', trigger: 'blur'}],
        inspect: [{required: true, message: '经营地段必填', trigger: 'blur'}],
      },
      merTypeRules3:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
        creditCode: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        creditCodeExpire:[{required: true, message: '社会信用代码证有效期必填', trigger: 'blur'}],
        businessContactName: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '业务联系人电话必填', trigger: 'blur'}],
        holderName:[{required: true, message: '控股股东姓名必填', trigger: 'blur'}],
        holderIdNo: [{required: true, message: '控股股东身份证必填', trigger: 'blur'}],
        holderExpire:[{required: true, message: '控股股东有效期必填', trigger: 'blur'}],
        registerFund: [{required: true, message: '注册资本必填', trigger: 'blur'}],
        stafftotal: [{required: true, message: '员工人数必填', trigger: 'blur'}],
        operateLimit: [{required: true, message: '经营区域必填', trigger: 'blur'}],
        inspect: [{required: true, message: '经营地段必填', trigger: 'blur'}],

      },
      merTypeRules4:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
      },
      merTypeRules6:{
        corpBusName:[{required: true, message: '营业执照名称必填', trigger: 'blur'}],
        creditCode: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        creditCodeExpire:[{required: true, message: '必填', trigger: 'blur'}],
        businessContactName: [{required: true, message: '业务联系人姓名必填', trigger: 'blur'}],
        businessContactPhone: [{required: true, message: '业务联系人电话必填', trigger: 'blur'}],
      },

      //客户经理
      saleperoptions:[{}],
      //所属父商户
      meroptions:[{}],

      //国籍
      country:[{
        label: '热门国家',
        options: [{
          value: '中国',
          label: '中国'
        } ]
      }, {
        label: '其他国家',
        options: [ ]
      }],

      merTermDialog:false,
      merTermloading:false
    }
  },mounted() {
    this.permissions = this.$store.state.permissions;
    this.DICKV=this.$store.state.DICKV;
    this.initAgency();
    this.mount();
  },
  methods:{
    mount(){
      this.$on('init',(res)=>{
        this.tableRadio.id=res
        this.init()
      })
    },
    cleanAll(){
      this.index =0;
      this.rowData={
        merInfoOtherTmp:{bnfNationality:'中国',nationality:'中国'},
        settleInfoTmp:{},
        merBranchInfoTmp:[],
        picList: [],
      }

    },
    init(){
      this.cleanAll();
      this.getRequest("/sys/mertmp/getOneAll", {id:this.tableRadio.id}).then(resp=> {
        let data = resp;
        if (data && data.status == 200) {
          this.rowData=data.obj
          this.updaterowData=data.obj
          // this.IMGList=data.obj.picList
          // this.baseIMG1(this.IMGList);
          this.mccoptions[0].value=data.obj.mccid
          this.mccoptions[0].label=data.obj.mccidName

          this.bankcityoptions[0].value=data.obj.settleInfoTmp.accresCode
          this.bankcityoptions[0].label=data.obj.settleInfoTmp.accresName

          this.bankcnapsoptions[0].value=data.obj.settleInfoTmp.cnapsCode
          this.bankcnapsoptions[0].label=data.obj.settleInfoTmp.cnapsName


        }else{
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
        this.initSaPeAndMer(0);
        this.baseIMG();
      });

    },
    baseIMG(){
      let IMGList=this.rowData.picList;
      for (let i = 0; i < IMGList.length; i++) {
        if (IMGList[i].picPath){
          this.getRequestBlob('/sys/fileStorage/baseIMG?path='+IMGList[i].picPath+'&type=MERTMP&time=' + new Date()).then(data => {
            this.IMGList[i].imageUrl = window.URL.createObjectURL(data);
            this.IMGList[i].picPath = IMGList[i].picPath;
          })
        }else{
          this.IMGList[i].imageUrl=''
          this.IMGList[i].picPath = '';
        }
      }

    },
    initSaPeAndMer(type){
      this.getRequest("/sys/mertmp/salePer",{belongAgencyNo:this.rowData.belongAgencyNo}).then(resp=> {
        if (resp && resp.status == 200) {
          this.saleperoptions=resp.obj
          if (type==1){
            this.rowData.expandMan=''
          }
        }
      });
      this.getRequest("/sys/mertmp/merInfo",{belongAgencyNo:this.rowData.belongAgencyNo}).then(resp=> {
        if (resp && resp.status == 200) {
          this.meroptions=resp.obj
          if (type==1){
            this.rowData.parentId=''
          }
        }
      });

    },
    initBank() {
      this.getRequest("/sys/bank/asyn",{type:0}).then(resp=> {
        if (resp && resp.status == 200) {
          this.bankoptions=resp.obj
        }
      });
    },
    initCity() {
      this.bankcnapsoptions=[{}]
      this.rowData.settleInfoTmp.accresCode=''
      this.rowData.settleInfoTmp.cnapsCode=''
      this.getRequest("/sys/bank/asyn",{type:1,bankCode:this.rowData.settleInfoTmp.bankCode}).then(resp=> {
        if (resp && resp.status == 200) {
          this.bankcityoptions=resp.obj
        }
      });
    },
    initCnaps() {
      this.rowData.settleInfoTmp.cnapsCode = ''
      this.getRequest("/sys/bank/asyn", {
        type: 2,
        bankCode: this.rowData.settleInfoTmp.bankCode,
        accresCode: this.rowData.settleInfoTmp.accresCode
      }).then(resp => {
        if (resp && resp.status == 200) {
          this.bankcnapsoptions = resp.obj
        }
      });
    },
    initCnapsDOM(){
      this.$forceUpdate();
    },
    areaHandleChange (value) {
      //这里可以把获取到的value值赋值给后台字段做存储使用
      //value是数组，如 绑定值：[ "330000", "330100", "330106" ]
      //只需要把区存到后台数据库即可，即取到value[2]即可，在数据回显时，便可以自动显示整个省市区
      //非常方便简介
      //CodeToText是个大对象，属性是区域码，属性值是汉字 用法例如：CodeToText['110000']输出北京市
      //TextToCode是个大对象，属性是汉字，属性值是区域码 用法例如：TextToCode['北京市'].code输出110000,TextToCode['北京市']['市辖区'].code输出110100,TextToCode['北京市']['市辖区']['朝阳区'].code输出110105
      //想获取到省市区中文的话，就这样写：CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
      if(value!=undefined){
        this.rowData.addressCode=value[2];
        this.rowData.address=CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
      }
    },
    branchAreaHandleChange (value) {
      if(value!=undefined){
        this.rowBranch.districtCode=value[2];
        this.rowBranch.districtName=CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
      }
    },
    otherAreaHandleChange(value) {
      if(value!=undefined){
        this.rowData.merInfoOtherTmp.busaddressCode=value[2];
        this.rowData.merInfoOtherTmp.districtCode=value[2];
        this.rowData.merInfoOtherTmp.businessAddress=CodeToText[value[0]]+CodeToText[value[1]]+CodeToText[value[2]];
        this.rowData.merInfoOtherTmp.districtName=CodeToText[value[2]];
      }
    },

    // 当用户输入内容开始远程搜索模糊查询的时候，会触发remoteMethod方法
    remoteMethod(query) {
      // 如果用户输入内容了，就发请求拿数据，远程搜索模糊查询
      if (query !== "") {
        this.mccloading = true; // 开始拿数据喽
        // 这里模拟发请求，res就当成发请求返回来的数据吧。
        this.getRequest("/sys/mcc/asyn",{parm:query}).then(resp=> {
          if (resp && resp.status == 200) {
            this.mcclist=resp.obj
            this.mccloading = false // 拿到数据喽
            this.mccoptions = this.mcclist.filter((item)=>{
              // indexOf等于0代表只要首个字匹配的，如：搜索 王 王小虎数据会被过滤出来，但是 小虎王的数据不会被过滤出来。因为indexOf等于0代表以什么开头
              // return item.label.toLowerCase().indexOf(query.toLowerCase()) == 0
              // indexOf大于-1代表的是，只要有这个字出现的即可，如：搜索 王 王小虎、小虎王、小王虎都会被过滤出来。因为indexOf找不到才会返回-1，
              // 大于-1说明只要有就行，不论是不是开头也好，中间也好，或者结尾也好
              return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
            })
          }
        });

      } else {
        this.mccoptions = [];
      }
    },
    changeMcc(val){
      this.rowData.mccid=val.value
      this.rowData.mccidName=val.label
    },

    getDICKV(e, type) {
      for (const key in this.DICKV) {
        if (key == type) {
          for (let i = 0; i < this.DICKV[key].length; i++) {
            let j = parseInt(this.DICKV[key][i].v); //j 就是数值类型了
            if (j == e ) { //dictValue，dictLabel保持和上面定义一致
              return this.DICKV[key][i].k;
            }
          }
        }
      }
    },
    showEditProRate(row){
      this.merRateDialog=true
      this.merRate=row
      this.merRate.relationId=this.tableRadio.id
    },
    closeMerRateDialog(){
      this.$confirm('确认取消？')
          .then(() => {
            this.merRateDialog=false
          })
          .catch(() => {
          });
    },
    submitMerRateForm(){
      // let Rate=this.merRate
      // if (Rate.lowLimit>Rate.topLimit){
      //   Message.error("借记区间设置错误："+Rate.lowLimit+"--"+Rate.topLimit)
      //   return false;
      // }
      // if (Rate.lowLimitCredit>Rate.topLimitCredit){
      //   Message.error("贷记区间设置错误："+Rate.lowLimitCredit+"--"+Rate.topLimitCredit)
      //   return false;
      // }
      this.$refs.merRateForm.validate((valid) => {
        if (valid) {
          this.postRequest('/sys/mertmp/merRate', this.merRate).then(data => {
            if (data.status == 200) {
              Message.success("更新成功")
              this.merRateDialog=false
              this.merRate={}
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          return false;
        }
      })
    },
    //将上传图片的原路径赋值给临时路径
    handleChange(file, fileList,data) {
      switch (data.type){
        case 'A':
          this.IMGList[0].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'B':
          this.IMGList[1].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'C':
          this.IMGList[2].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'D':
          this.IMGList[3].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'E':
          this.IMGList[4].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'F':
          this.IMGList[5].tempUrl = URL.createObjectURL(file.raw);
          break

        case 'G':
          this.IMGList[6].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'H':
          this.IMGList[7].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'I':
          this.IMGList[8].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'J':
          this.IMGList[9].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'K':
          this.IMGList[10].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'L':
          this.IMGList[11].tempUrl = URL.createObjectURL(file.raw);
          break

        case 'M':
          this.IMGList[12].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'N':
          this.IMGList[13].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'O':
          this.IMGList[14].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'P':
          this.IMGList[15].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'Q':
          this.IMGList[16].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'R':
          this.IMGList[17].tempUrl = URL.createObjectURL(file.raw);
          break

        case 'S':
          this.IMGList[18].tempUrl = URL.createObjectURL(file.raw);
          break
        case 'T':
          this.IMGList[19].tempUrl = URL.createObjectURL(file.raw);
          break
      }
    },
//实现图片上传功能
    httpRequest(item) {
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size < 1800 * 1024;/// 1024 / 1024 < 2
      if (!isJPG) {
        Message.error('上传图片只能是 JPG 或 PNG 格式!')
        return false
      }
      if (!isLt2M) {
        Message.error( '上传图片大小不能超过 1.8MB!')
        return false

      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        // post上传图片
        let App = this;
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        mf.append('type', item.data.type);
        mf.append('path', 'tmp');
        mf.append('relationId', this.rowData.id);
        App.postRequest('/sys/fileStorage/img',mf).then(result => {
          Message.success( result.message )
          this.imgtype(item.data.type,result)
        })
      }
    },
     imgtype(type,result){
      //将临时文件路径赋值给显示图片路径（前端显示的图片）
      //将后台传来的数据库图片路径赋值给car对象的图片路径
      switch (type){
        case 'A':
          this.IMGList[0].imageUrl = this.IMGList[0].tempUrl;
          this.IMGList[0].picPath = result.picPath;
          break
        case 'B':
          this.IMGList[1].imageUrl = this.IMGList[1].tempUrl;
          this.IMGList[1].picPath = result.picPath;
          break
        case 'C':
          this.IMGList[2].imageUrl = this.IMGList[2].tempUrl;
          this.IMGList[2].picPath = result.picPath;
          break
        case 'D':
          this.IMGList[3].imageUrl = this.IMGList[3].tempUrl;
          this.IMGList[3].picPath = result.picPath;
          break
        case 'E':
          this.IMGList[4].imageUrl = this.IMGList[4].tempUrl;
          this.IMGList[4].picPath = result.picPath;
          break
        case 'F':
          this.IMGList[5].imageUrl = this.IMGList[5].tempUrl;
          this.IMGList[5].picPath = result.picPath;
          break
        case 'G':
          this.IMGList[6].imageUrl = this.IMGList[6].tempUrl;
          this.IMGList[6].picPath = result.picPath;
          break
        case 'H':
          this.IMGList[7].imageUrl = this.IMGList[7].tempUrl;
          this.IMGList[7].picPath = result.picPath;
          break
        case 'I':
          this.IMGList[8].imageUrl = this.IMGList[8].tempUrl;
          this.IMGList[8].picPath = result.picPath;
          break
        case 'J':
          this.IMGList[9].imageUrl = this.IMGList[9].tempUrl;
          this.IMGList[9].picPath = result.picPath;
          break
        case 'K':
          this.IMGList[10].imageUrl = this.IMGList[10].tempUrl;
          this.IMGList[10].picPath = result.picPath;
          break
        case 'L':
          this.IMGList[11].imageUrl = this.IMGList[11].tempUrl;
          this.IMGList[11].picPath = result.picPath;
          break
        case 'M':
          this.IMGList[12].imageUrl = this.IMGList[12].tempUrl;
          this.IMGList[12].picPath = result.picPath;
          break
        case 'N':
          this.IMGList[13].imageUrl = this.IMGList[13].tempUrl;
          this.IMGList[13].picPath = result.picPath;
          break
        case 'O':
          this.IMGList[14].imageUrl = this.IMGList[14].tempUrl;
          this.IMGList[14].picPath = result.picPath;
          break
        case 'P':
          this.IMGList[15].imageUrl = this.IMGList[15].tempUrl;
          this.IMGList[15].picPath = result.picPath;
          break
        case 'Q':
          this.IMGList[16].imageUrl = this.IMGList[16].tempUrl;
          this.IMGList[16].picPath = result.picPath;
          break
        case 'R':
          this.IMGList[17].imageUrl = this.IMGList[17].tempUrl;
          this.IMGList[17].picPath = result.picPath;
          break
        case 'S':
          this.IMGList[18].imageUrl = this.IMGList[18].tempUrl;
          this.IMGList[18].picPath = result.picPath;
          break
        case 'T':
          this.IMGList[19].imageUrl = this.IMGList[19].tempUrl;
          this.IMGList[19].picPath = result.picPath;
          break
      }
    },
    initAgency(){
      this.getRequest("/sys/mertmp/agency").then(resp=> {
        if (resp && resp.status == 200) {
          this.allAgency=resp.obj
        }
      });
    },


    initR(parm){
      if (this.permissions.GET == 1) {
        this.rateloading = true
        this.getRequest("/sys/mertmp/merRateMenu", parm).then(data => {
          if (data) {
            this.rmenus = data.obj
            this.rateloading = false
          }
        })
      } else {
        Message.error("权限不足，请联系管理员")
      }
    },
    AddBranch(){
      this.rowBranch={}
      this.merBranchDialog=true
    },
    eduitBranchRow(rowData) {
      this.rowBranch = {}
      this.rowBranch = rowData
      this.eduitBranchDialog = true
    },
    closeBranchDia(){
      this.$confirm('确认取消？')
          .then(() => {
            this.rowBranch={}
            this.merBranchDialog=false
            this.eduitBranchDialog=false
            this.initBranch()
          })
          .catch(() => {
          });
    },
    submitAddBranchForm(){
      this.rowBranch.relationId=this.rowData.id
      this.$refs.addBranchForm.validate((valid) => {
        if (valid) {
          this.postRequest("/sys/mertmp/merBranch", this.rowBranch).then(resp=> {
            let data = resp.data;
            if (resp && resp.status == 200) {
              Message.success("成功")
              this.merBranchDialog=false
              this.initBranch();
            }else{
              Message.error({message: data.msg + '(' + data.status + ')'})
              return false;
            }
          });
        }else{
          return false;
        }
      })
    },
    submitEduitBranchForm(){
      this.rowBranch.relationId=this.rowData.id
      this.$refs.eduitBranchForm.validate((valid) => {
        if (valid) {
          this.putRequest("/sys/mertmp/merBranch", this.rowBranch).then(resp=> {
            let data = resp.data;
            if (resp && resp.status == 200) {
              Message.success("成功")
              this.eduitBranchDialog=false
              this.initBranch();
            }else{
              Message.error({message: data.msg + '(' + data.status + ')'})
              return false;
            }
          });
        }else{
          return false;
        }
      })
    },
    initBranch(){
      this.pm.pm.relationId=this.rowData.id
      this.getRequest('/sys/mertmp/merBranch', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.rowData.merBranchInfoTmp = data.obj.list
          this.pm.page = data.obj.page
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })

    },
    deleteBranchRow(row) {
      this.deleteRequest('/sys/mertmp/merBranch', row).then(data => {
        this.loading = false
        if (data.status == 200) {
          Message.success(data.msg)
          this.initBranch();
        } else {
          Message.error({message: data.msg + '(' + data.status + ')'})
        }
      })
    },
    handleSizeChange(val) {
      this.pm.page.pageNum = 1
      this.pm.page.pageSize = val
      this.initBranch()
    },
    handleCurrentChange(val) {
      this.pm.page.pageNum = val
      this.initBranch()
    },
    eduitProRow(row) {
      row.htmlFromType="ediut"
      this.merProCDialog = true
      this.$nextTick(() => {
        this.$refs.MTPC.$emit('initProC', row)
      })
    },
    close(){
      this.merProCDialog=false
      this.merTermDialog=false
    },
    eduitAffirm(){
      let ref=this.$refs.addForm5
      ref.validate((valid) => {
        if (valid) {
          this.postRequest("/sys/mertmp/merCommit", this.rowData).then(resp=> {
            if (resp && resp.status == 200) {
              Message.success("成功提交")
              this.cleanAll();
              this.$emit('colseFaDia');
            }else{
              Message.error({message: resp.msg})
            }
          });

        } else {
          Message.error({message: '信息未完整录入'})
          return false;
        }
      })

    },
    //门店绑定终端
    eduitTermRow(row){
      row.expandMan=this.rowData.expandMan
      row.agencyNo=this.rowData.belongAgencyNo
      row.htmlFromType="ediut"
      this.merTermDialog=true
      this.$nextTick(() => {
        this.$refs.merterm.$emit('init', row)
      })
    },
    next(){
      this.$forceUpdate();
      if (this.index == 3 && this.rowData.merBranchInfoTmp.length==0) {
        Message.error({message: '请添加部门' })
        return false;
      }
      else {
        let ref=this.$refs.addForm0
        switch (this.index){
          case 0:
            ref=this.$refs.addForm0
            break;
          case 1:
            ref=this.$refs.addForm1
            break;
            // case 2:
            //   ref=this.$refs.addForm2
            //   break;
            // case 3:
            //   ref=this.$refs.addForm3
            //   break;
          case 4:
            ref=this.$refs.addForm4
            this.rowData.picList = this.IMGList;
            break;
          case 5:
            ref=this.$refs.addForm5
            break;
        }
        if (this.index<=5){
          ref.validate((valid) => {
            if (valid) {
              this.rowData.index = this.index;
              this.postRequest("/sys/mertmp", this.rowData).then(resp=> {
                if (resp && resp.status == 200) {
                  Message.success("成功")
                  this.dialogVisible = false;
                  this.rowData.id=resp.obj
                  if (this.index==5){
                    this.cleanAll();
                    this.$emit('colseFaDia');
                  }else{
                    this.index ++;
                    if (this.index==2) {
                      this.tableRadio.relationId = this.rowData.id;
                      this.tableRadio.id= this.rowData.id;
                      this.tableRadio.belongAgencyNo= this.rowData.belongAgencyNo;
                      this.initR(this.tableRadio)
                    }
                  }
                }else{
                  Message.error({message: resp.msg + '(' + resp.status + ')'})
                  return false;
                }
              });
            } else {
              Message.error({message: '信息未完整录入'})
              return false;
            }
          })
          if(this.index==3){
            this.rowData.relationId = this.rowData.id;
            this.initBranch()
          }
        }else{
          this.index ++;
        }
      }
    },

  }
}
</script>


<style scoped>
.avatar-uploader {
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 150px;
  height: 150px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
}

</style>
